import { SubscriptionPlanCapabilities } from '~/services/Discovery.types'
import { useGetOrganisation } from './useGetOrganisation'

type HookResponse = Partial<SubscriptionPlanCapabilities>
type UseGetSubscriptionPlanCapabilities = () => HookResponse

export const useGetSubscriptionPlanCapabilities: UseGetSubscriptionPlanCapabilities =
  () => {
    const { subscriptionPlan } = useGetOrganisation()

    if (subscriptionPlan) return subscriptionPlan.capabilities || {}

    return {}
  }
