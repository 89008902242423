export type PayloadCreateOrganisation = {
  name: string
  organisationId: GUID
}

export type PayloadDeleteGenerationStrategy = {
  generationStrategyId: GUID
  organisationId: GUID
}

export type ResponseListGenerationStrategies = {
  identity: GUID
  name: string
  type: string
  description: string
}

export type ResponseGetGenerationStrategy = {
  id: GUID
  name: string
  description: string
  directoryItems: FolderOrFileStructure[]
}

const PROPERTY_TYPES = {
  CollectionPropertyBinding: 'CollectionPropertyBinding',
  ObjectPropertyBinding: 'ObjectPropertyBinding',
  TextPropertyBinding: 'TextPropertyBinding',
  TextSection: 'TextSection',
  ValuePropertyBinding: 'ValuePropertyBinding',
} as const

export type PropertyTypes = keyof typeof PROPERTY_TYPES

export type SectionType = {
  /** The section type. */
  _t: string
  /** Indicates if the section has delimiters. */
  hasDelimiter: boolean
  /** Indicates separator between children nodes. */
  separator: string
}

const VALUE_PROPERTY_BINDING_PROPERTY_NAME = [
  'FilePath',
  'Template',
  'Content',
  'Directory',
  'FileName',
  'ModelType',
  'AllowRewrite',
  'TargetFramework',
  'ShouldGenerateProject',
  'Name',
  'ReturnType',
  'Body',
  'PackageName',
  'Source',
  'Version',
  'Path',
  'Namespace',
  'Global',
  'Type',
  'LiteralExpression',
  'IsUnitTest',
  'BaseInitializer',
  'ThisInitializer',
  'ClassName',
  '',
] as const

export type ValuePropertyBindingPropertyName =
  (typeof VALUE_PROPERTY_BINDING_PROPERTY_NAME)[number]

type CommonStructure = {
  /** The binding context. */
  bindingContext?: never
  /** Node ID. */
  id: GUID
  /** Mapping condition. */
  mappingCondition?: string | null
  name: string
  path: string
}

export type FolderStructure = CommonStructure & {
  children: (FileStructure | FolderStructure)[]
  // FileStructure
  fileId?: never
  pathBinding?: never
  propertyBindings?: never
  propertyName?: never
  fileBinding?: never
}

export type FileStructure = CommonStructure & {
  // FolderStructure
  children?: never
  fileId: GUID
  pathBinding: {
    id: GUID
    parameters: unknown[]
    value: string
  }
  propertyBindings: PropertyBinding[]
  propertyName?: ValuePropertyBindingPropertyName
  fileBinding: PropertyBinding
}

export type FolderOrFileStructure = FolderStructure | FileStructure

export type ValuePropertyBinding = CommonStructure & {
  /** The node type. */
  _t: 'ValuePropertyBinding'
  /** The node children. */
  children?: never
  /** The content. */
  content?: never
  /** The text section delimiters. */
  delimiter?: never
  /** The node ID. */
  id: GUID
  /** The values of a `CollectionPropertyBinding`. */
  mappedValues?: never
  /** The original value. */
  originalValue: string | null
  /** The parameters. */
  parameters: Array<{
    path: string
  }>
  /** The sub-nodes of an `ObjectPropertyBinding`. */
  propertyBindings?: never
  /** The property name. */
  propertyName: ValuePropertyBindingPropertyName
  /** The property type. */
  propertyType: string
  /** The node separator. */
  separator?: never
  /** The node section type. */
  sectionType?: never
  /** The target type. */
  targetType?: never
  /** The current value. */
  value: string
}

export type TextPropertyBinding = CommonStructure & {
  /** The node type. */
  _t: 'TextPropertyBinding'
  /** The node children. */
  children?: never
  /** The content. */
  content?: TextSection
  /** The text section delimiters. */
  delimiter?: never
  /** The node ID. */
  id: GUID
  /** The values of a `CollectionPropertyBinding`. */
  mappedValues?: never
  /** The original value. */
  originalValue?: never
  /** The parameters. */
  parameters?: never
  /** The sub-nodes of an `ObjectPropertyBinding`. */
  propertyBindings?: never
  /** The property name. */
  propertyName: ObjectPropertyBindingPropertyName
  /** The property type. */
  propertyType?: never
  /** The node separator. */
  separator?: never
  /** The node section type. */
  sectionType?: never
  /** The target type. */
  targetType: string
  /** The current value. */
  value?: never
}

export enum TextSectionDelimitersEnum {
  ANGLED_BRACKETS = 'AngledBrackets',
  BRACES = 'Braces',
  NONE = '',
  PARENTHESIS = 'Parenthesis',
  SQUARE_BRACKETS = 'SquareBrackets',
}

type TextSectionDelimiter = {
  /** The delimiter type. */
  _t: TextSectionDelimitersEnum
  /** The start delimiter. */
  start?: string
  /** The end delimiter. */
  end?: string
}

export type TextSection = CommonStructure & {
  /** The node type. */
  _t: 'TextSection'
  /** The node children. */
  children?: TextSection[]
  /** The content. */
  content?: never
  /** The text section delimiters. */
  delimiter?: TextSectionDelimiter
  /** The node ID. */
  id: GUID
  /** The values of a `CollectionPropertyBinding`. */
  mappedValues?: never
  /** The original value. */
  originalValue?: string
  /** The parameters. */
  parameters: Array<{
    path: string
  }>
  /** The sub-nodes of an `ObjectPropertyBinding`. */
  propertyBindings?: never
  /** The property name. */
  propertyName: ObjectPropertyBindingPropertyName
  /** The property type. */
  propertyType?: never
  /** The node separator. */
  separator?: string
  /** The node section type. */
  sectionType?: SectionType
  /** The target type. */
  targetType?: never
  /** The current value. */
  value?: string
}

const OBJECT_PROPERTY_BINDING_PROPERTY_NAME = [
  'ClassDefinition',
  'ProjectType',
  'BaseInitialiser',
  'Delimiter',
  '',
] as const

export type ObjectPropertyBindingPropertyName =
  (typeof OBJECT_PROPERTY_BINDING_PROPERTY_NAME)[number]

export type ObjectPropertyBinding = CommonStructure & {
  /** The node type. */
  _t: 'ObjectPropertyBinding'
  /** The node children. */
  children?: never
  /** The content. */
  content?: never
  /** The node ID. */
  id: GUID
  /** The values of a `CollectionPropertyBinding`. */
  mappedValues?: never
  /** The original value. */
  originalValue?: never
  /** The parameters. */
  parameters?: never
  /** The sub-nodes of an `ObjectPropertyBinding`. */
  propertyBindings: PropertyBinding[]
  /** The property name. */
  propertyName: ObjectPropertyBindingPropertyName
  /** The property type. */
  propertyType?: never
  /** The node separator. */
  separator?: never
  /** The node section type. */
  sectionType?: never
  /** The target type. */
  targetType: string
  /** The current value. */
  value?: never
}

const COLLECTION_PROPERTY_BINDING_PROPERTY_NAME = [
  'Miscellaneous',
  'Projects',
  'Classes',
  'Interfaces',
  'Dependencies',
  'Constructors',
  'Attributes',
  'NamespaceAttributes',
  'Modifiers',
  'Methods',
  'Usings',
  'Fields',
  'Properties',
  'Collaborators',
  'BaseTypes',
  'GenericTypes',
  'Modifiers',
  'Statements',
  'Parameters',
  'Children',
  'Sections',
] as const

export type CollectionPropertyBindingPropertyName =
  (typeof COLLECTION_PROPERTY_BINDING_PROPERTY_NAME)[number]

export type CollectionMappedValuesType =
  | ObjectPropertyBinding[]
  | ValuePropertyBinding[]
  | TextPropertyBinding[]

export type CollectionPropertyBinding = CommonStructure & {
  /** The node type. */
  _t: 'CollectionPropertyBinding'
  /** The node children. */
  children?: never
  /** The content. */
  content?: never
  /** The node ID. */
  id: GUID
  /** The values of a `CollectionPropertyBinding`. */
  mappedValues: CollectionMappedValuesType
  /** The original value. */
  originalValue?: never
  /** The parameters. */
  parameters?: never
  /** The sub-nodes of an `ObjectPropertyBinding`. */
  propertyBindings?: never
  /** The property name. */
  propertyName: CollectionPropertyBindingPropertyName
  /** The property type. */
  propertyType?: never
  /** The node separator. */
  separator?: never
  /** The node section type. */
  sectionType?: never
  /** The target type. */
  targetType: string
  /** The current value. */
  value?: never
}

export type PropertyBinding =
  | ValuePropertyBinding
  | ObjectPropertyBinding
  | CollectionPropertyBinding
  | TextPropertyBinding
  | TextSection

export type ResponseListParametersByGenerationId = {
  /**
   * The depth of the parameter: used to group the
   * parameters as a hierarchy, for example:
   * `0 depth`
   *    `1 depth`
   */
  depth: number
  /** The label text to be rendered. */
  label: string
  /** The parameter ID. */
  id: GUID
  /**
   * Indicates if it is a grouping data:
   * groups the following items.
   */
  isGrouping: boolean
  /** The unique key related to the parameter. */
  key: string | null
  /** The token. */
  token: string | null
}

// Payload types.
export type PayloadCreateCollectionItem = {
  fileId: GUID
  propertyBindingId: GUID
}

export type PayloadDeleteFilePath = {
  generationStrategyId: GUID
  filePath: string
}

export type PayloadDeletePropertyBinding = {
  generationStrategyId: GUID
  fileId: GUID
  propertyBindingId: GUID
}

export type PayloadLoadGitRepository = {
  generationStrategyId: GUID
  authenticationToken: string
  repositoryUrl: string
}

export type PayloadReplaceTokens = {
  generationStrategyId: GUID
  fileId: GUID
  domainDictionaryEntries: ResponseListParametersByGenerationId[]
}

export type PayloadUpdatePropertyValue = {
  generationStrategyId: GUID
  fileId: GUID
  propertyBindingId: GUID
  value: string
  parameters: string[]
}

export type PayloadUpdatePropertyBindingContext = {
  fileId: GUID
  propertyBindingId: GUID
  bindingContext: string | null
}

export type PayloadUpdatePropertyMappingCondition = {
  fileId: GUID
  propertyBindingId: GUID
  condition: string | null
}

export type PayloadUpdateStrategyFilePaths = {
  generationStrategyId: GUID
  rootPath?: string
}

export type PayloadUpdateStrategyTokens = {
  generationStrategyId: GUID
  domainDictionaryEntries: Array<{ key: string | null; token: string | null }>
}

export type PayloadUpdateTextSection = {
  bindingContext: string
  delimiterType: TextSectionDelimitersEnum
  fileId: GUID
  mappingCondition: string
  parameters: string[]
  propertyBindingId: GUID
  separator: string
  value: string
}
