import { FolderOrFileStructure } from '~/services/GenerationStrategy.types'

export function getFolderIds(data?: FolderOrFileStructure[]): string[] {
  if (!Array.isArray(data)) {
    return ['root']
  }

  let folderIds: string[] = []

  data.forEach((node) => {
    if (node.children) {
      folderIds.push(node.id)
    }

    if (node.children) {
      folderIds = folderIds.concat(getFolderIds(node.children))
    }
  })

  return ['root', ...folderIds].filter((item) => item !== 'root').concat('root')
}

export function getFileIds(data?: FolderOrFileStructure[]): string[] {
  if (!data) {
    return []
  }

  let fileIds: string[] = []

  data.forEach((node) => {
    if (!node.children) {
      fileIds.push(node.id)
    }

    if (node.children) {
      fileIds = fileIds.concat(getFileIds(node.children))
    }
  })

  return fileIds.filter((item) => item !== 'root')
}

export function getNodeById(
  targetId?: string,
  data?: FolderOrFileStructure,
): FolderOrFileStructure | null {
  if (!data || !targetId) {
    return null
  }

  if (data.id === targetId) {
    return data
  }

  if (data.children) {
    for (const child of data.children) {
      const result = getNodeById(targetId, child)
      if (result) {
        return result
      }
    }
  }

  return null
}

export function findObjectById(
  root: any,
  idForPropertyBindings: string,
): any | undefined {
  if (root.propertyBindings) {
    for (const binding of root.propertyBindings) {
      if (binding.id === idForPropertyBindings) {
        return binding
      }

      if ('propertyBindings' in binding) {
        const result = findObjectById(binding, idForPropertyBindings)
        if (result) {
          return result
        }
      }

      if ('mappedValues' in binding) {
        for (const mappedValue of binding.mappedValues) {
          const result = findObjectById(mappedValue, idForPropertyBindings)
          if (result) {
            return result
          }
        }
      }
    }
  }

  return undefined
}

export function generatePaths(
  node: FolderOrFileStructure,
  parentPath: string = '',
): FolderOrFileStructure {
  const currentPath = parentPath ? `${parentPath}/${node.name}` : node.name

  const newNode: FolderOrFileStructure = {
    ...node,
    path: currentPath,
  }

  if (node.children) {
    newNode.children = node.children.map((child) =>
      generatePaths(child, currentPath),
    )
  }

  return newNode
}

export function getAllPropertyIdsByFileId(
  propertyBindings: any,
  result: any[] = [],
) {
  if (!Array.isArray(propertyBindings)) {
    return result
  }

  for (const binding of propertyBindings) {
    if (binding.propertyBindings && binding.propertyBindings.length) {
      // Recursive call for nested propertyBindings
      getAllPropertyIdsByFileId(binding.propertyBindings, result)
    }

    // Add IDs to the result array
    if (binding.id) {
      result.push(binding.id)
    }

    // Check for mappedValues and collect IDs from objects with propertyBindings
    if (binding.mappedValues && binding.mappedValues.length) {
      result.push(binding.id)
      for (const objectProperty of binding.mappedValues) {
        if (
          objectProperty.propertyBindings &&
          objectProperty.propertyBindings.length
        ) {
          // grab this object id
          result.push(objectProperty.id)
          // Collect IDs from objects inside mappedValues with propertyBindings
          for (const obj of objectProperty.propertyBindings) {
            if (obj.id) {
              result.push(obj.id)
            }
          }
        }
      }
    }
  }

  return result
}
