import { platforms } from '~/config'
import type { PlatformByOrganisation } from '~/services/PlatformTeam.types'

type PlatformSetupWizardStep = {
  /** The step ID. */
  id: number
  /** The step label name. */
  label: string
  /** The step content. */
  content?: string | null
}

/**
 * Provides the wizard steps for the
 * platform setup.
 * @param dataPlatform Platform data.
 */
export const getPlatformSetupWizardSteps = (
  dataPlatform: PlatformByOrganisation | null,
): Array<PlatformSetupWizardStep> => [
  {
    id: 1,
    label: 'Cloud Provider',
    content: dataPlatform?.cloudPlatform
      ? platforms.find(
          (platform) => platform.identity === dataPlatform?.cloudPlatform,
        )?.name
      : undefined,
  },
  {
    id: 2,
    label: 'Messaging Technology',
    content: dataPlatform?.routingStrategy
      ? platforms
          .find((platform) => platform.identity === dataPlatform?.cloudPlatform)
          ?.routingStrategies.find(
            (item) => item.identity === dataPlatform?.routingStrategy,
          )?.name
      : undefined,
  },
  {
    id: 3,
    label: 'Front-end Hosting',
    content: dataPlatform?.frontEndHosting
      ? platforms
          .find((platform) => platform.identity === dataPlatform?.cloudPlatform)
          ?.frontend.hostingStrategies.find(
            (item) => item.identity === dataPlatform?.frontEndHosting,
          )?.name
      : undefined,
  },
  {
    id: 4,
    label: 'Git Repository',
    content: dataPlatform?.gitRepositoryUrl,
  },
]
