import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { queryKeyUsersByOrganisation } from '~/services/Discovery'
import { PayloadRemoveUser } from '~/services/Discovery.types'
import {
  DiscoveryOrganisationAPI,
  serviceDiscoveryOrganisation,
} from '~/services/base'
// import { DiscoveryOrganisationAPI, serviceDiscoveryProcess } from '~/services/base'

export const actionConfigurationOrganisationUserDelete =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const { organisationId, platformId } = params
    const formData = await request.formData()
    const formEntries = Object.fromEntries(formData)
    // Types below are needed because FormDataEntryValue can be a string or a File
    const email = formEntries.userId as string | undefined

    invariant(email, 'Email is required')
    invariant(organisationId, 'Organisation ID is required')

    const postData = {
      email,
      organisationId,
    } satisfies PayloadRemoveUser

    const url = DiscoveryOrganisationAPI.RemoveUser
    const req = await serviceDiscoveryOrganisation.post(url, postData)

    if (req.status === 200) {
      await queryClient.invalidateQueries({
        queryKey: queryKeyUsersByOrganisation(organisationId),
      })
    }

    return redirect(`/${organisationId}/${platformId}/configuration/users`)

    // return null
  }
