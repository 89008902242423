import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, generatePath, redirect } from 'react-router-dom'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { queryKeyDevelopment } from '~/services/Development'
import { queryOrganisation } from '~/services/Discovery'
import { getUserEmail } from '~/utils/getUserEmail'
import { getLocalUserId } from '~/utils/getUserId'

export const actionDeveloperPublishCommand =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const userId = getLocalUserId(pca)
    const email = getUserEmail(pca)

    const { aggregateId, commandId, organisationId, platformId, processId } =
      params

    const qOrganisation = queryOrganisation(userId, email)
    const fetchOrganisation = await queryClient.ensureQueryData(qOrganisation)
    const ecosystemName = fetchOrganisation.data.ecosystemName

    const formData = await request.formData()
    const commitMessage = formData.get('commitMessage')
    const branch = formData.get('branch')

    // DTO to send data
    const postData = {
      aggregateId,
      commandId,
      developerId: userId,
      commitMessage,
      ecosystemName,
      branch,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.PublishCommand
    const req = await serviceDevelopmentAggregate.post(url, postData)
    const requestUrl = new URL(request.url)
    const isBusiness = requestUrl.pathname.includes('business')

    if (req.status === 200) {
      await queryClient.refetchQueries({
        queryKey: queryKeyDevelopment(platformId),
      })

      if (isBusiness) {
        return redirect(
          generatePath(BusinessRoutesEnum.BUSINESS_PROCESS, {
            organisationId: organisationId || '',
            platformId: platformId || '',
            processId: processId || '',
          }),
        )
      }
      return redirect(
        `/${organisationId}/${platformId}/developer/${aggregateId}`,
      )
    }

    throw req
  }
