import type { CodeGenerationStrategyBaseProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyBaseProps'
import type { RenderTextSectionNodeProps } from '~/models/types/components/codeGenerationStrategy/RenderTextSectionNodeProps'
import type { TextSection } from '~/services/GenerationStrategy.types'
import { RenderTextSectionNode } from './RenderTextSectionNode'
import { replaceValueTabs } from './RenderTextSections.utils'

export type RenderTextSectionProps = CodeGenerationStrategyBaseProps &
  Pick<RenderTextSectionNodeProps, 'onDeletePropertyBindingClick'> & {
    /**
     * The current node.
     * This will always be the node
     * immediately under the
     * `TextPropertyBinding` node.
     */
    node: TextSection
    /** The array of child nodes. */
    nodes?: TextSection[]
  }

/**
 * Render contents of a `TextSection` nodes.
 */
export const RenderTextSections = (props: RenderTextSectionProps) => {
  const { fileId, node, nodes, ...rest } = props
  const { id: parentId, sectionType: parentSectionType } = node

  if (!nodes || !nodes?.length) return null

  return (
    <div className="whitespace-pre">
      {nodes.map((currNode, index) => (
        <RenderTextSectionNode
          fileId={fileId}
          key={currNode.id}
          node={replaceValueTabs(currNode)}
          parent={parentId}
          parentSectionType={parentSectionType}
          siblings={nodes.map(({ id }: TextSection) => id)}
          shouldRenderSeparator={
            index !== nodes.length - 1 && index !== nodes.length - 2
          }
          {...rest}
        />
      ))}
    </div>
  )
}
