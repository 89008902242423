import { useMutation, useQueryClient } from '@tanstack/react-query'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import type { MoveStatementMutateProps } from '~/models/types/api/business/MoveStatementMutateProps'
import type { PayloadMoveStatement } from '~/models/types/api/business/PayloadMoveStatement'
import type { UseMutateStatementsBaseProps } from '~/models/types/api/business/UseMutateStatementsBaseProps'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import { DiscoveryProcessAPI, serviceDiscoveryProcess } from '~/services/base'
import { queryKeyProcess } from '~/services/Process'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

export type UseMoveStatementProps = Pick<
  UseMutateStatementsBaseProps,
  'process'
>

/**
 * Hook that implements react query `mutation`
 * to move a statement.
 */
export const useMoveStatement = (props: UseMoveStatementProps) => {
  const { process } = props

  // React Router Dom.
  const queryClient = useQueryClient()

  const processId = process.identity

  // The `useMutation` implementation.
  const {
    error: mutationError,
    isError,
    mutate,
    ...restMutation
  } = useMutation({
    mutationFn: ({
      statementId,
      referenceStatementId,
      option,
    }: MoveStatementMutateProps) => {
      invariant(processId, ERROR_MESSAGES.processId)

      const url = DiscoveryProcessAPI.MoveStatement

      const postData: PayloadMoveStatement = {
        processId,
        statementId,
        referenceStatementId,
        option,
      }

      return serviceDiscoveryProcess.post(url, postData)
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeyProcess(process.identity),
      }),
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError, mutate }
}
