/**
 * Enum with the form fields names
 * from `value binding tree item` form.
 */
export enum ValueBindingTreeItemFormEnum {
  /** The binding context. */
  BINDING_CONTEXT = 'bidingContext',
  /** The delimiter field. */
  DELIMITER = 'delimiter',
  /** The file id. */
  FILE_ID = 'fileId',
  /** The node id. */
  ID = 'id',
  /** The mapping condition. */
  MAPPING_CONDITION = 'mappingCondition',
  /** The node. */
  NODE = 'node',
  /** Any parameters associated with the item. */
  PARAMETERS = 'parameters',
  /** The separator field. */
  SEPARATOR = 'separator',
  /** The value to be changed. */
  VALUE = 'value',
}
