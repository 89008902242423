import { IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { EyeIcon } from '~/components/Icons/EyeIcon'
import { Row } from '~/components/Row'
import { useGetProcess } from '~/hooks/useGetProcess'

export const StatementsTitle = () => {
  const navigate = useNavigate()
  const process = useGetProcess()

  return (
    <Row className="mb-2 items-center justify-center gap-2">
      <h5 className="m-0 border border-white text-2xl font-medium text-primary text-shadow">
        {process.name}
      </h5>
      <IconButton onClick={() => navigate('view-process')}>
        <EyeIcon />
      </IconButton>
    </Row>
  )
}
