import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { redirect } from 'react-router-dom'
import { queryOrganisation } from '~/services/Discovery'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderBusinessAddProcess =
  (queryClient: QueryClient, pca: PublicClientApplication) => async () => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)

    // if no user, redirect to login page
    if (!userId || !email) {
      return redirect('/')
    }

    const queryOrg = queryOrganisation(userId, email)
    const dataFetchedOrg = await queryClient.ensureQueryData(queryOrg)

    return {
      organisation: dataFetchedOrg,
    }
  }
