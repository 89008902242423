/**
 * The query and mutation keys
 * for react query.
 */
export enum QueryKeysEnum {
  /** Query key for `domain read model types.  */
  DOMAIN_READ_MODEL_TYPES = 'domainReadModelTypes',
  /** Mutation key for `load git repository`. */
  LOAD_GIT_REPOSITORY = 'loadGitRepository',
}
