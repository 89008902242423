import ReadMoreIcon from '@mui/icons-material/ReadMore'
import WrapTextIcon from '@mui/icons-material/WrapText'
import { Chip, List, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useGetSubscriptionPlanCapabilities } from '~/hooks/useGetSubscriptionPlanCapabilities'
import { ListAttributes } from '~/pages/developer/components/ListAttributes'
import { MenuMore } from '~/pages/developer/components/MenuMore'
import {
  ListItemForDeveloper,
  ListItemHeader,
} from '~/pages/developer/components/styled/ListItem'
import { AggregateReadModel } from '~/services/Development.types'

type ListReadModelsProps = {
  readModels: AggregateReadModel[]
}
export function ListReadModels({ readModels }: ListReadModelsProps) {
  const { codeGeneration: isCodeGenerationAllowed } =
    useGetSubscriptionPlanCapabilities()

  return (
    <>
      {readModels.length ? (
        <Stack mx={1}>
          <List>
            {readModels.map((readModel, index) => (
              <ListItemForDeveloper key={readModel.name + index.toString()}>
                <Stack sx={{ flexGrow: 1 }} gap={1}>
                  <ListItemHeader>
                    <Stack
                      direction="row"
                      sx={{ alignItems: 'center' }}
                      gap={1}
                    >
                      <ReadMoreIcon fontSize="small" />
                      <Typography>
                        <strong>{readModel.name}</strong>
                      </Typography>
                    </Stack>
                    <Stack gap={1} direction="row" alignItems="center">
                      {isCodeGenerationAllowed && (
                        <Chip
                          label={readModel.state.name}
                          color={
                            readModel.state.name === 'Published'
                              ? 'success'
                              : 'warning'
                          }
                          size="small"
                          variant="outlined"
                        />
                      )}
                      <MenuMore
                        linkPublish={`generate-code/query/${readModel.identity}`}
                        linkEdit={`edit-readmodel/${readModel.identity}`}
                        linkDelete={
                          readModel.state.name === 'Published'
                            ? undefined
                            : `delete-readmodel/${readModel.identity}`
                        }
                        type="Query"
                        eventId={readModel.identity}
                      />
                    </Stack>
                  </ListItemHeader>
                  <ListAttributes attributes={readModel.attributes} />
                  {readModel.queries.map((query, queryIndex) => (
                    <React.Fragment key={query.name + queryIndex.toString()}>
                      <Stack
                        key={query.name}
                        direction="row"
                        sx={{ alignItems: 'center', mt: 1 }}
                        gap={1}
                      >
                        <WrapTextIcon fontSize="small" />
                        <Typography variant="body2">{query.name}</Typography>
                      </Stack>
                      <ListAttributes
                        attributes={query.parameters}
                        title="Parameters"
                      />
                    </React.Fragment>
                  ))}
                </Stack>
              </ListItemForDeveloper>
            ))}
          </List>
        </Stack>
      ) : null}
    </>
  )
}
