import { LoadingButton } from '@mui/lab'
import { Tooltip } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import type { Node, NodeProps } from '@xyflow/react'
import { twMerge } from '^/tailwind.config'
import { useCallback, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import PropertiesIcon from '~/assets/icons/properties.svg?react'
import PublishedIcon from '~/assets/icons/rocket-approved.svg?react'
import PublishIcon from '~/assets/icons/rocket.svg?react'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { useMarkAStepAsFeature } from '~/hooks/api/business/useMarkAStepAsFeature'
import { useGetSubscriptionPlanCapabilities } from '~/hooks/useGetSubscriptionPlanCapabilities'
import { useLinkEditCommandOrReaction } from '~/hooks/useLinkEditCommandOrReaction'
import type { BusinessChildrenParams } from '~/routes/business/routes.types'
import { queryDevelopment } from '~/services/Development'
import type { DomainByPlatformId } from '~/services/Development.types'
import {
  STATEMENT_PARSING_INFO_TYPE,
  STATEMENT_STATE_NAME,
  type NodeStatement,
} from '~/services/Process.types'
import { contextButton } from '~/styles/components'
import { commandsByAggregate, reactionsByAggregate } from '~/utils/transform'
import {
  getEditTooltipIcon,
  getEditTooltipText,
  getMarkAsFeatureIcon,
  getMarkAsFeatureTooltipText,
} from './SideMenu.utils'

const DEFAULT_BUTTON_CLASS_NAMES =
  'relative inline-flex min-w-[auto] h-8 w-8 items-center justify-center text-sm'

const REFETCH_PLATFORM_TIME = 1000

type SideMenuProps = NodeProps<Node<NodeStatement>>

/**
 * Display additional options for a map step.
 */
export function SideMenu(props: SideMenuProps) {
  const { data, id } = props

  // Constants.
  const { isEditable, isFetching, setIsEditingMode } = data

  // States.
  // State to indicate the command/event is processing on background.
  const [isProcessing, setIsProcessing] = useState<boolean>()

  // React Router Dom.
  const params = useParams<BusinessChildrenParams>()

  // React Query.
  const queryClient = useQueryClient()

  // Hooks.
  const {
    error: markAStepAsFeatureError,
    isPending: isMarkAStepAsFeaturePending,
    mutate: markAStepAsFeatureMutate,
  } = useMarkAStepAsFeature()

  const linkEditCommandOrReaction = useLinkEditCommandOrReaction(data.type, id)

  const { codeGeneration: isCodeGenerationAllowed } =
    useGetSubscriptionPlanCapabilities()

  // Callbacks.
  const cbCommandsByAggregate = useCallback(
    (boundedContext: DomainByPlatformId) => commandsByAggregate(boundedContext),
    [],
  )
  const cbReactionsByAggregate = useCallback(
    (boundedContext: DomainByPlatformId) =>
      reactionsByAggregate(boundedContext),
    [],
  )

  // Query.
  const { data: dataDevelopment } = useQuery({
    ...queryDevelopment(params.platformId),
    refetchInterval: (query) => {
      if (data.isFeature && query?.state.data?.data) {
        // commands
        if (data.type === STATEMENT_PARSING_INFO_TYPE.Command) {
          // grab commands by aggregate
          const commands = cbCommandsByAggregate(query?.state.data?.data)
          // get the selected command in the card
          const selectedCommand = commands
            ? commands.find((command) => command.identity === id)
            : undefined

          // refetch only if the command isn't in the boundedContext yet
          if (!selectedCommand) return REFETCH_PLATFORM_TIME
        }

        // reactions
        // same logic as above but for reactions
        if (data.type === STATEMENT_PARSING_INFO_TYPE.Reaction) {
          const reactions = cbReactionsByAggregate(query?.state.data?.data)
          const selectedReactions = reactions
            ? reactions.find((reaction) => reaction.identity === id)
            : undefined

          if (!selectedReactions) return REFETCH_PLATFORM_TIME
        }
      }

      // Stop processing.
      if (isProcessing) setIsProcessing(false)
      // Stop refetch.
      return false
    },
  })

  // Processing `mark as feature`.
  const isProcessingMarkAsFeature = isProcessing || isMarkAStepAsFeaturePending

  // Memoized constants.
  const commands = useMemo(() => {
    if (dataDevelopment?.data) {
      return cbCommandsByAggregate(dataDevelopment?.data)
    }

    return
  }, [cbCommandsByAggregate, dataDevelopment?.data])

  const reactions = useMemo(() => {
    if (dataDevelopment?.data) {
      return cbReactionsByAggregate(dataDevelopment?.data)
    }

    return
  }, [cbReactionsByAggregate, dataDevelopment?.data])

  const selectedCommand = useMemo(() => {
    if (commands && data.type === STATEMENT_PARSING_INFO_TYPE.Command) {
      return commands.find((command) => command.identity === id)
    }

    return
  }, [commands, data.type, id])

  const selectedReaction = useMemo(() => {
    if (reactions && data.type === STATEMENT_PARSING_INFO_TYPE.Reaction) {
      return reactions.find((reaction) => reaction.identity === id)
    }

    return
  }, [reactions, data.type, id])

  // Marked as feature should also account for existing command/reaction.
  const markedAsFeature = useMemo(
    () => data.isFeature && (!!selectedCommand || !!selectedReaction),
    [data.isFeature, selectedCommand, selectedReaction],
  )

  const selectedAggregateId = useMemo(() => {
    if (selectedCommand) {
      return selectedCommand.aggregateId
    }
    if (selectedReaction) {
      return selectedReaction.aggregateId
    }

    return
  }, [selectedCommand, selectedReaction])

  const selectedEvent = useMemo(() => {
    if (selectedCommand) {
      return selectedCommand
    }
    if (selectedReaction) {
      return selectedReaction
    }

    return
  }, [selectedCommand, selectedReaction])

  const handleLinkToPublish = useMemo(
    () =>
      `${selectedAggregateId}/generate-code/${data.type?.toLocaleLowerCase()}/${selectedEvent?.identity}`,
    [data.type, selectedAggregateId, selectedEvent?.identity],
  )

  // Functions.
  function handleClickEdit() {
    if (setIsEditingMode) {
      setIsEditingMode((prev: boolean) => !prev)
    }
  }

  function preFetchDevelopment() {
    queryClient.refetchQueries(queryDevelopment(params.platformId || ''))
  }

  // Handler for mark as feature click.
  const handleMarkAsFeatureClick = () => {
    // Allow mutation in case it is not marked as feature
    // OR, there is a mutation error - to allow the user to retry the mutation.
    if (!markedAsFeature || !!markAStepAsFeatureError) {
      markAStepAsFeatureMutate(id)

      // Set processing.
      if (!isProcessing) setIsProcessing(true)
    }
  }

  return (
    <section className="absolute -top-[70px]">
      <div className="flex w-[230px] flex-row justify-center gap-2">
        {FEATURE_TOGGLE.BUSINESS.APPROVE_FOR_DEVELOPMENT && !data.hasError && (
          <Tooltip
            arrow
            placement="top"
            title={getMarkAsFeatureTooltipText({
              error: markAStepAsFeatureError,
              isProcessing: isProcessingMarkAsFeature,
              markedAsFeature,
            })}
          >
            <LoadingButton
              className={twMerge(
                contextButton,
                DEFAULT_BUTTON_CLASS_NAMES,
                'cursor-auto p-1 hover:bg-background-paper',
                (!markedAsFeature || !!markAStepAsFeatureError) &&
                  'flex cursor-pointer gap-1 text-xs hover:bg-background-business',
              )}
              loading={isProcessingMarkAsFeature}
              onClick={handleMarkAsFeatureClick}
            >
              {getMarkAsFeatureIcon({
                error: markAStepAsFeatureError,
                isProcessing: isProcessingMarkAsFeature,
                markedAsFeature,
              })}
            </LoadingButton>
          </Tooltip>
        )}

        {!!selectedEvent && markedAsFeature && isCodeGenerationAllowed && (
          <>
            {linkEditCommandOrReaction ? (
              <Tooltip title="Define inputs and outputs" arrow placement="top">
                <Link
                  to={linkEditCommandOrReaction}
                  className={twMerge(
                    contextButton,
                    DEFAULT_BUTTON_CLASS_NAMES,
                    'py-0',
                  )}
                  onMouseEnter={preFetchDevelopment}
                >
                  <PropertiesIcon />
                </Link>
              </Tooltip>
            ) : null}

            {!!FEATURE_TOGGLE.BUSINESS.PUBLISH && (
              <Tooltip title="Generate code" arrow placement="top">
                <Link
                  to={handleLinkToPublish}
                  className={twMerge(
                    contextButton,
                    DEFAULT_BUTTON_CLASS_NAMES,
                    'py-0',
                  )}
                >
                  {selectedEvent.state.name ===
                  STATEMENT_STATE_NAME.Unpublished ? (
                    <PublishIcon />
                  ) : (
                    <PublishedIcon />
                  )}
                </Link>
              </Tooltip>
            )}
          </>
        )}

        {!markedAsFeature && (
          <Tooltip
            arrow
            placement="top"
            title={getEditTooltipText({ isEditable, isFetching })}
          >
            <LoadingButton
              className={twMerge(
                contextButton,
                DEFAULT_BUTTON_CLASS_NAMES,
                'cursor-auto p-1 hover:bg-background-paper',
                !isFetching && 'cursor-pointer hover:bg-background-business',
              )}
              loading={isFetching}
              onClick={() => setIsEditingMode?.((prevState) => !prevState)}
            >
              {getEditTooltipIcon({ isEditable })}
            </LoadingButton>
          </Tooltip>
        )}
      </div>
    </section>
  )
}
