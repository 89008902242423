import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { produce } from 'immer'
import { ActionFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { IS_DEV } from '~/config/constants'
import {
  getAttributesFromForm,
  getRaisedDomainEvents,
  redirectToDeveloperHomeOrAggregate,
} from '~/routes/developer/utils'
import {
  queryDevelopment,
  queryKeyDevelopment,
  queryKeyDevelopmentDomainsForCommands,
} from '~/services/Development'
import { queryKeyOrganisation } from '~/services/Discovery'
import { FREE_FEATURES_USAGE } from '~/services/Discovery.types'
import { queryKeyProcess } from '~/services/Process'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'
import { queriesByAggregate } from '~/utils/transform'

export const actionDeveloperEditReadModel =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    const { platformId, processId, readModelId } = params
    const qDevelopment = queryDevelopment(platformId)
    const fetchDevelopment = await queryClient.ensureQueryData(qDevelopment)
    const foundQueries = queriesByAggregate(fetchDevelopment.data)
    const aggregateId = foundQueries.find(
      (query) => query.identity === readModelId,
    )?.aggregateId
    invariant(aggregateId, 'No aggregateId found')
    invariant(platformId, 'No platformId found')
    invariant(userId, 'No userId found')

    // Get form data
    const formData = await request.formData()
    const readModelName = formData.get('readModel.name')
    const allForm = Object.fromEntries(formData)
    const attributes = getAttributesFromForm({
      formEntries: allForm,
      kind: 'action.',
    })
    const queries = getRaisedDomainEvents({
      formEntries: allForm,
      prefix: 'queries.',
    })
    const parsedQueries = queries.map((query) => ({
      name: query.name,
      parameters: query.attributes,
    }))

    // This will return early in case we are only auto generating attributes for reactions
    // It will also return the data from it available at useActionData()
    const autoGenerateButton = Object.keys(allForm).find((key) =>
      key.includes('AutoGenerateAttributes'),
    )
    if (autoGenerateButton) {
      if (aggregateId && typeof aggregateId === 'string') {
        const isReadModel = autoGenerateButton?.includes('readModel')
        const reqUrl = DevelopmentAggregateAPI.GenerateAttributes
        const data = {
          aggregateId: aggregateId?.toString() || '',
          entityType: isReadModel ? 'ReadModels' : 'Queries',
          entityName:
            (isReadModel
              ? readModelName?.toString()
              : allForm[
                  autoGenerateButton?.replace(
                    'AutoGenerateAttributes',
                    'name',
                  ) || ''
                ]?.toString()) || '',
        }

        try {
          const res = await serviceDevelopmentAggregate.post(reqUrl, data)
          queryClient.setQueryData(
            queryKeyOrganisation(userId, email),
            (organisation: any) =>
              produce(organisation, (draft: any) => {
                draft.data.subscriptionPlan[
                  `${FREE_FEATURES_USAGE.ATTRIBUTE_AUTO_GENERATION}Remaining`
                ] =
                  organisation.data.subscriptionPlan[
                    `${FREE_FEATURES_USAGE.ATTRIBUTE_AUTO_GENERATION}Remaining`
                  ] - 1 || 0
              }),
          )
          return {
            formName: autoGenerateButton?.replace(
              '.AutoGenerateAttributes',
              '',
            ),
            attributes: res.data?.value?.attributes,
          }
        } catch (error) {
          if (IS_DEV) {
            console.log(error)
          }
          return []
        }
      }
    }
    // end of auto generation of attributes for reactions

    const isCopyButtonPressed = Object.keys(allForm).find((key) =>
      key.includes('.copyButton'),
    )
    if (isCopyButtonPressed) {
      const attributes = getAttributesFromForm({
        formEntries: allForm,
        kind: isCopyButtonPressed.replace('copyButton', ''),
      })
      await navigator.clipboard.writeText(JSON.stringify(attributes))
      return null
    }

    // Builds the data to send to server
    const postData = {
      aggregateId,
      readModelName,
      attributes,
      queries: parsedQueries,
      readModelId,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.EditReadModel
    const req = await serviceDevelopmentAggregate.post(url, postData)

    // Invalidates the cache
    queryClient.invalidateQueries({ queryKey: queryKeyDevelopment(platformId) })
    queryClient.invalidateQueries({
      queryKey: queryKeyDevelopmentDomainsForCommands(platformId),
    })
    await queryClient.invalidateQueries({
      queryKey: queryKeyProcess(processId),
    })

    const requestUrl = new URL(request.url)
    const isBusiness = requestUrl.pathname.includes('business')

    if (isBusiness) {
      return null
    }

    // redirects
    return redirectToDeveloperHomeOrAggregate(req, params)
  }
