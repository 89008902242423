import { Box, styled } from '@mui/material'

export const CircleForIcon = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    p: 0.5,
    borderRadius: '50%',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'primary.main',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: 'background.paper',

    '> .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
  }),
)
