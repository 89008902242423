/**
 * Enum with the form fields names
 * from `load repository` form.
 */
export enum LoadRepositoryFormEnum {
  /** The authentication token related the `repository url`. */
  AUTHENTICATION_TOKEN = 'authenticationToken',
  /** The repository URL to be loaded. */
  REPOSITORY_URL = 'repositoryUrl',
}
