import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { queryKeyUsersByOrganisation } from '~/services/Discovery'
import { PayloadAddUser } from '~/services/Discovery.types'
import {
  DiscoveryOrganisationAPI,
  serviceDiscoveryOrganisation,
} from '~/services/base'

export const actionConfigurationOrganisationUserAdd =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const { organisationId, platformId } = params
    const formData = await request.formData()
    const formEntries = Object.fromEntries(formData)
    // Types below are needed because FormDataEntryValue can be a string or a File
    // Name was left here because it might be used in the short future
    // const name = formEntries.name as string | undefined
    const email = formEntries.email as string | undefined

    // Remove cases they are undefined
    // invariant(name, 'Name is required')
    invariant(email, 'Email is required')
    invariant(organisationId, 'Organisation ID is required')

    const postData = {
      // name,
      email,
      organisationId,
    } satisfies PayloadAddUser

    const url = DiscoveryOrganisationAPI.AddUser

    try {
      const req = await serviceDiscoveryOrganisation.post(url, postData)

      // If it succeeed, just redirect to the parent route
      if (req.status === 200) {
        // but first invalidade the query so the cache is updated
        await queryClient.invalidateQueries({
          queryKey: queryKeyUsersByOrganisation(organisationId),
        })
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err?.response?.data) {
        return err.response.data
      }

      return 'There was an error creating the user'
    }

    // then redirect, in both cases, we dont want to show any errors we didn't handle for now
    return redirect(`/${organisationId}/${platformId}/configuration/users`)

    // else, well, do nothing
    // return null
  }
