import { useMutation, useQueryClient } from '@tanstack/react-query'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import type { CodeGenerationStrategyApiProps } from '~/models/types/api/codeGenerationStrategy/CodeGenerationStrategyApiProps'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  queryKeyBindingParameters,
  queryKeyStrategy,
} from '~/services/GenerationStrategy'
import type {
  PayloadReplaceTokens,
  ResponseListParametersByGenerationId,
} from '~/services/GenerationStrategy.types'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

type ReplaceTokensMutationParams = {
  /** The domain dictionary entries to replace tokens. */
  domainDictionaryEntries: ResponseListParametersByGenerationId[]
  /** The file ID to replace tokens from. */
  fileId: GUID
}

/**
 * Hook that implements react query `mutation`
 * to submit a value property binding.
 */
export const useReplaceTokens = (
  props: Pick<CodeGenerationStrategyApiProps, 'generationStrategyId'>,
) => {
  const { generationStrategyId } = props

  const queryClient = useQueryClient()

  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: ({
      domainDictionaryEntries,
      fileId,
    }: ReplaceTokensMutationParams) => {
      invariant(generationStrategyId, ERROR_MESSAGES.generationStrategyId)
      invariant(fileId, ERROR_MESSAGES.fileId)

      const url = GenerationStrategyAPI.ReplaceTokens

      const postData: PayloadReplaceTokens = {
        domainDictionaryEntries,
        fileId,
        generationStrategyId,
      }

      return serviceGenerationStrategy.post(url, postData)
    },
    onSuccess: async () => {
      // Invalidate queries to refresh the table data.
      await queryClient.invalidateQueries({
        queryKey: queryKeyStrategy(generationStrategyId as GUID),
      })

      queryClient.invalidateQueries({
        queryKey: queryKeyBindingParameters(generationStrategyId as GUID),
      })
    },
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
