import { styled } from '@mui/material'
import { ControlButton as XyControlButton } from '@xyflow/react'
import CollapseIcon from '~/assets/icons/collapse.svg?react'
import ExpandIcon from '~/assets/icons/expand.svg?react'
import type { NodeStatement } from '~/services/Process.types'

export type ReactFlowControlProps = Pick<NodeStatement, 'isExpanded'> & {
  /** Callback handler for expand button click. */
  onToggleExpandClick?: () => void
}

const ControlButton = styled(XyControlButton)`
  &.react-flow__controls-button svg {
    max-width: 20px;
    min-height: 20px;
  }
`

/**
 * Custom control component for the `React Flow`.
 */
export const ReactFlowControl = (props: ReactFlowControlProps) => {
  const { isExpanded, onToggleExpandClick } = props

  return (
    <ControlButton
      onClick={onToggleExpandClick}
      title={isExpanded ? 'click to collapse' : 'click to expand'}
    >
      {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
    </ControlButton>
  )
}
