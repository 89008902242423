import { IconButton, Tooltip } from '@mui/material'
import {
  getNodesBounds,
  getViewportForBounds,
  useReactFlow,
} from '@xyflow/react'
import { toPng } from 'html-to-image'
import { useState } from 'react'
import { ExportIcon } from '~/components/Icons/ExportIcon'

export function DiagramDownloadButton() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { getNodes } = useReactFlow()

  const downloadImage = (dataUrl: string) => {
    const a = document.createElement('a')

    a.setAttribute('download', 'business.png')
    a.setAttribute('href', dataUrl)
    a.click()
  }

  async function handleClick() {
    try {
      setIsLoading(true)
      const nodeBounds = getNodesBounds(getNodes())
      const transform = getViewportForBounds(
        nodeBounds,
        nodeBounds.width,
        nodeBounds.height,
        0.5,
        2,
        0,
      )
      const dataUrl = await toPng(
        document.querySelector('.react-flow__viewport') as HTMLElement,
        {
          backgroundColor: '#fbfcff',
          width: nodeBounds.width,
          height: nodeBounds.height,
          style: {
            width: `${nodeBounds.width}px`,
            height: `${nodeBounds.height}px`,
            transform: `translate(${transform.x}px, ${transform.y}px) scale(${transform.zoom})`,
          },
        },
      )

      downloadImage(dataUrl)

      // PDF generation below
      /* const canvas = await toCanvas(
        document.querySelector('.react-flow__viewport') as HTMLElement,
        {
          backgroundColor: '#f8f9fe',
          width: nodeBounds.width,
          height: nodeBounds.height,
          style: {
            width: `${nodeBounds.width}px`,
            height: `${nodeBounds.height}px`,
            transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
          },
        }
      )

      const imgData = canvas.toDataURL('image/png')
      const isPortrait = canvas.width < canvas.height
      const pageWidth = isPortrait ? 210 : 297
      const pageHeight = isPortrait ? 297 : 210
      const pdf = new jsPDF(isPortrait ? 'p' : 'l', 'mm')
      pdf.addImage(imgData, 'PNG', 0, 0, pageWidth, pageHeight)
      pdf.save('business.pdf') */
    } catch (error) {
      console.warn('Downloading error: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    // <ControlButton onClick={handleClick}>
    //   {!isLoading ? (
    //     // <FileDownloadIcon />
    //     <ExportIcon />
    //   ) : (
    //     <CircularProgress style={{ width: 12, height: 12 }} />
    //   )}
    // </ControlButton>
    <Tooltip title="Download diagram">
      <IconButton className="rotate-180" onClick={handleClick}>
        <ExportIcon />
      </IconButton>
    </Tooltip>
  )
}
