import { AxiosResponse } from 'axios'
import { PlatformTeamAPI, servicePlatformTeam } from '~/services/base'
import { PlatformByOrganisation } from '~/services/PlatformTeam.types'

export const queryKeyPlatformTeam = (organisationId?: GUID) => {
  // invariant(organisationId, 'organisationId is required')
  return ['platform-team', { organisationId }]
}

export const queryPlatformTeam = <T = PlatformByOrganisation>(
  organisationId?: GUID,
) => ({
  queryKey: queryKeyPlatformTeam(organisationId),
  queryFn: async () => {
    const url = PlatformTeamAPI.getByOrganisationId(organisationId)
    const req = await servicePlatformTeam(url)
    return req as AxiosResponse<T, unknown>
  },
})
