import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs } from 'react-router-dom'
import { queryUsersByOrganisation } from '~/services/Discovery'
import { getOrganisation } from '~/utils/getOrganisation'

export const loaderConfigurationOrganisationUsers =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const dataOrganisation = await getOrganisation(queryClient, pca)
    const organisationId = dataOrganisation.data.identity

    const qUsers = queryUsersByOrganisation(organisationId)
    const fetchUsers = queryClient.ensureQueryData(qUsers)

    return {
      organisation: dataOrganisation.data,
      users: fetchUsers,
    }
  }
