import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'
import { produce } from 'immer'
import { useGetUserEmail } from '~/hooks/useGetAccount'
import { useGetUserId } from '~/hooks/useGetUserId'
import type {
  MutateStatementVariablesProps,
  UseMutateStatementsBaseProps,
} from '~/models/types/api/business/UseMutateStatementsBaseProps'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  getStatementById,
  updateTargetStatement,
} from '~/pages/business/components/ProcessInfo/utils'
import { queryKeyOrganisation } from '~/services/Discovery'
import { FREE_FEATURES_USAGE } from '~/services/Discovery.types'
import { queryKeyProcess } from '~/services/Process'
import type {
  ResponseDiscoveryProcess,
  Statement,
  StatementParsingInfo,
} from '~/services/Process.types'
import { DiscoveryProcessAPI, serviceDiscoveryProcess } from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to insert statement.
 */
export const useInsertStatement = (
  props: Omit<UseMutateStatementsBaseProps, 'addLocalChildStatement'>,
) => {
  const { process, selectedStatement, setSelectedStatements } = props

  // React Router Dom.
  const queryClient = useQueryClient()
  const { userId } = useGetUserId()
  const { email } = useGetUserEmail()

  // Callback handler for mutate.
  const handleMutate = ({ newStatement }: MutateStatementVariablesProps) => {
    // Update `selected statements` states.
    setSelectedStatements((current) => {
      if (current[0]?.id === newStatement?.id) {
        const data = produce(selectedStatement, (draft: Statement) => {
          draft.content = newStatement?.content
          draft.isFetching = true

          if (!draft.parsingInfo)
            draft.parsingInfo = {
              action: newStatement?.content,
            } as StatementParsingInfo
        })
        return [data]
      }
      return current
    })

    // Update query cache.
    queryClient.setQueryData(
      queryKeyProcess(process.identity),
      (currentProcess: AxiosResponse<ResponseDiscoveryProcess>) =>
        updateTargetStatement(currentProcess, newStatement as Statement, true),
    )
  }

  // Callback handler for success.
  const handleSuccess = async (
    _: AxiosResponse<any, any>,
    { newStatement }: MutateStatementVariablesProps,
  ) => {
    // Fetch latest process, to get the updated statement data.
    // The fetch is not using react-client to avoid changing the whole cache:
    // This is because some other `add statement` may be running on the background,
    // eg: two steps were created on the client but the mutations are still running
    // for both, when 1 finishes, the whole cache can not be replaced, otherwise
    // the pending step will disappear from UI.
    const processUrl = DiscoveryProcessAPI.getByProcessId(process.identity)
    const fetchedData = await serviceDiscoveryProcess(processUrl)

    if (fetchedData?.data) {
      // Get the latest statement data from API.
      const found = getStatementById(
        fetchedData.data.statements,
        newStatement?.id || '',
      )

      if (found) {
        // Update `selected statements` states.
        setSelectedStatements((current) => {
          if (current[0]?.id === newStatement?.id) {
            return [found]
          }
          return current
        })

        // Update query cache.
        queryClient.setQueryData(
          queryKeyProcess(process.identity),
          (currentProcess: AxiosResponse<ResponseDiscoveryProcess>) =>
            updateTargetStatement(currentProcess, found),
        )
      }
    }

    // Update query cache.
    queryClient.setQueryData(
      queryKeyOrganisation(userId, email),
      (organisation: any) =>
        produce(organisation, (draft: any) => {
          draft.data.subscriptionPlan[
            `${FREE_FEATURES_USAGE.STEP_AUTOCOMPLETION}Remaining`
          ] =
            organisation.data.subscriptionPlan[
              `${FREE_FEATURES_USAGE.STEP_AUTOCOMPLETION}Remaining`
            ] - 1 || 0
        }),
    )
  }

  // Callback handler for error.
  const handleError = (
    _: Error,
    { newStatement }: MutateStatementVariablesProps,
  ) => {
    // Update `selected statements` states.
    setSelectedStatements((current) => {
      if (current[0]?.id === newStatement?.id) {
        const data = produce(selectedStatement, (draft: Statement) => {
          if (draft.isFetching) draft.isFetching = false
        })
        return [data]
      }
      return current
    })

    // Update query cache.
    queryClient.setQueryData(
      queryKeyProcess(process.identity),
      (currentProcess: AxiosResponse<ResponseDiscoveryProcess>) =>
        produce(
          currentProcess,
          (draft: AxiosResponse<ResponseDiscoveryProcess>) => {
            // Find the index of the statement to update.
            const statementIndex = draft.data.statements.findIndex(
              (statement) => statement.id === newStatement?.id,
            )

            if (statementIndex !== -1) {
              // Set `is fetching` to false.
              if (
                (draft.data.statements[statementIndex] as Statement).isFetching
              ) {
                ;(
                  draft.data.statements[statementIndex] as Statement
                ).isFetching = false
              }

              // Set `is edit mutation error`.
              ;(
                draft.data.statements[statementIndex] as Statement
              ).isEditMutationError = true

              // Retry mutation.
              ;(
                draft.data.statements[statementIndex] as Statement
              ).retryEditMutation = () => {
                mutate({ newStatement })
              }
            }
          },
        ),
    )
  }

  // The `useMutation` implementation.
  const {
    error: mutationError,
    isError,
    mutate,
    ...restMutation
  } = useMutation({
    mutationFn: ({ newStatement }: MutateStatementVariablesProps) => {
      const url = DiscoveryProcessAPI.InsertStatement
      return serviceDiscoveryProcess.post(url, newStatement)
    },
    onMutate: handleMutate,
    onSuccess: handleSuccess,
    onError: handleError,
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError, mutate }
}
