import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react'
import LogoutIcon from '@mui/icons-material/Logout'
import {
  Box,
  Button,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import React from 'react'
import { Navigate, Outlet, useLoaderData } from 'react-router-dom'
import DotstarLogoDark from '~/assets/icons/dotstar-logo-dark.svg?react'
import DotstarLogo from '~/assets/icons/dotstar-logo.svg?react'
import { useGetUserEmail } from '~/hooks/useGetAccount'
import { queryOrganisation } from '~/services/Discovery'
import { ResponseDiscoveryOrganisation } from '~/services/Discovery.types'
import { useGlobalStore } from '~/store'
import { StackCentered } from '~/styled/StackCentered'
import { WizardStepIcon } from '~/styled/WizardBullet'
import { getUserIdByMsal, getUserName } from '~/utils/func'

export function Root() {
  const { instance, accounts } = useMsal()
  const userName = getUserName(accounts)
  const { email: userEmail } = useGetUserEmail()
  const userId = getUserIdByMsal(accounts)
  const colorMode = useGlobalStore((state) => state.colorMode)

  const initialData = useLoaderData() as {
    organisation: Awaited<
      AxiosResponse<ResponseDiscoveryOrganisation | null, unknown>
    >
  }
  const { data: dataOrg } = useQuery({
    ...queryOrganisation(userId, userEmail),
    initialData: initialData.organisation,
  })

  const wizardSteps = React.useMemo(
    () => [
      {
        id: 1,
        label: 'Organisation Name',
        content: dataOrg?.data?.name,
      },
      {
        id: 2,
        label: 'Product Name',
        content: dataOrg?.data?.ecosystemName,
      },
    ],
    [dataOrg],
  )

  async function handleLogout() {
    await instance.logoutRedirect()
  }

  return (
    <StackCentered>
      <Paper
        elevation={7}
        sx={{ borderRadius: 4, minWidth: 980, overflow: 'hidden' }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: '610px 370px' }}>
          <Box>
            <Stack gap={2.75} sx={{ pt: 7, pl: 7, pr: 7 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {colorMode === 'dark' ? <DotstarLogoDark /> : <DotstarLogo />}
                <AuthenticatedTemplate>
                  <Button
                    onClick={handleLogout}
                    variant="text"
                    startIcon={<LogoutIcon />}
                    sx={{
                      fontWeight: 400,
                      '.MuiButton-startIcon > *:nth-of-type(1)': {
                        fontSize: '1rem',
                      },
                    }}
                  >
                    Logout
                  </Button>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Navigate to="/" />
                </UnauthenticatedTemplate>
              </Stack>
              <Stack gap={3} direction="row" alignItems="flex-end">
                <Typography variant="welcomeTitle" component="h2">
                  <>Welcome, {userName}</>
                </Typography>
              </Stack>
            </Stack>
            <Outlet />
          </Box>
          <Box
            sx={{
              bgcolor: 'background.bright',
              pl: 5,
              pr: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Stepper
              orientation="vertical"
              activeStep={wizardSteps.filter((step) => step.content).length}
              sx={{
                '.MuiStepLabel-iconContainer': {
                  pr: 3,
                },
                '.MuiStepLabel-label, .MuiStepLabel-label.Mui-completed': {
                  color: '#6A6DA3',
                  fontWeight: 400,
                },
                '.MuiStepConnector-root': {
                  ml: 2,
                },
              }}
            >
              {wizardSteps.map((step) => (
                <Step key={step.id}>
                  <StepLabel StepIconComponent={WizardStepIcon}>
                    {step.label}
                    {step.content ? (
                      typeof step.content === 'string' &&
                      step.content.length > 26 ? (
                        <Tooltip title={step.content}>
                          <Typography
                            variant="welcomeWizardStepper"
                            component="p"
                            color="primary"
                            sx={{
                              lineHeight: 1,
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              maxWidth: '19ch',
                            }}
                          >
                            <>{step.content}</>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          variant="welcomeWizardStepper"
                          component="p"
                          color="primary"
                          sx={{
                            lineHeight: 1,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: '19ch',
                          }}
                        >
                          <>{step.content}</>
                        </Typography>
                      )
                    ) : null}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
      </Paper>
    </StackCentered>
  )
}
