import {
  Aggregate,
  AggregateCommand,
  AggregateReaction,
  AggregateReadModel,
  AggregateType,
  BoundedContexts,
  DomainByPlatformId,
} from '~/services/Development.types'

export function commandsByAggregate(data: DomainByPlatformId | null) {
  const transformCommand = (
    command: AggregateCommand,
    aggregate: Aggregate,
  ) => ({
    aggregateId: aggregate.identity,
    aggregateName: aggregate.name,
    boundedContext: aggregate.boundedContext,
    ...command,
  })

  const transformAggregate = (aggregate: Aggregate) =>
    aggregate.commands.map((command) => transformCommand(command, aggregate))

  const transformBoundedContext = (boundedContext: BoundedContexts) =>
    boundedContext.aggregates.flatMap(transformAggregate)

  return data ? data.boundedContexts.flatMap(transformBoundedContext) : []
}

export function reactionsByAggregate(data: DomainByPlatformId | null) {
  const transformReaction = (
    reaction: AggregateReaction,
    aggregate: Aggregate,
  ) => ({
    aggregateId: aggregate.identity,
    aggregateName: aggregate.name,
    boundedContext: aggregate.boundedContext,
    ...reaction,
  })

  const transformAggregate = (aggregate: Aggregate) =>
    aggregate.reactions.map((reaction) =>
      transformReaction(reaction, aggregate),
    )

  const transformBoundedContext = (boundedContext: BoundedContexts) =>
    boundedContext.aggregates.flatMap(transformAggregate)

  return data ? data.boundedContexts.flatMap(transformBoundedContext) : []
}

export function typesByAggregate(data: DomainByPlatformId | null) {
  const transformType = (type: AggregateType, aggregate: Aggregate) => ({
    aggregateId: aggregate.identity,
    aggregateName: aggregate.name,
    boundedContext: aggregate.boundedContext,
    ...type,
  })

  const transformAggregate = (aggregate: Aggregate) =>
    aggregate.types.map((type) => transformType(type, aggregate))

  const transformBoundedContext = (boundedContext: BoundedContexts) =>
    boundedContext.aggregates.flatMap(transformAggregate)

  return data ? data.boundedContexts.flatMap(transformBoundedContext) : []
}

export function queriesByAggregate(data: DomainByPlatformId | null) {
  const transformReadModel = (
    readModels: AggregateReadModel,
    aggregate: Aggregate,
  ) => ({
    aggregateId: aggregate.identity,
    aggregateName: aggregate.name,
    ...readModels,
  })

  const transformAggregate = (aggregate: Aggregate) =>
    aggregate.readModels.map((readModel) =>
      transformReadModel(readModel, aggregate),
    )

  const transformBoundedContext = (boundedContext: BoundedContexts) =>
    boundedContext.aggregates.flatMap(transformAggregate)

  return data ? data.boundedContexts.flatMap(transformBoundedContext) : []
}

export function getAggregateByEvent(
  eventType: 'commands' | 'reactions' | 'readModels' = 'commands',
  data?: DomainByPlatformId | null,
  id?: GUID,
) {
  return data && id
    ? data.boundedContexts
        .flatMap((context) => context.aggregates)
        .find((aggregate) =>
          aggregate[eventType].some((entity) => entity.identity === id),
        )
    : undefined
}
