import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { ActionFunctionArgs, generatePath, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import { getPlatform } from '~/routes/setup/platform/utils'
import { queryKeyOrganisation } from '~/services/Discovery'
import { ResponseDiscoveryOrganisation } from '~/services/Discovery.types'
import { queryKeyPlatformTeam } from '~/services/PlatformTeam'
import { PlatformPostResponse } from '~/services/PlatformTeam.types'
import {
  DiscoveryOrganisationAPI,
  PlatformTeamAPI,
  serviceDiscoveryOrganisation,
  servicePlatformTeam,
} from '~/services/base'
import { getOrganisation } from '~/utils/getOrganisation'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const actionStep2 =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params, context }: ActionFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    invariant(userId, `Must be logged in: ${userId}`)
    invariant(email, `Must be logged in: ${userId}`)
    // Get form data
    const formData = await request.formData()
    const name = formData.get('name')
    const organisationId = formData.get('organisationId')

    // Builds the data to send to server
    const postData = {
      name,
      organisationId,
    }

    // Setups and send to server
    const url = DiscoveryOrganisationAPI.AddEcosystem
    const req = (await serviceDiscoveryOrganisation.post(
      url,
      postData,
    )) as AxiosResponse<ResponseDiscoveryOrganisation, unknown>

    // If it succeeed, we need to create a platform as the next step
    if (req.status === 200 && typeof organisationId === 'string') {
      await queryClient.invalidateQueries({
        queryKey: queryKeyOrganisation(userId, email),
      })
      const newDataOrganisation = await getOrganisation(queryClient, pca)

      // get post data for the platform team api
      const postDataPlat = {
        organisationId: newDataOrganisation.data.identity,
        ecosystemName: newDataOrganisation.data.ecosystemName,
      }
      const urlPlat = PlatformTeamAPI.CreatePlatform
      const reqPlat = (await servicePlatformTeam.post(
        urlPlat,
        postDataPlat,
      )) as AxiosResponse<PlatformPostResponse, unknown>

      // then redirect to it
      if (reqPlat.status === 200) {
        // but first invalidade the query so the cache is updated
        await queryClient.refetchQueries({
          queryKey: queryKeyPlatformTeam(newDataOrganisation.data.identity),
        })
        const dataPlatform = await getPlatform(
          queryClient,
          newDataOrganisation.data.identity,
        )
        const platformId = dataPlatform.data.identity

        return redirect(
          generatePath(BusinessRoutesEnum.BUSINESS, {
            organisationId: organisationId || '',
            platformId: platformId || '',
          }),
        )
      }

      if (reqPlat.status !== 200) {
        throw reqPlat
      }
    }

    throw req
  }
