import CloseIcon from '@mui/icons-material/Close'
import { IconButton, type IconButtonProps } from '@mui/material'

/**
 * Implements an `IconButton` component from MUI
 * with a `CloseIcon`.
 */
export const ButtonIconClose = (props: IconButtonProps) => (
  <IconButton {...props}>
    <CloseIcon />
  </IconButton>
)
