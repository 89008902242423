import { useMsal } from '@azure/msal-react'
import { getUserEmailByMsal } from '~/utils/func'

export const useGetUserEmail = () => {
  const { accounts } = useMsal()
  const email = getUserEmailByMsal(accounts)

  return {
    email,
  }
}
