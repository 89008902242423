/**
 * Common tailwind class styles for components.
 */

import { twMerge } from '^/tailwind.config'

// Components that need the
// primary border style.
export const primaryBordered =
  'rounded border border-solid border-primary-200 bg-background-paper shadow-1'

// Style for the context button:
// these appears as floating buttons based on
// a selected component.
export const contextButton = twMerge(
  primaryBordered,
  'font-medium cursor-pointer hover:bg-background-business',
)
