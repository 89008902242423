import { produce } from 'immer'
import type { SelectedTextSectionsType } from '~/models/types/components/codeGenerationStrategy/ManageNodeSelection/SelectedTextSectionsType'
import type { TextSection } from '~/services/GenerationStrategy.types'

/**
 * Provides the selected nodes state.
 * When `allowMultiSelection` is truthy
 * the multi selection will be enabled.
 * @param node The current node data to be selected.
 * @param parent The parent node ID.
 * @param siblings The array of sibling nodes.
 * @param selectedState The current `selected nodes` state.
 * @param allowMultiSelection Allow multi node selection.
 */
export const getSelectedNodes = (
  node: TextSection,
  parent: GUID,
  siblings: GUID[],
  selectedState: SelectedTextSectionsType | null,
  allowMultiSelection?: boolean,
): SelectedTextSectionsType | null => {
  const { id: nodeId } = node || {}

  const {
    parent: stateParent,
    selected: stateSelected = [],
    selectedNode: stateSelectedNode,
    siblings: stateSiblings = [],
  } = (selectedState as SelectedTextSectionsType) || {}

  // The index of the selected node within the siblings array.
  const index = siblings?.indexOf(nodeId)

  // Current node not part of siblings or it is already selected.
  if (index === -1) return selectedState

  let newSelected: GUID[]

  if (
    stateSelected.length === 0 ||
    JSON.stringify(siblings) !== JSON.stringify(stateSiblings) ||
    !allowMultiSelection
  ) {
    // If selected array is empty OR current siblings different from state siblings:
    // add the new selection directly.
    newSelected = [nodeId]
  } else {
    // Get the index of the first and last selected node.
    const firstIndex = siblings.indexOf(stateSelected[0] || '')
    const lastIndex = siblings.indexOf(
      stateSelected[stateSelected.length - 1] || '',
    )

    // Reference index for min/max comparison:
    // - If current index less than first index, use last index as reference;
    // - Otherwise, use first index as reference;
    // This is necessary to preserve the selections:
    // - When selecting a lower index, the range should be current and the last selected;
    // - When selecting a greater index, the range should be the first selected and current.
    const referenceIndex = index < firstIndex ? lastIndex : firstIndex

    // Determine the range between the existing selected node and the new selection
    const start = Math.min(referenceIndex, index)
    const end = Math.max(referenceIndex, index)

    // Extract the items within the range from siblings array and add them to the selected array
    newSelected = siblings.slice(start, end + 1)
  }

  // Set selected node value when there is only 1 selected
  // AND it is equal to the provided node ID.
  const selectedNode =
    newSelected.length === 1 && newSelected[0] === nodeId
      ? { ...node }
      : undefined

  return produce(selectedState || {}, (draft: SelectedTextSectionsType) => {
    // Set parent when it is different.
    if (parent !== stateParent) draft.parent = parent

    // Set selected.
    draft.selected = newSelected as GUID[]

    // Set selected node when it is different.
    if (JSON.stringify(selectedNode) !== JSON.stringify(stateSelectedNode))
      draft.selectedNode = selectedNode

    // Set siblings when they are different.
    if (JSON.stringify(siblings) !== JSON.stringify(stateSiblings))
      draft.siblings = siblings
  })
}
