import {
  Autocomplete,
  TextField,
  Typography,
  type AutocompleteProps,
  type TextFieldProps,
} from '@mui/material'
import { Controller, type UseFormReturn } from 'react-hook-form'

type ControlledAutocompleteProps = Partial<Pick<UseFormReturn, 'control'>> &
  Partial<TextFieldProps> &
  Partial<AutocompleteProps<any, any, any, any>> // To be reviewed.

/**
 * A controlled `text field autocomplete` component
 * made for update statement feature.
 */
export const ControlledAutocomplete = (props: ControlledAutocompleteProps) => {
  const {
    className,
    control,
    inputProps,
    label,
    name,
    onBlur,
    options = [],
    ...rest
  } = props

  if (!control || !name) return null

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Autocomplete
          {...rest}
          {...field}
          className={className}
          onBlur={(e) => {
            field.onBlur()
            onBlur?.(e)
          }}
          onChange={(_, newValue) => {
            field.onChange(newValue)
          }}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                ...inputProps,
                className: 'p-0',
              }}
              inputRef={field.ref}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                className:
                  'border-primary-200 text-primary text-sm pt-2 pb-2 pl-2',
              }}
              label={
                label && (
                  <Typography
                    className="text-[10px] uppercase"
                    component={'span'}
                  >
                    {label}
                  </Typography>
                )
              }
              size="small"
            />
          )}
        />
      )}
    />
  )
}
