import { ListSubheader, Menu, MenuItem, MenuList } from '@mui/material'
import type { CodeGenerationStrategyBaseProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyBaseProps'
import type { ConfigForMenuToReplace } from '~/models/types/components/codeGenerationStrategy/ValueBindingTreeItemProps'

const DROPDOWN_HEIGHT_BASE = 48
const DROPDOWN_WIDTH_BASE = '40ch'

export type MenuToReplaceTextProps = Pick<
  CodeGenerationStrategyBaseProps,
  'dataDomainDictionary'
> & {
  /** Handler for menu close. */
  onMenuClose: () => void
  /** Handler for menu item click. */
  onMenuItemClick: (event: EventFor<'li', 'onClick'>) => void
  /** Options for the menu component. */
  options?: ConfigForMenuToReplace
}

/**
 * A menu component with options to replace text.
 */
export const MenuToReplaceText = (props: MenuToReplaceTextProps) => {
  const { dataDomainDictionary, onMenuClose, onMenuItemClick, options } = props

  return (
    <Menu
      open={!!options}
      onClose={onMenuClose}
      anchorReference="anchorPosition"
      anchorPosition={
        !!options
          ? {
              top: options?.mouseY,
              left: options?.mouseX,
            }
          : undefined
      }
      slotProps={{
        paper: {
          style: {
            maxHeight: DROPDOWN_HEIGHT_BASE * 4.5,
            width: DROPDOWN_WIDTH_BASE,
          },
        },
      }}
      disablePortal
    >
      <MenuList dense>
        {dataDomainDictionary?.map(({ depth, label, key }) => {
          // TODO: This flow is very similar to the one on `ControlledSelect`
          // component, consider the refactor of both in the future
          // (This was not done at the moment due to regular changes to UI).
          const compKey = `${depth}-${key || label}`
          const style = {
            marginLeft: `${depth * 15}px`,
          }

          if (key === null)
            return (
              <ListSubheader
                className="font-bold leading-9"
                key={compKey}
                style={style}
              >
                {label}
              </ListSubheader>
            )

          return (
            <MenuItem
              data-value={key}
              key={compKey}
              onClick={onMenuItemClick}
              style={style}
            >
              {label}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}
