import styled from '@emotion/styled'
import { Button as MuiButton } from '@mui/material'
import type { PanelAsideDetailsBaseProps } from '~/models/types/components/codeGenerationStrategy/PanelAsideDetailsBaseProps'

export type PanelAsideDetailsButtonsProps = PanelAsideDetailsBaseProps & {
  /** Handler for toggle node properties. */
  onToggleNodePropertiesClick?: () => void
  /** Handler for toggle parameters. */
  onToggleParametersClick?: () => void
}

const Button = styled(MuiButton)``
Button.defaultProps = {
  className: 'min-w-0 p-3 [writing-mode:vertical-lr]',
  size: 'small',
  variant: 'outlined',
}

/**
 * The panel aside details buttons component.
 */
export const PanelAsideDetailsButtons = (
  props: PanelAsideDetailsButtonsProps,
) => {
  const {
    onToggleNodePropertiesClick,
    onToggleParametersClick,
    showNodePropertiesPanel,
    showParametersPanel,
  } = props

  return (
    <div className="max-w-[40px] flex-1">
      <div className="flex flex-col gap-2">
        <Button onClick={onToggleParametersClick}>
          {!showParametersPanel ? 'Show' : 'Hide'} Domain Dictionary
        </Button>

        <Button onClick={onToggleNodePropertiesClick}>
          {!showNodePropertiesPanel ? 'Show' : 'Hide'} Properties
        </Button>
      </div>
    </div>
  )
}
