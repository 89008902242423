import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { redirectToDeveloperHomeOrAggregate } from '~/routes/developer/utils'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import {
  queryDevelopment,
  queryKeyDevelopment,
  queryKeyDevelopmentDomainsForCommands,
} from '~/services/Development'
import { queryProcess } from '~/services/Process'

export const actionDeveloperDeleteReadModel =
  (queryClient: QueryClient) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const { platformId, aggregateId, organisationId, processId, readModelId } =
      params
    invariant(platformId, 'No platformId found')
    invariant(aggregateId, 'No aggregateId found')
    invariant(readModelId, 'No readModelId found')

    const postData = {
      aggregateId,
      readModelId,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.DeleteReadModel
    const req = await serviceDevelopmentAggregate.post(url, postData)

    // Invalidates the cache
    queryClient.invalidateQueries({ queryKey: queryKeyDevelopment(platformId) })
    queryClient.invalidateQueries({
      queryKey: queryKeyDevelopmentDomainsForCommands(platformId),
    })

    const requestUrl = new URL(request.url)
    const isBusiness = requestUrl.pathname.includes('business')

    if (isBusiness) {
      const eventType = requestUrl.searchParams.get('eventType')
      const eventId = requestUrl.searchParams.get('eventId')
      await queryClient.invalidateQueries(queryDevelopment(platformId))
      await queryClient.invalidateQueries(
        queryProcess({ processId: params.processId }),
      )
      return redirect(
        `/${organisationId}/${platformId}/business/${processId}/edit-${eventType}/${eventId}`,
      )
    }

    // redirects
    return redirectToDeveloperHomeOrAggregate(req, params)
  }
