import { useContext } from 'react'
import ReactFlowStatesContext, {
  type ReactFlowStatesContextProps,
} from '~/contexts/ReactFlowStatesContext'

/**
 * Hook to consume the ReactFlow States context states.
 */
export const useReactFlowStatesContext = (): ReactFlowStatesContextProps => {
  const context = useContext(ReactFlowStatesContext)

  return context
}
