import CloseIcon from '@mui/icons-material/Close'
import { Collapse } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { Fragment, useState } from 'react'
import { Button } from '~/components/Button'
import { Row } from '~/components/Row'

type ChipProps = {
  disabled?: boolean
  eventId: number | GUID
  eventType?: 'RequiredData' | 'RaisedDomainEvent'
  /** Callback handler for the chip on click event. */
  handleChipClick?: (event: EventFor<'div', 'onClick'>) => void
  /** Callback handler for the close button on click event. */
  handleXClick?: (event: EventFor<'button', 'onClick'>) => void
  /** Indicates if the close button should not be rendered. */
  hideClose?: boolean
  /** The label to be rendered as the content. */
  label: string
  /** Controls the rendering of the close button on hover. */
  renderCloseOnHover?: boolean
}

export function Chip(props: ChipProps) {
  const {
    disabled,
    eventId,
    eventType,
    handleChipClick,
    handleXClick,
    hideClose,
    label,
    renderCloseOnHover,
  } = props

  const [isHovered, setIsHovered] = useState<boolean>(false)

  // Additional props for the containing node.
  const additionalProps = {
    ...(renderCloseOnHover && {
      onMouseEnter: () => setIsHovered(true),
      onMouseLeave: () => setIsHovered(false),
    }),
  }

  // Set button wrapper component.
  const Wrapper = renderCloseOnHover ? Collapse : Fragment

  // Set wrapper props.
  const wrapperProps = {
    ...(renderCloseOnHover && {
      className: 'h-[16px]',
      in: isHovered,
      orientation: 'horizontal',
    }),
  } as any

  return (
    <Row
      className={twMerge(
        'border-10 min-h-[24px] w-fit shrink-0 items-center rounded-full bg-background-chip px-2 text-xs text-primary',
        eventType === 'RequiredData' &&
          'bg-blue-100 text-blue-950 hover:cursor-pointer hover:bg-blue-200',
        eventType === 'RaisedDomainEvent' &&
          'bg-purple-100 text-purple-900 hover:cursor-pointer hover:bg-purple-200',
      )}
      data-event-id={eventId}
      data-value={label}
      onClick={handleChipClick}
      {...additionalProps}
    >
      <span data-event-id={eventId}>{label}</span>

      {!hideClose && (
        <Wrapper {...wrapperProps}>
          <Button
            className={twMerge(
              'shadow-0 bg-background-white group ml-2 min-w-0 rounded-full p-0 hover:bg-primary-200/50',
              eventType === 'RequiredData' && 'hover:bg-blue-300',
              eventType === 'RaisedDomainEvent' && 'hover:bg-purple-300',
            )}
            data-event-id={eventId}
            disabled={disabled}
            onClick={handleXClick}
          >
            <CloseIcon className="z-[0] h-[16px] w-[16px] text-tertiary group-hover:text-black" />
          </Button>
        </Wrapper>
      )}
    </Row>
  )
}
