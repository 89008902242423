import type { Node } from '@xyflow/react'
import {
  useCallback,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useMoveStatement } from '~/hooks/api/business/useMoveStatement'
import { getLinkEditCommandOrReaction } from '~/hooks/useLinkEditCommandOrReaction'
import type { MoveStatementOptionsEnum } from '~/models/enums/api/MoveStatementOptionsEnum'
import type { AddLocalChildStatementProps } from '~/models/types/components/processInfo/AddLocalChildStatementProps'
import type {
  NodeStatement,
  ResponseDiscoveryProcess,
  Statement,
} from '~/services/Process.types'
import { useGlobalStore } from '~/store'

type UseProcessInfoProps = {
  /** Add a local child statement. */
  addLocalChildStatement: (props?: AddLocalChildStatementProps) => Promise<void>
  /** Add a local first statement. */
  addLocalFirstStatement: (props?: AddLocalChildStatementProps) => Promise<void>
  /** Add a local last child statement. */
  addLocalLastChildStatement: (
    props?: AddLocalChildStatementProps,
  ) => Promise<void>
  /** Add a local parent statement. */
  addLocalParentStatement: (props?: AddLocalChildStatementProps) => void
  /** Add a local statement in between. */
  insertLocalStatementBetween: (props?: AddLocalChildStatementProps) => void
  /** The current process data. */
  process: ResponseDiscoveryProcess
  /**
   * Handles the selection and fit view of the
   * selected node - and remove any local node
   * which was not submitted.
   */
  selectNewAndRemoveCurrentIfNeed: (props: AddLocalChildStatementProps) => void
  /** Set state method for `editing mode`. */
  setIsEditingMode: Dispatch<SetStateAction<boolean>>
  /** The statements array. */
  statements: Statement[]
}

/**
 * Hook to support the `process info` component.
 */
export const useProcessInfo = (props: UseProcessInfoProps) => {
  const {
    addLocalChildStatement,
    addLocalFirstStatement,
    addLocalLastChildStatement,
    addLocalParentStatement,
    insertLocalStatementBetween,
    process,
    selectNewAndRemoveCurrentIfNeed,
    setIsEditingMode,
    statements,
  } = props

  // Global states.
  const storeShouldShowHelper = useGlobalStore(
    (state) => state.shouldShowHelpWindowHowToUseDotstar,
  )
  const storeSetShouldShowHelper = useGlobalStore(
    (state) => state.setShouldShowHelpWindowHowToUseDotstar,
  )

  // States.
  const [isExplanationVisible, setIsExplanationVisible] = useState(
    storeShouldShowHelper,
  )

  // Hooks.
  const navigate = useNavigate()

  const { isPending: isMoveStatementPending, mutate: moveStatementMutate } =
    useMoveStatement({
      process,
    })

  // Methods.
  function handleExplanationClick(isChecked: boolean) {
    storeSetShouldShowHelper(!isChecked)
    setIsExplanationVisible(false)
  }

  // Callback handler for add a reaction.
  const handleAddStatementReaction = useCallback(
    (suggestedDescription?: string | undefined, targetStatementId?: GUID) => {
      // Get the target statement (if any).
      const targetStatement = statements?.find(
        (item) => item.id === targetStatementId,
      )

      // Add the reaction statement to the viewport.
      addLocalChildStatement({
        force: true, // Necessary to add the node when there are no selected nodes.
        suggestedDescription,
        targetStatement,
      })

      // Set editing mode.
      setTimeout(() => setIsEditingMode(true), 300)
    },
    [addLocalChildStatement, setIsEditingMode],
  )

  /**
   * Callback handler for adding local child: either at the end or in between nodes.
   * @param hasChildren Indicates if the node statement has children nodes.
   * @param statementId The ID of the statement to add a child from.
   */
  const handleAddLocalChild = useCallback(
    (hasChildren?: boolean, statementId?: GUID) => {
      // Check if the statement exists.
      const targetStatement = statements?.find(
        (item: Statement) => item.id === statementId,
      )

      if (!targetStatement) return

      // If the statement node doesn't have children, add a node at the end.
      if (!hasChildren) {
        addLocalChildStatement({ force: true, targetStatement })
      } else {
        // Otherwise, add a node in between.
        insertLocalStatementBetween({ force: true, targetStatement })
      }

      // Set editing mode.
      setIsEditingMode(true)
    },
    [addLocalChildStatement, insertLocalStatementBetween],
  )

  /**
   * Callback handler for adding a local next sibling.
   * @param statementId The ID of the statement to add the next sibling from.
   */
  const handleAddLocalNextSibling = useCallback(
    (statementId?: GUID | null) => {
      // Check if the statement exists.
      const targetStatement = statements?.find(
        (item: Statement) => item.id === statementId,
      )

      if (!targetStatement) return

      // Otherwise, add a local last statement.
      addLocalLastChildStatement({ force: true, targetStatement })

      // Set editing mode.
      setIsEditingMode(true)
    },
    [addLocalLastChildStatement],
  )

  /**
   * Callback handler for adding local parent.
   * @param parentStatementId The ID of the current statement node parent.
   * @param currentStatementId The ID of the current statement.
   */
  const handleAddLocalParent = useCallback(
    (parentStatementId?: GUID | null, currentStatementId?: GUID) => {
      // Check if the statements exist.
      const targetStatement = statements?.find(
        (item: Statement) => item.id === parentStatementId,
      )

      const currStatement = statements?.find(
        (item: Statement) => item.id === currentStatementId,
      )

      if (!targetStatement && !currStatement) return

      // In case there is the parent, add a node in between.
      if (!!targetStatement) {
        insertLocalStatementBetween({ force: true, targetStatement })
      } else {
        addLocalParentStatement({
          force: true,
          targetStatement: currStatement,
        })
      }

      // Set editing mode.
      setIsEditingMode(true)
    },
    [insertLocalStatementBetween],
  )

  /**
   * Callback handler for adding a local previous sibling.
   * @param statementId The ID of the statement to add the previous sibling from.
   */
  const handleAddLocalPreviousSibling = useCallback(
    (statementId?: GUID | null) => {
      // Check if the statement exists.
      const targetStatement = statements?.find(
        (item: Statement) => item.id === statementId,
      )

      if (!targetStatement) return

      // Otherwise, add a local first statement.
      addLocalFirstStatement({ force: true, targetStatement })

      // Set editing mode.
      setIsEditingMode(true)
    },
    [addLocalFirstStatement],
  )

  /**
   * Callback handler triggered when a draggable node is dropped into.
   * droppable areas.
   */
  const handleDropNodeIntoDropAreas = (
    currentNodeId?: GUID,
    targetNodeId?: GUID,
    position?: MoveStatementOptionsEnum,
  ) => {
    if (
      !!currentNodeId &&
      !!targetNodeId &&
      currentNodeId !== targetNodeId &&
      position
    )
      moveStatementMutate({
        statementId: currentNodeId,
        referenceStatementId: targetNodeId,
        option: position,
      })
  }

  /**
   * Callback handler for node double click.
   * @param node The double clicked node.
   */
  const handleNodeDoubleClick = (
    _: React.MouseEvent<Element, MouseEvent>,
    node: Node<NodeStatement>,
  ) => {
    const { data, id } = node || {}
    const { isFeature, type } = data || {}

    // If it is marked as feature.
    if (isFeature) {
      const url = getLinkEditCommandOrReaction(type, id)

      // If there is an url navigate to it.
      if (!!url) return navigate(url)
      // Otherwise, do nothing.
      return
    }

    // If it is NOT marked as feature, centralize and enable edit mode.
    const targetStatement = statements.find((item) => item.id === node.id)
    selectNewAndRemoveCurrentIfNeed({ nodeFit: true, targetStatement, zoom: 1 })
    return setIsEditingMode?.(true)
  }

  return {
    handleAddLocalChild,
    handleAddLocalNextSibling,
    handleAddLocalParent,
    handleAddLocalPreviousSibling,
    handleAddStatementReaction,
    handleExplanationClick,
    handleDropNodeIntoDropAreas,
    handleNodeDoubleClick,
    isExplanationVisible,
    isLoading: isMoveStatementPending,
  }
}
