import type { RenderTreeItemProps } from '~/models/types/components/codeGenerationStrategy/RenderTreeItemProps'
import type { PropertyBinding } from '~/services/GenerationStrategy.types'
import { RenderLabel } from '../RenderLabel/RenderLabel'
import { getRenderLabelProps } from './RenderTreeItemLabel.utils'

/**
 * Renders the label for a tree item.
 */
export const RenderTreeItemLabel = (props: RenderTreeItemProps) => {
  const { node } = props

  if (
    !node ||
    ((node as PropertyBinding)._t === 'CollectionPropertyBinding' &&
      node.propertyName === 'Children')
  ) {
    return null
  }

  let renderLabelProps = getRenderLabelProps(props)

  return <RenderLabel {...renderLabelProps} />
}

export default RenderTreeItemLabel
