import { Button, Tooltip } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import React from 'react'
import DiamondIcon from '~/assets/icons/diamond.svg?react'
import { DialogUpgradePlan } from '~/components/dialogs/DialogUpgradePlan'
import { Text } from '~/components/Text'
import { useSubscriptionUsage } from '~/hooks/useSubscriptionUsage'
import { FREE_FEATURES_USAGE } from '~/services/Discovery.types'

type BadgeUsesLeftProps = {
  featureType: keyof typeof FREE_FEATURES_USAGE
  shouldHideTooltip?: boolean
}
export function BadgeUsesLeft({
  featureType,
  shouldHideTooltip = false,
}: BadgeUsesLeftProps) {
  const { remainingUsage, remainingUsageTotal } = useSubscriptionUsage({
    featureType,
  })
  const [isOpen, setIsOpen] = React.useState(false)

  if (
    typeof remainingUsage !== 'number' ||
    typeof remainingUsageTotal !== 'number'
  ) {
    return null
  }

  const updateColor = remainingUsage > 0 ? 'bg-secondary' : 'bg-rose-500'
  const handleTooltipClose = () => {
    setIsOpen(false)
  }

  const handleTooltipOpen = () => {
    setIsOpen(true)
  }

  return (
    <>
      <Tooltip
        arrow
        title={
          <TooltipContent
            remainingUsage={remainingUsage}
            remainingUsageTotal={remainingUsageTotal}
          />
        }
        placement="right"
        onClose={handleTooltipClose}
        open={shouldHideTooltip ? false : isOpen}
        leaveDelay={1000}
        slotProps={{
          tooltip: {
            className:
              'mt-1 rounded border border-solid border-primary-100 bg-background-paper p-3 shadow-1',
          },
          popper: {
            disablePortal: true,
            className: 'max-w-[190px]',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
          arrow: {
            className: 'text-primary-100',
          },
        }}
      >
        <span
          className={twMerge(
            'flex h-6 w-[30px] flex-row flex-wrap content-center items-center justify-center rounded-lg bg-primary px-[6px] text-sm font-medium leading-none text-white',
            updateColor,
            'transform-none cursor-default rounded border-0 text-primary-50 shadow-none',
          )}
          onClick={handleTooltipOpen}
          onMouseEnter={handleTooltipOpen}
        >
          {String(remainingUsage).padStart(2, '0')}
        </span>
      </Tooltip>
    </>
  )
}

type TooltipContentProps = {
  remainingUsage: number
  remainingUsageTotal: number
}

function TooltipContent({
  remainingUsage,
  remainingUsageTotal,
}: TooltipContentProps) {
  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] =
    React.useState(false)

  function handleToggleDialog() {
    setIsUpgradePlanDialogOpen((toggle) => !toggle)
  }

  return (
    <div className="flex flex-col items-center">
      <Text className="text-center text-xs font-normal text-tertiary-800">
        You have{' '}
        <strong className="text-sm font-medium">{remainingUsage}</strong> of{' '}
        <strong className="text-sm font-medium">{remainingUsageTotal}</strong>{' '}
        remaining on the{' '}
        <strong className="text-sm font-medium">Free Plan</strong>
      </Text>
      <div className="mt-2">
        <Button
          className="border-secondary-500 bg-secondary-100 text-primary hover:border-secondary-700 hover:bg-secondary-200"
          startIcon={<DiamondIcon />}
          variant="outlined"
          onClick={handleToggleDialog}
        >
          Upgrade Plan
        </Button>
        <DialogUpgradePlan
          open={isUpgradePlanDialogOpen}
          onClose={handleToggleDialog}
          currentPlan="Free"
        />
      </div>
    </div>
  )
}
