import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '~/pages/developer/components/styled/Accordions'
import { Attribute } from '~/services/Development.types'

export type ListAttributesProps = {
  attributes?: Attribute[] | []
  title?: string
}

export function ListAttributes({
  attributes,
  title = 'Attributes',
}: ListAttributesProps) {
  if (attributes) {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          disabled={!attributes.length}
        >
          <>
            {attributes.length ? (
              <Typography>
                {title} ({attributes.length})
              </Typography>
            ) : (
              <Typography>No {title}</Typography>
            )}
          </>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table aria-label="attributes" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attributes.map((attribute, index) => (
                  <TableRow key={attribute.name + index.toString()}>
                    <TableCell>{attribute.name}</TableCell>
                    <TableCell>{attribute.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    )
  }

  return null
}
