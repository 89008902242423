import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { FieldValues } from 'react-hook-form'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import { DeleteFilePathFormEnum } from '~/models/enums/forms/DeleteFilePathFormEnum'
import type { CodeGenerationStrategyApiProps } from '~/models/types/api/codeGenerationStrategy/CodeGenerationStrategyApiProps'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import type { PayloadDeleteFilePath } from '~/services/GenerationStrategy.types'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'
import { invalidateQueries } from '~/utils/api/codeGenerationStrategy/invalidateQueries'

/**
 * Hook that implements react query `mutation`
 * to delete a property.
 */
export const useDeleteFilePath = (
  props: Pick<CodeGenerationStrategyApiProps, 'generationStrategyId'>,
) => {
  const { generationStrategyId } = props

  const queryClient = useQueryClient()

  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: (fieldValues: FieldValues) => {
      const filePath = fieldValues[DeleteFilePathFormEnum.FILE_PATH] as string

      invariant(filePath, ERROR_MESSAGES.filePath)
      invariant(generationStrategyId, ERROR_MESSAGES.generationStrategyId)

      const url = GenerationStrategyAPI.DeleteFilePath

      const postData: PayloadDeleteFilePath = {
        filePath,
        generationStrategyId,
      }

      return serviceGenerationStrategy.post(url, postData)
    },
    onSuccess: () => invalidateQueries(queryClient, generationStrategyId),
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
