import { TextField } from '@mui/material'
import type {
  FocusEventHandler,
  MouseEventHandler,
  ReactNode,
  RefObject,
} from 'react'
import { Controller } from 'react-hook-form'
import { ValueBindingTreeItemFormEnum } from '~/models/enums/forms/ValueBindingTreeItemFormEnum'
import type { FormHook } from '~/models/types/hooks/FormHook'
import type { QueryMutationHook } from '~/models/types/hooks/QueryMutationHook'

export type EditableFieldFormFieldsProps = Pick<FormHook, 'control'> &
  Pick<QueryMutationHook, 'isPending'> & {
    /** Indicates if the fields are editable. */
    isEditable?: boolean
    /** If `true`, only the hidden fields will be rendered. */
    onlyHiddenFields?: boolean
    /** The `onBlur` handler from the value field. */
    onValueBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    /** The `onContextMenu` handler from the value field. */
    onValueContextMenu?: MouseEventHandler<HTMLDivElement>
    /** The class name applied to the value field. */
    valueClassName?: string
    /** The label to be applied to the `value` field. */
    valueFieldLabel?: ReactNode | string
    /** The `inputRef` from the value field. */
    valueRef?: RefObject<HTMLTextAreaElement>
  }

/**
 * The support form fields for and editable field.
 * Currently there are two types of editable fields:
 * - Value Property Binding
 * - Text Section
 *
 * The support fields Includes the hidden fields with
 * the data required for the post request when editing
 * a `Value Property Binding` or a `Text Section`.
 */
export const EditableFieldFormFields = (
  props: EditableFieldFormFieldsProps,
) => {
  const {
    control,
    isEditable,
    isPending,
    onlyHiddenFields,
    onValueBlur,
    onValueContextMenu,
    valueClassName,
    valueFieldLabel,
    valueRef,
  } = props

  if (!isEditable) return null

  return (
    <>
      {!onlyHiddenFields && (
        <Controller
          control={control}
          name={ValueBindingTreeItemFormEnum.VALUE}
          render={({ field: { onBlur, ...restField } }) => (
            <TextField
              {...restField}
              className={valueClassName}
              disabled={isPending}
              inputProps={{ autoCorrect: 'off', spellCheck: 'false' }}
              inputRef={valueRef}
              label={valueFieldLabel}
              multiline
              onBlur={onValueBlur}
              onContextMenu={onValueContextMenu}
              size="small"
            />
          )}
        />
      )}

      <Controller
        control={control}
        name={ValueBindingTreeItemFormEnum.ID}
        render={({ field }) => <input type="hidden" {...field} />}
      />
      <Controller
        control={control}
        name={ValueBindingTreeItemFormEnum.FILE_ID}
        render={({ field }) => <input type="hidden" {...field} />}
      />
      <Controller
        control={control}
        name={ValueBindingTreeItemFormEnum.PARAMETERS}
        render={({ field }) => <input type="hidden" {...field} />}
      />
      <Controller
        control={control}
        name={ValueBindingTreeItemFormEnum.NODE}
        render={({ field }) => <input type="hidden" {...field} />}
      />
    </>
  )
}
