import type {
  AggregateCommand,
  AggregateReaction,
  BoundedContexts,
  CommitHistory,
} from '~/services/Development.types'

type CommandReactionType = {
  /** The current command ID. */
  commandId?: string
  /** The current reaction ID. */
  reactionId?: string
}

/**
 * Get the expected commit history:
 * From the provided command or reaction.
 * @param data The array of bounded contexts.
 * @param commandReaction The command or reaction ID.
 */
export const getCommitHistory = (
  data: BoundedContexts[],
  { commandId, reactionId }: CommandReactionType,
): CommitHistory[] | undefined => {
  for (const context of data) {
    for (const aggregate of context.aggregates || []) {
      const currItem =
        (commandId
          ? aggregate.commands?.find(
              (cmd: AggregateCommand) => cmd.identity === commandId,
            )
          : undefined) ||
        (reactionId
          ? aggregate.reactions?.find(
              (reaction: AggregateReaction) => reaction.identity === reactionId,
            )
          : undefined)

      if (currItem) return currItem.commitHistory
    }
  }

  return undefined
}
