import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'
import {
  queryKeyBindingParameters,
  queryKeyStrategy,
} from '~/services/GenerationStrategy'
import {
  FileStructure,
  PayloadCreateCollectionItem,
  PayloadUpdatePropertyValue,
  PayloadUpdateStrategyFilePaths,
  PayloadUpdateStrategyTokens,
  ResponseListParametersByGenerationId,
} from '~/services/GenerationStrategy.types'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'

export const SUBMIT_INTENT = [
  'auto-map-bindings-for-properties',
  'update-value-property-binding',
  'auto-map-bindings-for-filepath',
  'parameter-map-update',
  'create-collection-item',
] as const

export type CodeGenerationStrategyEditSubmitIntent =
  (typeof SUBMIT_INTENT)[number]

export type ActionDataResponse = null | {
  intent: CodeGenerationStrategyEditSubmitIntent
  error?: boolean
  errorMessage?: string
}

export const actionCodeGenerationStrategyEdit =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const formData = await request.formData()
    const formEntries = Object.fromEntries(formData)
    const generationStrategyId = params.generationStrategyId

    invariant(generationStrategyId, 'Generation Strategy ID is required')

    // Fork submitting the code, one for saving the code, and one for loading the git repository
    const submitIntent = formEntries.intent as
      | CodeGenerationStrategyEditSubmitIntent
      | undefined

    if (submitIntent === 'create-collection-item') {
      return await createCollectionItem({
        formEntries,
        queryClient,
        generationStrategyId,
      })
    }

    // If the intent is to update the parameter map table
    if (submitIntent === 'parameter-map-update') {
      return await parameterMapUpdate({
        formEntries,
        generationStrategyId,
        queryClient,
      })
    }

    // If the intent is to auto-map the bindings for a specific file/path
    if (submitIntent === 'auto-map-bindings-for-filepath') {
      return await autoMapBindingsForFilePath({
        formEntries,
        generationStrategyId,
        queryClient,
      })
    }

    // If the intent is to auto-map the bindings
    if (submitIntent === 'auto-map-bindings-for-properties') {
      return await autoMapBindingsForProperties({
        generationStrategyId,
        queryClient,
      })
    }

    // If the intent is to save the code
    if (submitIntent === 'update-value-property-binding') {
      return await updateValuePropertyBinding({
        formEntries,
        generationStrategyId,
        queryClient,
      })
    }

    return null

    // then redirect, in both cases, we dont want to show any errors we didn't handle for now
    // return redirect(`/${platformId}/configuration/code-generation-strategies`)

    // else, well, do nothing
    // return null
  }

async function createCollectionItem({
  formEntries,
  queryClient,
  generationStrategyId,
}: {
  formEntries: { [k: string]: FormDataEntryValue }
  queryClient: QueryClient
  generationStrategyId: string
}) {
  const fileId = formEntries.fileId as string | undefined
  const propertyBindingId = formEntries.propertyBindingId as string | undefined

  invariant(fileId, 'File ID is required')
  invariant(propertyBindingId, 'Property Binding ID is required')

  const postData: PayloadCreateCollectionItem = {
    fileId,
    propertyBindingId,
  }
  // API URL
  const urlServiceCreateCollectionItem =
    GenerationStrategyAPI.CreateCollectionItem
  // Invoke the method
  await serviceGenerationStrategy.post(urlServiceCreateCollectionItem, postData)

  // Invalidate queries to refresh the table data
  await queryClient.invalidateQueries({
    queryKey: queryKeyStrategy(generationStrategyId),
  })

  // Return nothing (for now)
  return null
}

// === Functions ===

async function parameterMapUpdate({
  formEntries,
  generationStrategyId,
  queryClient,
}: {
  formEntries: { [k: string]: FormDataEntryValue }
  generationStrategyId: GUID
  queryClient: QueryClient
}) {
  if (formEntries.rows) {
    const inputRows = JSON.parse(
      formEntries.rows as string,
    ) as ResponseListParametersByGenerationId[]
    const tokens = inputRows.map((item) => ({
      token: item.token,
      key: item.key,
    }))

    invariant(generationStrategyId, 'Generation Strategy ID is required')

    // Data to send to the API
    const postUpdateStrategyTokens = {
      generationStrategyId,
      domainDictionaryEntries: tokens,
    } satisfies PayloadUpdateStrategyTokens
    // API URL
    const urlServiceUpdateStrategyTokens =
      GenerationStrategyAPI.UpdateStrategyTokens
    // Invoke the method
    await serviceGenerationStrategy.post(
      urlServiceUpdateStrategyTokens,
      postUpdateStrategyTokens,
    )

    // Invalidate queries to refresh the table data
    await queryClient.invalidateQueries({
      queryKey: queryKeyStrategy(generationStrategyId),
    })

    await queryClient.invalidateQueries({
      queryKey: queryKeyBindingParameters(generationStrategyId),
    })

    // Return nothing (for now)
    return null
  }
}

async function autoMapBindingsForFilePath({
  formEntries,
  generationStrategyId,
  queryClient,
}: {
  formEntries: { [k: string]: FormDataEntryValue }
  generationStrategyId: GUID
  queryClient: QueryClient
}) {
  const inputFilePath = formEntries.filePath as string | undefined

  if (inputFilePath && generationStrategyId) {
    // Data to send to the API
    const postUpdateStrategyFilePaths = {
      generationStrategyId,
      rootPath: inputFilePath,
    } satisfies PayloadUpdateStrategyFilePaths

    // API URL
    const urlServiceUpdateStrategyFilePaths =
      GenerationStrategyAPI.UpdateStrategyFilePaths

    // Invoke the method
    await serviceGenerationStrategy.post(
      urlServiceUpdateStrategyFilePaths,
      postUpdateStrategyFilePaths,
    )

    // Invalidate queries
    await queryClient.invalidateQueries({
      queryKey: queryKeyStrategy(generationStrategyId),
    })

    // Return nothing (for now)
    return null
  }
}

async function autoMapBindingsForProperties({
  generationStrategyId,
  queryClient,
}: {
  generationStrategyId: GUID
  queryClient: QueryClient
}) {
  if (generationStrategyId) {
    // Data to send to the API
    const postUpdateStrategyFilePaths = {
      generationStrategyId,
    } satisfies PayloadUpdateStrategyFilePaths

    // API URL
    const urlServiceUpdateStrategyFilePaths =
      GenerationStrategyAPI.UpdateStrategyFilePaths

    // Invoke the method
    await serviceGenerationStrategy.post(
      urlServiceUpdateStrategyFilePaths,
      postUpdateStrategyFilePaths,
    )

    // Invalidate queries
    await queryClient.invalidateQueries({
      queryKey: queryKeyBindingParameters(generationStrategyId),
    })

    // Return nothing (for now)
    return null
  }
}

async function updateValuePropertyBinding({
  formEntries,
  generationStrategyId,
  queryClient,
}: {
  formEntries: { [k: string]: FormDataEntryValue }
  generationStrategyId: GUID
  queryClient: QueryClient
}) {
  const file = formEntries.file as string | undefined
  const parameters = formEntries.parameters as string | undefined
  const text = formEntries.text as string | undefined
  const itemId = formEntries.itemId as string | undefined

  invariant(file, 'File is required')
  invariant(text, 'Text is required')
  invariant(itemId, 'File is required')
  invariant(generationStrategyId, 'Generation Strategy ID is required')
  invariant(parameters?.length, 'Parameters is required')

  const jsonFile = JSON.parse(file) as FileStructure | undefined

  invariant(jsonFile, 'File is invalid')

  const postData: PayloadUpdatePropertyValue = {
    generationStrategyId: generationStrategyId,
    fileId: jsonFile.fileId,
    propertyBindingId: itemId,
    value: text,
    parameters: JSON.parse(parameters),
  }

  const url = GenerationStrategyAPI.UpdatePropertyValue

  try {
    const req = await serviceGenerationStrategy.post(url, postData)

    // If it succeeed, just redirect to the parent route
    if (req.status === 200) {
      // but first invalidade the query so the cache is updated
      await queryClient.invalidateQueries({
        queryKey: queryKeyStrategy(generationStrategyId),
      })
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (err?.response?.data) {
      return err.response.data
    }

    return 'There was an error saving the code strategy'
  }

  return null
}
