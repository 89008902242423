import DataArrayIcon from '@mui/icons-material/DataArray'
import DataObjectIcon from '@mui/icons-material/DataObject'
import KeyboardIcon from '@mui/icons-material/Keyboard'

import { PropertyTypes } from '~/services/GenerationStrategy.types'

type RenderRightIconForPropertyBindingProps = {
  type?: PropertyTypes
}
export function RenderRightIconForPropertyBinding({
  type,
}: RenderRightIconForPropertyBindingProps) {
  if (!type) {
    return null
  }

  if (type === 'ValuePropertyBinding') {
    return <KeyboardIcon />
  }
  if (type === 'ObjectPropertyBinding' || type === 'TextPropertyBinding') {
    return <DataObjectIcon />
  }
  if (type === 'CollectionPropertyBinding') {
    return <DataArrayIcon />
  }

  return null
}
