import type { AttributeOption } from '~/components/Attribute'
import { AGGREGATE_TYPES } from '~/constants/components/aggregateTypes/aggregateTypes'
import type { AggregateType } from '~/services/Development.types'

type GroupedTypes = {
  [key: string]: AggregateType[]
}

/**
 * Provides the types options for the `Attributes` component.
 * These options will be used for the `type` field.
 * @param types The aggregate types from the API.
 */
export const getAggregateTypesOptions = (
  types?: AggregateType[],
): AttributeOption[] => {
  if (!!types?.length) {
    // Group the types by family.
    const groupedTypes = types.reduce(
      (acc: GroupedTypes, item: AggregateType) => {
        const { family = '' } = item

        // Add the family as a key in case it does not exist.
        if (!acc[family]) acc[family] = []

        // Add the item to the family group array.
        acc[family].push(item)

        return acc
      },
      {},
    )

    // Transform the grouped types into the `AttributeOption` format type.
    const formattedTypes = Object.entries(groupedTypes).flatMap(
      ([family, items]) => [
        {
          depth: 0,
          id: family,
          label: family,
        },
        ...items.map(({ identity, label, name }: AggregateType) => ({
          depth: 1,
          id: identity,
          label: label || name,
          name,
        })),
      ],
    )

    return AGGREGATE_TYPES.concat(formattedTypes)
  }

  return AGGREGATE_TYPES
}
