import { PublicClientApplication } from '@azure/msal-browser'
import invariant from 'tiny-invariant'

export function getUserId(pca: PublicClientApplication) {
  const account = pca.getActiveAccount()
  const userId = account?.idTokenClaims?.oid

  return userId ? userId : null
}

export function getLocalUserId(pca: PublicClientApplication) {
  const userId = getUserId(pca)
  invariant(userId, `Must be logged in: ${userId}`)

  return userId
}
