import type { RenderTreeItemProps } from '~/models/types/components/codeGenerationStrategy/RenderTreeItemProps'
import { RenderRightIconForPropertyBinding } from '~/routes/configuration/code-generation-strategies/edit/components/RenderRightIconForPropertyBinding'
import { StyledTreeItem } from '~/routes/configuration/code-generation-strategies/edit/components/StyledTreeItem'
import type {
  FolderOrFileStructure,
  PropertyBinding,
} from '~/services/GenerationStrategy.types'
import RenderTreeCollection from './RenderTreeCollection'
import RenderTreeItemLabel from './RenderTreeItemLabel'
import RenderTreeObject from './RenderTreeObject'

export function RenderTreeItem(props: RenderTreeItemProps) {
  const { node } = props

  // Nothing to render if there's no node
  if (!node) return null

  // Get the rendered nodes.
  const collectionNode = <RenderTreeCollection {...props} />
  const objectNode = <RenderTreeObject {...props} />

  if (!(node as FolderOrFileStructure).fileId && node.id) {
    const thisNode = node as PropertyBinding

    if (
      collectionNode ||
      objectNode ||
      thisNode.propertyName ||
      thisNode.value ||
      thisNode.propertyType
    ) {
      return (
        <StyledTreeItem
          key={thisNode.id}
          nodeId={thisNode.id}
          label={<RenderTreeItemLabel {...props} />}
          icon={<RenderRightIconForPropertyBinding type={thisNode._t} />}
          data-node-id={thisNode.id}
          data-node-property={thisNode.propertyName}
          // Looks like disabled but it's not
          sx={{
            '.MuiTreeItem-content .MuiTreeItem-iconContainer, .MuiTreeItem-content .MuiTreeItem-label > div > div:first-of-type':
              thisNode._t === 'CollectionPropertyBinding' && !collectionNode
                ? {
                    opacity: '0.38',
                    backgroundColor: 'transparent',
                  }
                : {},
          }}
        >
          {collectionNode}
          {objectNode}
        </StyledTreeItem>
      )
    }
  }

  // Renders the first time (root)
  return (
    <>
      {collectionNode}
      {objectNode}
    </>
  )
}
