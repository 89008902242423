import Tooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import { twMerge } from '^/tailwind.config'

// We have 7 colors for 7 params,
// repeating (slightly different) for more 7, in case it's needed
// If more are needed, add more colors
// Default is the first one COLOR_FOR_PARAMS[0]
const COLORS_FOR_PARAMS = [
  'bg-yellow-100 text-yellow-900',
  'bg-lime-100 text-lime-900',
  'bg-teal-100 text-teal-900',
  'bg-blue-100 text-blue-900',
  'bg-violet-100 text-violet-900',
  'bg-fuchsia-100 text-fuchsia-900',
  'bg-orange-100 text-orange-900',
  // repeat above but slightly different
  'bg-amber-100 text-amber-900',
  'bg-emerald-100 text-emerald-900',
  'bg-cyan-100 text-cyan-900',
  'bg-indigo-100 text-indigo-900',
  'bg-purple-100 text-purple-900',
  'bg-red-100 text-red-900',
  'bg-orange-100 text-orange-900',
]

export type ColoredParamsProps = {
  /** Indicates whether this is a group node. */
  isGroupNode?: boolean
  params: string[]
  value: string
}

export function ColoredParams(props: ColoredParamsProps) {
  const { isGroupNode, params, value } = props

  // Will match {0} or {0:some_text} or {1:Some TexT} and so on
  // 2 digits max, any text as needed until closing braces
  const regex = /\{(\d{1,2})(?::([a-zA-Z_]+))?\}/g
  const uniqueId = `UNIQUE_ID_${Math.floor(Math.random() * 1000000)}`
  const matches: string[] = []
  let match: RegExpExecArray | null

  while ((match = regex.exec(value)) !== null) {
    matches.push(match[0])
  }

  // If no matches found, return the original string.
  if (matches.length === 0) {
    return (
      <span
        className={twMerge(
          (value === '' || value === `\r\n`) && !isGroupNode ? 'px-1' : '',
        )}
      >
        {value}
      </span>
    )
  }

  let index = 0
  const parts = value.split(regex).map((part, partIndex) => {
    if (partIndex % 3 === 0) {
      return (
        <span key={`${uniqueId}-${index++}`} data-value={part}>
          {part}
        </span>
      )
    }

    const matchIndex = (partIndex - 1) / 3
    const toNumber = findNumber(matches[matchIndex])
    const title = params[toNumber]
    const backgroundColor = COLORS_FOR_PARAMS[toNumber]?.split(' ')[0]
    const textColor = COLORS_FOR_PARAMS[toNumber]?.split(' ')[1]

    // Matched parts
    if (partIndex % 3 === 1) {
      return (
        <span key={`${uniqueId}-${index++}`} data-value={matches[matchIndex]}>
          <Tooltip
            title={title}
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 100 }}
            arrow
            componentsProps={{
              tooltip: {
                className: backgroundColor + ' ' + textColor,
              },
              arrow: {
                className: textColor,
              },
            }}
          >
            <span
              className={twMerge(
                'mx-[0px] font-mono text-black',
                COLORS_FOR_PARAMS[toNumber] ?? COLORS_FOR_PARAMS[0],
              )}
            >
              {matches[matchIndex]}
            </span>
          </Tooltip>
        </span>
      )
    }

    // Optional formatting parts
    return <span key={`${uniqueId}-${index++}`}>{matches[matchIndex + 1]}</span>
  })

  // Use React Fragment to wrap the JSX elements
  return <>{parts}</>
}

function findNumber(inputString?: string) {
  if (!inputString) {
    return 0
  }
  // Regular expression to match sequences of digits
  const regex = /\d+/g

  // Use match method to find all matches in the input string
  const matchedStrings = inputString.match(regex)

  // Convert the matched strings to integers
  const numbers = matchedStrings ? matchedStrings.map(Number) : null

  return numbers?.[0] ? numbers[0] : 0
}
