export function KeyDownArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        d="M4 5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V5Z"
        fill="#B6F1E6"
      />
      <path
        d="M5 4.5H19V3.5H5V4.5ZM19.5 5V19H20.5V5H19.5ZM19 19.5H5V20.5H19V19.5ZM4.5 19V5H3.5V19H4.5ZM5 19.5C4.72386 19.5 4.5 19.2761 4.5 19H3.5C3.5 19.8284 4.17157 20.5 5 20.5V19.5ZM19.5 19C19.5 19.2761 19.2761 19.5 19 19.5V20.5C19.8284 20.5 20.5 19.8284 20.5 19H19.5ZM19 4.5C19.2761 4.5 19.5 4.72386 19.5 5H20.5C20.5 4.17157 19.8284 3.5 19 3.5V4.5ZM5 3.5C4.17157 3.5 3.5 4.17157 3.5 5H4.5C4.5 4.72386 4.72386 4.5 5 4.5V3.5Z"
        fill="#080D97"
      />
      <path
        d="M10.75 13.75L12.25 13.75L13.75 13.75L12.25 15.25L10.75 13.75Z"
        fill="#080D97"
      />
      <path
        d="M12.25 8.75L12.25 13.75M12.25 13.75L10.75 13.75L12.25 15.25L13.75 13.75L12.25 13.75Z"
        stroke="#080D97"
      />
    </svg>
  )
}
