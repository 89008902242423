import { useCallback, useEffect, type RefObject } from 'react'
import { useParams } from 'react-router-dom'
import { useAppPopperContext } from '~/hooks/contexts/useAppPopperContext'
import { useSelectedTextSectionsContext } from '~/hooks/contexts/useSelectedTextSectionsContext'
import type { CodeGenerationStrategyBaseProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyBaseProps'
import type { RenderTextSectionNodeProps } from '~/models/types/components/codeGenerationStrategy/RenderTextSectionNodeProps'
import { ConfigPopper } from '../ConfigPopper/ConfigPopper'

export type UseSelectedNodesPopperProps = Pick<
  CodeGenerationStrategyBaseProps,
  'fileId'
> &
  Pick<RenderTextSectionNodeProps, 'node' | 'onDeletePropertyBindingClick'> & {
    /** The ref of the anchor component. */
    anchorRef?: RefObject<HTMLPreElement>
  }

/**
 * Hook to manage the popper with
 * the selected node(s) options.
 */
export const useSelectedNodesPopper = (props: UseSelectedNodesPopperProps) => {
  const { anchorRef, fileId, node, onDeletePropertyBindingClick } = props

  // React Router Dom.
  const { generationStrategyId } = useParams()

  // Selected text sections states.
  const {
    isMergeModeActivateByKeyboard,
    isMergeMode,
    setIsMergeMode,
    selectedTextSections,
    setSelectedTextSections,
  } = useSelectedTextSectionsContext()
  const selectedNodes = selectedTextSections?.selected

  // Popper Context.
  const { closeAppPopper, componentProps, openAppPopper } =
    useAppPopperContext()
  const { open } = componentProps || {}

  // Handler for activate merge node click.
  const handleActivateMergeModeClick = useCallback(() => {
    setIsMergeMode?.((state?: boolean) => !state)
  }, [setIsMergeMode])

  // Lifecycle to open/close the config popper.
  useEffect(() => {
    // Open popper when there are selected nodes.
    if (selectedNodes?.length && node?.id === selectedNodes?.[0]) {
      openAppPopper?.({
        anchorEl: anchorRef?.current,
        children: (
          <ConfigPopper
            fileId={fileId}
            generationStrategyId={generationStrategyId}
            isMergeMode={isMergeMode}
            isMergeModeActivateByKeyboard={isMergeModeActivateByKeyboard}
            node={node}
            onActivateMergeModeClick={handleActivateMergeModeClick}
            onDeleteClick={onDeletePropertyBindingClick}
            onDeselectClick={() => setSelectedTextSections?.(null)}
            selectedTextSections={selectedTextSections}
          />
        ),
        open: true,
      })
    }

    // Close popper when there are no selected nodes.
    if (!selectedNodes?.length && open) closeAppPopper?.()
  }, [isMergeMode, isMergeModeActivateByKeyboard, open, selectedNodes])
}
