import { Typography } from '@mui/material'
import { Form, useParams } from 'react-router-dom'
import { FormFooter } from '~/pages/developer/components/FormFooter'

export function DeveloperDeleteReadModel() {
  const params = useParams()

  return (
    <>
      <Typography>Do you really want to delete this Query?</Typography>
      <Form id="delete-readmodel-form" method="post">
        <input type="hidden" name="platformId" value={params.platformId} />
        <input type="hidden" name="aggregateId" value={params.aggregateId} />
        <input type="hidden" name="readModelId" value={params.readModelId} />
        <FormFooter
          showReset={false}
          submitText="Confirm"
          submittingText="Confirming"
        />
      </Form>
    </>
  )
}
