export function KeyRightArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#B6F1E6"
        d="M4 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5Z"
      />
      <path
        fill="#080D97"
        d="M5 4.5h14v-1H5v1Zm14.5.5v14h1V5h-1ZM19 19.5H5v1h14v-1ZM4.5 19V5h-1v14h1Zm.5.5a.5.5 0 0 1-.5-.5h-1A1.5 1.5 0 0 0 5 20.5v-1Zm14.5-.5a.5.5 0 0 1-.5.5v1a1.5 1.5 0 0 0 1.5-1.5h-1ZM19 4.5a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 19 3.5v1Zm-14-1A1.5 1.5 0 0 0 3.5 5h1a.5.5 0 0 1 .5-.5v-1ZM14 13.5v-3l1.5 1.5-1.5 1.5Z"
      />
      <path stroke="#080D97" d="M9 12h5m0 0v1.5l1.5-1.5-1.5-1.5V12Z" />
    </svg>
  )
}
