import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  SvgIcon,
} from '@mui/material'
import {
  Form,
  useLoaderData,
  useLocation,
  useNavigation,
  useSearchParams,
} from 'react-router-dom'
import { Text } from '~/components/Text'
import { switchIcon } from '~/routes/setup/platform/utils'
import { Platform } from '~/services/PlatformTeam.types'

export function ContextPersistence() {
  const navigation = useNavigation()
  const [searchParams] = useSearchParams()
  const isInitialise = searchParams.toString().includes('initialise')
  const location = useLocation()
  const isBusiness = location.pathname.includes('business')
  const isButtonDisabled = navigation.state !== 'idle'
  // const errors = useActionData() as GenericObj<string> | undefined
  const persistences = useLoaderData() as
    | Platform['services']['persistenceStrategies']
    | null

  return (
    <>
      <Stack gap={2}>
        {isInitialise && !isBusiness ? (
          <Alert severity="info">
            Before initialising, you need to setup the persistence strategy.
          </Alert>
        ) : null}
        <Text className="text-xl">
          Please select the <strong>technology to save your data</strong>:
        </Text>
        <Paper sx={{ px: 2, py: 1 }}>
          <List component="nav">
            {persistences?.map((persistence) => (
              <ListItem disablePadding key={persistence.identity}>
                <Box
                  component={Form}
                  method="post"
                  id="form-aggregate-persistence"
                  sx={{ flexGrow: 1, display: 'flex' }}
                >
                  <input
                    type="hidden"
                    defaultValue={persistence.identity}
                    name="name"
                  />
                  <ListItemButton
                    disabled={isButtonDisabled}
                    component="button"
                    type="submit"
                  >
                    <ListItemIcon>
                      <SvgIcon>{switchIcon(persistence.identity)}</SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={persistence.name} />
                  </ListItemButton>
                </Box>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Stack>
    </>
  )
}
