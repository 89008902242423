import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { getLocalUserId } from '~/utils/getUserId'

export const actionDeveloperConfirm =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const userId = getLocalUserId(pca)

    const { aggregateId, organisationId, platformId, processId } = params
    const formData = await request.formData()
    const commitMessage = formData.get('commitMessage')
    const branch = formData.get('branch')

    const postData = {
      commitMessage,
      developerId: userId,
      aggregateId,
      branch,
    }

    const requestUrl = new URL(request.url)
    const isBusiness = requestUrl.pathname.includes('business')
    const eventId = requestUrl.searchParams.get('initialise')
    const eventType = requestUrl.searchParams.get('type')
    const publishType = `publish-${
      eventType === 'Command' ? 'command' : 'reaction'
    }`

    // Setups and send to server
    const url = DevelopmentAggregateAPI.Initialise
    // const req = await serviceDevelopmentAggregate.post(url, postData)
    await serviceDevelopmentAggregate.post(url, postData)

    if (isBusiness) {
      return redirect(
        `/${organisationId}/${platformId}/business/${processId}/${aggregateId}/${publishType}/${eventId}`,
      )
    }

    return redirect(
      `/${organisationId}/${platformId}/developer/${aggregateId}/${publishType}/${eventId}`,
    )

    // return redirectToDeveloperHomeOrAggregate(req, params)
  }
