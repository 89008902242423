import { InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { Button, Popover } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { useState, type MouseEvent } from 'react'
import DiamondIcon from '~/assets/icons/diamond.svg?react'
import UserIcon from '~/assets/icons/person-purple.svg?react'
import { Column } from '~/components/Column'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'
import { DialogUpgradePlan } from '~/components/dialogs/DialogUpgradePlan'
import { useCodeGenerationStore, useGlobalStore } from '~/store'
import { BadgeUsesLeft } from '../BadgeUsesLeft'

export type UserMenuProps = {
  currentPlan: string
  user: string
}

export function UserMenu({ currentPlan, user }: UserMenuProps) {
  // Global States.
  const deleteGlobalStore = useGlobalStore((state) => state.deleteEverything)
  const deleteCodeGenerationStore = useCodeGenerationStore(
    (state) => state.deleteEverything,
  )

  // Hooks.
  const { instance, inProgress } = useMsal()

  // States.
  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false)
  const [userMenuEl, setUserMenuEl] = useState<null | HTMLElement>(null)
  const isUserMenuOpen = Boolean(userMenuEl)

  // Methods.
  function handleOpenUserMenu(event: MouseEvent<HTMLButtonElement>) {
    setUserMenuEl(event.currentTarget)
  }

  function handleCloseUserMenu(_: MouseEvent<HTMLLIElement>) {
    setUserMenuEl(null)
  }

  function handleToggleDialog() {
    setIsUpgradePlanDialogOpen((toggle) => !toggle)
  }

  async function handleLogout() {
    if (inProgress === InteractionStatus.None) {
      await instance.logoutRedirect()
      deleteGlobalStore()
      deleteCodeGenerationStore()
    }
  }

  return (
    <>
      <Button
        id="button-user-menu"
        aria-controls={isUserMenuOpen ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isUserMenuOpen ? 'true' : undefined}
        data-testid="button-user-menu"
        onClick={handleOpenUserMenu}
        variant="outlined"
        className="ml-2 flex min-w-fit border border-primary-100 p-0 px-[5px] py-[5px]"
      >
        <UserIcon />
      </Button>

      <Popover
        id={isUserMenuOpen ? 'user-menu' : undefined}
        open={isUserMenuOpen}
        anchorEl={userMenuEl}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="min-w-[246px] px-4 py-4">
          <Row className="gap-2">
            <div className="flex rounded border border-solid border-primary-100 bg-white p-0 px-[5px] py-[5px]">
              <UserIcon />
            </div>

            <Column>
              <Text className="text-xs font-medium leading-4 text-tertiary-700">
                {user}
              </Text>

              <Text className="text-tertiary-600 text-tertiary-600 text-xs font-normal leading-4">
                {currentPlan}
              </Text>
            </Column>
          </Row>

          {currentPlan.toLowerCase().includes('free') ? (
            <>
              <hr className="m-0 my-3 shrink-0 border-0 border-b border-solid border-primary-50" />
              <Column className="gap-1">
                <Text className="text-sm leading-5 text-primary-600">
                  You are on the Free Plan
                </Text>

                <Text className="-mt-1 mb-3 text-xs font-normal leading-4 text-tertiary">
                  Remaining Operations
                </Text>

                <Column className="gap-2">
                  <Row className="items-center justify-between">
                    <Text className="text-xs font-medium leading-4 text-tertiary-700">
                      Step Autocompletion
                    </Text>

                    <BadgeUsesLeft
                      featureType="STEP_AUTOCOMPLETION"
                      shouldHideTooltip
                    />
                  </Row>

                  <Row className="items-center justify-between">
                    <Text className="text-xs font-medium leading-4 text-tertiary-700">
                      Automatic Process Generation
                    </Text>

                    <BadgeUsesLeft
                      featureType="PROCESS_AUTO_GENERATION"
                      shouldHideTooltip
                    />
                  </Row>

                  <Row className="items-center justify-between">
                    <Text className="text-xs font-medium leading-4 text-tertiary-700">
                      Mockup Analysis
                    </Text>

                    <BadgeUsesLeft
                      featureType="MOCKUP_ANALYSIS"
                      shouldHideTooltip
                    />
                  </Row>

                  <Row className="items-center justify-between">
                    <Text className="text-xs font-medium leading-4 text-tertiary-700">
                      Auto-generate Attributes
                    </Text>

                    <BadgeUsesLeft
                      featureType="ATTRIBUTE_AUTO_GENERATION"
                      shouldHideTooltip
                    />
                  </Row>
                </Column>

                <Text className="mt-3 text-xs font-medium leading-5 text-primary">
                  Need more operations?
                </Text>

                <Text className="-mt-2 text-xs leading-4 text-tertiary-800">
                  Choose a plan that best suits you
                </Text>

                <div className="mt-2">
                  <Button
                    className="border-secondary-500 bg-secondary-100 text-primary hover:border-secondary-700 hover:bg-secondary-200"
                    startIcon={<DiamondIcon />}
                    variant="outlined"
                    onClick={handleToggleDialog}
                  >
                    Upgrade Plan
                  </Button>

                  <DialogUpgradePlan
                    open={isUpgradePlanDialogOpen}
                    onClose={handleToggleDialog}
                    currentPlan="Process Plan"
                  />
                </div>
              </Column>

              <hr
                className={twMerge(
                  'm-0 mb-2 mt-3 shrink-0 border-0 border-b border-solid border-primary-50',
                )}
              />
            </>
          ) : null}

          {currentPlan.toLowerCase().includes('process') ? (
            <>
              <hr className="m-0 my-3 shrink-0 border-0 border-b border-solid border-primary-50" />

              <Column className="gap-1">
                <Text className="text-sm leading-5 text-primary-600">
                  You are on the Process Plan
                </Text>

                <Text className="text-xs font-normal leading-4 text-tertiary-800">
                  Go <strong className="font-semibold">Full</strong> for auto
                  code generation
                </Text>

                <div className="mt-2">
                  <Button
                    className="border-secondary-500 bg-secondary-100 text-primary hover:border-secondary-700 hover:bg-secondary-200"
                    startIcon={<DiamondIcon />}
                    variant="outlined"
                    onClick={handleToggleDialog}
                  >
                    Upgrade Plan
                  </Button>

                  <DialogUpgradePlan
                    open={isUpgradePlanDialogOpen}
                    onClose={handleToggleDialog}
                    currentPlan="Process Plan"
                  />
                </div>
              </Column>
            </>
          ) : null}

          {!currentPlan.toLowerCase().includes('free') ? (
            <>
              <hr
                className={twMerge(
                  'm-0 mb-2 mt-3 shrink-0 border-0 border-b border-solid border-primary-50',
                )}
              />

              <a
                href="https://billing.stripe.com/p/login/test_cN2aEQf702cq6uA144"
                target="_blank"
                rel="noreferrer"
                className="block py-1 text-xs font-semibold leading-4 text-tertiary no-underline"
              >
                View Subscription
              </a>

              <hr className="m-0 my-1 shrink-0 border-0 border-b border-solid border-primary-50" />
            </>
          ) : null}

          <button
            className="block w-full cursor-pointer border-0 bg-transparent p-0 py-1 text-left text-xs font-semibold leading-4 text-tertiary"
            onClick={handleLogout}
          >
            Log out
          </button>
        </div>
      </Popover>
    </>
  )
}
