import { Button, TextField, Typography } from '@mui/material'
import type { MouseEvent } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { Form } from 'react-router-dom'
import { ButtonAutoGenerate } from '~/components/ButtonAutoGenerate'
import { Column } from '~/components/Column'
import { ReactionIcon } from '~/components/Icons/ReactionIcon'
import { Row } from '~/components/Row'
import { FormActionName } from '~/pages/developer/components/FormActionName/index'
import { FormAttributes } from '~/pages/developer/components/FormAttributes'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { RaisedDomainEvent } from '~/pages/developer/components/FormRaisedDomainEvent'

export function DeveloperAddCommand() {
  // React Hook Form.
  const methods = useForm({
    defaultValues: {
      events: [
        {
          name: '',
          attributes: [
            {
              name: '',
              type: 'string',
            },
          ],
        },
      ],
      command: {
        name: '',
        actor: '',
        aggregate: '',
        attributes: [
          {
            name: '',
            type: 'string',
          },
        ],
      },
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'events',
  })

  // Methods.
  function handleRemoveEvent(event: MouseEvent<HTMLButtonElement>) {
    const index = event.currentTarget.dataset.index
    if (index) {
      remove(Number(index))
    }
  }

  function handleAddRaisedDomainEvent() {
    append({ name: '', attributes: [] })
  }

  return (
    <Column className="gap-4 pb-12">
      <FormProvider {...methods}>
        <Form method="post" id="form-aggregate-add-command">
          <Column className="gap-4">
            <FormActionName inputName="command.name">
              <TextField
                label="Aggregate"
                variant="outlined"
                name="command.aggregate"
                fullWidth
                sx={{
                  mb: 2,
                }}
              />

              <TextField
                label="Actor"
                variant="outlined"
                name="command.actor"
                fullWidth
                sx={{
                  mb: 2,
                }}
              />

              <FormAttributes
                autoGenerateComponent={<ButtonAutoGenerate name="command." />}
                formName="command"
              />
            </FormActionName>

            <Row className="gap-1">
              <ReactionIcon />

              <Typography>Raised Domain Events ({fields.length})</Typography>
            </Row>

            {fields.map((field, index) => (
              <RaisedDomainEvent
                key={field.id}
                defaultValue={field.name}
                index={index}
                handleRemoveEvent={handleRemoveEvent}
                isRemoveDisabled={fields.length <= 1}
                inputName={`events.${index}.name`}
              >
                <FormAttributes
                  autoGenerateComponent={
                    <ButtonAutoGenerate name={`events.${index}.`} />
                  }
                  formName={`events.${index}`}
                />
              </RaisedDomainEvent>
            ))}

            <Button variant="outlined" onClick={handleAddRaisedDomainEvent}>
              Add raised domain event
            </Button>
          </Column>

          <FormFooter />
        </Form>
      </FormProvider>
    </Column>
  )
}
