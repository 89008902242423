import { twMerge } from '^/tailwind.config'
import { useDrop } from 'react-dnd'
import { MoveStatementOptionsEnum as OptionsEnum } from '~/models/enums/api/MoveStatementOptionsEnum'
import type { NodeStatement } from '~/services/Process.types'
import { getStylePosition } from './DropArea.utils'

export type DropAreaProps = Pick<
  NodeStatement,
  'id' | 'onDropNodeIntoDropAreas'
> & {
  /**
   * The position of the drop area (top, bottom, left or right).
   * Default value is `top`.
   */
  position?: OptionsEnum
}

/**
 * The statement drop area:
 * A drop area where the draggable node
 * can be dropped into.
 */
export const DropArea = (props: DropAreaProps) => {
  const { id, onDropNodeIntoDropAreas, position = OptionsEnum.TOP } = props

  const [{ isOver }, drop] = useDrop({
    accept: 'node',
    drop: (item: NodeStatement) => {
      onDropNodeIntoDropAreas?.(item.id, id, position)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  })

  const stylePosition = getStylePosition(position)

  return (
    <div
      className={twMerge(
        'absolute z-30 rounded p-5 ',
        position === OptionsEnum.TOP || position === OptionsEnum.BOTTOM
          ? 'left-[50%] translate-x-[-50%]'
          : '',
        position === OptionsEnum.LEFT || position === OptionsEnum.RIGHT
          ? 'top-[50%] translate-y-[-50%]'
          : '',
      )}
      data-testid={position}
      ref={drop}
      style={{
        [stylePosition]: -50,
        backgroundColor: isOver ? 'rgba(0, 255, 0, 0.5)' : 'rgba(0, 0, 0, 0.1)',
      }}
    />
  )
}
