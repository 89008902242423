import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Form, useLoaderData, useParams } from 'react-router-dom'
import { ProcessForm } from '~/pages/business/components/ProcessForm'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { queryProcess } from '~/services/Process'
import { ResponseDiscoveryProcess } from '~/services/Process.types'
import { BusinessChildrenParams } from '../routes.types'

export function ViewProcess() {
  const initialData = useLoaderData() as {
    process: Awaited<AxiosResponse<ResponseDiscoveryProcess | null, unknown>>
  }
  const params = useParams<BusinessChildrenParams>()
  const { data } = useQuery({
    ...queryProcess({ processId: params.processId }),
    initialData: initialData.process,
  })

  const defaultValues = {
    name: data?.data?.name || '',
    description: data?.data?.description || '',
    generateProcessBasedOnDescription:
      data?.data?.generateProcessBasedOnDescription || false,
  }
  return (
    <Form method="post" id="form-domain-view-process">
      <ProcessForm
        nameFieldLabel="Process name"
        descriptionFieldLabel="Process description"
        defaultValues={defaultValues}
      />
      <FormFooter showReset={false} showConfirm={false} />
    </Form>
  )
}
