import {
  InvalidateQueryFilters,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { BusinessChildrenParams } from '~/routes/business/routes.types'
import { ResponseProcessFiles, queryProcessFiles } from '~/services/Process'

type HookResponse = {
  data: ResponseProcessFiles | undefined
  error: unknown
  invalidateQuery: () => Promise<void>
  isLoading: boolean
}

type UseGetProcessFiles = () => HookResponse

export const useGetProcessFiles: UseGetProcessFiles = () => {
  const params = useParams<BusinessChildrenParams>()
  const { processId } = params

  const queryClient = useQueryClient()

  const queryKey = ['processFiles', processId]

  const { data, isLoading, error, refetch } = useQuery<
    AxiosResponse<ResponseProcessFiles>,
    unknown
  >({
    ...queryProcessFiles(processId),
    queryKey,
    enabled: !!processId,
  })

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKey,
    } as InvalidateQueryFilters)
  }

  return {
    data: data?.data,
    isLoading,
    error,
    invalidateQuery,
  }
}
