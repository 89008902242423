import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import { queryKeyDevelopment } from '~/services/Development'
import { queryKeyProcess } from '~/services/Process'
import { DiscoveryProcessAPI, serviceDiscoveryProcess } from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to mark a step as feature.
 */
export const useMarkAStepAsFeature = () => {
  const params = useParams()
  const { processId, platformId } = params

  const queryClient = useQueryClient()

  // Mutation.
  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: (eventId: GUID) => {
      invariant(processId, ERROR_MESSAGES.processId)
      invariant(eventId, ERROR_MESSAGES.eventId)

      // Endpoint URL.
      const url = DiscoveryProcessAPI.MarkAStepAsFeature

      return serviceDiscoveryProcess.post(url, {
        processId,
        stepId: eventId,
      })
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: queryKeyProcess(processId),
      })

      queryClient.refetchQueries({
        queryKey: queryKeyDevelopment(platformId),
      })
    },
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return {
    ...restMutation,
    error,
    isError,
  }
}
