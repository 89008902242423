import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { platforms } from '~/config'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderDeveloperHosting =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params }: LoaderFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    invariant(userId, `Must be logged in: ${userId}`)
    invariant(email, `Must be logged in: ${userId}`)
    const { organisationId, platformId, aggregateId } = params

    const qPlatform = queryPlatformTeam(organisationId)
    const fetchPlatform = await queryClient.ensureQueryData(qPlatform)

    if (!fetchPlatform?.data?.cloudPlatform) {
      return redirect(
        `/${organisationId}/${platformId}/developer/${aggregateId}/config-cloud-provider?redirect=/${organisationId}/${platformId}/developer/${aggregateId}/edit-hosting`,
      )
    }

    // return null
    const hostings = platforms.find(
      (platform) => platform.identity === fetchPlatform.data?.cloudPlatform,
    )?.services.hostingStrategies
    return hostings ?? null
  }
