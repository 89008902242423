import {
  LoadingButton as MuiLoadingButton,
  type LoadingButtonProps,
} from '@mui/lab'
import {
  Button as MuiButton,
  Tooltip as MuiTooltip,
  styled,
  type ButtonProps,
} from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { contextButton } from '~/styles/components'

export const buttonClasses = twMerge(contextButton, 'p-2 min-w-fit')

export const iconClasses = 'h-[20px] w-[20px]'

export const Tooltip = styled(MuiTooltip)``
Tooltip.defaultProps = {
  arrow: true,
  placement: 'top',
}

const buttonProps = {
  color: 'primary',
  size: 'small',
  variant: 'outlined',
}

export const Button = styled(MuiButton)``
Button.defaultProps = {
  ...(buttonProps as ButtonProps),
}

export const LoadingButton = styled(MuiLoadingButton)``
LoadingButton.defaultProps = {
  ...(buttonProps as LoadingButtonProps),
}
