import { Button, ButtonProps, styled } from '@mui/material'

export const WizardButton = styled((props: ButtonProps) => (
  <Button {...props} />
))({
  borderRadius: 12,
  fontSize: '1.25rem',
  fontWeight: 400,
  padding: '18px 34px',
  lineHeight: 1,
})
