import { Button, ButtonProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useRef, useState } from 'react'

const CircularButton = styled(Button)(({ theme }) => ({
  borderRadius: '50%',
  width: '8px',
  height: '8px',
  minWidth: 'unset',
  padding: 0,
  backgroundColor: '#23c0c0',
  color: 'white',
  fontSize: 0,
  lineHeight: 0,
  position: 'relative',
  overflow: 'hidden',
}))

const ArrowSVG = styled('svg')<{ direction: string }>(({ direction }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: `translate(-50%, -50%) rotate(${
    direction === 'Up'
      ? '-90deg'
      : direction === 'Down'
        ? '90deg'
        : direction === 'Left'
          ? '180deg'
          : '0deg'
  })`,
  width: '60%',
  height: '60%',
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
}))

interface CircleButtonProps extends ButtonProps {
  direction?: 'Up' | 'Down' | 'Left' | 'Right'
}

const CircleButton: React.FC<CircleButtonProps> = ({
  direction = 'Right',
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    const button = buttonRef.current
    if (!button) return

    const handleMouseEnter = () => {
      button.style.transform = 'scale(4)'
      button.style.transition = 'transform 0.3s ease-in-out'
      setIsHovered(true)
    }

    const handleMouseLeave = () => {
      button.style.transform = 'scale(1)'
      setIsHovered(false)
    }

    button.addEventListener('mouseenter', handleMouseEnter)
    button.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      button.removeEventListener('mouseenter', handleMouseEnter)
      button.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [])

  return (
    <CircularButton ref={buttonRef} variant="contained" {...props}>
      <ArrowSVG
        viewBox="0 0 24 24"
        style={{ opacity: isHovered ? 1 : 0 }}
        direction={direction}
      >
        <path
          fill="white"
          d="M5 13h11.86l-3.63 4.36a1 1 0 0 0 1.54 1.28l5-6a1.19 1.19 0 0 0 .09-.15c0-.05.05-.08.07-.13A1 1 0 0 0 20 12a1 1 0 0 0-.07-.36c0-.05-.05-.08-.07-.13a1.19 1.19 0 0 0-.09-.15l-5-6A1 1 0 0 0 14 5a1 1 0 0 0-.64.23 1 1 0 0 0-.13 1.41L16.86 11H5a1 1 0 0 0 0 2z"
        />
      </ArrowSVG>
    </CircularButton>
  )
}

export default CircleButton
