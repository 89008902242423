export const HOSTNAME_LOCAL = 'localhost'
export const HOSTNAME_PROD = 'app.dot-star.ie'
export const HOSTNAME_PROD_2 = 'app.dot-star.ai'

export function shouldShowOnlyInDev() {
  return window.location.hostname === HOSTNAME_LOCAL
}

export function shouldShowInProd() {
  return (
    window.location.hostname === HOSTNAME_PROD ||
    window.location.hostname === HOSTNAME_PROD_2 ||
    window.location.hostname === HOSTNAME_LOCAL
  )
}
