import { shouldShowOnlyInDev } from '~/config/environments'

export const IS_DEV = shouldShowOnlyInDev()

export const TIMER = {
  DEFAULT: 5 * 1000, // 5 seconds
  REFRESH_GENERATING_PROCESS: 5 * 1000,
  DEBOUNCE_DELAY_IN_MS: 3000,
  REFRESH_DATA_FOR_SERVICE_MAP: 5 * 1000,
}

export const STRIPE = {
  PRICING_TABLE_ID: 'prctbl_1NkS76Ixdudlpev86H2FcLOm',
  PUBLISHABLE_KEY:
    'pk_test_51Nk2RQIxdudlpev8k7cP0hRhc9vEDJUpu286Stvq0fpiEIUzHZx9mtNGdJCyHJwvOSUuVCKua2b932rp6bcXAVAX00o4TfdCEb',
  SCRIPT_URL: 'https://js.stripe.com/v3/pricing-table.js',
}

export const ErrorMessage = {
  PlatformConfigurationGitCredentials:
    'We could not go through with the information provided. Please verify the information about the git credentials are correct and try again.',
}
