import type { Node, NodeProps } from '@xyflow/react'
import { isEqual } from 'lodash'
import { useForm, type FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { StatementEditionBoxFormEnum as FormEnum } from '~/models/enums/forms/StatementEditionBoxFormEnum'
import {
  STATEMENT_PARSING_INFO_TYPE,
  type NodeStatement,
  type PayloadUpdateStatementParsingInfoCommand,
} from '~/services/Process.types'

type UseExpandedStepContentProps = {
  /** The node data. */
  data: NodeProps<Node<NodeStatement>>
}

/**
 * Hook to support `expanded step` features.
 */
export const useExpandedStepContent = (props: UseExpandedStepContentProps) => {
  const {
    data: { data, id },
  } = props

  const {
    actor,
    action,
    aggregate,
    boundedContext,
    isEditable,
    raisedDomainEvents,
    reactingToDomainEvent,
    requiredDataInfo,
    type,
    updateStatement,
  } = data || {}

  // Hooks.
  const params = useParams()
  const { processId } = params

  const formDefaultValues = {
    [FormEnum.ACTION]: action || '',
    [FormEnum.AGGREGATE]: aggregate || '',
    [FormEnum.BOUNDED_CONTEXT]: boundedContext || '',
    [FormEnum.RAISED_DOMAIN_EVENTS]: raisedDomainEvents,
    [FormEnum.REQUIRED_DATA_INFO]: requiredDataInfo,
    [FormEnum.TYPE]: type,
    ...(type === STATEMENT_PARSING_INFO_TYPE.Reaction
      ? {
          [FormEnum.REACTING_TO_DOMAIN_EVENT]: reactingToDomainEvent || {},
        }
      : { [FormEnum.ACTOR]: actor || '' }),
  }

  const { control, handleSubmit, watch } = useForm<FieldValues>({
    values: formDefaultValues,
  })

  // Methods.
  // Prepare the data and call the mutation.
  const handleFormSubmit = (formData: FieldValues) => {
    const payloadData = {
      processId,
      statementId: id,
      parsingInfo: {
        [FormEnum.ACTION]: formData!.action,
        [FormEnum.AGGREGATE]: formData!.aggregate,
        [FormEnum.BOUNDED_CONTEXT]: formData!.boundedContext,
        [FormEnum.RAISED_DOMAIN_EVENTS]: formData!.raisedDomainEvents,
        [FormEnum.REQUIRED_DATA_INFO]: formData!.requiredDataInfo,
        [FormEnum.TYPE]: formData!.type,
        ...(formData!.type === STATEMENT_PARSING_INFO_TYPE.Reaction
          ? {
              [FormEnum.REACTING_TO_DOMAIN_EVENT]:
                formData!.reactingToDomainEvent,
            }
          : { [FormEnum.ACTOR]: formData!.actor }),
      },
    } as unknown as PayloadUpdateStatementParsingInfoCommand

    // Don't submit if the form didn't change.
    if (isEqual(formDefaultValues, payloadData.parsingInfo)) return

    updateStatement?.(payloadData)
  }

  return {
    control,
    handleFormSubmit,
    handleSubmit,
    isEditable,
    watch,
  }
}
