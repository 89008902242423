import CheckIcon from '@mui/icons-material/Check'
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { Link, useParams } from 'react-router-dom'
import { Column } from '~/components/Column'
import { Text } from '~/components/Text'
import type { GenerateCodeTilesBaseProps } from '~/models/types/components/generateCode/GenerateCodeTilesBaseProps'
import type { ResponseListGenerationStrategies } from '~/services/GenerationStrategy.types'

export type CustomAiBlueprintTileProps = GenerateCodeTilesBaseProps & {
  /** Array of AI blueprints (code generation strategies). */
  blueprints?: ResponseListGenerationStrategies[]
}

/**
 * The `custom Ai blueprint tile`
 * from the generate code modal.
 */
export const CustomAiBlueprintTile = (props: CustomAiBlueprintTileProps) => {
  const { blueprints, onTileClick, selectedStrategyType, shouldDisableFields } =
    props

  // URL params.
  const params = useParams<Record<string, string>>()
  const { organisationId, platformId } = params

  // Common props for the link component.
  const aiBlueprintsLinkProps = {
    rel: 'noopener noreferrer',
    to: `/${organisationId}/${platformId}/configuration/code-generation-strategies`,
    target: '_blank',
  }

  return (
    <Column
      className={twMerge(
        'flex flex-1 gap-2 rounded-lg border-2 border-solid border-slate-200 p-4',
        selectedStrategyType === 'custom' && 'border-primary-300',
      )}
      onClick={onTileClick}
      data-type="custom"
    >
      <div
        className={twMerge(
          '-mx-4 -mt-4 flex cursor-pointer justify-between gap-2 rounded-lg rounded-b-none bg-tertiary-50 px-4 py-2',
          selectedStrategyType === 'custom' && 'bg-primary-50',
        )}
      >
        <Text>Custom AI Blueprint</Text>

        {selectedStrategyType === 'custom' ? (
          <CheckIcon className="text-primary" />
        ) : (
          <CheckIcon className="invisible text-primary" />
        )}
      </div>

      {!!blueprints?.length && (
        <Text>
          Select all the applicable options or{' '}
          <Link
            className="transition hover:text-teal-700"
            {...aiBlueprintsLinkProps}
          >
            add a custom AI Blueprint
          </Link>
          .
        </Text>
      )}

      {!blueprints?.length && (
        <>
          <Text>
            There are no custom AI Blueprints, please click the following button
            to create one:
          </Text>

          <Button
            component={Link}
            variant="outlined"
            {...aiBlueprintsLinkProps}
          >
            Add a custom AI Blueprint
          </Button>
        </>
      )}

      {!!blueprints?.length && (
        <FormGroup>
          {blueprints?.map((strategy) => (
            <FormControlLabel
              key={strategy.identity}
              disabled={
                (selectedStrategyType !== 'custom' &&
                  selectedStrategyType !== undefined) ||
                shouldDisableFields
              }
              label={
                strategy.description
                  ? strategy.name + ' - ' + strategy.description
                  : strategy.name
              }
              name="strategy"
              value={strategy.identity}
              control={<Checkbox />}
            />
          ))}
        </FormGroup>
      )}
    </Column>
  )
}
