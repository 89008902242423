import { AxiosResponse } from 'axios'
import invariant from 'tiny-invariant'
import {
  DiscoveryOrganisationAPI,
  serviceDiscoveryOrganisation,
} from '~/services/base'
import {
  ResponseDiscoveryOrganisation,
  ResponseGetUserProfileByOrganisationId,
  ResponseGetUsersByOrganisationId,
} from '~/services/Discovery.types'

export const queryKeyOrganisation = (ownerId?: GUID, email?: string) => {
  if (!ownerId && !email) {
    return ['discovery', 'organisation']
  }

  return ['discovery', 'organisation', { email }, { ownerId }]
}

export const queryOrganisation = <T = ResponseDiscoveryOrganisation>(
  ownerId: GUID = '',
  email: string = '',
) => ({
  queryKey: ['discovery', 'organisation', { email }, { ownerId }],
  queryFn: async () => {
    const url = DiscoveryOrganisationAPI.getByOwnerIdOrEmail({ ownerId, email })
    const req = await serviceDiscoveryOrganisation(url)
    return req as AxiosResponse<T, unknown>
  },
})

export const queryKeyUsersByOrganisation = (organisationId: GUID) => [
  'discovery',
  'organisation',
  'users',
  { organisationId },
]

export const queryUsersByOrganisation = <T = ResponseGetUsersByOrganisationId>(
  organisationId?: GUID,
) => {
  invariant(organisationId, 'Organisation ID is required')

  return {
    queryKey: queryKeyUsersByOrganisation(organisationId),
    queryFn: async () => {
      const url =
        DiscoveryOrganisationAPI.getUsersByOrganisationId(organisationId)
      const req = await serviceDiscoveryOrganisation(url)
      return req as AxiosResponse<T, unknown>
    },
  }
}

export const queryKeyUserProfileByOrganisation = (organisationId: GUID) => [
  'discovery',
  'organisation',
  'user-profile',
  { organisationId },
]

export const queryUserProfileByOrganisation = <
  T = ResponseGetUserProfileByOrganisationId,
>(
  organisationId?: GUID,
) => {
  invariant(organisationId, 'Organisation ID is required')

  return {
    queryKey: queryKeyUsersByOrganisation(organisationId),
    queryFn: async () => {
      const url =
        DiscoveryOrganisationAPI.getUserProfileByOrganisationId(organisationId)
      const req = await serviceDiscoveryOrganisation(url)
      return req as AxiosResponse<T, unknown>
    },
  }
}
