import { Button } from '@mui/material'
import { Form } from 'react-router-dom'

export function Welcome() {
  return (
    <>
      <Form method="post" id="form-welcome">
        <Button variant="contained" type="submit">
          Thank you
        </Button>
      </Form>
    </>
  )
}
