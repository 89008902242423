import { useMsal } from '@azure/msal-react'
import { getUserIdByMsal } from '~/utils/func'

export const useGetUserId = () => {
  const { accounts } = useMsal()
  const userId = getUserIdByMsal(accounts)

  return {
    userId,
  }
}
