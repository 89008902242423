import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs, generatePath, redirect } from 'react-router-dom'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import { queryOrganisation } from '~/services/Discovery'
import { queryProcessListByOrganisationId } from '~/services/Process'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderBusinessConfigBusiness =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)

    // if no user, redirect to login page
    if (!userId || !email) {
      return redirect('/')
    }

    const qOrganisation = queryOrganisation(userId, email)
    const fetchOrganisation = await queryClient.ensureQueryData(qOrganisation)
    const organisationId = fetchOrganisation.data?.identity

    if (!organisationId) {
      return redirect('/')
    }

    const qProcessList = queryProcessListByOrganisationId(organisationId)
    const { data: dataProcessList } =
      await queryClient.ensureQueryData(qProcessList)
    const { platformId } = params

    if (dataProcessList.length > 0) {
      return redirect(
        generatePath(BusinessRoutesEnum.BUSINESS, {
          organisationId: organisationId || '',
          platformId: platformId || '',
        }),
      )
    }

    return null
  }
