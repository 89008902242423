import {
  AddNodeButtons,
  type AddNodeButtonsProps,
} from '../AddNodeHelpers/AddNodeButtons'

type HelpersProps = AddNodeButtonsProps

export function Helpers(props: HelpersProps) {
  const {
    hasChildren,
    isLocal,
    isNodeDragging,
    isNodeLayerDragging,
    isSelected,
    onAddLocalChild,
    onAddLocalNextSibling,
    onAddLocalParent,
    onAddLocalPreviousSibling,
    parentStatementId,
    statementId,
  } = props

  return (
    <AddNodeButtons
      hasChildren={hasChildren}
      isLocal={isLocal}
      isNodeDragging={isNodeDragging}
      isNodeLayerDragging={isNodeLayerDragging}
      isSelected={isSelected}
      onAddLocalChild={onAddLocalChild}
      onAddLocalNextSibling={onAddLocalNextSibling}
      onAddLocalParent={onAddLocalParent}
      onAddLocalPreviousSibling={onAddLocalPreviousSibling}
      parentStatementId={parentStatementId}
      statementId={statementId}
    />
  )
}
