import { useCallback, useEffect, useState } from 'react'
import {
  useDrag,
  useDragDropManager,
  useDragLayer,
  type ConnectDragSource,
} from 'react-dnd'
import { NodeStatement } from '~/services/Process.types'

type UseStepProps = Pick<NodeStatement, 'id' | 'isLocal' | 'type'>

export type UseStepReturn = {
  /** The ref for the `useDrag` hook, to set up the draggable component. */
  dragRef: ConnectDragSource | null
  /** Set `should not drag` state to truthy. */
  handleShouldNotDrag: () => void
  /** Indicates if the current node is a command type. */
  isCommand?: boolean
  /** Indicates if there any of the draggable nodes are being dragged. */
  isAnyDragging?: boolean
  /** Indicates if the current node is being dragged. */
  isNodeDragging?: boolean
  /** Indicates if the current node dragging layer is being dragged. */
  isNodeLayerDragging?: boolean
  /** Indicates if the current node is a reaction type. */
  isReaction?: boolean
}

/**
 * Hook to support the steps components.
 */
export const useStep = (props: UseStepProps): UseStepReturn => {
  const { id, isLocal, type } = props

  // States.
  // Indicates if any of draggable nodes are being dragged.
  const [isAnyDragging, setIsAnyDragging] = useState<boolean>(false)
  // Additional drag control.
  const [shouldNotDrag, setShouldNotDrag] = useState<boolean>(false)

  // Step type states.
  const isCommand = type === 'Command'
  const isReaction = type === 'Reaction'

  // Manage dragging node state.
  const [{ isDragging: isNodeDragging }, dragRef] = useDrag(
    () => ({
      type: 'node',
      item: { id },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id],
  )

  // Manager drag layer state.
  const { item: dragLayerItem } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
  }))

  // Manage drag and drop states.
  const manager = useDragDropManager()

  // Handle the `isDragging` state change based on `react-dnd` state.
  const handleDraggingStateChange = useCallback(() => {
    const monitor = manager.getMonitor()
    setIsAnyDragging(monitor.isDragging())
  }, [manager])

  // Subscribe to watch for `react-dnd` states.
  useEffect(() => {
    const unsubscribe = manager
      .getMonitor()
      .subscribeToStateChange(handleDraggingStateChange)

    return unsubscribe
  }, [manager, handleDraggingStateChange])

  // Set `should not drag` to true - called at the component level.
  const handleShouldNotDrag = useCallback(() => {
    setShouldNotDrag(true)
  }, [setShouldNotDrag])

  /**
   * Callback handler for `mouseup` event:
   * Needs to be set as document listener because the component one
   * is never called due to start of a connection from `reactflow`.
   */
  const handleMouseUp = useCallback(() => {
    setShouldNotDrag(false)
  }, [setShouldNotDrag])

  // Set `mouseup` event listener.
  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [handleMouseUp])

  return {
    dragRef: isLocal || shouldNotDrag ? null : dragRef,
    handleShouldNotDrag,
    isCommand,
    isAnyDragging,
    isNodeDragging,
    isNodeLayerDragging: dragLayerItem?.id === id,
    isReaction,
  }
}
