import ErrorIcon from '@mui/icons-material/Error'
import { Button } from '@mui/material'
import {
  Link as RouterLink,
  useLocation,
  useRouteError,
} from 'react-router-dom'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'

export function Error() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error = useRouteError() as any
  const location = useLocation()

  return (
    <>
      <div className="flex h-screen flex-col items-center justify-center gap-3 bg-[#fff4f4] dark:bg-[#3c3232] dark:text-[#fff4f4]">
        <ErrorIcon color="error" fontSize="large" />
        <Text className="text-3xl">Sorry, an error has ocurred</Text>
        <Text className="text-xs uppercase">
          {error?.statusText || error?.message}
        </Text>
        <Row className="mt-2 gap-4">
          <Button
            component={RouterLink}
            to={location.pathname}
            variant="contained"
            reloadDocument
          >
            Reload page
          </Button>
          <Button component={RouterLink} to="/" variant="outlined">
            Go home
          </Button>
        </Row>
      </div>
    </>
  )
}
