export function KeyBackspaceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#B6F1E6"
        d="M2 7a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7Z"
      />
      <path
        fill="#080D97"
        d="M3 6.5h18v-1H3v1Zm18.5.5v10h1V7h-1ZM21 17.5H3v1h18v-1ZM2.5 17V7h-1v10h1Zm.5.5a.5.5 0 0 1-.5-.5h-1A1.5 1.5 0 0 0 3 18.5v-1Zm18.5-.5a.5.5 0 0 1-.5.5v1a1.5 1.5 0 0 0 1.5-1.5h-1ZM21 6.5a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 21 5.5v1Zm-18-1A1.5 1.5 0 0 0 1.5 7h1a.5.5 0 0 1 .5-.5v-1ZM7.857 15V8.7h2.214c.444 0 .81.072 1.098.216.294.138.513.33.657.576.144.24.216.51.216.81 0 .408-.11.729-.333.963a1.699 1.699 0 0 1-.783.486 1.51 1.51 0 0 1 1.089.819 1.5 1.5 0 0 1 .171.71c0 .319-.078.61-.234.874a1.67 1.67 0 0 1-.693.62c-.306.15-.678.226-1.116.226H7.857Zm.756-3.546h1.404c.402 0 .711-.093.927-.28.216-.191.324-.455.324-.791 0-.318-.108-.573-.324-.765-.21-.192-.528-.288-.954-.288H8.613v2.124Zm0 2.916h1.45c.431 0 .764-.1.998-.297.234-.204.351-.486.351-.846 0-.354-.123-.636-.369-.846-.246-.216-.579-.324-.999-.324h-1.43v2.313ZM15.278 15.108c-.462 0-.864-.084-1.206-.252a1.883 1.883 0 0 1-.791-.702A1.94 1.94 0 0 1 13 13.11h.793c0 .246.056.474.17.684.114.204.28.369.495.495.223.12.495.18.82.18.425 0 .756-.102.99-.306a.973.973 0 0 0 .35-.765c0-.252-.053-.453-.162-.603a1.185 1.185 0 0 0-.44-.378c-.18-.096-.39-.18-.63-.252-.235-.072-.48-.153-.739-.243-.486-.168-.845-.375-1.08-.621-.233-.252-.35-.58-.35-.981a1.585 1.585 0 0 1 .233-.891c.162-.264.39-.468.684-.612.3-.15.655-.225 1.063-.225.402 0 .75.075 1.043.225a1.699 1.699 0 0 1 .954 1.521h-.791c0-.174-.046-.345-.136-.513a1.038 1.038 0 0 0-.414-.423c-.18-.108-.407-.162-.683-.162-.342-.006-.624.08-.846.26-.216.18-.324.43-.324.748 0 .27.075.477.225.62.155.145.371.265.647.36.277.09.595.196.955.316.3.108.57.234.81.378.24.144.425.33.557.558.139.228.207.519.207.873 0 .3-.078.585-.233.855-.157.264-.393.48-.712.648-.312.168-.705.252-1.179.252Z"
      />
    </svg>
  )
}
