import type { MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'
import CircleButton from '~/components/CircularButton'
import type { StatementsDiagramBaseProps } from '~/models/types/components/processInfo/StatementsDiagramBaseProps'
import type { NodeStatement } from '~/services/Process.types'
import type { UseStepReturn } from '../Step/useStep'

// Base classes.
const WRAPPER_CLASSES = 'z-30 absolute'

// Left and right base classes.
const WRAPPER_HORIZONTAL_CLASSES = 'top-[48%]'

// Top and bottom base classes.
const WRAPPER_VERTICAL_CLASSES = 'left-[49%]'

export type AddNodeButtonsProps = Pick<
  StatementsDiagramBaseProps,
  | 'onAddLocalChild'
  | 'onAddLocalNextSibling'
  | 'onAddLocalParent'
  | 'onAddLocalPreviousSibling'
> &
  Pick<
    NodeStatement,
    'hasChildren' | 'isLocal' | 'isSelected' | 'parentStatementId'
  > &
  Pick<UseStepReturn, 'isNodeDragging' | 'isNodeLayerDragging'> & {
    /** The current statement ID. */
    statementId?: GUID
  }

/**
 * The add statement node buttons,
 * part of the `AddNodeHelpers` component.
 */
export const AddNodeButtons = (props: AddNodeButtonsProps) => {
  const {
    hasChildren,
    isLocal,
    isNodeDragging,
    isNodeLayerDragging,
    isSelected,
    onAddLocalChild,
    onAddLocalNextSibling,
    onAddLocalParent,
    onAddLocalPreviousSibling,
    parentStatementId,
    statementId,
  } = props

  if (isLocal || !isSelected || isNodeDragging || isNodeLayerDragging)
    return null

  // Handles the addition of a local child node.
  const handleAddLocalChild = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    onAddLocalChild?.(hasChildren, statementId)
  }

  // Handles the addition of a local next sibling node.
  const handleAddLocalNextSibling = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    onAddLocalNextSibling?.(statementId)
  }

  // Handles the addition of a local parent node.
  const handleAddLocalParent = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    onAddLocalParent?.(parentStatementId, statementId)
  }

  // Handles the addition of a local previous sibling node.
  const handleAddLocalPreviousSibling = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    onAddLocalPreviousSibling?.(statementId)
  }

  return (
    <>
      {/* Add a node to the top. */}
      <CircleButton
        className={twMerge(
          WRAPPER_CLASSES,
          WRAPPER_VERTICAL_CLASSES,
          '-top-[25px]',
        )}
        direction="Up"
        onClick={handleAddLocalPreviousSibling}
        title="Add a node to the top."
      />

      {/* Add a node to the right. */}
      <CircleButton
        className={twMerge(
          WRAPPER_CLASSES,
          WRAPPER_HORIZONTAL_CLASSES,
          '-right-[25px]',
        )}
        direction="Right"
        onClick={handleAddLocalChild}
        title="Add a node to the right."
      />

      {/* Add a node to the bottom. */}
      <CircleButton
        className={twMerge(
          WRAPPER_CLASSES,
          WRAPPER_VERTICAL_CLASSES,
          '-bottom-[25px]',
        )}
        direction="Down"
        onClick={handleAddLocalNextSibling}
        title="Add a node to the bottom."
      />

      {/* Add a node to the left. */}
      <CircleButton
        className={twMerge(
          WRAPPER_CLASSES,
          WRAPPER_HORIZONTAL_CLASSES,
          '-left-[25px]',
        )}
        direction="Left"
        onClick={handleAddLocalParent}
        title="Add a node to the left."
      />
    </>
  )
}
