import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, generatePath, redirect } from 'react-router-dom'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { queryKeyDevelopment } from '~/services/Development'
import { getLocalUserId } from '~/utils/getUserId'

export const actionDeveloperPublishReaction =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const userId = getLocalUserId(pca)
    const { aggregateId, organisationId, platformId, processId, reactionId } =
      params

    const formData = await request.formData()
    const commitMessage = formData.get('commitMessage')
    const branch = formData.get('branch')

    // DTO to send data
    const postData = {
      aggregateId,
      reactionId,
      developerId: userId,
      commitMessage,
      branch,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.PublishReaction
    const req = await serviceDevelopmentAggregate.post(url, postData)
    const requestUrl = new URL(request.url)
    const isBusiness = requestUrl.pathname.includes('business')

    if (req.status === 200) {
      await queryClient.refetchQueries({
        queryKey: queryKeyDevelopment(platformId),
      })

      if (isBusiness) {
        return redirect(
          generatePath(BusinessRoutesEnum.BUSINESS_PROCESS, {
            organisationId: organisationId || '',
            platformId: platformId || '',
            processId: processId || '',
          }),
        )
      }

      return redirect(`/${organisationId}/${platformId}/developer`)
    }

    throw req
  }
