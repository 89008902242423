import type { Viewport } from '@xyflow/react'
import { createContext, type Dispatch, type SetStateAction } from 'react'

/**
 * ReactFlow state:
 * - `process id` as a key - the process the flow related to;
 * - `viewport` data as the value - used to set the viewport settings.
 */
export type ReactFlowState = {
  [key: string]: Viewport
}

export type ReactFlowStatesContextProps = {
  /** The `service map` viewport params. */
  serviceMapViewport?: Viewport
  /** The set state for `service map viewport` state. */
  setServiceMapViewport?: Dispatch<SetStateAction<Viewport | undefined>>
  /** The `statements diagram` viewport params. */
  statementsDiagramViewport?: ReactFlowState
  /** The set state for `statements diagram viewport` state. */
  setStatementsDiagramViewport?: Dispatch<
    SetStateAction<ReactFlowState | undefined>
  >
}

const defaultValue = {}

/**
 * Context for React Flow states.
 */
const ReactFlowStatesContext =
  createContext<ReactFlowStatesContextProps>(defaultValue)

export default ReactFlowStatesContext
