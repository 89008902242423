/**
 * Enum with the form fields names
 * from `statement edition box` form.
 */
export enum StatementEditionBoxFormEnum {
  ACTION = 'action',
  ACTOR = 'actor',
  AGGREGATE = 'aggregate',
  BOUNDED_CONTEXT = 'boundedContext',
  EDIT_RAISED_DOMAIN_EVENT = 'editRaisedDomainEvent',
  EDIT_REQUIRED_DATA_INFO = 'editRequiredDataInfo',
  RAISED_DOMAIN_EVENT = 'raisedDomainEvent',
  RAISED_DOMAIN_EVENTS = 'raisedDomainEvents',
  REACTING_TO_DOMAIN_EVENT = 'reactingToDomainEvent',
  REQUIRED_DATA_INFO = 'requiredDataInfo',
  TYPE = 'type',
}
