import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  SvgIcon,
} from '@mui/material'
import { Form, useNavigation } from 'react-router-dom'
import { WizardContent } from '~/components/WizardContent'
import { platforms } from '~/config'
import { switchIcon } from '~/routes/setup/platform/utils'

export function PlatformCloud() {
  const navigation = useNavigation()
  const isButtonDisabled = navigation.state !== 'idle'

  return (
    <WizardContent
      step="1"
      intro=""
      title={
        <>
          Please select the <strong>Cloud Provider</strong>
        </>
      }
    >
      <Stack direction="row" gap={2} sx={{ ml: 2 }}>
        <Paper sx={{ px: 2, py: 1 }}>
          <List aria-labelledby="label-select-cloud-provider">
            {platforms.map((platform) => (
              <ListItem disablePadding key={platform.identity}>
                <Box
                  component={Form}
                  method="post"
                  id="form-setup-platform-cloud"
                  sx={{ flexGrow: 1, display: 'flex' }}
                >
                  <input
                    type="hidden"
                    defaultValue={platform.identity}
                    name="cloudPlatformName"
                  />
                  <ListItemButton
                    disabled={platform.disabled || isButtonDisabled}
                    component="button"
                    type="submit"
                  >
                    <ListItemIcon>
                      <SvgIcon>{switchIcon(platform.identity)}</SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={platform.name} />
                  </ListItemButton>
                </Box>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Stack>
    </WizardContent>
  )
}
