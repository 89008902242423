import { useContext } from 'react'
import AppSnackbarContext, {
  type AppSnackbarContextProps,
} from '~/contexts/AppSnackbarContext'

/**
 * Hook to consume the App Snackbar context states.
 */
export const useAppSnackbarContext = (): AppSnackbarContextProps => {
  const context = useContext(AppSnackbarContext)

  return context
}
