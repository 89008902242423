import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import { queryKeyPlatformTeam } from '~/services/PlatformTeam'
import { PlatformPostResponse } from '~/services/PlatformTeam.types'
import { PlatformTeamAPI, servicePlatformTeam } from '~/services/base'

export const actionDeveloperCloudProvider =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params, context }: ActionFunctionArgs) => {
    const { organisationId, platformId } = params

    // Get form data
    const formData = await request.formData()
    const cloudPlatformName = formData.get('name')

    // Builds the data to send to server
    const postData = {
      platformId,
      cloudPlatformName,
    }

    // Setups and send to server
    const url = PlatformTeamAPI.SelectCloudPlatform
    const req = await servicePlatformTeam.post<PlatformPostResponse>(
      url,
      postData,
    )

    if (req.status === 200) {
      await queryClient.invalidateQueries({
        queryKey: queryKeyPlatformTeam(organisationId),
      })
      const queryStrings = new URL(document.location.toString()).searchParams
      const redirectPath = queryStrings.get('redirect')
      if (redirectPath) {
        return redirect(redirectPath)
      }
      return redirect(`/${organisationId}/${platformId}/developer`)
    }

    return redirect(`/${organisationId}/${platformId}/developer`)
  }
