import RestoreIcon from '@mui/icons-material/Restore'
import { Button, Divider } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { ValueBindingTreeItemFormEnum } from '~/models/enums/forms/ValueBindingTreeItemFormEnum'
import type { FormHook } from '~/models/types/hooks/FormHook'
import { RevertToOriginalValueLabel } from './RevertToOriginalValueLabel'

export type RevertToOriginalValueProps = Pick<
  FormHook,
  'setFormValue' | 'watchForm'
> & {
  /** The class name applied to the containing node. */
  className?: string
  /** If `true`, only the revert button will be displayed. */
  onlyButton?: boolean
  /** The property binding field original value. */
  originalValue?: string | null
  /** The property binding field current value. */
  value?: string | null
}

/**
 * The `revert to original value` component.
 */
export const RevertToOriginalValue = (props: RevertToOriginalValueProps) => {
  const { className, onlyButton, originalValue, value, setFormValue } = props

  // Handler for revert to original click.
  const handleRevertToOriginalClick = () => {
    if (originalValue) {
      setFormValue?.(ValueBindingTreeItemFormEnum.VALUE, originalValue ?? '', {
        shouldDirty: true,
      })
      setFormValue?.(
        ValueBindingTreeItemFormEnum.PARAMETERS,
        JSON.stringify([]),
        { shouldDirty: true },
      )
    }
  }

  return (
    <div className={twMerge('p-1', className)}>
      {!onlyButton && (
        <div className="mb-2">
          <RevertToOriginalValueLabel label="Original" value={originalValue} />

          <Divider className="border-slate-600" />

          <RevertToOriginalValueLabel label="Current" value={value} />
        </div>
      )}

      {originalValue !== value && (
        <div className="flex items-center justify-center">
          <Button
            className="bg-slate-500 text-white hover:bg-slate-600"
            onClick={handleRevertToOriginalClick}
            size="small"
            startIcon={<RestoreIcon />}
            variant="contained"
          >
            Revert to original
          </Button>
        </div>
      )}
    </div>
  )
}
