import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs } from 'react-router-dom'
import { queryUserProfileByOrganisation } from '~/services/Discovery'
import { getOrganisation } from '~/utils/getOrganisation'

export const loaderConfigurationProfile =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const fetchOrganisation = await getOrganisation(queryClient, pca)
    const organisationId = fetchOrganisation.data.identity

    const qUserProfile = queryUserProfileByOrganisation(organisationId)
    const fetchUserProfile = queryClient.ensureQueryData(qUserProfile)

    // return data for organisation
    return {
      organisationId,
      profile: fetchUserProfile,
    }
  }
