import { useContext } from 'react'
import AppPopperContext, {
  type AppPopperContextProps,
} from '~/contexts/AppPopperContext'

/**
 * Hook to consume the App Popper context states.
 */
export const useAppPopperContext = (): AppPopperContextProps => {
  const context = useContext(AppPopperContext)

  return context
}
