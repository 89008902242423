import RestartAltIcon from '@mui/icons-material/RestartAlt'
import SaveIcon from '@mui/icons-material/Save'
import { Button, CircularProgress } from '@mui/material'
import React from 'react'
import { useNavigation } from 'react-router-dom'
import { FormFooterStyled } from '~/pages/developer/components/styled/FormFooter'

type FormFooterProps = {
  showReset?: boolean
  showConfirm?: boolean
  submitText?: string
  submittingText?: string
}
export function FormFooter({
  showReset = true,
  showConfirm = true,
  submitText = 'Save',
  submittingText = 'Saving...',
}: FormFooterProps) {
  const navigation = useNavigation()
  const isNavigating = navigation.state !== 'idle'
  const shouldDisableButtons = React.useMemo(() => {
    if (navigation.formData) {
      const checkIfButtonSubmitWasClicked = Object.fromEntries(
        navigation.formData,
      ).footerSubmit
      if (checkIfButtonSubmitWasClicked) {
        return true
      }

      const checkIfButtonResetWasClicked = Object.fromEntries(
        navigation.formData,
      ).footerReset
      if (checkIfButtonResetWasClicked) {
        return true
      }
    }

    return false
  }, [navigation.formData])

  return (
    <FormFooterStyled>
      {showReset ? (
        <Button
          variant="outlined"
          startIcon={<RestartAltIcon />}
          type="reset"
          name="footerReset"
          value="true"
          disabled={isNavigating}
        >
          Reset
        </Button>
      ) : null}
      {showConfirm && (
        <Button
          variant="contained"
          startIcon={
            shouldDisableButtons ? (
              <CircularProgress size="1rem" color="inherit" />
            ) : (
              <SaveIcon />
            )
          }
          type="submit"
          disabled={isNavigating}
          name="footerSubmit"
          value="true"
          sx={{ width: 'fit-content' }}
        >
          {shouldDisableButtons ? submittingText : submitText}
        </Button>
      )}
    </FormFooterStyled>
  )
}
