import { Stack, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Form, useActionData, useLoaderData, useParams } from 'react-router-dom'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { queryDevelopment } from '~/services/Development'
import { DomainByPlatformId } from '~/services/Development.types'

export function DeveloperEditDetails() {
  const errors = useActionData() as GenericObj<string> | undefined
  const params = useParams()
  const initialData = useLoaderData() as Awaited<
    AxiosResponse<DomainByPlatformId | null, unknown>
  >
  const {
    data,
    // isInitialLoading, // etc
  } = useQuery({
    ...queryDevelopment(params.platformId),
    initialData: initialData,
    select: (data) => {
      if (data.data && params.aggregateId) {
        const boundedContext = data.data.boundedContexts.find((context) =>
          context.aggregates.find(
            (aggregate) => aggregate.identity === params.aggregateId,
          ),
        )
        const aggregate = boundedContext?.aggregates.find(
          (aggregate) => aggregate.identity === params.aggregateId,
        )

        return {
          boundedContext: boundedContext?.name,
          name: aggregate?.name,
        }
      }

      return
    },
  })

  if (data) {
    return (
      <>
        <Stack gap={2}>
          <Typography>Please enter the service details:</Typography>
          <Form method="post" id="form-aggregate-edit-details">
            <Stack gap={2}>
              <TextField
                label="Bounded Context"
                variant="outlined"
                name="boundedContext"
                defaultValue={data.boundedContext}
                error={!!errors?.boundedContext}
                helperText={errors?.boundedContext ? errors.boundedContext : ''}
              />
              <TextField
                label="Service Name"
                variant="outlined"
                name="name"
                defaultValue={data.name}
                error={!!errors?.name}
                helperText={errors?.name ? errors.name : ''}
              />
              <FormFooter />
            </Stack>
          </Form>
        </Stack>
      </>
    )
  }

  return null
}
