import { TextField, TextFieldProps, styled } from '@mui/material'

export const WizardTextField = styled((props: TextFieldProps) => (
  <TextField variant="outlined" {...props} />
))({
  '.MuiInputBase-root': {
    borderRadius: 10,
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#5d62ec',
  },
  '.MuiFormLabel-root': {
    color: '#9396f2',
    left: 12,
  },
  '.MuiInputLabel-root': {
    left: 'auto',
  },
})
