import {
  Box,
  Modal,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import {
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { ExternalLink } from '~/components/ExternalLink'
import { ResponseDiscoveryOrganisation } from '~/services/Discovery.types'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import { PlatformByOrganisation } from '~/services/PlatformTeam.types'
import { WizardStepIcon } from '~/styled/WizardBullet'
import { getPlatformSetupWizardSteps } from './PlatformSetupModal.utils'

/**
 * Modal with wizard steps to setup
 * the Cloud Provider platform.
 */
export function PlatformSetupModal() {
  const params = useParams()

  const initialData = useLoaderData() as {
    organisation: Awaited<
      AxiosResponse<ResponseDiscoveryOrganisation | null, unknown>
    >
    platform: Awaited<AxiosResponse<PlatformByOrganisation | null, unknown>>
    organisationId?: string
  }

  const navigate = useNavigate()

  const location = useLocation()

  const { data: dataPlatform } = useQuery({
    ...queryPlatformTeam(initialData.organisationId),
    initialData: initialData.platform,
    enabled: !!initialData?.organisationId,
  })

  const wizardSteps = getPlatformSetupWizardSteps(dataPlatform?.data)

  function handleClose() {
    const isBusinessPage = location.pathname.includes('business')
    if (isBusinessPage) {
      const aggregateId = params.aggregateId
      const pathWithoutConfig = location.pathname.split(
        aggregateId ? `/${aggregateId}/platform-setup` : '/platform-setup',
      )
      if (pathWithoutConfig[0]) {
        return navigate(pathWithoutConfig[0])
      }
    }
    return navigate(`..`)
  }

  return (
    <Modal disableAutoFocus open={true} onClose={handleClose} disablePortal>
      <Paper
        elevation={7}
        className="absolute left-1/2 top-1/2 w-[80%] max-w-[800px] -translate-x-1/2 -translate-y-1/2 transform overflow-hidden rounded-2xl"
      >
        <div className="grid grid-cols-[2fr_1fr]">
          <Box>
            <Box gap={2.75} sx={{ pt: 5, pl: 5, pr: 6 }}>
              <p className="m-0 mb-4 text-2xl text-primary">
                <>
                  Dot-star will generate shared infrastructure code using{' '}
                  <ExternalLink href="https://www.terraform.io/">
                    Terraform
                  </ExternalLink>
                  , as well as pipeline code for{' '}
                  <ExternalLink href="https://azure.microsoft.com/en-us/products/devops">
                    Azure DevOps
                  </ExternalLink>
                  .
                </>
              </p>
            </Box>

            <Outlet />
          </Box>

          <Box
            sx={{
              bgcolor: 'background.bright',
              p: 4,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Stepper
              orientation="vertical"
              activeStep={wizardSteps.filter((step) => step.content).length}
              sx={{
                '.MuiStepLabel-iconContainer': {
                  pr: 2,
                },
                '.MuiStepLabel-label, .MuiStepLabel-label.Mui-completed': {
                  color: '#6A6DA3',
                  fontWeight: 400,
                },
                '.MuiStepConnector-root': {
                  ml: 2,
                },
              }}
            >
              {wizardSteps.map((step) => (
                <Step key={step.id}>
                  <StepLabel StepIconComponent={WizardStepIcon}>
                    {step.label}
                    {step.content ? (
                      typeof step.content === 'string' &&
                      step.content.length > 26 ? (
                        <Tooltip title={step.content}>
                          <Typography
                            variant="welcomeWizardStepper"
                            component="p"
                            color="primary"
                            sx={{
                              lineHeight: 1,
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              maxWidth: '19ch',
                            }}
                          >
                            <>{step.content}</>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          variant="welcomeWizardStepper"
                          component="p"
                          color="primary"
                          sx={{
                            lineHeight: 1,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: '19ch',
                          }}
                        >
                          <>{step.content}</>
                        </Typography>
                      )
                    ) : null}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>
      </Paper>
    </Modal>
  )
}
