import { LoadingButton } from '@mui/lab'
import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

import { MappingConditionFormEnum } from '~/models/enums/forms/MappingConditionFormEnum'
import type { CodeGenerationStrategyDialogProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyDialogProps'
import {
  SlideTransition,
  type SlideTransitionProps,
} from '../../SlideTransition'
import DialogForm from '../DialogForm/DialogForm'
import { useDialogMappingCondition } from './useDialogMappingCondition'

/**
 * Dialog to add/edit a mapping condition.
 */
export function DialogMappingCondition(
  props: CodeGenerationStrategyDialogProps,
) {
  const { control, error, handleFormSubmit, handleSubmit, isPending } =
    useDialogMappingCondition(props)

  const { handleCloseDialog, isDialogOpen } = props

  return (
    <DialogForm
      actions={
        <LoadingButton loading={isPending} type="submit" variant="contained">
          Save
        </LoadingButton>
      }
      closeIconButton
      content={
        <Controller
          control={control}
          disabled={isPending}
          name={MappingConditionFormEnum.MAPPING_CONDITION}
          render={({ field }) => (
            <TextField
              label="Mapping Condition"
              fullWidth
              type="text"
              multiline
              size="medium"
              inputProps={{
                style: {
                  height: '100px',
                },
              }}
              {...field}
            />
          )}
        />
      }
      disableEscapeKeyDown={isPending}
      error={error as string}
      formProps={{ onSubmit: handleSubmit?.(handleFormSubmit) }}
      onClose={handleCloseDialog}
      open={isDialogOpen}
      sx={{
        '.MuiPaper-root': {
          maxWidth: 'unset',
          width: '50%',
        },
      }}
      title={'Add / Edit Mapping Condition'}
      TransitionComponent={SlideTransition}
      TransitionProps={{ direction: 'left' } as SlideTransitionProps}
    />
  )
}
