import {
  Cancel,
  CheckCircle,
  FolderOpen,
  Refresh,
  Schedule,
} from '@mui/icons-material'
import {
  Box,
  Chip,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import React from 'react'

export interface FileStatus {
  id: string
  relativePath: string
  status:
    | 'completed'
    | 'failed'
    | 'processing'
    | 'pending'
    | 'file path processed'
  progress?: number
}

interface FileProcessingStatusProps {
  files: FileStatus[]
  onRefresh?: () => void
  onOpenFileLocation: (filePath: string) => void
}

export const FileProcessingStatus: React.FC<FileProcessingStatusProps> = ({
  files,
  onRefresh,
  onOpenFileLocation,
}) => {
  const getStatusIcon = (status: FileStatus['status']) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return <CheckCircle sx={{ color: '#4caf50' }} />
      case 'file path processed':
        return <CheckCircle sx={{ color: '#2196f3' }} />
      case 'failed':
        return <Cancel sx={{ color: '#f44336' }} />
      case 'processing':
      case 'pending':
        return <Schedule sx={{ color: '#3f51b5' }} />
    }
  }

  const getStatusColor = (status: FileStatus['status']) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return '#4caf50'
      case 'file path processed':
        return '#2196f3'
      case 'failed':
        return '#f44336'
      case 'processing':
        return '#3f51b5'
      case 'pending':
        return '#757575'
    }
  }

  const getFileName = (filePath: string) => {
    return filePath.split('/').pop() || filePath
  }

  return (
    <Paper
      elevation={3}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #e0e0e0',
          bgcolor: '#ffffff',
          position: 'sticky',
          top: 0,
          zIndex: 1,
        }}
      >
        <Typography variant="h6" sx={{ color: '#3f51b5' }}>
          Files
        </Typography>
        {onRefresh && (
          <Tooltip title="Refresh">
            <IconButton
              onClick={onRefresh}
              size="small"
              sx={{ color: '#3f51b5' }}
            >
              <Refresh />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <List
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          py: 0,
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#FFFFFF',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#bdbdbd',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#9e9e9e',
            },
          },
        }}
      >
        {files.map((file) => (
          <ListItem
            key={file.id}
            sx={{
              py: 2,
              px: 3,
              borderBottom: '1px solid #e0e0e0',
              transition: 'background-color 0.3s',
              '&:hover': {
                bgcolor: '#e8eaf6',
              },
            }}
          >
            <ListItemIcon>{getStatusIcon(file.status)}</ListItemIcon>
            <ListItemText
              primary={
                <Tooltip title={file.relativePath}>
                  <Typography
                    noWrap
                    fontWeight="medium"
                    sx={{ color: '#3f51b5' }}
                  >
                    {getFileName(file.relativePath)}
                  </Typography>
                </Tooltip>
              }
              secondary={
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}
                >
                  <Chip
                    label={file.status}
                    size="small"
                    sx={{
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      bgcolor: getStatusColor(file.status),
                      color: '#ffffff',
                    }}
                  />
                  {file.status === 'processing' &&
                    file.progress !== undefined && (
                      <Box sx={{ flexGrow: 1, maxWidth: 150 }}>
                        <LinearProgress
                          variant="determinate"
                          value={file.progress}
                          sx={{
                            height: 8,
                            borderRadius: 4,
                            bgcolor: '#e0e0e0',
                            '& .MuiLinearProgress-bar': {
                              bgcolor: '#3f51b5',
                            },
                          }}
                        />
                      </Box>
                    )}
                </Box>
              }
            />
            <Tooltip title="Open file location">
              <IconButton
                size="small"
                onClick={() => onOpenFileLocation(file.relativePath)}
                sx={{
                  bgcolor: '#e3f2fd',
                  '&:hover': { bgcolor: '#bbdefb' },
                  transition: 'background-color 0.3s',
                }}
              >
                <FolderOpen sx={{ color: '#3f51b5' }} />
              </IconButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}

export default FileProcessingStatus
