import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Column } from '~/components/Column'
import { Text } from '~/components/Text'
import type { PreConfiguredStrategyTileProps } from '~/models/types/components/generateCode/PreConfiguredStrategyTileProps'

/**
 * The `available hosting strategies` section,
 * part of the `pre configured strategy tile`
 * from the generate code modal.
 */
export const PersistenceStrategies = (
  props: Pick<
    PreConfiguredStrategyTileProps,
    | 'dataAggregate'
    | 'initialData'
    | 'selectedStrategyType'
    | 'shouldDisableFields'
  >,
) => {
  const {
    dataAggregate,
    initialData,
    selectedStrategyType,
    shouldDisableFields,
  } = props

  const persistenceOptions = initialData?.persistences

  if (!persistenceOptions?.length) return null

  return (
    <Column className="mt-4 gap-2">
      <Text className="text-lg font-medium">Persistence strategies</Text>

      <FormControl>
        <RadioGroup
          aria-labelledby="radio-available-persistence-strategies"
          name="persistence"
          defaultValue={dataAggregate?.persistence}
        >
          {persistenceOptions.map((persistence) => (
            <FormControlLabel
              key={persistence.identity}
              label={persistence.name}
              value={persistence.identity}
              disabled={
                (selectedStrategyType !== 'regular' &&
                  selectedStrategyType !== undefined) ||
                shouldDisableFields
              }
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Column>
  )
}
