import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import { queryKeyOrganisation } from '~/services/Discovery'
import { ResponseDiscoveryOrganisation } from '~/services/Discovery.types'
import {
  DiscoveryOrganisationAPI,
  serviceDiscoveryOrganisation,
} from '~/services/base'
import { getUserEmail } from '~/utils/getUserEmail'
import { getLocalUserId } from '~/utils/getUserId'

export const actionStep1 =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request }: ActionFunctionArgs) => {
    const userId = getLocalUserId(pca)
    const ownerEmail = getUserEmail(pca)

    // Get form data
    const formData = await request.formData()
    const name = formData.get('name')

    // Builds the data to send to server
    const postData = {
      name,
      ownerId: userId,
      ownerEmail,
    }

    // Setups and send to server
    const url = DiscoveryOrganisationAPI.CreateOrganisation
    const req = (await serviceDiscoveryOrganisation.post(
      url,
      postData,
    )) as AxiosResponse<ResponseDiscoveryOrganisation, unknown>

    // If it succeeed, just redirect to the next step
    if (req.status === 200) {
      // but first invalidade the query so the cache is updated
      await queryClient.invalidateQueries({ queryKey: queryKeyOrganisation() })
      // then redirect
      return redirect('/welcome/step2')
    }

    // Else, redirect to "home"
    return redirect('/welcome/step1')
  }
