import { AxiosResponse } from 'axios'
import invariant from 'tiny-invariant'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import {
  Aggregate,
  DomainByPlatformId,
  DomainForCommand,
} from '~/services/Development.types'

export const queryKeyDevelopment = (platformId?: GUID) => {
  invariant(platformId, 'platformId is required')
  return ['development', { platformId }]
}

export const queryDevelopment = <T = DomainByPlatformId>(platformId?: GUID) => {
  invariant(platformId, 'platformId is required')

  return {
    queryKey: queryKeyDevelopment(platformId),
    queryFn: async () => {
      const url = DevelopmentAggregateAPI.getByPlatformId(platformId)
      const req = await serviceDevelopmentAggregate(url)
      return req as AxiosResponse<T, unknown>
    },
  }
}

export const queryKeyDevelopmentDomainsForCommands = (platformId: GUID) => {
  invariant(platformId, 'platformId is required')
  return ['development', 'domains-for-commands', { platformId }]
}

export const queryDevelopmentDomainsForCommands = <T = DomainForCommand>(
  platformId?: GUID,
) => {
  invariant(platformId, 'platformId is required')

  return {
    queryKey: queryKeyDevelopmentDomainsForCommands(platformId),
    queryFn: async () => {
      const url = DevelopmentAggregateAPI.getDomainsForCommands(platformId)
      const req = await serviceDevelopmentAggregate(url)
      return req as AxiosResponse<T, unknown>
    },
  }
}

export const queryKeyAggregate = (aggregateId: GUID) => {
  invariant(aggregateId, 'aggregate is required')
  return ['development', { aggregateId }]
}

export const queryAggregate = <T = Aggregate>(aggregateId?: GUID) => {
  invariant(aggregateId, 'aggregate is required')

  return {
    queryKey: queryKeyAggregate(aggregateId),
    queryFn: async () => {
      const url = DevelopmentAggregateAPI.getAggregateById(aggregateId)
      const req = await serviceDevelopmentAggregate(url)
      return req as AxiosResponse<T, unknown>
    },
  }
}
