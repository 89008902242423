import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { FieldValues } from 'react-hook-form'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import type { CodeGenerationStrategyApiProps } from '~/models/types/api/codeGenerationStrategy/CodeGenerationStrategyApiProps'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import type { PayloadUpdatePropertyValue } from '~/services/GenerationStrategy.types'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'
import { invalidateQueries } from '~/utils/api/codeGenerationStrategy/invalidateQueries'

/**
 * Hook that implements react query `mutation`
 * to submit a value property binding.
 */
export const useSubmitValuePropertyBinding = (
  props: Pick<CodeGenerationStrategyApiProps, 'generationStrategyId'>,
) => {
  const { generationStrategyId } = props

  const queryClient = useQueryClient()

  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: (fieldValues: FieldValues) => {
      const {
        fileId,
        id: propertyBindingId,
        node: nodeField,
        parameters: parametersFromEntries,
        value,
      } = fieldValues || {}

      const node = nodeField ? JSON.parse(nodeField as string) : undefined

      invariant(generationStrategyId, ERROR_MESSAGES.generationStrategyId)
      invariant(fileId, ERROR_MESSAGES.fileId)
      invariant(propertyBindingId, ERROR_MESSAGES.propertyBindingId)

      const url = GenerationStrategyAPI.UpdatePropertyValue

      const parameters = parametersFromEntries?.length
        ? JSON.parse(parametersFromEntries)
        : node?.parameters?.map((item: { path: string }) => item.path)

      const postData: PayloadUpdatePropertyValue = {
        generationStrategyId,
        fileId,
        propertyBindingId,
        value,
        parameters,
      }

      return serviceGenerationStrategy.post(url, postData)
    },
    onSuccess: () => invalidateQueries(queryClient, generationStrategyId),
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
