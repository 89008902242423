export function Key_S_Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#B6F1E6"
        d="M4 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5Z"
      />
      <path
        fill="#080D97"
        d="M5 4.5h14v-1H5v1Zm14.5.5v14h1V5h-1ZM19 19.5H5v1h14v-1ZM4.5 19V5h-1v14h1Zm.5.5a.5.5 0 0 1-.5-.5h-1A1.5 1.5 0 0 0 5 20.5v-1Zm14.5-.5a.5.5 0 0 1-.5.5v1a1.5 1.5 0 0 0 1.5-1.5h-1ZM19 4.5a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 19 3.5v1Zm-14-1A1.5 1.5 0 0 0 3.5 5h1a.5.5 0 0 1 .5-.5v-1ZM12.072 15.108c-.462 0-.864-.084-1.206-.252a1.883 1.883 0 0 1-.792-.702 1.94 1.94 0 0 1-.28-1.044h.793c0 .246.057.474.17.684.115.204.28.369.496.495.222.12.495.18.819.18.426 0 .756-.102.99-.306a.973.973 0 0 0 .35-.765c0-.252-.053-.453-.161-.603a1.185 1.185 0 0 0-.441-.378c-.18-.096-.39-.18-.63-.252-.234-.072-.48-.153-.738-.243-.486-.168-.846-.375-1.08-.621-.234-.252-.351-.58-.351-.981a1.585 1.585 0 0 1 .234-.891c.162-.264.39-.468.684-.612.3-.15.654-.225 1.062-.225.402 0 .75.075 1.044.225a1.699 1.699 0 0 1 .954 1.521h-.792c0-.174-.045-.345-.135-.513a1.038 1.038 0 0 0-.414-.423c-.18-.108-.408-.162-.684-.162-.342-.006-.624.08-.846.26-.216.18-.324.43-.324.748 0 .27.075.477.225.62.156.145.372.265.648.36.276.09.594.196.954.316.3.108.57.234.81.378.24.144.426.33.558.558.138.228.207.519.207.873 0 .3-.078.585-.234.855-.156.264-.393.48-.711.648-.312.168-.705.252-1.18.252Z"
      />
    </svg>
  )
}
