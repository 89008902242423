import { v4 as uuidv4 } from 'uuid'
import type { FileType } from '~/models/types/components/files/FileType'
import type { ResponseGetSasToken } from '~/services/Process.types'
import { DiscoveryProcessAPI, serviceDiscoveryProcess } from '~/services/base'

/**
 * Get files SAS token.
 * @param fileName The file name.
 */
export const getSasToken = async (
  fileName: string,
): Promise<ResponseGetSasToken> => {
  const { data } = await serviceDiscoveryProcess.get<ResponseGetSasToken>(
    DiscoveryProcessAPI.getSasToken(fileName),
  )
  return data
}

/** The type of the formatted file. */
export type FormattedFile = {
  /** The file base URL. */
  baseUrl: string
  /** The file content type. */
  contentType: string
  /** The original file data. */
  file: FileType
  /** The file name. */
  fileName: string
  /** The file ID. */
  id: GUID
  /** The file SAS token. */
  sasToken: string
  /** The file size. */
  size: number
}

/**
 * Return the array of files formatted
 * to be provided to the upload endpoint.
 * @param files The array of files to be uploaded.
 */
export const getFormattedFiles = async (
  processId: string,
  files: FileType[],
): Promise<FormattedFile[]> =>
  Promise.all(
    files.map(async (file: FileType) => {
      // Backend needs to receive the full path as file name.
      const fileName = file.path || file.name

      let fileNameForSasToken = fileName
      // Remove the first `/` if exists.
      if (fileNameForSasToken.startsWith('/'))
        fileNameForSasToken = fileNameForSasToken.slice(1)

      fileNameForSasToken = `${processId}/${fileNameForSasToken}`

      const sasToken = await getSasToken(fileNameForSasToken)

      return {
        baseUrl: sasToken.url,
        contentType: file.type,
        file,
        fileName,
        id: uuidv4(),
        mediaType: file.type,
        sasToken: sasToken.sasToken,
        size: file.size,
      }
    }),
  )
