import type { RenderTreeItemProps } from '~/models/types/components/codeGenerationStrategy/RenderTreeItemProps'
import type { PropertyBinding } from '~/services/GenerationStrategy.types'
import { mapCustomizedMappedValues } from './RenderTreeCollection.utils'
import { RenderTreeItem } from './RenderTreeItem'

/**
 * Renders a tree item collection.
 */
const RenderTreeCollection = (props: RenderTreeItemProps) => {
  const { node, ...rest } = props

  const mappedValues = (node as PropertyBinding)?.mappedValues

  // Return null in case of no mapped values.
  if (!mappedValues || !Array.isArray(mappedValues)) return null

  // Get customized mapped values.
  const customizedMappedValues = mappedValues
    ?.map(mapCustomizedMappedValues)
    ?.filter(Boolean) as PropertyBinding[]

  return customizedMappedValues?.map((item: PropertyBinding) => (
    <RenderTreeItem
      key={item?.id}
      isCollectionItem={true}
      node={item as PropertyBinding}
      {...rest}
    />
  ))
}

export default RenderTreeCollection
