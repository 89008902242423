import { twMerge } from '^/tailwind.config'
import React from 'react'

const DEFAULT_BUTTON_CLASSNAMES =
  'rounded p-2 bg-background text-sm leading-snug font-medium text-primary hover:opacity-80 border-none cursor-pointer shadow-1 inline-flex items-center justify-center relative grow'

type ButtonProps = React.ComponentPropsWithoutRef<'button'> & {
  className?: string
}
function ButtonComponent(
  props: ButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const mergedClassName = twMerge(DEFAULT_BUTTON_CLASSNAMES, props.className)

  return (
    <button ref={ref} type="button" {...props} className={mergedClassName}>
      {props.children}
    </button>
  )
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ButtonComponent,
)
