import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import WrapTextIcon from '@mui/icons-material/WrapText'
import { Box, IconButton, Paper, TextField } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { FormInputWithIcon } from '~/pages/developer/components/FormInputWithIcon'

type FormQueriesProps = PropsWithChildren<{
  defaultValue?: string
  handleRemoveQuery: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleAddQuery: () => void
  isRemoveDisabled: boolean
  inputName: string
  index: number
  isLastItem: boolean
}>

export function FormQueries({
  defaultValue,
  children,
  handleRemoveQuery,
  handleAddQuery,
  index,
  isRemoveDisabled,
  inputName,
  isLastItem,
}: FormQueriesProps) {
  function handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
    if (event.key === 'Tab' || event.code === 'Tab') {
      if (isLastItem) {
        handleAddQuery()
      }
    }
  }

  return (
    <>
      <Paper
        elevation={0}
        sx={{ bgcolor: 'background.highlight', p: 2, position: 'relative' }}
      >
        <FormInputWithIcon
          icon={<WrapTextIcon />}
          input={
            <>
              <TextField
                label="Query name"
                variant="outlined"
                name={inputName}
                defaultValue={defaultValue}
                fullWidth
                required
              />
              <Box>
                <IconButton
                  onClick={handleRemoveQuery}
                  disabled={isRemoveDisabled}
                  color="warning"
                  data-index={index}
                  onKeyDown={handleKeyDown}
                  aria-label="remove attribute"
                >
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            </>
          }
        />
        {children}
      </Paper>
    </>
  )
}
