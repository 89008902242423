import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { IconButton, Paper, TextField } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Row } from '~/components/Row'

export type RaisedDomainEventProps = PropsWithChildren & {
  defaultValue?: string
  handleRemoveEvent: (event: React.MouseEvent<HTMLButtonElement>) => void
  index: number
  inputName?: string
  isRemoveDisabled: boolean
}

export function RaisedDomainEvent({
  inputName = 'domainEvents',
  defaultValue,
  children,
  index,
  handleRemoveEvent,
  isRemoveDisabled,
}: RaisedDomainEventProps) {
  return (
    <>
      <Paper elevation={0} sx={{ bgcolor: 'background.highlight', p: 2 }}>
        <Row>
          <TextField
            label="Domain Event Name"
            variant="outlined"
            name={inputName}
            defaultValue={defaultValue}
            fullWidth
            required
            sx={{
              mb: 2,
            }}
          />

          <div className="flex items-center">
            <IconButton
              onClick={handleRemoveEvent}
              disabled={isRemoveDisabled}
              color="warning"
              data-index={index}
              aria-label="remove event"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </div>
        </Row>

        {children}
      </Paper>
    </>
  )
}
