import { Button, IconButton, Tooltip, Typography } from '@mui/material'
import { useEffect, type ReactNode } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useActionData, useParams } from 'react-router-dom'
import CopyIcon from '~/assets/icons/copy.svg?react'
import PasteIcon from '~/assets/icons/paste.svg?react'
import PlusIcon from '~/assets/icons/plus.svg?react'
import { Attribute } from '~/components/Attribute'
import { Column } from '~/components/Column'
import { Row } from '~/components/Row'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { useGetDomainReadModelTypes } from '~/hooks/api/business/useGetDomainReadModelTypes'
import { useCopyAndPasteAttributes } from '~/hooks/useCopyAndPasteAttributes'
import { AttributesFormValues } from '~/routes/developer/edit-command'
import { renamePropertiesToAttributes } from '~/routes/developer/utils'
import type { Attribute as TAttribute } from '~/services/Development.types'
import { getAggregateTypesOptions } from './FormAttributes.utils'

type FormAttributesProps = {
  /** The aggregate ID. */
  aggregateId?: GUID
  autoGenerateComponent?: ReactNode
  formLabel?: string
  formName?: string
  inputLabel?: string
  isDisabled?: boolean
}

export function FormAttributes(props: FormAttributesProps) {
  const {
    aggregateId,
    autoGenerateComponent,
    formLabel = 'Attributes',
    inputLabel = 'Attribute',
    formName,
    isDisabled,
  } = props

  // Hooks.
  const dataAutoGeneratedAttributes = useActionData() as {
    formName: string
    attributes: TAttribute[]
  }
  const { control, setValue } = useFormContext<AttributesFormValues>()

  const { pasteAttributesFromClipboard } = useCopyAndPasteAttributes({
    copyingWholeForm: true,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${formName}.attributes` as 'events.0.attributes',
  })

  const params = useParams()
  const { aggregateId: paramAggregateId } = params || {}

  const { data: aggregateTypes } = useGetDomainReadModelTypes({
    aggregateId: aggregateId || paramAggregateId,
  })

  // Methods.
  function handleAddAttribute() {
    append({ name: '', type: 'string' })
  }

  function handleRemoveAttribute(event: React.MouseEvent<HTMLButtonElement>) {
    const index = event.currentTarget.dataset.index
    if (index) {
      remove(Number(index))
    }
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
    if (event.key === 'Tab' || event.code === 'Tab') {
      const dataIndex = event.currentTarget.dataset.index
      if (dataIndex) {
        const index = Number(dataIndex)
        if (typeof fields[index + 1] === 'undefined') {
          handleAddAttribute()
        }
      }
    }
  }

  const handlePasteAttributes = async () => {
    const copiedAttributes = await pasteAttributesFromClipboard()
    if (!copiedAttributes) {
      return
    }

    append(
      copiedAttributes.map((attr) => ({
        ...attr,
        type: attr.type?.replace('[]', ''),
        name_is_array: attr.type?.includes('[]'),
      })),
    )
  }

  // Lifecycle.
  useEffect(() => {
    if (fields.length === 0) {
      append({
        name: '',
        type: 'string',
        attributes: [],
      })
    }
  }, [append, fields.length])

  useEffect(() => {
    if (
      dataAutoGeneratedAttributes &&
      dataAutoGeneratedAttributes?.formName === formName &&
      dataAutoGeneratedAttributes?.attributes.length > 0
    ) {
      setValue(
        `${formName}.attributes` as 'events.0.attributes',
        renamePropertiesToAttributes(dataAutoGeneratedAttributes?.attributes),
      )
    }
  }, [dataAutoGeneratedAttributes, setValue, formName])

  return (
    <Column className="gap-4">
      <Typography>{formLabel}</Typography>

      <Row className="items-center gap-2">
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAddAttribute}
          startIcon={
            isDisabled ? <PlusIcon className="opacity-30" /> : <PlusIcon />
          }
          sx={{ width: 'fit-content' }}
          disabled={isDisabled}
        >
          Add
        </Button>

        {FEATURE_TOGGLE.DEVELOPMENT.AUTOGENERATE_ATTRIBUTES &&
        autoGenerateComponent
          ? autoGenerateComponent
          : null}

        <Row>
          <Tooltip title="Copy attributes">
            <IconButton
              name={formName + '.copyButton'}
              type="submit"
              disabled={isDisabled}
            >
              {isDisabled ? <CopyIcon className="opacity-30" /> : <CopyIcon />}
            </IconButton>
          </Tooltip>

          <Tooltip title="Paste attributes">
            <IconButton onClick={handlePasteAttributes} disabled={isDisabled}>
              {isDisabled ? (
                <PasteIcon className="opacity-30" />
              ) : (
                <PasteIcon />
              )}
            </IconButton>
          </Tooltip>
        </Row>
      </Row>

      {fields.map((item, index) => (
        <Attribute
          attribute={item}
          formName={`${formName}.attributes` || ''}
          handleKeyDown={handleKeyDown}
          handleRemoveAttribute={handleRemoveAttribute}
          index={index}
          isDisabled={isDisabled}
          isRemoveDisabled={fields.length < 2}
          inputLabel={inputLabel}
          key={item.id}
          options={getAggregateTypesOptions(aggregateTypes)}
        />
      ))}
    </Column>
  )
}
