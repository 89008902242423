import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  SvgIcon,
} from '@mui/material'
import {
  Form,
  useLoaderData,
  useLocation,
  useNavigation,
  useSearchParams,
} from 'react-router-dom'
import { Text } from '~/components/Text'
import { switchIcon } from '~/routes/setup/platform/utils'
import { Platform } from '~/services/PlatformTeam.types'

export function DeveloperHosting() {
  const navigation = useNavigation()
  const [searchParams] = useSearchParams()
  const isInitialise = searchParams.toString().includes('initialise')
  const location = useLocation()
  const isBusiness = location.pathname.includes('business')
  const isButtonDisabled = navigation.state !== 'idle'
  // const errors = useActionData() as GenericObj<string> | undefined
  const hostings = useLoaderData() as Platform[] | null

  return (
    <>
      <Stack gap={2}>
        {isInitialise && !isBusiness ? (
          <Alert severity="info">
            Before initialising, you need to setup the hosting strategy.
          </Alert>
        ) : null}
        <Text className="text-xl">
          Please select the <strong>technology to host</strong> your feature
        </Text>
        <Paper sx={{ px: 2, py: 1 }}>
          <List component="nav">
            {hostings?.map((hosting) => {
              return (
                <ListItem disablePadding key={hosting.identity}>
                  <Box
                    component={Form}
                    method="post"
                    id="form-aggregate-hosting"
                    sx={{ flexGrow: 1, display: 'flex' }}
                  >
                    <input
                      type="hidden"
                      defaultValue={hosting.identity}
                      name="name"
                    />
                    <ListItemButton
                      disabled={isButtonDisabled || hosting.disabled}
                      component="button"
                      type="submit"
                    >
                      <ListItemIcon>
                        <SvgIcon>{switchIcon(hosting.identity)}</SvgIcon>
                      </ListItemIcon>
                      <ListItemText primary={hosting.name} />
                    </ListItemButton>
                  </Box>
                </ListItem>
              )
            })}
          </List>
        </Paper>
      </Stack>
    </>
  )
}
