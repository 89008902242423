import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  type DialogProps,
} from '@mui/material'
import { Fragment, type ElementType, type ReactNode } from 'react'
import { ButtonIconClose } from '~/components/ButtonIconClose'

export type BaseDialogProps<T> = Omit<DialogProps, 'content'> & {
  /** The `dialog actions` content. */
  actions?: ReactNode
  /** Any adjacent content to be rendered right after the `content` node. */
  adjacentContent?: ReactNode
  /** If `true` a close icon button will be rendered. */
  closeIconButton?: boolean
  /** The `dialog content` content. */
  content?: ReactNode
  /** The content node ID for `aria-describedby`. */
  contentId?: string
  /** The `dialog title` content. */
  title?: ReactNode
  /** The title node ID for `aria-labelledby`. */
  titleId?: string
  /** A wrapper element that could wrap the title, content and actions. */
  wrapper?: ElementType
  /** The wrapper element props. */
  wrapperProps?: T
}

/**
 * A base dialog component
 * with support for:
 * - Dialog title;
 * - Dialog content;
 * - Dialog actions;
 * - And adjacent content.
 */
export const BaseDialog = <T,>(props: BaseDialogProps<T>) => {
  const {
    actions,
    adjacentContent,
    closeIconButton,
    content,
    contentId,
    onClose,
    title,
    titleId,
    wrapper: Wrapper = Fragment,
    wrapperProps,
    ...rest
  } = props

  return (
    <Dialog
      aria-labelledby={titleId}
      aria-describedby={contentId}
      onClose={onClose}
      {...rest}
    >
      {closeIconButton && (
        <ButtonIconClose
          className="absolute right-2 top-2"
          onClick={(e) => onClose?.(e, 'escapeKeyDown')}
        />
      )}

      <Wrapper {...wrapperProps}>
        {!!title && <DialogTitle id={titleId}>{title}</DialogTitle>}

        {!!content && <DialogContent id={contentId}>{content}</DialogContent>}

        {adjacentContent}

        {!!actions && <DialogActions>{actions}</DialogActions>}
      </Wrapper>
    </Dialog>
  )
}
