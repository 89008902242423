import { useState, type Dispatch, type SetStateAction } from 'react'

export type UseValueBindingTreeItemStylesHook = {
  /** CSS class to indicate if the form component is hovered. */
  formHoveredClass: boolean
  /** The set state method for `isFormHovered` state. */
  setIsFormHovered: Dispatch<SetStateAction<boolean>>
}

/**
 * Provides styles utilities for the `ValueBindingTreeItem`
 * component.
 */
export const useValueBindingTreeItemStyles = () => {
  // Indicates if the form is hovered.
  const [isFormHovered, setIsFormHovered] = useState<boolean>(false)

  const formHoveredClass = isFormHovered ? 'is-hovered' : ''

  return {
    formHoveredClass,
    setIsFormHovered,
  }
}
