import { Panel } from 'react-resizable-panels'
import ResizeHandle from '~/components/ResizeHandle'
import type { CodeGenerationStrategyBaseProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyBaseProps'
import type { PanelAsideDetailsBaseProps } from '~/models/types/components/codeGenerationStrategy/PanelAsideDetailsBaseProps'
import {
  DomainDictionary,
  type DomainDictionaryProps,
} from './DomainDictionary/DomainDictionary'
import {
  TextSectionProperties,
  type TextSectionPropertiesProps,
} from './TextSectionProperties/TextSectionProperties'

export type PanelAsideDetailsProps = CodeGenerationStrategyBaseProps &
  PanelAsideDetailsBaseProps &
  DomainDictionaryProps &
  Pick<TextSectionPropertiesProps, 'onDeletePropertyBindingClick'>

/**
 * The panel aside details component.
 */
export const PanelAsideDetails = (props: PanelAsideDetailsProps) => {
  const {
    dataDomainDictionary,
    fileId,
    generationStrategyId,
    gridApiRef,
    isSubmittingDictionary,
    isWaitingAutoMapDictionary,
    onDeletePropertyBindingClick,
    onRowSelectionModelChange,
    onUpdateParametersMap,
    selectedDomainDictionaryItem,
    showNodePropertiesPanel,
    showParametersPanel,
  } = props

  if (!showNodePropertiesPanel && !showParametersPanel) return null

  return (
    <>
      <ResizeHandle />

      <Panel id="3" order={3} defaultSize={30} collapsible={true}>
        <div className="relative h-[calc(100svh-161px)] w-[calc((40/${panels})*1%)] flex-1 overflow-auto border-r-2 border-solid border-transparent border-r-slate-200 bg-slate-100 p-4">
          {showNodePropertiesPanel && !showParametersPanel && (
            <TextSectionProperties
              dataDomainDictionary={dataDomainDictionary}
              fileId={fileId}
              onDeletePropertyBindingClick={onDeletePropertyBindingClick}
              selectedDomainDictionaryItem={selectedDomainDictionaryItem}
            />
          )}

          {showParametersPanel && !showNodePropertiesPanel && (
            <DomainDictionary
              dataDomainDictionary={dataDomainDictionary}
              generationStrategyId={generationStrategyId}
              gridApiRef={gridApiRef}
              fileId={fileId}
              isSubmittingDictionary={isSubmittingDictionary}
              isWaitingAutoMapDictionary={isWaitingAutoMapDictionary}
              onRowSelectionModelChange={onRowSelectionModelChange}
              onUpdateParametersMap={onUpdateParametersMap}
            />
          )}
        </div>
      </Panel>
    </>
  )
}
