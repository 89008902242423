import { getAddEditString } from './RenderLabel.utils'

export type UseRenderLabelProps = {
  /** Mapping condition. */
  mappingCondition: string | null
}

type UseRenderLabelReturn = {
  /** Tooltip text for the `mapping condition` button. */
  mappingConditionTooltipText: string
}

/**
 * Hook to support `RenderLabel` component.
 */
export const useRenderLabel = ({
  mappingCondition,
}: UseRenderLabelProps): UseRenderLabelReturn => {
  const mappingConditionTooltipText = `${getAddEditString(!!mappingCondition)} mapping condition`

  return {
    mappingConditionTooltipText,
  }
}
