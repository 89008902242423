import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { ValueBindingTreeItemFormEnum } from '~/models/enums/forms/ValueBindingTreeItemFormEnum'
import type { FormHook } from '~/models/types/hooks/FormHook'

export type ParametersTableProps = Pick<
  FormHook,
  'setFormValue' | 'watchForm'
> & {
  /** The class name applied to the containing node. */
  className?: string
  /** If `true` all remove buttons will be disabled. */
  isDisabled?: boolean
}

/**
 * The parameters table field associated
 * with the `Value Binding` node:
 * It renders all parameters related to a node,
 * and allow to remove the any of the params.
 *
 * The params come from the form state,
 * which is accessible via `watchForm` method
 * (this is basically `watch` from react hook form).
 */
export const ParametersTable = (props: ParametersTableProps) => {
  const { className, isDisabled, setFormValue, watchForm } = props

  // Get the current param part of the form state.
  const paramsFieldValue = watchForm?.(ValueBindingTreeItemFormEnum.PARAMETERS)
  const params: string[] = JSON.parse(paramsFieldValue || '[]')

  if (!params || !params.length) return null

  // Handler for remove param click.
  const handleRemoveParamClick = (event: EventFor<'button', 'onClick'>) => {
    const value = event.currentTarget.dataset.value

    const filteredParams = params.filter((item: string) => item !== value)
    setFormValue?.(
      ValueBindingTreeItemFormEnum.PARAMETERS,
      JSON.stringify(filteredParams),
      { shouldDirty: true },
    )
  }

  return (
    <TableContainer
      component={Paper}
      className={twMerge('max-w-[57ch] transition-all', className)}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className="text-sm font-bold">Parameter</TableCell>

            <TableCell className="text-sm font-bold">Index</TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {params.map((param, index) => (
            <TableRow key={param}>
              <TableCell className="break-all">{param}</TableCell>

              <TableCell>{index}</TableCell>

              <TableCell className="text-right">
                <Button
                  aria-label={`Remove ${param} param`}
                  data-value={param}
                  disabled={isDisabled}
                  onClick={handleRemoveParamClick}
                  size="small"
                  variant="outlined"
                >
                  Remove
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
