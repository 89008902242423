import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import '@xyflow/react/dist/style.css'
import { LoaderFunctionArgs, generatePath, redirect } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { IS_DEV } from '~/config/constants'
import { shouldShowOnlyInDev } from '~/config/environments'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import { queryDevelopment } from '~/services/Development'
import { queryKeyOrganisation, queryOrganisation } from '~/services/Discovery'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import {
  queryKeyProcess,
  queryKeyProcessListByOrganisationId,
  queryProcess,
  queryProcessListByOrganisationId,
} from '~/services/Process'
import { DiscoveryProcessAPI, serviceDiscoveryProcess } from '~/services/base'
import { useGlobalStore } from '~/store'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderBusiness =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    // if no user, redirect to login page
    if (!userId || !email) {
      return redirect('/')
    }

    // if no organisation, redirect to welcome page
    const qOrganisation = queryOrganisation(userId, email)
    const fetchOrganisation = await queryClient.ensureQueryData(qOrganisation)

    // Store the identity:
    // For some reason the cached data is losing the `identity` property.
    if (fetchOrganisation?.data?.identity) {
      useGlobalStore.setState({
        organisationId: fetchOrganisation.data.identity,
      })
    }

    if (fetchOrganisation.status !== 200) {
      return redirect('/welcome/step1')
    }

    // Try to get organization id from cached data
    // or from the storage.
    const organisationId =
      fetchOrganisation?.data?.identity ||
      useGlobalStore.getState().organisationId

    const { platformId, processId } = params
    const requestUrl = new URL(request.url)

    // if the user comes from the demo
    const isDemo = useGlobalStore.getState().isDemo

    if (isDemo) {
      if (shouldShowOnlyInDev()) {
        console.log('added business process via loader')
      }

      const name = fetchOrganisation.data.ecosystemName
      const description = useGlobalStore.getState().demoTerm
      const generateProcessBasedOnDescription = true

      const newProcessId = uuidv4()
      const processUrl = DiscoveryProcessAPI.CreateProcess
      await serviceDiscoveryProcess.post(processUrl, {
        platformId,
        processId: newProcessId,
        name,
        ownerId: userId,
        // subdomain: postData.name,
        description,
        generateProcessBasedOnDescription,
        organisationId,
      })

      await queryClient.invalidateQueries({
        queryKey: queryKeyProcessListByOrganisationId(organisationId),
      })
      await queryClient.invalidateQueries({
        queryKey: queryKeyProcess(newProcessId),
      })
      await queryClient.invalidateQueries({
        queryKey: queryKeyOrganisation(userId, email),
      })

      // once the demo is sent to the server, remove the user from the demo
      useGlobalStore.setState({ isDemo: false })
      useGlobalStore.setState({ demoTerm: '' })
    }

    // request for platform team to get data from developer side
    const qPlatform = queryPlatformTeam(organisationId)
    const fetchPlatform = await queryClient.ensureQueryData(qPlatform)
    const qProcessList = queryProcessListByOrganisationId(organisationId)
    const { data: dataProcessList } = await queryClient.fetchQuery(qProcessList)

    if (!isDemo) {
      if (
        !dataProcessList.length &&
        requestUrl.pathname !==
          `/${organisationId}/${platformId}/business/config-business`
      ) {
        return redirect(
          `/${organisationId}/${platformId}/business/config-business`,
        )
      }

      if (!dataProcessList.length) {
        return {
          organisation: fetchOrganisation,
          ownerId: fetchOrganisation.data.ownerId,
          userId,
          processList: null,
          processId: '',
          selectedProcess: null,
          platform: fetchPlatform,
          development: null,
          shouldConfigCoreProcess: true,
        }
      }
    }

    if (!processId) {
      const firstProcess = dataProcessList[0]
      return redirect(
        `/${organisationId}/${platformId}/business/${firstProcess?.identity}`,
      )
    }

    const qProcess = queryProcess({ processId })

    try {
      // fetch the data for the selected process
      const fetchSelectedProcess = queryClient.ensureQueryData(qProcess)

      // fetch the data for development aggregates
      const query = queryDevelopment(platformId)
      const fetchDevelopment = queryClient.ensureQueryData(query)

      return {
        organisation: fetchOrganisation,
        ownerId: fetchOrganisation.data.ownerId,
        userId,
        processList: dataProcessList,
        selectedProcess: fetchSelectedProcess,
        processId,
        platform: fetchPlatform,
        development: fetchDevelopment,
        shouldConfigCoreProcess: false,
      }
    } catch (err) {
      if (IS_DEV) {
        console.log({ err })
      }
      return redirect(
        generatePath(BusinessRoutesEnum.BUSINESS, {
          organisationId: organisationId || '',
          platformId: platformId || '',
        }),
      )
    }
  }
