export function ReactionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#B6F1E6"
        stroke="#080D97"
        strokeLinejoin="round"
        d="m10.752 12.673-6.759-.001 5.005-9.678 7.336-.026-3.584 6.78h7.505L7.821 21.058l2.931-8.384Z"
      />
    </svg>
  )
}
