import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { queryDevelopment } from '~/services/Development'

export const loaderDeveloperPublishAll =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const { platformId } = params

    // * Query is invalidated in the action function from add-command and add-reaction
    const qDevelopment = queryDevelopment(platformId)
    const fetchDevelopment = await queryClient.ensureQueryData(qDevelopment)
    invariant(
      fetchDevelopment.status === 200 && fetchDevelopment.data,
      `No data for development: ${fetchDevelopment}`,
    )

    return fetchDevelopment
  }
