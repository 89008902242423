import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import type { Statement } from '~/services/Process.types'

type GlobalStore = {
  colorMode: string
  isMenuOpen: boolean
  setColorMode: (colorMode: string) => void
  toggleIsMenuOpen: () => void
  demoTerm: string
  setDemoTerm: (demoTerm: string) => void
  isDemo: boolean
  setIsDemo: (isDemo: boolean) => void
  lastSelectedStatement: Statement | null
  setLastSelectedStatement: (lastSelectedStatement: Statement | null) => void
  shouldShowHelpWindowHowToUseDotstar: boolean
  setShouldShowHelpWindowHowToUseDotstar: (
    shouldShowHelpWindowHowToUseDotstar: boolean,
  ) => void
  /** The identity from `getByOwnerIdOrEmail` endpoint. */
  organisationId: string
  /**
   * The process ID:
   * Required for navigation purposes, to take the user
   * the last opened process, instead of the default one.
   */
  processId: string

  /** Clears the entire store. */
  deleteEverything: () => void
}

export const useGlobalStore = create<GlobalStore>()(
  devtools(
    persist(
      (set, get) => ({
        colorMode: 'system',
        isMenuOpen: false,
        demoTerm: '',
        isDemo: false,
        lastSelectedStatement: null,
        shouldShowHelpWindowHowToUseDotstar: true,
        setColorMode: (colorMode) => set((state) => ({ colorMode })),
        toggleIsMenuOpen: () => set({ isMenuOpen: !get().isMenuOpen }),
        setDemoTerm: (demoTerm) => set((state) => ({ demoTerm })),
        setIsDemo: (isDemo) => set((state) => ({ isDemo })),
        setLastSelectedStatement: (lastSelectedStatement) =>
          set(() => ({ lastSelectedStatement })),
        setShouldShowHelpWindowHowToUseDotstar: (
          shouldShowHelpWindowHowToUseDotstar,
        ) => set(() => ({ shouldShowHelpWindowHowToUseDotstar })),
        organisationId: '',
        processId: '',

        // Clear all states.
        deleteEverything: () => set({}, true),
      }),
      { name: 'global-storage' },
    ),
  ),
)

type CodeGenerationStore = {
  selectedTreeItem: string[]
  setSelectedTreeItem: (selectedPropertyBindingId: string[]) => void
  expandedProperties: string[]
  setExpandedProperties: (expandedProperties: string[]) => void

  /** Clears the entire store. */
  deleteEverything: () => void
}

export const useCodeGenerationStore = create<CodeGenerationStore>()(
  devtools(
    persist(
      (set, get) => ({
        selectedTreeItem: [],
        setSelectedTreeItem: (selectedPropertyBindingId) =>
          set(() => ({
            selectedTreeItem: selectedPropertyBindingId,
          })),
        expandedProperties: [],
        setExpandedProperties: (expandedProperties) =>
          set(() => ({
            expandedProperties,
          })),

        // Clear all states.
        deleteEverything: () => set({}, true),
      }),
      { name: 'code-generation-store' },
    ),
  ),
)
