import { createTheme } from '@mui/material/styles'
import { tailwindColors } from '~/styles/tailwindColors'

type ColorsNames =
  | 'rose'
  | 'pink'
  | 'fuchsia'
  | 'purple'
  | 'violet'
  | 'indigo'
  | 'blue'
  | 'sky'
  | 'cyan'
  | 'teal'
  | 'emerald'
  | 'green'
  | 'lime'
  | 'yellow'
  | 'amber'
  | 'orange'
  | 'red'
  | 'warmGray'
  | 'trueGray'
  | 'gray'
  | 'coolGray'
  | 'blueGray'

type ColorsVariations = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900

type Colors = Record<
  ColorsNames,
  {
    [K in ColorsVariations]: string
  }
>

declare module '@mui/material/styles' {
  interface TypeBackground {
    highlight: string
    bright: string
    panel: string
  }

  interface TypographyVariants {
    subhero: React.CSSProperties
    subHeader: React.CSSProperties
    welcomeWizardStepper: React.CSSProperties
    welcomeTitle: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subhero?: React.CSSProperties
    subHeader?: React.CSSProperties
    welcomeWizardStepper?: React.CSSProperties
    welcomeTitle?: React.CSSProperties
  }

  interface Theme {
    colors: Colors
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subhero: true
    subHeader: true
    welcomeWizardStepper: true
    welcomeTitle: true
  }
}

const paletteDark = {
  primary: {
    // main: '#94afff',
    main: '#97F0E2',
    contrastText: '#03231E',
  },
  secondary: {
    main: '#54ffe2',
  },
  background: {
    // default: '#121212',
    default: '#1E1F2C',
    paper: '#272b39',
    highlight: '#1E1F2C',
    bright: '#121212',
    panel: '#474b57',
  },
}

export const theme = (prefersDarkMode: boolean) =>
  createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      text: {
        primary: prefersDarkMode ? '#ffffff' : '#292C6C',
      },
      primary: {
        main: prefersDarkMode ? paletteDark.primary.main : '#020890',
      },
      secondary: {
        main: prefersDarkMode ? paletteDark.secondary.main : '#32ad9b',
      },
      background: {
        default: prefersDarkMode ? paletteDark.background.default : '#fbfcff',
        paper: prefersDarkMode ? paletteDark.background.paper : '#ffffff',
        highlight: prefersDarkMode
          ? paletteDark.background.highlight
          : '#f4f5ff',
        bright: prefersDarkMode ? paletteDark.background.bright : '#fafaff',
        panel: prefersDarkMode ? paletteDark.background.panel : '#ffffff',
      },
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#334155',
            fontSize: '0.75rem',
          },
          arrow: {
            color: '#334155',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
          outlinedPrimary: {
            borderColor: prefersDarkMode ? paletteDark.primary.main : '#cac8c5',
            background: prefersDarkMode ? 'transparent' : '#ffffff',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: prefersDarkMode ? '#272b39' : '#ffffff',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
        html, body {
          height: 100%;
          font-family:
            "DM Sans",
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            Roboto,
            "Helvetica Neue",
            Arial,
            sans-serif,
            "Apple Color Emoji",
            "Segoe UI Emoji",
            "Segoe UI Symbol";
        }

        body {
          height: auto;
        }
      `,
      },
    },
    typography: {
      welcomeWizardStepper: {
        fontSize: '1.25rem',
        fontWeight: 400,
        color: prefersDarkMode ? '#686bb6' : '#ffffff',
      },
      welcomeTitle: {
        fontSize: '1.25rem',
        fontWeight: 700,
        color: prefersDarkMode ? 'rgba(255, 255, 255, 0.7)' : '#565888',
      },
      subhero: {
        fontSize: '2rem',
      },
      subHeader: {
        color: prefersDarkMode ? 'rgba(255, 255, 255, 0.7)' : '#686bb6',
        fontSize: '0.875rem',
      },
      fontFamily: [
        'DM Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    ...tailwindColors,
  })
