import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material'
import { useState, type MouseEvent, type ReactNode } from 'react'
import OutlineTrashIcon from '~/assets/icons/outline-trash.svg?react'
import TrashIcon from '~/assets/icons/trash.svg?react'

type ConfirmationButtonProps = {
  /**
   * The text to be rendered in the button.
   * It only makes sense when used with `isRegularButton`.
   */
  buttonText?: string
  confirmButtonText: string
  /**
   * The icon to rendered in the button.
   * It defaults to `TrashIcon`.
   */
  icon?: JSX.Element
  /**
   * If true it will render a regular button, otherwise
   * it will render an icon button.
   */
  isRegularButton?: boolean
  itemId: string
  modalText: string
  onConfirmDeleteClick: (itemId: string) => void
  titleText: ReactNode
  tooltipText: string
}

export function ConfirmationButton(props: ConfirmationButtonProps) {
  const {
    buttonText,
    confirmButtonText,
    onConfirmDeleteClick: handleConfirmDeletion,
    icon = <TrashIcon />,
    isRegularButton,
    itemId,
    modalText,
    titleText,
    tooltipText,
  } = props

  const [open, setOpen] = useState(false)

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(true)
  }

  return (
    <>
      <Tooltip title={tooltipText} placement="right" arrow>
        {isRegularButton ? (
          <Button
            onClick={handleButtonClick}
            startIcon={icon}
            variant="outlined"
          >
            {buttonText}
          </Button>
        ) : (
          <IconButton
            className="p-1 group-hover:flex"
            onClick={handleButtonClick}
          >
            {icon}
          </IconButton>
        )}
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        PaperProps={{
          className: 'rounded-lg p-3',
        }}
      >
        <DialogTitle>{titleText}</DialogTitle>

        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              setOpen(false)
            }}
            variant="outlined"
          >
            Cancel
          </Button>

          <Button
            onClick={(e) => {
              e.stopPropagation()
              handleConfirmDeletion(itemId)
              setOpen(false)
            }}
            startIcon={<OutlineTrashIcon />}
            className="bg-rose-500 px-3 text-white hover:bg-rose-600"
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
