import { Alert, Stack } from '@mui/material'
import { Form, useActionData, useLoaderData } from 'react-router-dom'
import { WizardContent } from '~/components/WizardContent'
import { WizardFooterButtons } from '~/components/WizardFooterButtons'
import { ErrorMessage } from '~/config/constants'
import { WizardTextField } from '~/styled/WizardTextField'
import { isObjectEmpty } from '~/utils/func'

export function PlatformGitCredentials() {
  const loaderData = useLoaderData()
  const actionData = useActionData() as undefined | { error?: boolean }

  return (
    <WizardContent
      step="4"
      intro="The generated code will be pushed to the Git repository specified below"
      title={
        <>
          Please configure your <strong>Git Credentials</strong>
        </>
      }
    >
      {actionData?.error || !isObjectEmpty(loaderData) ? (
        <Alert severity="error" className="mb-6">
          {ErrorMessage.PlatformConfigurationGitCredentials}
        </Alert>
      ) : null}
      <Form method="post" id="form-setup-platform-git-credentials">
        <Stack gap={2} sx={{ ml: 2 }}>
          <WizardTextField
            label="Repository url"
            name="repositoryUrl"
            fullWidth
            autoFocus
          />
          <WizardTextField
            label="Authentication token"
            name="token"
            type="password"
            fullWidth
          />
        </Stack>
        <WizardFooterButtons />
      </Form>
    </WizardContent>
  )
}
