import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { styled } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  ':has(.Mui-disabled)': {
    borderColor:
      theme.palette.mode === 'dark'
        ? theme.palette.action.disabled
        : theme.palette.action.disabledBackground,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.background.paper
        : theme.palette.background.default,
  },
  borderRadius: theme.shape.borderRadius,
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f6f6fc',
  borderColor:
    theme.palette.mode === 'dark' ? theme.palette.action.selected : '#a6a6e5',
  borderWidth: '1px',
  borderStyle: 'solid',
  '&:not(:last-child)': {
    // borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  padding: `0 ${theme.spacing(1)}`,
  minHeight: 'unset',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
    svg: { fontSize: '1.1rem' },
  },
  '& .MuiAccordionSummary-content': {
    margin: '8px 0',
    marginLeft: theme.spacing(1),
  },
  '& .MuiTypography-root': {
    fontSize: '0.75rem',
  },
}))

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  padding: 0,
  borderTop: '1px solid rgba(0, 0, 0, .125)',

  '.MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root:last-child:not':
    {
      borderBottom: 0,
    },
}))
