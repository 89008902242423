import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { queryDevelopment } from '~/services/Development'
import { queryOrganisation } from '~/services/Discovery'
import { queryStrategiesByOrganisationId } from '~/services/GenerationStrategy'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderConfigCodeGenerationStrategies =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params }: LoaderFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    invariant(userId, `Must be logged in: ${userId}`)
    invariant(email, `Must be logged in: ${userId}`)

    const platformId = params.platformId
    invariant(platformId, 'platformId is not defined')

    const queryOrg = queryOrganisation(userId, email)
    const dataOrganisationFetched = await queryClient.ensureQueryData(queryOrg)
    const organisationId = dataOrganisationFetched.data.identity
    invariant(organisationId, 'organisationId is not defined')

    const qStrategies = queryStrategiesByOrganisationId(organisationId)
    const fetchStrategies = await queryClient.ensureQueryData(qStrategies)

    const qDevelopment = queryDevelopment(platformId)
    const fetchDevelopment = await queryClient.ensureQueryData(qDevelopment)

    return {
      strategies: fetchStrategies.data,
      organisationId,
      development: fetchDevelopment.data,
    }
  }
