import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Typography } from '@mui/material'
import type { DropzoneState } from 'react-dropzone'
import type { UploadFilesOverlayBaseProps } from '~/models/types/components/UploadFilesOverlayBaseProps'

export type UploadFilesOverlayFeedbackProps = UploadFilesOverlayBaseProps &
  Pick<DropzoneState, 'isDragActive' | 'isDragReject'>

/**
 * Return the feedback messages for the
 * `UploadFilesOverlay` component:
 * Messages are related to drag active and reject.
 */
export const UploadFilesOverlayFeedback = (
  props: UploadFilesOverlayFeedbackProps,
) => {
  const { dropAreaActiveText, dropAreaRejectText, isDragActive, isDragReject } =
    props

  if (isDragActive && !isDragReject) {
    return (
      dropAreaActiveText || (
        <>
          <Typography
            variant="h3"
            sx={{ color: 'white', mr: 1, fontWeight: 'bold' }}
          >
            Drop to upload
          </Typography>
          <CloudUploadIcon sx={{ color: 'white' }} fontSize="large" />
        </>
      )
    )
  }

  if (isDragActive && isDragReject && dropAreaRejectText) {
    return dropAreaRejectText
  }

  return null
}
