import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Column } from '~/components/Column'
import { Text } from '~/components/Text'
import type { PreConfiguredStrategyTileProps } from '~/models/types/components/generateCode/PreConfiguredStrategyTileProps'

/**
 * The `available hosting strategies` section,
 * part of the `pre configured strategy tile`
 * from the generate code modal.
 */
export const AvailableHostingStrategies = (
  props: Pick<
    PreConfiguredStrategyTileProps,
    | 'dataAggregate'
    | 'initialData'
    | 'selectedStrategyType'
    | 'shouldDisableFields'
  >,
) => {
  const {
    dataAggregate,
    initialData,
    selectedStrategyType,
    shouldDisableFields,
  } = props

  const hostingOptions = initialData?.hostings

  if (!hostingOptions?.length) return null

  return (
    <Column className="gap-2">
      <Text
        className="text-lg font-medium"
        id="radio-available-hosting-strategies"
      >
        Available hosting strategies
      </Text>

      <FormControl>
        <RadioGroup
          aria-labelledby="radio-available-hosting-strategies"
          name="hosting"
          defaultValue={dataAggregate?.host}
        >
          {hostingOptions.map((hosting) => {
            return (
              <FormControlLabel
                key={hosting.identity}
                label={hosting.name}
                value={hosting.identity}
                disabled={
                  (selectedStrategyType !== 'regular' &&
                    selectedStrategyType !== undefined) ||
                  shouldDisableFields
                }
                control={<Radio />}
              />
            )
          })}
        </RadioGroup>
      </FormControl>
    </Column>
  )
}
