import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { queryOrganisation } from '~/services/Discovery'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const actionWelcome =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request }: ActionFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    invariant(userId, `Must be logged in: ${userId}`)
    invariant(email, `Must be logged in: ${userId}`)
    const query = queryOrganisation(userId, email)
    const dataFetched = await queryClient.ensureQueryData(query)

    if (dataFetched.status === 204) {
      return redirect('/welcome/step1')
    }
  }
