export function ExportIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path stroke="#B6F1E6" strokeWidth={2} d="M7 17v3.5h12V17" />
      <path
        fill="#B6F1E6"
        d="M13.354 5.646a.5.5 0 0 0-.708 0L9.464 8.828a.5.5 0 1 0 .708.708L13 6.707l2.828 2.829a.5.5 0 1 0 .707-.708l-3.181-3.182ZM13.5 18V6h-1v12h1Z"
      />
      <path
        fill="#080D97"
        d="M12.354 4.646a.5.5 0 0 0-.708 0L8.464 7.828a.5.5 0 1 0 .708.708L12 5.707l2.828 2.829a.5.5 0 1 0 .708-.708l-3.182-3.182ZM12.5 17V5h-1v12h1Z"
      />
      <path stroke="#080D97" d="M6 16.5V20h12v-3.5" />
    </svg>
  )
}
