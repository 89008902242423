import { Paper, TextField } from '@mui/material'
import { PropsWithChildren } from 'react'

type FormActionNameProps = PropsWithChildren & {
  inputLabel?: string
  inputName?: string
  defaultValue?: string
}

export function FormActionName({
  defaultValue,
  inputLabel = 'Command Name',
  inputName = 'commandName',
  children,
}: FormActionNameProps) {
  return (
    <Paper
      elevation={0}
      sx={{
        bgcolor: 'background.highlight',
        p: 2,
      }}
    >
      <TextField
        label={inputLabel}
        variant="outlined"
        name={inputName}
        defaultValue={defaultValue}
        fullWidth
        required
        sx={{
          mb: !!children ? 2 : 0,
        }}
      />
      {children}
    </Paper>
  )
}
