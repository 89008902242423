export function UserIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <circle cx={12} cy={8} r={4.5} fill="#B6F1E6" stroke="#080D97" />
      <path
        fill="#B6F1E6"
        stroke="#080D97"
        d="M19.85 18.904c.312.596.51 1.142.597 1.596H12l-8.448-.025c.088-.454.286-.998.598-1.593.432-.822 1.062-1.7 1.844-2.505C7.568 14.76 9.697 13.5 12 13.5c2.302 0 4.431 1.266 6.005 2.89.783.808 1.413 1.69 1.845 2.514Z"
      />
    </svg>
  )
}
