import { Tooltip } from '@mui/material'
import type { MutableRefObject } from 'react'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'
import { Column } from '~/components/Column'
import { GearIcon } from '~/components/Icons/GearIcon'
import { ReactionIcon } from '~/components/Icons/ReactionIcon'
import { UserIcon } from '~/components/Icons/UserIcon'
import { Row } from '~/components/Row'
import { StatementEditionBoxFormEnum as FormEnum } from '~/models/enums/forms/StatementEditionBoxFormEnum'
import { STATEMENT_PARSING_INFO_TYPE } from '~/services/Process.types'
import { ControlledTextField } from '../ExpandedStep/ControlledTextField'
import type { UpdateStatementBaseProps } from '../Step/Step.types'

type ChipProps = UpdateStatementBaseProps & {
  /** The ref for actor field. */
  actorFieldRef?: MutableRefObject<HTMLElement | null>
}

export function Chip(props: ChipProps) {
  const {
    actorFieldRef,
    control,
    data: { data },
    onSubmit,
    watch,
  } = props

  const {
    actor,
    isEditable,
    reactingToDomainEvent,
    type: statementType,
  } = data || {}

  const formType = watch?.('type')

  const type = formType || statementType

  // Styles.
  const chip = tv({
    slots: {
      container: 'absolute z-10',
      internal:
        'items-center rounded-full border-solid bg-background-paper text-xs',
    },
    variants: {
      type: {
        Command: {
          container: '-top-3 w-full justify-center',
          internal: 'px-2 py-1 gap-1 border-node-border border',
        },
        Reaction: {
          container: '-left-3 h-full items-center',
          internal:
            'gap-0 w-6 h-6 cursor-pointer border-event border-2 items-center justify-center',
        },
      },
    },
  })

  const { container, internal } = chip({
    type: type as 'Command' | 'Reaction',
  })

  return (
    <Row className={container()}>
      <Tooltip
        title={
          type === STATEMENT_PARSING_INFO_TYPE.Reaction ? (
            <Column className="gap-1">
              <p className="m-0 w-fit rounded border border-solid border-event bg-event px-2 py-0.5 text-11 font-bold uppercase text-white">
                Reaction
              </p>

              <Row className="translate items-center gap-1 text-primary">
                <GearIcon />
                <span>{actor}</span>
              </Row>

              <Row className="translate items-center gap-1 text-primary">
                <ReactionIcon />
                <span>{reactingToDomainEvent?.name}</span>
              </Row>
            </Column>
          ) : (
            false
          )
        }
        PopperProps={{
          disablePortal: true,
        }}
        componentsProps={{
          tooltip: {
            className:
              'bg-background-paper w-48 rounded-md shadow-1 border border-solid rounded-md border-node-border py-2',
          },
          arrow: {
            className: 'text-node-border',
          },
        }}
        placement="top"
        arrow
      >
        <Row
          className={twMerge(
            internal(),
            isEditable && type === 'Reaction' && 'hidden',
          )}
        >
          {!isEditable && type === STATEMENT_PARSING_INFO_TYPE.Reaction && (
            <ReactionIcon viewBox="0 0 22 22" width={12} height={12} />
          )}

          {type === 'Command' && (
            <>
              <UserIcon viewBox="0 0 25 25" width={14} height={14} />

              {isEditable ? (
                <ControlledTextField
                  control={control}
                  inputProps={{
                    className: 'py-0 px-1 text-xs',
                  }}
                  name={FormEnum.ACTOR}
                  onBlur={onSubmit}
                  ref={actorFieldRef}
                />
              ) : (
                <span>{actor}</span>
              )}
            </>
          )}
        </Row>
      </Tooltip>
    </Row>
  )
}
