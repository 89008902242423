import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import '@xyflow/react/dist/style.css'
import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import {
  queryDevelopment,
  queryDevelopmentDomainsForCommands,
} from '~/services/Development'
import { queryOrganisation } from '~/services/Discovery'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderDeveloper =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params }: LoaderFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    // if no user, redirect to login page
    if (!userId || !email) {
      return redirect('/')
    }

    // if no organisation, redirect to welcome page
    const queryOrg = queryOrganisation(userId, email)
    const dataFetchedOrg = await queryClient.ensureQueryData(queryOrg)
    if (dataFetchedOrg.status !== 200) {
      return redirect('/welcome/step1')
    }

    // platformId should be a url param by now
    const { platformId } = params

    // fetch the data in the header
    const qPlatform = queryPlatformTeam(dataFetchedOrg.data.identity)
    const fetchPlatform = queryClient.ensureQueryData(qPlatform)

    // fetch the development api
    const qDevelopment = queryDevelopment(platformId)
    const fetchDevelopment = queryClient.ensureQueryData(qDevelopment)

    // fetch the domains for commands api
    const qDomains = queryDevelopmentDomainsForCommands(platformId)
    const fetchDomains = queryClient.ensureQueryData(qDomains)

    return {
      development: fetchDevelopment,
      platform: fetchPlatform,
      organisationId: dataFetchedOrg.data.identity,
      organisation: dataFetchedOrg,
      domains: fetchDomains,
    }
  }
