import {
  Button,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import '@xyflow/react/dist/style.css'
import React from 'react'
import {
  Link,
  generatePath,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom'
import PublishIcon from '~/assets/icons/rocket.svg?react'
import TrashIcon from '~/assets/icons/trash.svg?react'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import { BusinessInitialData } from '~/pages/business'
import { useBizDevResources } from '~/routes/business-developer/biz-dev-utils/useBizDevResources'
import { queryDevelopment } from '~/services/Development'
import { queryProcess } from '~/services/Process'
import { getAggregateByEvent } from '~/utils/transform'
import { ButtonIconClose } from '../ButtonIconClose'

export type DrawerConfigProps = {
  children: React.ReactNode
  isOpen: boolean
  title?: string
  toggleDrawer: (
    event: unknown,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => void
}

export function DrawerConfig(props: DrawerConfigProps) {
  const { children, isOpen, title, toggleDrawer } = props

  // React Router Dom.
  const loaderData = useLoaderData() as BusinessInitialData
  const navigate = useNavigate()
  const params = useParams()

  // Base resources.
  const {
    // Pathnames.
    isAddPage,
    isCommandPage,
    isCommitsHistoryPage,
    isDeletePage,
    isEditPage,
    isReactionPage,
    isReadModelPage,
    // URL params.
    commandId,
    organisationId,
    platformId,
    processId,
    reactionId,
    readModelId,
    // Constants.
    dataId,
    dataType,
    eventType,
    paramType,
    singularEventType,
  } = useBizDevResources()

  // Queries.
  const { data: developmentData } = useQuery({
    ...queryDevelopment(platformId),
    initialData: loaderData.development,
  })

  const { data: selectedProcess } = useQuery({
    ...queryProcess({ processId }),
  })

  // Resources.
  const aggregate = getAggregateByEvent(
    eventType as any,
    developmentData?.data,
    params[paramType],
  )

  const eventReadModels = selectedProcess?.data?.statements?.find(
    (step) => step.id === dataId,
  )?.parsingInfo?.requiredDataInfo

  const filteredReadModels = aggregate?.readModels.filter(
    (readModel) =>
      !!eventReadModels?.find(
        (eventReadModel) => eventReadModel.identity === readModel.identity,
      ),
  )

  let panelTitle = 'Action'
  if (isEditPage) panelTitle = 'Edit'
  if (isAddPage) panelTitle = 'Add'
  if (isDeletePage) panelTitle = 'Delete'
  if (isReadModelPage) panelTitle = 'Query'
  if (isCommitsHistoryPage) panelTitle = 'History'

  // Methods.
  function handleNavigate(event: EventFor<'div', 'onClick'>) {
    const dataset = event?.currentTarget.dataset
    if (dataset?.eventId) {
      if (dataset.eventType === 'query' || dataset.eventType === 'readModel') {
        navigate(
          `/${organisationId}/${platformId}/business/${processId}/edit-readmodel/${dataset.eventId}`,
        )
      }
      if (dataset.eventType === 'command') {
        navigate(
          `/${organisationId}/${platformId}/business/${processId}/edit-command/${dataset.eventId}`,
        )
      }
      if (dataset.eventType === 'reaction') {
        navigate(
          `/${organisationId}/${platformId}/business/${processId}/edit-reaction/${dataset.eventId}`,
        )
      }
    }
  }

  const handleNavigateToHistory = () => {
    if (eventType === 'commands')
      return navigate(
        generatePath(BusinessRoutesEnum.EDIT_COMMAND_COMMITS_HISTORY, {
          commandId,
          organisationId,
          platformId,
          processId,
        }),
      )

    if (eventType === 'reactions')
      return navigate(
        generatePath(BusinessRoutesEnum.EDIT_REACTION_COMMITS_HISTORY, {
          organisationId,
          platformId,
          processId,
          reactionId,
        }),
      )

    if (eventType === 'readModels')
      return navigate(
        generatePath(BusinessRoutesEnum.EDIT_READ_MODEL_COMMITS_HISTORY, {
          organisationId,
          platformId,
          processId,
          readModelId,
        }),
      )
  }

  function handleRemoveReadmodel(event: EventFor<'button', 'onClick'>) {
    event?.stopPropagation() // so the user can delete the event and not "select" it
    const dataset = event?.currentTarget.dataset
    if (aggregate && dataset?.eventId && dataset?.eventType === 'query') {
      navigate(
        `/${organisationId}/${platformId}/business/${processId}/delete-readmodel/${dataset.eventId}/${aggregate.identity}?eventId=${dataId}&eventType=${dataType}`,
      )
    }
  }

  // Callback handler for drawer close.
  const handleDrawerClose = (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'escapeKeyDown') toggleDrawer(event)
  }

  return (
    <Drawer
      anchor="right"
      disablePortal
      onClose={handleDrawerClose}
      open={isOpen}
    >
      <div className="h-[calc(100svh-82px)]">
        <header className="flex items-center justify-between p-6 shadow-appBar">
          <Text>{title}</Text>

          <ButtonIconClose onClick={toggleDrawer} size="small" />
        </header>

        <Row className="h-full">
          <div className="relative flex min-w-[260px] flex-col bg-white px-6 pb-4 pt-6 shadow-menuLeft">
            <Text className="text-xs uppercase text-slate-500">Action</Text>

            <List>
              <ListItemButton
                className="mb-1"
                data-event-id={dataId}
                data-event-type={dataType}
                onClick={handleNavigate}
                selected={
                  (isCommandPage || isReactionPage || isReadModelPage) &&
                  !isCommitsHistoryPage
                }
              >
                <ListItemText primary="Edit action" />
              </ListItemButton>

              <ListItemButton
                onClick={handleNavigateToHistory}
                selected={isCommitsHistoryPage}
              >
                <ListItemText primary="History" />
              </ListItemButton>
            </List>

            <div className="flex flex-1 flex-col">
              <Text className="mt-4 text-xs uppercase text-slate-500">
                Required Data
              </Text>

              <List>
                {filteredReadModels?.map((readModel) => (
                  <ListItemButton
                    key={readModel.identity}
                    onClick={handleNavigate}
                    data-event-id={readModel.identity}
                    data-event-type="query"
                    selected={readModel.identity === params?.readModelId}
                    className="group h-fit"
                  >
                    <ListItemText primary={readModel.name} />

                    <IconButton
                      onClick={handleRemoveReadmodel}
                      data-event-id={readModel.identity}
                      data-event-type="query"
                      className="invisible group-hover:visible"
                      size="small"
                    >
                      <TrashIcon />
                    </IconButton>
                  </ListItemButton>
                ))}
              </List>
            </div>

            {FEATURE_TOGGLE.BUSINESS.PUBLISH_WITH_READ_MODELS ? (
              <Button
                startIcon={<PublishIcon />}
                variant="outlined"
                to={`${aggregate?.identity}/generate-code/${singularEventType}/${params[paramType]}`}
                relative="path"
                component={Link}
              >
                Generate Code
              </Button>
            ) : null}
          </div>

          <div
            className="min-w-[550px] max-w-[550px] overflow-y-scroll p-6"
            key={params[paramType]}
          >
            <Text className="mb-4">{panelTitle}</Text>

            {children}
          </div>
        </Row>
      </div>
    </Drawer>
  )
}
