import { UseQueryOptions } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import invariant from 'tiny-invariant'
import { DiscoveryProcessAPI, serviceDiscoveryProcess } from '~/services/base'
import {
  ResponseDiscoveryProcess,
  ResponseGetProcessListByOwnerId,
  ResponseRaisedDomainEventsByOrganisationId,
} from './Process.types'

type QueryProcessParam = {
  processId?: GUID
}
export const queryKeyProcess = (processId?: GUID) => {
  return ['discovery', 'process', { processId }]
}

export const queryProcess = <T = ResponseDiscoveryProcess>({
  processId,
}: QueryProcessParam) => {
  return {
    queryKey: queryKeyProcess(processId),
    queryFn: async (): Promise<AxiosResponse<T, unknown>> => {
      invariant(processId, 'The process ID is required.')

      const url = DiscoveryProcessAPI.getByProcessId(processId)
      const req = await serviceDiscoveryProcess(url)

      return req as AxiosResponse<T, unknown>
    },
  }
}

export type ResponseProcessFiles = {
  files: Array<{ id: string; status: string; relativePath: string }>
}

export const queryKeyProcessFiles = (processId?: GUID) => {
  return ['discovery', 'process', 'files', { processId }]
}

export const queryProcessFiles = <T = ResponseProcessFiles>(
  processId?: GUID,
) => ({
  queryKey: queryKeyProcessFiles(processId),
  queryFn: async () => {
    const url = DiscoveryProcessAPI.getFilesByProcessId(processId)
    const req = await serviceDiscoveryProcess(url)
    return req as AxiosResponse<T, unknown>
  },
})

export const queryKeyRaisedDomainEventsByOrganisationId = (
  organisationId: GUID,
) => {
  invariant(organisationId, 'organisationId is required')
  return [
    'discovery',
    'process',
    'raised',
    'domain',
    'events',
    { organisationId },
  ]
}

export const queryRaisedDomainEventsByOrganisationId = (
  organisationId: GUID,
): UseQueryOptions<ResponseRaisedDomainEventsByOrganisationId, unknown> => {
  return {
    queryKey: queryKeyRaisedDomainEventsByOrganisationId(organisationId),
    queryFn: async (): Promise<ResponseRaisedDomainEventsByOrganisationId> => {
      const url =
        DiscoveryProcessAPI.getProcessGetRaisedDomainEventsByOrganisationId(
          organisationId,
        )
      const res =
        await serviceDiscoveryProcess.get<ResponseRaisedDomainEventsByOrganisationId>(
          url,
        )

      return res.data
    },
  }
}

export const queryKeyProcessListByOrganisationId = (organisationId: GUID) => {
  invariant(organisationId, 'organisationId is required')

  return ['discovery', 'process', 'list', { organisationId }]
}

export const queryProcessListByOrganisationId = (organisationId: GUID) => {
  return {
    queryKey: queryKeyProcessListByOrganisationId(organisationId),
    queryFn: () => {
      const url = DiscoveryProcessAPI.GetProcessListByOrganisationId({
        organisationId,
      })
      return serviceDiscoveryProcess.get<ResponseGetProcessListByOwnerId>(url)
    },
  }
}
