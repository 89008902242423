import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import { getPlatform, getUrlWithoutSetup } from '../utils'

const STEP_NAME = 'git-credentials'

export const loaderPlatformGitCredentials =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params, context }: LoaderFunctionArgs) => {
    const { organisationId } = params

    const {
      status,
      data: { identity: platformId, gitRepositoryUrl, isSubmitted },
    } = await getPlatform(queryClient, organisationId)

    // If never filled, redirect to the beggining of the setup
    if (status === 204) {
      if (platformId) {
        const splitUrl = getUrlWithoutSetup(request.url)
        return redirect(`${splitUrl}/platform-setup/cloud`)
      }
      return redirect('/')
    }

    // If already submitted, redirect to the dashboard (?)

    // No, we need to redirect to the publish step
    if (isSubmitted && gitRepositoryUrl) {
      const isBusiness = request.url.includes('business')
      if (isBusiness) {
        return redirect(
          `/${organisationId}/${platformId}/business/${params.processId}/${params.aggregateId}/generate-code`,
        )
      }

      return redirect(`../../generate-code`)
    }

    // if (!frontEndHosting) {
    //   const splitUrl = getUrlWithoutSetup(request.url)
    //   return redirect(`${splitUrl}/platform-setup/hosting${search}`)
    // }

    // If they already filled the repository url, return it
    if (gitRepositoryUrl) {
      // const search = new URL(request.url).search
      // const splitUrl = getUrlWithoutSetup(request.url)
      // return redirect(`${splitUrl}/platform-setup/confirm${search}`)

      // we assume there's an error
      return {
        gitRepositoryUrl,
      }
    }

    return null
  }
