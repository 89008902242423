import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined'
import RuleIcon from '@mui/icons-material/Rule'
import { IconButton, Tooltip } from '@mui/material'
import { PropsWithChildren } from 'react'
import { useSubmit } from 'react-router-dom'
import { Row } from '~/components/Row'
import { CodeGenerationStrategyEditSubmitIntent } from '~/routes/configuration/code-generation-strategies/edit/action'
import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'
import { useRenderLabel } from './useRenderLabel'

export type RenderLabelProps = PropsWithChildren & {
  nodeId: GUID
  fileId?: GUID
  node?: any
  isCollectionItem?: boolean
  isCollection?: boolean
  onDeleteNodeClick?: (propertyBinding?: FolderOrFileStructure) => void
  onOpenMappingConditionClick?: (
    propertyBinding?: FolderOrFileStructure,
  ) => void
}

export function RenderLabel({
  nodeId,
  fileId,
  node,
  isCollectionItem,
  isCollection,
  onDeleteNodeClick,
  onOpenMappingConditionClick,
  children,
}: RenderLabelProps) {
  const submit = useSubmit()

  const { mappingConditionTooltipText } = useRenderLabel({
    mappingCondition: node.mappingCondition,
  })

  function handleSubmitCreateCollectionItem(
    event: EventFor<'button', 'onClick'>,
  ) {
    event.preventDefault()
    event.stopPropagation()

    if (fileId && nodeId) {
      const data = {
        fileId,
        propertyBindingId: nodeId,
        intent:
          'create-collection-item' satisfies CodeGenerationStrategyEditSubmitIntent,
      }
      submit(data, { method: 'POST' })
    }
  }

  return (
    <>
      <div className="group/wrapper flex items-center justify-between">
        <div className="flex items-center">
          {children}

          {node.mappingCondition && (
            <span className="text-primary-700 ml-[0.7em] inline-block whitespace-nowrap rounded-[0.27rem] bg-primary-100 px-[0.5em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.55em] leading-none">
              Condition
            </span>
          )}
        </div>

        {isCollection || isCollectionItem ? (
          <Row>
            <>
              {isCollection ? (
                <IconButton
                  className="group/button h-fit p-1 hover:bg-primary"
                  data-node-id={nodeId}
                  onClick={handleSubmitCreateCollectionItem}
                >
                  <LibraryAddOutlinedIcon
                    fontSize="small"
                    className="text-primary-400 opacity-50 group-hover/button:!text-white group-hover/wrapper:text-primary-400 group-hover/button:opacity-100 group-hover/wrapper:opacity-100"
                  />
                </IconButton>
              ) : null}

              {isCollectionItem ? (
                <>
                  {node._t === 'ObjectPropertyBinding' ||
                  node._t === 'TextPropertyBinding' ? (
                    <Tooltip title={mappingConditionTooltipText}>
                      <IconButton
                        className="h-fit p-1"
                        onClick={(e) => {
                          e.stopPropagation()
                          onOpenMappingConditionClick?.(node)
                        }}
                      >
                        <RuleIcon
                          fontSize="small"
                          className="text-primary-400 opacity-50 group-hover/button:!text-white group-hover/wrapper:text-primary-400 group-hover/button:opacity-100 group-hover/wrapper:opacity-100"
                        />
                      </IconButton>
                    </Tooltip>
                  ) : null}

                  <IconButton
                    className="h-fit p-1"
                    onClick={(e) => {
                      e.stopPropagation()
                      onDeleteNodeClick?.(node)
                    }}
                    data-node-id={nodeId}
                  >
                    <DeleteForeverOutlinedIcon
                      fontSize="small"
                      className="text-slate-300 group-hover:text-slate-500"
                    />
                  </IconButton>
                </>
              ) : null}
            </>
          </Row>
        ) : null}
      </div>
    </>
  )
}
