import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeyOrganisation } from '~/services/Discovery'
import { DiscoveryProcessAPI, serviceDiscoveryProcess } from '~/services/base'
import { useGetUserEmail } from './useGetAccount'
import { useGetUserId } from './useGetUserId'

export function useMutateProcess() {
  const queryClient = useQueryClient()
  const { userId } = useGetUserId()
  const { email: userEmail } = useGetUserEmail()

  const deleteProcess = useMutation({
    mutationFn: ({ processId }: { processId: string }) => {
      const url = DiscoveryProcessAPI.DeleteProcess

      return serviceDiscoveryProcess.post(url, {
        processId,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeyOrganisation(userId, userEmail),
      })
    },
  })

  return {
    deleteProcess,
  }
}
