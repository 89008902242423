import { ListItemText, MenuItem, MenuList } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { Link, useParams, useRouteLoaderData } from 'react-router-dom'
import { Text } from '~/components/Text'
import { shouldShowOnlyInDev } from '~/config/environments'

/**
 * `Code Generation Strategies` is rendered as `AI Blueprints` in the UI.
 */
type LayoutConfigurationProps = PropsWithChildren<{
  selected?: 'Organisation Users' | 'Profile' | 'Code Generation Strategies'
}>

export function LayoutConfiguration({
  selected,
  children,
}: LayoutConfigurationProps) {
  const { organisationId, platformId } = useParams()

  // ! This needs a refactor with a root component
  const dataBusiness = useRouteLoaderData('business-root') as any
  const dataDeveloper = useRouteLoaderData('developer-root') as any
  const dataConfiguration = useRouteLoaderData('configuration-root') as any

  const isOrganisationOwner = React.useMemo(() => {
    if (dataBusiness && dataBusiness.organisation?.data?.isOrganisationOwner) {
      return dataBusiness.organisation?.data?.isOrganisationOwner
    }
    if (
      dataDeveloper &&
      dataDeveloper.organisation?.data?.isOrganisationOwner
    ) {
      return dataDeveloper.organisation?.data?.isOrganisationOwner
    }
    if (
      dataConfiguration &&
      dataConfiguration.organisation?.data?.isOrganisationOwner
    ) {
      return dataConfiguration.organisation?.data?.isOrganisationOwner
    }
  }, [dataBusiness, dataDeveloper, dataConfiguration])

  return (
    <>
      <div className="mt-4 flex h-[calc(100svh-81px)] justify-center gap-4 px-4">
        <div className="min-w-[250px]">
          <Text className="mb-2 text-sm uppercase text-slate-500">
            Configuration
          </Text>

          <MenuList>
            <MenuItem
              selected={selected === 'Profile'}
              component={Link}
              to={`/${organisationId}/${platformId}/configuration/profile`}
            >
              <ListItemText primary="Profile" />
            </MenuItem>

            {isOrganisationOwner || shouldShowOnlyInDev() ? (
              <MenuItem
                selected={selected === 'Organisation Users'}
                component={Link}
                to={`/${organisationId}/${platformId}/configuration/users`}
              >
                <ListItemText primary="Organisation Users" />
              </MenuItem>
            ) : null}

            <MenuItem
              selected={selected === 'Code Generation Strategies'}
              component={Link}
              to={`/${organisationId}/${platformId}/configuration/code-generation-strategies`}
            >
              <ListItemText primary="AI Blueprints" />
            </MenuItem>
          </MenuList>
        </div>

        <div className="max-w-[800px] flex-1">{children}</div>
      </div>
    </>
  )
}
