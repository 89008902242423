import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import { GlobalStyles, Stack } from '@mui/material'
import { Outlet, useNavigation } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { Header } from '~/components/Header'
import { Unauthenticated } from '~/pages/developer/components/Unauthenticated'

const globalStyles = (
  <GlobalStyles
    styles={{
      '#root': {
        display: 'block',
        height: '100svh',
      },
      strong: {
        fontWeight: 500,
      },
    }}
  />
)

TopBarProgress.config({
  barColors: {
    '0': 'rgba(16,200,171,1)',
    '1.0': '#020890',
  },
  shadowBlur: 5,
})

export function BusinessLayout() {
  const navigation = useNavigation()

  return (
    <>
      {navigation.state === 'loading' && <TopBarProgress />}
      <AuthenticatedTemplate>
        <Header />
        <Stack className="absolute inset-0 top-[65px] md:top-[95px]">
          <>
            {globalStyles}
            <Outlet />
          </>
        </Stack>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Unauthenticated />
      </UnauthenticatedTemplate>
    </>
  )
}
