/* eslint-disable @typescript-eslint/no-namespace */
import { CircularProgress } from '@mui/material'
import * as React from 'react'
import { STRIPE } from '~/config/constants'
import { useGetUserEmail } from '~/hooks/useGetAccount'
import { useGetUserId } from '~/hooks/useGetUserId'
import { useScript } from '~/hooks/useScript'

// If using TypeScript, add the following snippet to your file as well.
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

export function StripePricingTable() {
  const scriptStatus = useScript(STRIPE.SCRIPT_URL)
  const { userId } = useGetUserId()
  const { email } = useGetUserEmail()

  return scriptStatus === 'ready' ? (
    <stripe-pricing-table
      pricing-table-id={STRIPE.PRICING_TABLE_ID}
      publishable-key={STRIPE.PUBLISHABLE_KEY}
      client-reference-id={userId}
      customer-email={email}
    ></stripe-pricing-table>
  ) : (
    <CircularProgress />
  )
}
