import { Slide, type SlideProps } from '@mui/material'
import type { TransitionProps } from '@mui/material/transitions'
import { forwardRef, type JSXElementConstructor } from 'react'

export interface SlideTransitionProps
  extends Omit<TransitionProps, 'children'>,
    Pick<SlideProps, 'children' | 'direction'> {}

/**
 * A transition component that implements the `Slide`
 * component from MUI.
 */
export const SlideTransition: JSXElementConstructor<SlideTransitionProps> =
  forwardRef(function Transition(props, ref) {
    return <Slide ref={ref} {...props} />
  })
