import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { FieldValues } from 'react-hook-form'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import type { CodeGenerationStrategyApiProps } from '~/models/types/api/codeGenerationStrategy/CodeGenerationStrategyApiProps'
import type { PayloadCreateTextSectionGroup } from '~/models/types/api/codeGenerationStrategy/PayloadCreateTextSectionGroup'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'
import { invalidateQueries } from '~/utils/api/codeGenerationStrategy/invalidateQueries'

/**
 * Hook that implements react query `mutation`
 * to merge text sections.
 */
export const useMergeTextSections = (
  props: Pick<CodeGenerationStrategyApiProps, 'generationStrategyId'>,
) => {
  const { generationStrategyId } = props

  const queryClient = useQueryClient()

  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: (fieldValues: FieldValues) => {
      const { fileId, firstSectionId, lastSectionId, parentSectionId } =
        fieldValues || {}

      invariant(fileId, ERROR_MESSAGES.fileId)
      invariant(generationStrategyId, ERROR_MESSAGES.generationStrategyId)

      const url = GenerationStrategyAPI.MergeTextSections

      const postData: PayloadCreateTextSectionGroup = {
        fileId,
        firstSectionId,
        lastSectionId,
        parentSectionId,
      }

      return serviceGenerationStrategy.post(url, postData)
    },
    onSuccess: () => invalidateQueries(queryClient, generationStrategyId),
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
