import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'
import { produce } from 'immer'
import type { UseMutateStatementsBaseProps } from '~/models/types/api/business/UseMutateStatementsBaseProps'
import {
  getStatementById,
  removeStatement,
  swapTwoStatements,
  updateStatement,
} from '~/pages/business/components/ProcessInfo/utils'
import {
  queryKeyProcess,
  queryKeyRaisedDomainEventsByOrganisationId,
} from '~/services/Process'
import type {
  PayloadUpdateStatementParsingInfoCommand,
  ResponseDiscoveryProcess,
  Statement,
} from '~/services/Process.types'
import { DiscoveryProcessAPI, serviceDiscoveryProcess } from '~/services/base'
import { useAddStatement } from './api/business/useAddStatement'
import { useInsertStatement } from './api/business/useInsertStatement'

export const useMutateStatements = (props: UseMutateStatementsBaseProps) => {
  const {
    addLocalChildStatement,
    process,
    selectedStatement,
    setSelectedStatements,
  } = props

  const queryClient = useQueryClient()

  const editMutation = useAddStatement({
    addLocalChildStatement,
    process,
    selectedStatement,
    setSelectedStatements,
  })

  const insertMutation = useInsertStatement({
    process,
    selectedStatement,
    setSelectedStatements,
  })

  const removeMutation = useMutation({
    mutationFn: (statementToRemove: {
      processId: string
      statementId?: string | null
    }) => {
      const url = DiscoveryProcessAPI.DeleteStatement
      return serviceDiscoveryProcess.post(url, statementToRemove)
    },
    onMutate: () => {
      queryClient.setQueryData(
        queryKeyProcess(process.identity),
        (currentProcess: AxiosResponse<ResponseDiscoveryProcess>) => {
          const newStatementList = removeStatement(
            currentProcess.data.statements,
            selectedStatement,
          )

          const parent = getStatementById(
            currentProcess.data.statements,
            selectedStatement?.parentStatementId || '',
          )
          if (parent) {
            setSelectedStatements([parent])
          } else {
            const firstChild = currentProcess.data.statements.filter(
              (statement: Statement) =>
                statement.parentStatementId === selectedStatement?.id,
            )[0]
            if (firstChild) {
              setSelectedStatements([firstChild])
            }
          }

          return produce(
            currentProcess,
            (draft: AxiosResponse<ResponseDiscoveryProcess>) => {
              draft.data.statements = newStatementList
            },
          )
        },
      )
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeyProcess(process.identity),
      })
    },
  })

  const swapMutation = useMutation({
    mutationFn: ({
      firstStatement,
      secondStatement,
    }: {
      firstStatement?: Statement
      secondStatement?: Statement
    }) => {
      const url = DiscoveryProcessAPI.SwapStatements
      return serviceDiscoveryProcess.post(url, {
        processId: process.identity,
        swapThisStatementId: firstStatement?.id,
        withThisStatementId: secondStatement?.id,
      })
    },
    onMutate: ({ firstStatement, secondStatement }) => {
      queryClient.setQueryData(
        queryKeyProcess(process.identity),
        (currentProcess: AxiosResponse<ResponseDiscoveryProcess>) => {
          const newList = swapTwoStatements(
            currentProcess.data.statements,
            firstStatement,
            secondStatement,
          )

          return produce(
            currentProcess,
            (draft: AxiosResponse<ResponseDiscoveryProcess>) => {
              draft.data.statements = newList
            },
          )
        },
      )
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeyProcess(process.identity),
      })
    },
  })

  const updateStatementParsingInfoMutation = useMutation({
    mutationFn: (data: PayloadUpdateStatementParsingInfoCommand) => {
      const url = DiscoveryProcessAPI.UpdateStatementParsingInfo
      return serviceDiscoveryProcess.post<ResponseDiscoveryProcess>(url, data)
    },
    onMutate: ({ statementId }) => {
      setSelectedStatements((current) => {
        if (current[0]?.id === statementId) {
          const data = produce(selectedStatement, (draft: Statement) => {
            draft.isFetching = true
          })
          return [data]
        }
        return current
      })

      queryClient.setQueryData(
        queryKeyProcess(process.identity),
        (currentProcess: AxiosResponse<ResponseDiscoveryProcess>) => {
          let newList = currentProcess.data.statements
          const foundStatement = getStatementById(newList, statementId)

          if (foundStatement) {
            const data = produce(foundStatement, (draft: Statement) => {
              draft.isFetching = true
            })
            newList = updateStatement(newList, data)
          }

          return produce(
            currentProcess,
            (draft: AxiosResponse<ResponseDiscoveryProcess>) => {
              draft.data.statements = newList
            },
          )
        },
      )
    },
    onSuccess: ({ data }, { statementId }) => {
      setSelectedStatements((current) => {
        if (current[0]?.id === statementId && !!data) {
          return [data]
        }

        return current
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeyProcess(process.identity),
      })

      queryClient.invalidateQueries({
        queryKey: queryKeyRaisedDomainEventsByOrganisationId(
          process.organisationId,
        ),
      })
    },
  })

  return {
    editMutation,
    insertMutation,
    removeMutation,
    swapMutation,
    updateStatementParsingInfoMutation,
  }
}
