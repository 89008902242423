import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { TIMER } from '~/config/constants'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import { queryKeyProcess, queryProcess } from '~/services/Process'
import { DiscoveryProcessAPI, serviceDiscoveryProcess } from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'
import { useGetProcess } from '../../useGetProcess'
import { getFormattedFiles, type FormattedFile } from './useUploadFiles.utils'

/**
 * Hook that implements react query `mutation`
 * to upload mockup files.
 */
export const useUploadFiles = () => {
  const process = useGetProcess()

  const queryClient = useQueryClient()

  // Query process: to get `isProcessingFiles` indicator.
  const { data: dataProcess } = useQuery({
    ...queryProcess({ processId: process.identity }),
    enabled: !!process.identity,
    refetchInterval: (data) =>
      !!data.state.data?.data?.isProcessingFiles
        ? TIMER.REFRESH_GENERATING_PROCESS
        : false,
  })

  // Mutation.
  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: async (files: File[]) => {
      // Files to be uploaded in the format for the endpoint.
      const filesToBeUploaded = await getFormattedFiles(
        process.identity ?? '',
        files,
      )

      // Endpoint URL.
      const url = DiscoveryProcessAPI.UploadFiles

      // Form data to be provided to endpoint.
      const formData = new FormData()

      // Append the necessary form data.
      filesToBeUploaded.forEach((fileToUpload: FormattedFile) => {
        const { file, fileName } = fileToUpload

        // The `fileName` is the full path:
        // Backend needs to receive the full path as file name.
        formData.append('files', file, fileName)
      })
      formData.append('processId', process.identity || '')
      formData.append(
        'fileUploadInfos',
        JSON.stringify(filesToBeUploaded.map(({ file, ...rest }) => rest)),
      )

      return serviceDiscoveryProcess.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    onSuccess: () => {
      // Invalidate the process query
      queryClient.invalidateQueries({
        queryKey: queryKeyProcess(process.identity),
      })
      // Invalidate the process files query
      queryClient.invalidateQueries({
        queryKey: ['processFiles', process.identity],
      })
    },
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return {
    ...restMutation,
    error,
    isError,
    isProcessingFiles: dataProcess?.data?.isProcessingFiles,
  }
}
