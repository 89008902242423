import DeselectIcon from '@mui/icons-material/Deselect'
import MergeIcon from '@mui/icons-material/Merge'
import { twMerge } from '^/tailwind.config'
import type { ConfigPopperBaseProps } from '~/models/types/components/codeGenerationStrategy/ConfigPopperBaseProps'
import {
  Button,
  LoadingButton,
  Tooltip,
  buttonClasses,
  iconClasses,
} from './ConfigPopper.styles'

export type MultipleNodesButtonsProps = Pick<
  ConfigPopperBaseProps,
  'onDeselectClick' | 'selectedTextSections'
> & {
  /** Indicates if the merge mutation is pending. */
  isMergePending?: boolean
  /** The callback handler for merge button click. */
  onMergeClick?: () => void
}

/**
 * Renders the config buttons when multiple nodes
 * are selected.
 */
export const MultipleNodesButtons = (props: MultipleNodesButtonsProps) => {
  const {
    isMergePending,
    onDeselectClick,
    onMergeClick,
    selectedTextSections,
  } = props

  if (
    !selectedTextSections?.selected?.length ||
    (selectedTextSections?.selected as GUID[]).length <= 1
  )
    return null

  return (
    <>
      <Tooltip title="Merge nodes">
        <LoadingButton
          className={twMerge('mr-2', buttonClasses)}
          loading={isMergePending}
          onClick={onMergeClick}
          name="Merge nodes"
        >
          <MergeIcon className={iconClasses} />
        </LoadingButton>
      </Tooltip>

      <Tooltip title="Deselect nodes">
        <Button
          className={buttonClasses}
          onClick={onDeselectClick}
          name="Deselect nodes"
        >
          <DeselectIcon className={iconClasses} />
        </Button>
      </Tooltip>
    </>
  )
}
