import { Stack, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Form, useLoaderData, useParams } from 'react-router-dom'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { queryDevelopment } from '~/services/Development'
import { DomainByPlatformId } from '~/services/Development.types'

export function PublishReaction() {
  // const errors = useActionData() as GenericObj<string> | undefined
  const params = useParams()

  const initialData = useLoaderData() as Awaited<
    AxiosResponse<DomainByPlatformId | null, unknown>
  >
  const {
    data,
    // isInitialLoading, // etc
  } = useQuery({
    ...queryDevelopment(params.platformId),
    initialData,
    select: (response) => response.data,
  })

  const selectedAggregate =
    data && params.aggregateId
      ? data.boundedContexts.find((context) =>
          context.aggregates.find(
            (aggregate) => aggregate.identity === params.aggregateId,
          ),
        )
      : undefined
  const selectedReaction =
    params.reactionId && selectedAggregate
      ? selectedAggregate?.aggregates[0]?.reactions.find(
          (reaction) => reaction.identity === params.reactionId,
        )
      : undefined

  return (
    <>
      <Stack gap={2}>
        <Typography>
          Please enter the commit details to generate the code for the feature:{' '}
          <strong>{selectedReaction?.actionName}</strong>
        </Typography>
        <Form method="post" id="form-aggregate-publish-reaction">
          <Stack gap={2}>
            <TextField
              label="Commit"
              variant="outlined"
              name="commitMessage"
              defaultValue={`Generate code for the feature ${selectedReaction?.actionName}`}
              fullWidth
              required
            />
            <TextField
              label="Branch"
              variant="outlined"
              name="branch"
              fullWidth
            />
            <input
              type="hidden"
              name="reactionName"
              value={params.reactionName}
            />
            <FormFooter
              submittingText="Generating code..."
              submitText="Generate code"
            />
          </Stack>
        </Form>
      </Stack>
    </>
  )
}
