import { useLocation, useParams } from 'react-router-dom'
import { BusinessDeveloperRoutesEnum } from '~/models/enums/routes/BusinessDeveloperRoutesEnum'

/**
 * Hook to provide resources for `business` and
 * `developer` pages.
 */
export const useBizDevResources = () => {
  // React Router Dom.
  const { pathname } = useLocation()
  const params = useParams()

  // Pathnames.
  const isCommandPage = pathname.includes('command')
  const isReactionPage = pathname.includes('reaction')
  const isReadModelPage = pathname.includes('readmodel')
  const isEditPage = pathname.includes('edit')
  const isAddPage = pathname.includes('add')
  const isDeletePage = pathname.includes('delete')
  const isCommitsHistoryPage = pathname.includes(
    BusinessDeveloperRoutesEnum.COMMITS_HISTORY,
  )
  const isPlatformSetup =
    pathname.includes('platform-setup') || pathname.includes('generate-code')

  // URL params.
  const {
    aggregateId = '',
    boundedContext = '',
    commandId = '',
    organisationId = '',
    platformId = '',
    processId = '',
    reactionId = '',
    readModelId = '',
  } = params

  // Constants.
  const eventType = isCommandPage
    ? 'commands'
    : isReactionPage
      ? 'reactions'
      : 'readModels'
  const paramType = isCommandPage
    ? 'commandId'
    : isReactionPage
      ? 'reactionId'
      : 'readModelId'
  const singularEventType = isCommandPage
    ? 'command'
    : isReactionPage
      ? 'reaction'
      : 'readmodel'

  // Refs.
  const dataId = params[paramType]
  const dataType = paramType.replace('Id', '')

  return {
    // Pathnames.
    isAddPage,
    isCommandPage,
    isCommitsHistoryPage,
    isDeletePage,
    isEditPage,
    isPlatformSetup,
    isReactionPage,
    isReadModelPage,

    // URL params.
    aggregateId,
    boundedContext,
    commandId,
    organisationId,
    platformId,
    processId,
    reactionId,
    readModelId,

    // Constants.
    dataId,
    dataType,
    eventType,
    paramType,
    singularEventType,
  }
}
