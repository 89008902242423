import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import { queryKeyPlatformTeam } from '~/services/PlatformTeam'
import { PlatformTeamAPI, servicePlatformTeam } from '~/services/base'
import { getOrganisation } from '~/utils/getOrganisation'
import { getPlatform } from '../utils'

// This file is not used anymore
// it's being handled by Git Credentials actions `../git-credentials/action.ts`

export const actionPlatformConfirm =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params, context }: ActionFunctionArgs) => {
    const {
      data: { identity: organisationId },
    } = await getOrganisation(queryClient, pca)
    if (!organisationId) {
      return redirect('/welcome/step1')
    }

    await queryClient.invalidateQueries({
      queryKey: queryKeyPlatformTeam(organisationId),
    })
    const {
      data: { identity: platformId },
    } = await getPlatform(queryClient, organisationId)
    // Get form data
    const formData = await request.formData()

    // Builds the data to send to server
    const comment = formData.get('comment')

    const postData = {
      comment,
      platformId,
    }

    // Setups and send to server
    const url = PlatformTeamAPI.Publish
    const req = await servicePlatformTeam.post(url, postData)

    // If it succeeed, just redirect to the next step
    if (req.status === 200) {
      const requestUrl = new URL(request.url)
      const { platformId, processId, aggregateId } = params

      // if user is coming from the business side, we want to continue to the initialise step
      if (requestUrl.pathname.includes('business')) {
        const eventId = requestUrl.searchParams.get('initialise')
        const eventType = requestUrl.searchParams.get('type')

        return redirect(
          `/${organisationId}/${platformId}/business/${processId}/${aggregateId}/edit-hosting?initialise=${eventId}&type=${eventType}`,
        )
      }

      return redirect(`/${organisationId}/${platformId}/developer`)
    }

    // Else, redirect to "home"
    return redirect('/')
  }
