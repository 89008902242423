import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'
import { StyledTreeItem } from '../StyledTreeItem'
import { TreeItemLabel } from './TreeItemLabel'
import { TreeItemLabelIcon } from './TreeItemLabelIcon'

type RenderTreeFilePathProps = {
  /** Handler for context menu event. */
  handleContextMenu: (event: EventFor<'li', 'onContextMenu'>) => void
  /** The current node. */
  node: FolderOrFileStructure
}

/**
 * Renders the file path `tree items`.
 */
export const RenderTreeFilePath = (props: RenderTreeFilePathProps) => {
  const { handleContextMenu, node } = props
  const { children, id, name } = node || {}

  return (
    <StyledTreeItem
      data-node-id={id}
      data-node-label={name}
      icon={<TreeItemLabelIcon node={node} />}
      key={id}
      label={<TreeItemLabel node={node} />}
      nodeId={id}
      onContextMenu={handleContextMenu}
      size="small"
    >
      {children?.map((currNode) => (
        <RenderTreeFilePath
          handleContextMenu={handleContextMenu}
          key={currNode.id}
          node={currNode}
        />
      ))}
    </StyledTreeItem>
  )
}
