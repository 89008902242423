import { Alert, Fade, Snackbar } from '@mui/material'
import { useAppSnackbarContext } from '~/hooks/contexts/useAppSnackbarContext'

/**
 * The application snackbar component.
 * It can be used for general snackbar information:
 * - Indicates a success request;
 * - Indicates if something went wrong.
 *
 * It is implemented with an `Alert` component,
 * so the content should be provided as the
 * alert component `children` not as a the
 * snackbar `message`.
 */
const AppSnackbar = () => {
  const { componentProps, setComponentProps } = useAppSnackbarContext()
  const { alertProps, snackbarProps } = componentProps || {}

  const { children: snackbarChildren, ...restSnackbarProps } =
    snackbarProps || {}

  /**
   * Reset the state when the transition exited.
   * To preserve the "close" transition effect, it is necessary
   * to keep all the props and only change `open` prop,
   * otherwise the snackbar will simply disappear.
   * This method will make sure to cleanup the snackbar contents
   * and make it ready for any other necessary use.
   */
  const handleTransitionExited = () => {
    setComponentProps?.({})
  }

  return (
    <Snackbar
      TransitionComponent={Fade}
      TransitionProps={{ onExited: handleTransitionExited }}
      {...restSnackbarProps}
    >
      <div>
        {snackbarChildren}
        <Alert {...alertProps} />
      </div>
    </Snackbar>
  )
}

export default AppSnackbar
