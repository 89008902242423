import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { queryKeyStrategiesByOrganisationId } from '~/services/GenerationStrategy'
import { PayloadCreateOrganisation } from '~/services/GenerationStrategy.types'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'

export const actionConfigurationGenerationStrategyCreate =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const { organisationId, platformId } = params
    const formData = await request.formData()
    const formEntries = Object.fromEntries(formData)
    // Types below are needed because FormDataEntryValue can be a string or a File
    const name = formEntries.name as string | undefined

    invariant(name, 'Name is required')
    invariant(organisationId, 'Organisation ID is required')

    const postData = {
      name,
      organisationId,
    } satisfies PayloadCreateOrganisation

    const url = GenerationStrategyAPI.CreateStrategy

    try {
      const req = await serviceGenerationStrategy.post(url, postData)

      // If it succeeed, just redirect to the parent route
      if (req.status === 200) {
        // but first invalidade the query so the cache is updated
        await queryClient.invalidateQueries({
          queryKey: queryKeyStrategiesByOrganisationId(organisationId),
        })
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err?.response?.data) {
        return err.response.data
      }

      return 'There was an error creating the user'
    }

    // then redirect, in both cases, we dont want to show any errors we didn't handle for now
    return redirect(
      `/${organisationId}/${platformId}/configuration/code-generation-strategies`,
    )

    // else, well, do nothing
    // return null
  }
