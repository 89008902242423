import { twMerge } from '^/tailwind.config'
import React from 'react'

const DEFAULT_ROW_CLASSNAMES = 'flex flex-row'

type RowComponentProps = React.ComponentPropsWithoutRef<'div'> & {
  className?: string
}
function RowComponent(
  props: RowComponentProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const mergedClassName = twMerge(DEFAULT_ROW_CLASSNAMES, props.className)

  return (
    <div ref={ref} {...props} className={mergedClassName}>
      {props.children}
    </div>
  )
}

export const Row = React.forwardRef<HTMLDivElement, RowComponentProps>(
  RowComponent,
)
