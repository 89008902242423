import { useGetColors } from '../config'

export function CustomSubscribeEdgePointer() {
  const colors = useGetColors()
  return (
    <svg
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        display: 'hidden',
        width: 0,
        height: 0,
      }}
    >
      <defs>
        <marker
          id="logo"
          viewBox="0 0 40 40"
          markerHeight={12}
          markerWidth={12}
          refX={4}
          refY={12}
        >
          <path
            d="M14.1893 11.261C14.6244 11.6576 14.6244 12.3424 14.1893 12.739L2.42369 23.4646C1.78164 24.0499 0.75 23.5944 0.75 22.7256L0.750001 1.27437C0.750001 0.405574 1.78164 -0.0499429 2.42369 0.535356L14.1893 11.261Z"
            fill={colors.subscribeEdgeColors.line}
          />
        </marker>
      </defs>
    </svg>
  )
}
