import { CircularProgress } from '@mui/material'
import { Column } from '~/components/Column'
import type { StatementsDiagramBaseProps } from '~/models/types/components/processInfo/StatementsDiagramBaseProps'
import type { UpdateStatementBaseProps } from '../Step/Step.types'
import { ContentRaisedDomainEvent } from './ContentRaisedDomainEvent'
import { ContentRequiredDataInfo } from './ContentRequiredDataInfo'

type ContentProps = UpdateStatementBaseProps &
  Pick<StatementsDiagramBaseProps, 'onAddReaction'> & {
    /** Callback handler for the `raised domain event` mouse down event. */
    onRaisedDomainEventMouseDown: () => void
  }

export function Content(props: ContentProps) {
  const {
    data: { data },
  } = props

  const { errorMessage, hasError, isEditable, isEmpty, isFetching } = data || {}

  // Conditional render.
  if (!isEditable && isFetching) {
    return (
      <div className="flex items-center justify-center gap-4 p-4">
        <CircularProgress size="2rem" />
        <p className="text-muted m-0">Processing</p>
      </div>
    )
  }

  if (isEmpty) {
    return (
      <p className="m-0 flex flex-1 items-center justify-center break-words p-4 text-center text-font-muted">
        Please enter a simple description of the next step in the process.
      </p>
    )
  }

  if (hasError) {
    return (
      <p className="m-0 flex flex-1 items-start justify-start break-words p-4 font-semibold text-primary">
        {errorMessage}
      </p>
    )
  }

  return (
    <Column className="items-start pb-3 pl-3 pt-2">
      <ContentRequiredDataInfo {...props} />

      <ContentRaisedDomainEvent {...props} />
    </Column>
  )
}
