import ArrowKeysIcon from '~/assets/explanation-panel/arrow-keys.svg?react'
import MenuIcon from '~/assets/explanation-panel/menu.svg?react'
import ReturnKeyIcon from '~/assets/explanation-panel/return-key.svg?react'
import RocketIcon from '~/assets/explanation-panel/rocket.svg?react'
import StarIcon from '~/assets/explanation-panel/star.svg?react'
import { Column } from '~/components/Column'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'

export function ExplanationDialogContent() {
  return (
    <>
      <Text className="pb-6 text-xl">How to use dot-star</Text>
      <Column className="gap-4">
        <ExplanationStep
          // step="1"
          icon={<ArrowKeysIcon />}
          description="Use the arrow keys to navigate through the steps in the process"
        />
        <ExplanationStep
          // step="2"
          icon={<ReturnKeyIcon />}
          description="To add or modify an existing step, simply describe the step using natural language. Add, remove or modify steps as required"
        />
        <ExplanationStep
          // step="3"
          icon={<StarIcon />}
          description="When you are happy with the description of the step, mark it as a 'feature'. This step will be transformed in to a service, where code can be generated"
        />
        <ExplanationStep
          // step="4"
          icon={<MenuIcon />}
          description="Adjust the feature's input and output properties to meet your requirements"
        />
        <ExplanationStep
          // step="5"
          icon={<RocketIcon />}
          description="Connect to your source code repository and automatically generate service code"
        />
      </Column>
    </>
  )
}

type ExplanationStepProps = {
  step?: string
  icon?: React.ReactNode
  description?: string
}
export function ExplanationStep({
  step,
  icon,
  description,
}: ExplanationStepProps) {
  return (
    <Row className="gap-3">
      <div className="mt-1 min-w-[34px]">
        {/* <WizardBullet width={23} height={26} step="1">
          <Text className="text-sm text-white">{step}</Text>
        </WizardBullet> */}
        {icon}
      </div>
      <Text className="text-sm">{description}</Text>
    </Row>
  )
}
