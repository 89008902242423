import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RuleIcon from '@mui/icons-material/Rule'
import {
  Breadcrumbs,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
} from '@mui/material'
import {
  GridCallbackDetails,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid'
import { TreeView } from '@mui/x-tree-view/TreeView'
import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { twMerge } from '^/tailwind.config'
import { AxiosResponse } from 'axios'
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react'
import { Panel, PanelGroup } from 'react-resizable-panels'
import {
  Link,
  useLoaderData,
  useNavigation,
  useParams,
  useSubmit,
} from 'react-router-dom'
import { Column } from '~/components/Column'
import ResizeHandle from '~/components/ResizeHandle'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'
import { DialogMappingCondition } from '~/components/dialogs/DialogMappingCondition'
import { useAppPopperContext } from '~/hooks/contexts/useAppPopperContext'
import { useSelectedTextSectionsContext } from '~/hooks/contexts/useSelectedTextSectionsContext'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import type { SelectedTextSectionsType } from '~/models/types/components/codeGenerationStrategy/ManageNodeSelection/SelectedTextSectionsType'
import { CodeGenerationStrategyEditSubmitIntent } from '~/routes/configuration/code-generation-strategies/edit/action'
import { DialogDeleteFilePath } from '~/routes/configuration/code-generation-strategies/edit/components/DialogDeleteFilePath/DialogDeleteFilePath'
import { DialogDeleteProperty } from '~/routes/configuration/code-generation-strategies/edit/components/DialogDeleteProperty/DialogDeleteProperty'
import { RenderTreeItem } from '~/routes/configuration/code-generation-strategies/edit/components/RenderTreeItem/RenderTreeItem'
import {
  getAllPropertyIdsByFileId,
  getFolderIds,
  getNodeById,
} from '~/routes/configuration/code-generation-strategies/edit/utils/utils'
import { ResponseDiscoveryOrganisation } from '~/services/Discovery.types'
import {
  mutationConvertFileToTemplate,
  queryListBindingParameters,
  queryStrategyById,
} from '~/services/GenerationStrategy'
import type {
  FileStructure,
  FolderOrFileStructure,
  PropertyBinding,
  ResponseGetGenerationStrategy,
  ResponseListParametersByGenerationId,
} from '~/services/GenerationStrategy.types'
import { useCodeGenerationStore } from '~/store'
import { invalidateQueries } from '~/utils/api/codeGenerationStrategy/invalidateQueries'
import { LoadRepositoryData } from './components/LoadRepositoryData/LoadRepositoryData'
import { PanelAsideDetails } from './components/PanelAsideDetails/PanelAsideDetails'
import { PanelAsideDetailsButtons } from './components/PanelAsideDetails/PanelAsideDetailsButtons'
import { useManagePanelAsideDetails } from './components/PanelAsideDetails/useManagePanelAsideDetails'
import { RenderTreeFilePath } from './components/RenderTreeFilePath/RenderTreeFilePath'
import { UploadFiles } from './components/UploadFiles/UploadFiles'
import { getDomainDictionaryPayloadData } from './utils/getDomainDictionaryPayloadData'

export function CodeGenerationStrategiesEdit() {
  // React Router Dom.
  const navigation = useNavigation()

  const params = useParams()
  const { generationStrategyId } = params

  const submit = useSubmit()

  // Data from the server
  const initialData = useLoaderData() as {
    organisation: Awaited<
      AxiosResponse<ResponseDiscoveryOrganisation | null, unknown>['data']
    >
    strategy: Awaited<
      AxiosResponse<ResponseGetGenerationStrategy | null, unknown>
    >
    parameters: Awaited<
      AxiosResponse<ResponseListParametersByGenerationId[], unknown>
    >
  }

  // Storage states.
  const selectedTreeItem = useCodeGenerationStore(
    (state) => state.selectedTreeItem,
  )
  const setSelectedTreeItem = useCodeGenerationStore(
    (state) => state.setSelectedTreeItem,
  )

  // Uses local storage to save the expanded properties so that when the user navigates back and forth, it will keep the expanded properties
  const expandedProperties = useCodeGenerationStore(
    (state) => state.expandedProperties,
  )
  const setExpandedProperties = useCodeGenerationStore(
    (state) => state.setExpandedProperties,
  )

  // Queries.
  const { data: dataStrategy } = useQuery({
    ...queryStrategyById(generationStrategyId),
    initialData: initialData.strategy,
    select: (response) => response.data,
  })

  const { data: dataDirectoryItems, isLoading: isLoadingDataDirectoryItems } =
    useQuery({
      ...queryStrategyById(generationStrategyId),
      initialData: initialData.strategy,
      select: (response) => {
        if (
          response?.data?.directoryItems &&
          response.data.directoryItems.length
        ) {
          return {
            children: response.data.directoryItems,
            id: 'root',
            name: 'root',
            path: '',
          }
        }
      },
    })

  const { data: dataDomainDictionary } = useQuery({
    ...queryListBindingParameters(generationStrategyId),
    initialData: initialData.parameters,
    select: (response) => response.data,
  })

  const isSubmittingGitRepository = Boolean(
    useIsMutating({
      mutationKey: [QueryKeysEnum.LOAD_GIT_REPOSITORY],
    }),
  )

  // A combination of `submit git repo` mutation and
  // `data directory items` fetch.
  const isPendingSubmitGitRepository =
    isSubmittingGitRepository || isLoadingDataDirectoryItems

  // States.
  const [treeExpanded, setTreeExpanded] = useState<string[]>([])
  const [paramSelected, setParamsSelected] = useState<string>('')
  const [selectedPropertyBindingId, setSelectedPropertyBindingId] =
    useState<string>('')
  const [selectedPropertyBinding, setSelectedPropertyBinding] = useState<
    FolderOrFileStructure | PropertyBinding
  >({} as FolderOrFileStructure)
  const [selectedFileId, setSelectedFileId] = useState<string>(
    getNodeById(selectedTreeItem?.[0], dataDirectoryItems)?.fileId ?? '',
  )

  const selectedFileInfo = React.useMemo(
    () => getNodeById(selectedTreeItem?.[0], dataDirectoryItems),
    [dataDirectoryItems, selectedTreeItem],
  )
  const [contextMenuFilePathTree, setContextMenuFilePathTree] = useState<{
    mouseX: number
    mouseY: number
    id?: GUID
    label?: string
  } | null>(null)
  const [isDeleteDialogFilePathOpen, setIsDeleteDialogFilePathOpen] =
    useState(false)
  const [isDeleteDialogPropertyOpen, setIsDeleteDialogPropertyOpen] =
    useState(false)
  const [isMappingConditionDialogOpen, setIsMappingConditionDialogOpen] =
    useState(false)

  // Queries states.
  const isWaitingAutoMapDictionary =
    navigation.state === 'submitting' &&
    navigation.formData &&
    Object.fromEntries(navigation.formData).intent ===
      ('auto-map-bindings-for-properties' satisfies CodeGenerationStrategyEditSubmitIntent)

  const isSubmittingDictionary =
    navigation.state === 'submitting' &&
    navigation.formData &&
    Object.fromEntries(navigation.formData).intent ===
      ('parameter-map-update' satisfies CodeGenerationStrategyEditSubmitIntent)

  // Hooks.
  const {
    showNodePropertiesPanel,
    showParametersPanel,
    handleToggleNodePropertiesClick,
    handleToggleParametersClick,
  } = useManagePanelAsideDetails()

  // DataGrid
  const gridApiRef = useGridApiRef()

  // Selected text sections state.
  const { selectedTextSections, setSelectedTextSections } =
    useSelectedTextSectionsContext()

  // Popper Context.
  const { closeAppPopper } = useAppPopperContext()

  // Context state refs: this is necessary to call the functions
  // when the component unmounts.
  const resetRef = useRef<{
    closeAppPopper?: () => void
    setSelectedTextSections?: Dispatch<
      SetStateAction<SelectedTextSectionsType | null>
    >
  }>()

  // Set reset methods to ref.
  resetRef.current = {
    closeAppPopper,
    setSelectedTextSections,
  }

  // Lifecycle.
  useEffect(
    () => () => {
      resetRef.current?.closeAppPopper?.()
      resetRef.current?.setSelectedTextSections?.(null)
    },
    [],
  )

  useEffect(() => {
    if (dataDirectoryItems?.children) {
      setTreeExpanded(getFolderIds(dataDirectoryItems?.children))
    }
  }, [dataDirectoryItems?.children])

  // Methods.
  const handleRightClickContextMenuFilePathTree = useCallback(
    (event: EventFor<'li', 'onContextMenu'>) => {
      event.preventDefault()
      event.stopPropagation() // prevents from propagating all the way to root

      const id = event.currentTarget.dataset.nodeId
      const label = event.currentTarget.dataset.nodeLabel

      // Selects the node
      if (id) {
        setSelectedTreeItem([id])
      }

      // Opens the context menu
      setContextMenuFilePathTree(
        contextMenuFilePathTree === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
              id,
              label,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
      )
    },
    [contextMenuFilePathTree, setSelectedTreeItem],
  )

  // Deletes a property binding node.
  const handleDeletePropertyBinding = useCallback(
    (propertyBinding?: FolderOrFileStructure | PropertyBinding) => {
      // Selects the node
      if (propertyBinding?.id) {
        setSelectedPropertyBinding(propertyBinding)
        setSelectedPropertyBindingId(propertyBinding?.id)
      }

      handleMenuClickDeleteObjectProperty()
    },
    [setSelectedPropertyBindingId],
  )

  const handleOpenMappingConditionDialogInsideCollection = useCallback(
    (propertyBinding?: FolderOrFileStructure) => {
      if (propertyBinding) {
        setSelectedPropertyBinding(propertyBinding)
        setIsMappingConditionDialogOpen(true)
      }
    },
    [setSelectedPropertyBinding],
  )

  function handleToggleTree(_: React.SyntheticEvent, nodeIds: string[]) {
    setTreeExpanded(nodeIds)
  }

  function handleTogglePropertyTree(
    _: React.SyntheticEvent,
    nodeIds: string[],
  ) {
    setExpandedProperties(nodeIds)
  }

  const handleSelectTree = useCallback(
    (_: React.SyntheticEvent, nodeIds: string[]) => {
      // Reset selected text sections: this is to avoid
      // popper from the previous file to remain opened.
      if (!!selectedTextSections?.selected?.length) {
        setSelectedTextSections?.(null)
      }

      setSelectedTreeItem(nodeIds)

      const nodeInfo = getNodeById(
        nodeIds?.[0],
        dataDirectoryItems,
      ) as FileStructure | null

      setSelectedFileId(nodeInfo?.fileId ?? '')

      // Sets the expanded nodes for properties
      const allIds = getAllPropertyIdsByFileId(nodeInfo?.propertyBindings)
      setExpandedProperties(allIds)
    },
    [
      dataDirectoryItems,
      selectedTextSections,
      setExpandedProperties,
      setSelectedTreeItem,
      setSelectedFileId,
    ],
  )

  function handleSelectPropertyTree(_: React.SyntheticEvent, nodeId: string) {
    if (nodeId) {
      setSelectedPropertyBindingId(nodeId)
    }
  }

  function handleExpandClick() {
    setTreeExpanded((oldExpanded) => {
      return oldExpanded.length === 0
        ? getFolderIds(dataDirectoryItems?.children)
        : []
    })
  }

  function handleMenuClickDeleteFilePath(event: EventFor<'button', 'onClick'>) {
    handleMenuClickCommonAction(event)
    setIsDeleteDialogFilePathOpen(true)
  }

  function handleMenuClickDeleteObjectProperty() {
    setIsDeleteDialogPropertyOpen(true)
  }

  function handleMenuClickCommonAction(event: EventFor<'button', 'onClick'>) {
    event.stopPropagation()
    handleCloseContextMenuFilePathTree()
  }

  function handleCloseDeleteDialogs() {
    setIsDeleteDialogFilePathOpen(false)
    setIsDeleteDialogPropertyOpen(false)
  }

  function handleCloseMappingConditionDialog() {
    setIsMappingConditionDialogOpen(false)
  }

  function handleCloseContextMenuFilePathTree() {
    setContextMenuFilePathTree(null)
  }

  function handleUpdateParametersMap() {
    const rowModels = gridApiRef.current?.getRowModels()
    const mappedRowModels = [
      ...rowModels.values(),
    ] as unknown as ResponseListParametersByGenerationId[]

    const domainDictionaryEntries =
      getDomainDictionaryPayloadData(mappedRowModels)

    const data = {
      rows: JSON.stringify(domainDictionaryEntries),
      intent:
        'parameter-map-update' satisfies CodeGenerationStrategyEditSubmitIntent,
    }

    submit(data, { method: 'POST' })
  }

  function handleRowSelectionModelChange(
    rowSelectionModel: GridRowSelectionModel,
    _: GridCallbackDetails,
  ) {
    if (rowSelectionModel.length) {
      const first = rowSelectionModel[0]

      if (first) {
        const param = dataDomainDictionary.find(
          (item) => item.key === String(first),
        )
        setParamsSelected(param?.key || '')
      }
    }
  }

  const queryClient = useQueryClient()
  const convertToTemplateMutation = useMutation<
    AxiosResponse<any, any>,
    Error,
    string
  >({
    mutationFn: (fileId: string) => mutationConvertFileToTemplate(fileId),
    onSuccess: (data) => {
      // Handle success (e.g., show a success message)
      console.log('File converted successfully:', data)
      invalidateQueries(queryClient, generationStrategyId)
    },
    onError: (error) => {
      // Handle error (e.g., show an error message)
      console.error('Error converting file to template:', error)
    },
  })

  const handleConvertToTemplate = () => {
    if (selectedFileId) {
      convertToTemplateMutation.mutate(selectedFileId)
    }
  }

  if (dataStrategy && dataDomainDictionary) {
    return (
      <>
        <div className="bg-white p-4">
          <div className="ml-2">
            <Breadcrumbs
              separator=">"
              aria-label="breadcrumb"
              className="text-xs"
            >
              <Link
                to="../../"
                relative="path"
                className="border-0 border-b border-solid border-b-tertiary-100 text-tertiary no-underline hover:border-b-tertiary-300 hover:text-tertiary-700"
              >
                AI Blueprints
              </Link>

              <Text className="text-tertiary">{dataStrategy.name}</Text>
            </Breadcrumbs>

            <Text className="mb-4 text-2xl">
              AI Blueprint: {dataStrategy.name}
            </Text>
          </div>

          <Row className="gap-2">
            <PanelGroup direction="horizontal">
              <Panel id="1" order={1} defaultSize={30} collapsible={false}>
                <div className="group relative h-[calc(100svh-161px)] overflow-auto rounded-l-lg border-r-2 border-solid border-transparent border-r-slate-300 bg-slate-200 p-4">
                  <LoadRepositoryData dataDirectoryItems={dataDirectoryItems} />

                  <Divider className="-mx-4 my-2 border-slate-300" />

                  <Row className="mb-2 items-center justify-between gap-2">
                    <Column>
                      <Text className="text-xl font-semibold">Files</Text>

                      {dataDirectoryItems ? (
                        <Text className="text-tertiary">
                          Repository file and path representation
                        </Text>
                      ) : null}
                    </Column>

                    {dataDirectoryItems ? (
                      <Row className="my-2 gap-2">
                        <div>
                          <Button
                            onClick={handleExpandClick}
                            variant="outlined"
                            size="small"
                            className="w-fit"
                          >
                            {treeExpanded.length === 0
                              ? 'Expand all'
                              : 'Collapse all'}
                          </Button>
                        </div>
                      </Row>
                    ) : null}
                  </Row>

                  {!dataDirectoryItems && !isPendingSubmitGitRepository && (
                    <UploadFiles />
                  )}

                  {dataDirectoryItems && !isPendingSubmitGitRepository && (
                    <>
                      <TreeView
                        aria-label="repository tree view"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        expanded={treeExpanded}
                        selected={selectedTreeItem}
                        onNodeToggle={handleToggleTree}
                        onNodeSelect={handleSelectTree}
                        multiSelect
                      >
                        <RenderTreeFilePath
                          handleContextMenu={
                            handleRightClickContextMenuFilePathTree
                          }
                          node={dataDirectoryItems}
                        />
                      </TreeView>

                      <Menu
                        open={contextMenuFilePathTree !== null}
                        onClose={handleCloseContextMenuFilePathTree}
                        anchorReference="anchorPosition"
                        anchorPosition={
                          contextMenuFilePathTree !== null
                            ? {
                                top: contextMenuFilePathTree.mouseY,
                                left: contextMenuFilePathTree.mouseX,
                              }
                            : undefined
                        }
                      >
                        <MenuItem className="p-0">
                          <button
                            onClick={handleMenuClickDeleteFilePath}
                            className="w-full cursor-pointer border-0 bg-transparent px-4 py-2 text-left text-base text-primary"
                            type="button"
                          >
                            Delete
                          </button>
                        </MenuItem>
                      </Menu>

                      <DialogDeleteFilePath
                        filePath={
                          getNodeById(selectedTreeItem?.[0], dataDirectoryItems)
                            ?.path
                        }
                        isDialogOpen={isDeleteDialogFilePathOpen}
                        handleCloseDialog={handleCloseDeleteDialogs}
                      />
                    </>
                  )}

                  {isPendingSubmitGitRepository && (
                    <Column>
                      {Array.from({ length: 8 }, (_, i) => (
                        <Skeleton key={i} />
                      ))}
                    </Column>
                  )}
                </div>
              </Panel>

              <ResizeHandle />

              <Panel id="2" order={2} collapsible={false}>
                <div
                  className={twMerge(
                    `relative h-[calc(100svh-161px)] flex-1 overflow-auto border-r-2 border-solid border-transparent border-r-slate-200 bg-slate-100 p-4`,
                  )}
                >
                  {selectedFileInfo?.fileBinding ? (
                    <>
                      <Row className="mb-4 items-center justify-between">
                        <Text className="text-xl font-semibold">
                          File details
                        </Text>
                        <Tooltip title="Convert the selected file to an AI blueprint template">
                          <Button
                            onClick={handleConvertToTemplate}
                            variant="contained"
                            color="primary"
                            size="medium"
                            startIcon={<ConvertIcon />}
                            className="bg-blue-600 hover:bg-blue-700"
                          >
                            Convert to template
                          </Button>
                        </Tooltip>
                      </Row>

                      <Row>
                        <Text>
                          {selectedFileInfo.fileBinding.mappingCondition}
                        </Text>

                        <IconButton
                          className="h-fit p-1"
                          onClick={(e) => {
                            e.stopPropagation()
                            handleOpenMappingConditionDialogInsideCollection?.(
                              selectedFileInfo,
                            )
                          }}
                        >
                          <RuleIcon
                            fontSize="small"
                            className="text-primary-400 opacity-50 group-hover/button:!text-white group-hover/wrapper:text-primary-400 group-hover/button:opacity-100 group-hover/wrapper:opacity-100"
                          />
                        </IconButton>
                      </Row>
                      <div className="mb-4">
                        <TreeView
                          aria-label="file nodes tree view"
                          defaultCollapseIcon={<ExpandMoreIcon />}
                          defaultExpandIcon={<ChevronRightIcon />}
                          expanded={expandedProperties}
                          selected={selectedPropertyBindingId}
                          onNodeToggle={handleTogglePropertyTree}
                          onNodeSelect={handleSelectPropertyTree}
                          className="w-full"
                          sx={{
                            '[data-node-property="Content"] > ul': {
                              borderLeft: 0,
                            },
                          }}
                        >
                          <RenderTreeItem
                            dataDomainDictionary={dataDomainDictionary}
                            fileId={selectedFileId}
                            node={selectedFileInfo}
                            onClickOpenMappingConditionDialog={
                              handleOpenMappingConditionDialogInsideCollection
                            }
                            onDeletePropertyBindingClick={
                              handleDeletePropertyBinding
                            }
                            selectedDomainDictionaryItem={paramSelected}
                          />
                        </TreeView>

                        <DialogMappingCondition
                          isDialogOpen={isMappingConditionDialogOpen}
                          handleCloseDialog={handleCloseMappingConditionDialog}
                          propertyBinding={selectedFileInfo.fileBinding!}
                          fileId={selectedFileId}
                        />

                        <DialogDeleteProperty
                          isDialogOpen={isDeleteDialogPropertyOpen}
                          handleCloseDialog={handleCloseDeleteDialogs}
                          propertyBinding={selectedPropertyBinding}
                          fileId={selectedFileId}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </Panel>

              <PanelAsideDetails
                dataDomainDictionary={dataDomainDictionary}
                fileId={selectedFileId}
                generationStrategyId={generationStrategyId}
                gridApiRef={gridApiRef}
                isSubmittingDictionary={isSubmittingDictionary}
                isWaitingAutoMapDictionary={isWaitingAutoMapDictionary}
                onDeletePropertyBindingClick={handleDeletePropertyBinding}
                onRowSelectionModelChange={handleRowSelectionModelChange}
                onUpdateParametersMap={handleUpdateParametersMap}
                selectedDomainDictionaryItem={paramSelected}
                showNodePropertiesPanel={showNodePropertiesPanel}
                showParametersPanel={showParametersPanel}
              />
            </PanelGroup>

            <PanelAsideDetailsButtons
              onToggleNodePropertiesClick={handleToggleNodePropertiesClick}
              onToggleParametersClick={handleToggleParametersClick}
              showNodePropertiesPanel={showNodePropertiesPanel}
              showParametersPanel={showParametersPanel}
            />
          </Row>
        </div>
      </>
    )
  }
}
const ConvertIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M17 3v10" />
    <path d="m12.7 6.3 3.3-3.3 3.3 3.3" />
    <path d="M3 3h6" />
    <path d="M21 21H3" />
    <path d="M12 3v6" />
    <path d="M3 9h6" />
    <path d="M3 15h6" />
  </svg>
)
