import type { TooltipProps } from '@mui/material'
import InfoIconTooltip from '../IconTooltip/InfoIconTooltip'

export type InfoLabelIconTooltipProps = Pick<TooltipProps, 'style' | 'title'>

/**
 * Implements the `InfoIconTooltip`, to be
 * rendered in a field label.
 */
const InfoLabelIconTooltip = (props: InfoLabelIconTooltipProps) => {
  const { style, title } = props

  return (
    <InfoIconTooltip
      iconProps={{
        className: 'ml-2',
        style: {
          fontSize: 18,
          ...style,
        },
      }}
      title={title}
      tooltipProps={{ arrow: true, placement: 'top' }}
    />
  )
}

export default InfoLabelIconTooltip
