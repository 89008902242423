import { useMediaQuery } from '@mui/material'
import React from 'react'
import { theme } from '~/config/theme'
import { useGlobalStore } from '~/store'

// DISABLING DARK MODE FOR NOW

export function useGlobalColorMode() {
  const colorMode = useGlobalStore((state) => state.colorMode)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const themeModified = React.useMemo(() => {
    if (prefersDarkMode && colorMode === 'system') {
      return theme(false)
    }
    return theme(false)
  }, [colorMode, prefersDarkMode])

  return themeModified
}
