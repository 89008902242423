import InfoIcon from '@mui/icons-material/Info'
import SaveIcon from '@mui/icons-material/Save'
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import {
  CircularProgress,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Tooltip,
  inputBaseClasses,
} from '@mui/material'
import { twMerge } from '^/tailwind.config'
import {
  useLayoutEffect,
  useRef,
  useState,
  type ElementRef,
  type MouseEvent,
} from 'react'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'
import { ControlledContentEditable } from '~/components/form/ControlledContentEditable/ControlledContentEditable'
import { useSelectedTextSectionsContext } from '~/hooks/contexts/useSelectedTextSectionsContext'
import { ValueBindingTreeItemFormEnum } from '~/models/enums/forms/ValueBindingTreeItemFormEnum'
import type { ValueBindingTreeItemProps } from '~/models/types/components/codeGenerationStrategy/ValueBindingTreeItemProps'
import { RevertToOriginalValue } from '~/routes/configuration/code-generation-strategies/edit/components/RevertToOriginalValue/RevertToOriginalValue'
import { ColoredParams } from '~/routes/configuration/code-generation-strategies/edit/components/ValueBindingTreeItem/ColoredParam'
import type { TextSection } from '~/services/GenerationStrategy.types'
import { EditableFieldFormFields } from '../EditableFieldFormFields/EditableFieldFormFields'
import { MenuToReplaceText } from '../MenuToReplaceText/MenuToReplaceText'
import { ParametersTable } from '../ParametersTable/ParametersTable'
import { useManageNodeSelection } from '../RenderTextSections/useManageNodeSelection'
import { Form, getIsContentNodeClasses } from './ValueBindingTreeItem.styles'
import { useMenuReplaceText } from './useMenuReplaceText'
import { useValueBindingTreeItem } from './useValueBindingTreeItem'
import { useValueBindingTreeItemStyles } from './useValueBindingTreeItemStyles'

/**
 * TODO: 20230213 - Consider creating a form component to avoid duplicates.
 *    Not done for now avoid a high risk of regression.
 */
const ValueBindingTreeItem = (props: ValueBindingTreeItemProps) => {
  const {
    dataDomainDictionary,
    fileId,
    initialValue,
    isContent,
    isParentSelectable,
    isParentSelected,
    node,
    parent,
    selectedDomainDictionaryItem,
    siblings,
  } = props

  const { children, name, originalValue, propertyName } = node || {}

  // States.
  const [toggleParametersPanel, setToggleParametersPanel] = useState(false)
  const [valueEditable, setValueEditable] = useState<boolean>(false)

  // Ref.
  const textFieldRef = useRef<ElementRef<'textarea'>>(null)

  // Selected text sections states.
  const { editableNode, isMergeMode, setEditableNode } =
    useSelectedTextSectionsContext()

  // Component main hook.
  const {
    configForMenuToReplace,
    control,
    handleFormSubmit,
    handleSubmit,
    handleValueFieldBlur,
    isPending,
    resetFormToInitialValues,
    setConfigForMenuToReplace,
    setFormValue,
    watchForm,
  } = useValueBindingTreeItem({ initialValue, fileId, node })

  // Node selection manager hook.
  const {
    handleNodeDoubleClick,
    handleSelectNodeClick,
    isSelectable,
    isSelected,
  } = useManageNodeSelection({
    node: node as TextSection,
    parent,
    siblings,
  })

  // Styles hook.
  const { formHoveredClass, setIsFormHovered } = useValueBindingTreeItemStyles()

  // Context menu hook.
  const { handleContextMenu, handleContextMenuClose, handleMenuItemClick } =
    useMenuReplaceText({
      dataDomainDictionary,
      fieldRef: textFieldRef,
      selectedDomainDictionaryItem,
      setConfigForMenuToReplace,
      setFormValue,
      watchForm,
    })

  // Field values.
  const textFieldValue = watchForm?.(ValueBindingTreeItemFormEnum.VALUE) || ''
  const paramsFieldValue = watchForm?.(ValueBindingTreeItemFormEnum.PARAMETERS)
  const parsedParams = JSON.parse(paramsFieldValue || '[]')

  // Indicates if the value is editable.
  const isValueEditable =
    node?._t === 'TextSection' ? editableNode === node?.id : valueEditable

  const handleTextClickToEdit = (
    event: MouseEvent<HTMLSpanElement>,
    node?: TextSection,
  ) => {
    // in case the button (param) inside was clicked
    if (
      (event.target as HTMLButtonElement).name === 'param' ||
      isPending ||
      isMergeMode
    )
      return

    if (node?._t === 'TextSection') {
      // Necessary to distinguish single from double click behavior.
      handleNodeDoubleClick(node)
      // Set the editable text section node.
      setEditableNode?.(node?.id || null)
    } else {
      // Set if the value field is editable.
      setValueEditable(true)
    }

    setTimeout(() => textFieldRef.current?.focus(), 200)
  }

  // Makes ESC close the modal / dropdown / dialog
  // and leave editable mode.
  useLayoutEffect(() => {
    const closeMenu = (event: KeyboardEvent) => {
      // Do nothing if the event was already processed
      if (event.defaultPrevented) return

      if (event.key === 'Escape') {
        handleContextMenuClose()

        if (editableNode || valueEditable) {
          setEditableNode?.(null)
          setValueEditable(false)
          resetFormToInitialValues?.()
        }
      }
    }

    document.addEventListener('keydown', closeMenu)

    return () => {
      document.removeEventListener('keydown', closeMenu)
    }
  }, [isValueEditable])

  // The form mouse enter handler.
  const handleFormMouseEnter = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    // Avoid the standard hover behavior when `isMergeMode` is active.
    if (!isMergeMode) {
      setIsFormHovered(true)
    }
  }

  // The form mouse leave handler.
  const handleFormMouseLeave = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setIsFormHovered(false)
  }

  // Render null.
  if (!node) return null

  // Indicates empty and `empty group` (empty value with children) nodes.
  const isEmpty = textFieldValue === ''
  const isGroupNode = !!children?.length && isEmpty

  if (isContent) {
    return (
      <Form
        className={twMerge('inline align-middle', formHoveredClass)}
        name={name}
        onMouseEnter={handleFormMouseEnter}
        onMouseLeave={handleFormMouseLeave}
      >
        <>
          <span
            className={getIsContentNodeClasses(
              isMergeMode,
              isSelectable,
              isSelected,
              isParentSelectable,
              isParentSelected,
            )}
            data-testid="editableField"
            onClick={() => {
              if (!isValueEditable) handleSelectNodeClick?.(node as TextSection)
            }}
            onDoubleClick={(event) =>
              handleTextClickToEdit?.(event, node as TextSection)
            }
          >
            {/*
             * TODO: Consider moving this to `EditableFieldFormFields`,
             * in case a wider usage is necessary.
             */}
            {isValueEditable && (
              <ControlledContentEditable
                className="py-2"
                control={control}
                disabled={isPending}
                name={ValueBindingTreeItemFormEnum.VALUE}
                onBlur={handleSubmit?.(handleFormSubmit)}
                onContextMenu={handleContextMenu}
                ref={textFieldRef}
                setValue={setFormValue}
                watch={watchForm}
              />
            )}

            <EditableFieldFormFields control={control} onlyHiddenFields />

            {!isValueEditable && (
              <span>
                <ColoredParams
                  isGroupNode={isGroupNode}
                  params={parsedParams}
                  value={textFieldValue}
                />
              </span>
            )}

            {isEmpty && !isGroupNode && (
              <Tooltip
                arrow
                placement="top"
                title="This is an empty node (no content)."
              >
                <TextFieldsIcon
                  aria-label="Empty node"
                  className="pr-1 text-base"
                  color="secondary"
                />
              </Tooltip>
            )}

            {isGroupNode && (
              <Tooltip arrow placement="top" title="This is a group node.">
                <SettingsEthernetIcon
                  aria-label="Node group"
                  className="pr-1 text-base"
                  color="secondary"
                />
              </Tooltip>
            )}
          </span>

          <MenuToReplaceText
            dataDomainDictionary={dataDomainDictionary}
            onMenuClose={handleContextMenuClose}
            onMenuItemClick={handleMenuItemClick}
            options={configForMenuToReplace}
          />
        </>
      </Form>
    )
  }

  return (
    <form className="w-full" onSubmit={handleSubmit?.(handleFormSubmit)}>
      <>
        <Row className="my-1 w-full items-center justify-start gap-1">
          <Text>{propertyName}</Text>

          <Tooltip
            arrow
            placement="top"
            title={
              <RevertToOriginalValue
                originalValue={originalValue}
                setFormValue={setFormValue}
                value={textFieldValue}
              />
            }
            componentsProps={{
              tooltip: {
                className: twMerge(
                  (originalValue?.length && originalValue.length > 35) ||
                    textFieldValue.length > 35
                    ? 'max-w-[500px]'
                    : '',
                ),
              },
            }}
          >
            <div className="flex p-1">
              <InfoIcon className="text-base text-slate-500 opacity-70" />
            </div>
          </Tooltip>

          <div
            className="relative min-h-[40px] rounded border-solid border-slate-100 bg-slate-50 px-[14px] py-[6px] hover:cursor-pointer hover:border-solid hover:border-slate-300 hover:bg-white"
            data-testid="editableField"
            onMouseUp={handleTextClickToEdit}
          >
            <EditableFieldFormFields
              control={control}
              isEditable={isValueEditable}
              isPending={isPending}
              onValueBlur={handleValueFieldBlur}
              onValueContextMenu={handleContextMenu}
              valueClassName={twMerge(
                'text border-transparent bg-transparent hover:border-solid hover:border-gray-300 hover:bg-white focus:bg-white',
                isValueEditable
                  ? `absolute top-0 left-0 right-0 bottom-0 [&_.${inputBaseClasses.root}]:absolute [&_.${inputBaseClasses.root}]:top-0 [&_.${inputBaseClasses.root}]:left-0 [&_.${inputBaseClasses.root}]:right-0 [&_.${inputBaseClasses.root}]:bottom-0`
                  : 'hidden',
              )}
              valueRef={textFieldRef}
            />

            <ColoredParams value={textFieldValue} params={parsedParams} />
          </div>

          <MenuToReplaceText
            dataDomainDictionary={dataDomainDictionary}
            onMenuClose={handleContextMenuClose}
            onMenuItemClick={handleMenuItemClick}
            options={configForMenuToReplace}
          />

          {parsedParams.length ? (
            <FormGroup className="ml-2">
              <FormControlLabel
                label="Parameters"
                sx={{ '.MuiFormControlLabel-label': { fontSize: '12px' } }}
                control={
                  <Switch
                    name="toggleParameters"
                    size="small"
                    inputProps={{ 'aria-label': `Toggle parameter's table` }}
                    value={toggleParametersPanel}
                    onChange={(e) => {
                      setToggleParametersPanel(e.target.checked)
                    }}
                  />
                }
              />
            </FormGroup>
          ) : null}

          <IconButton disabled={isPending} size="small" type="submit">
            {isPending ? (
              <CircularProgress size={20} />
            ) : (
              <SaveIcon className="text-xl text-primary opacity-70" />
            )}
          </IconButton>
        </Row>

        <Collapse in={toggleParametersPanel} mountOnEnter unmountOnExit>
          <div className="flex flex-col items-center">
            <ParametersTable
              isDisabled={isPending}
              setFormValue={setFormValue}
              watchForm={watchForm}
            />
          </div>
        </Collapse>
      </>
    </form>
  )
}

export default ValueBindingTreeItem
