export function KeyEnterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#B6F1E6"
        fillRule="evenodd"
        d="M8 3H5a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v8a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H8Z"
        clipRule="evenodd"
      />
      <path
        fill="#080D97"
        d="M5 3.5h3v-1H5v1ZM4.5 10V4h-1v6h1Zm2.5.5H5v1h2v-1ZM8.5 20v-8h-1v8h1Zm9.5.5H9v1h9v-1ZM18.5 4v16h1V4h-1ZM8 3.5h10v-1H8v1Zm11.5.5A1.5 1.5 0 0 0 18 2.5v1a.5.5 0 0 1 .5.5h1Zm-12 16A1.5 1.5 0 0 0 9 21.5v-1a.5.5 0 0 1-.5-.5h-1ZM7 11.5a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 7 10.5v1ZM3.5 10A1.5 1.5 0 0 0 5 11.5v-1a.5.5 0 0 1-.5-.5h-1ZM18 21.5a1.5 1.5 0 0 0 1.5-1.5h-1a.5.5 0 0 1-.5.5v1ZM5 2.5A1.5 1.5 0 0 0 3.5 4h1a.5.5 0 0 1 .5-.5v-1ZM11 6.5v3L9.5 8 11 6.5Z"
      />
      <path stroke="#080D97" d="M16 6v2h-5m0 0V6.5L9.5 8 11 9.5V8Z" />
    </svg>
  )
}
