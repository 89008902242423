import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { generatePath, redirect } from 'react-router-dom'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import { queryKeyOrganisation, queryOrganisation } from '~/services/Discovery'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderIndex =
  (queryClient: QueryClient, pca: PublicClientApplication) => async () => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)

    if (!userId || !email) return null

    // Redirect if organisation was already filled
    await queryClient.refetchQueries({
      queryKey: queryKeyOrganisation(userId, email),
    })
    const qOrganisation = queryOrganisation(userId, email)
    const fetchOrganisation = await queryClient.ensureQueryData(qOrganisation)
    const organisationId = fetchOrganisation.data.identity

    // There is an organisation and something filled
    if (fetchOrganisation.status === 200) {
      // redirect to step 2 in case the name is already filled and there is no ecosystem name
      if (
        !fetchOrganisation.data.ecosystemName &&
        fetchOrganisation.data.name
      ) {
        return redirect('/welcome/step2')
      }

      // check if there is a platform team
      const qPlatform = queryPlatformTeam(organisationId)
      const fetchPlatform = await queryClient.ensureQueryData(qPlatform)
      const platformId = fetchPlatform.data.identity

      // if a user has already filled the organisation and the ecosystem, and has a platform created, so we redirect him to the business page
      return redirect(
        generatePath(BusinessRoutesEnum.BUSINESS, {
          organisationId: organisationId || '',
          platformId: platformId || '',
        }),
      )

      // if we arrived here we have an error
      console.error({ dataFetched: fetchOrganisation })
      console.error({ dataFetchedPlat: fetchPlatform })
      console.error({ platformId })

      // * This will only happen if:
      // * 1. There is an organisation / name and ecosystemName are filled
      // * 2. Platform is not submitted and is returning something different than 200
      // *    like 204, which isn't an error, but we can't do anything with it, since we need
      // *    its GUID to redirect to the correct page
      // throw new Error(
      //   'Sorry, something went wrong and we were not able to redirect you to the correct page.'
      // )
    }

    // There is no organisation yet, so we redirect to the welcome page
    if (fetchOrganisation.status === 204) return redirect('/welcome/step1')

    // Nothing to do here
    return null
  }
