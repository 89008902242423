import { tv } from 'tailwind-variants'

export const step = tv({
  base: `relative w-[230px] bg-background-paper flex flex-col border border-solid rounded-md border-node-border border-2 transition-all`,
  variants: {
    isCommand: {
      true: 'border-command bg-command-light',
    },
    isReaction: {
      true: 'border-reaction bg-reaction-light',
    },
    isSelected: {
      true: 'border-primary opacity-100',
    },
    isNodeLayerDragging: {
      true: 'opacity-50',
    },
    status: {
      default: '',
      hasError: 'border-node-red bg-[#FFE8E8]',
      isFetching: 'border-node-green',
      isEmpty: 'border-node-green',
    },
    isEditingMode: {
      true: '',
    },
    isSwapModeEnabled: {
      true: '',
    },
  },
  compoundVariants: [
    {
      isSelected: false,
      isEditingMode: true,
      className: 'opacity-30',
    },
    {
      isSelected: false,
      status: 'hasError',
      className: 'border-node-red',
    },
    {
      isSwapModeEnabled: true,
      isSelected: true,
      className: 'border-node-yellow',
    },
    {
      isSelected: true,
      className: 'border-primary opacity-100 border-2',
    },
  ],
})
