import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { redirectToDeveloperHomeOrAggregate } from '~/routes/developer/utils'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import {
  queryKeyDevelopment,
  queryKeyDevelopmentDomainsForCommands,
} from '~/services/Development'

export const actionDeveloperDeleteReaction =
  (queryClient: QueryClient) =>
  async ({ params }: ActionFunctionArgs) => {
    const { platformId, aggregateId, reactionId } = params
    invariant(platformId, 'No platformId found')

    const postData = {
      aggregateId,
      reactionId,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.DeleteReaction
    const req = await serviceDevelopmentAggregate.post(url, postData)

    // Invalidates the cache
    queryClient.invalidateQueries({ queryKey: queryKeyDevelopment(platformId) })
    queryClient.invalidateQueries({
      queryKey: queryKeyDevelopmentDomainsForCommands(platformId),
    })

    // redirects
    return redirectToDeveloperHomeOrAggregate(req, params)
  }
