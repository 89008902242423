import type { TextFieldProps } from '@mui/material'
import { decode, encode } from 'he'
import { forwardRef, useEffect, useRef, type FormEvent } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

export type ControlledContentEditableProps = Partial<
  Pick<UseFormReturn, 'control' | 'setValue' | 'watch'>
> &
  Pick<
    TextFieldProps,
    'className' | 'disabled' | 'onBlur' | 'onContextMenu' | 'name'
  >

/**
 * A `span` component that implements
 * the `content editable` feature,
 * controlled by `react-hook-form`.
 */
export const ControlledContentEditable = forwardRef<
  HTMLSpanElement,
  ControlledContentEditableProps
>((props: ControlledContentEditableProps, ref) => {
  const {
    className,
    control,
    disabled,
    name,
    onBlur,
    onContextMenu,
    setValue,
    watch,
  } = props

  if (!name) return null

  const cursorPosition = useRef<number | null>(null)
  const contentEditableRef = useRef<HTMLSpanElement | null>(null)
  const watchedContent = watch?.(name)

  // Lifecycle to set up the caret position when the field
  // form field value state change.
  useEffect(() => {
    if (contentEditableRef.current && cursorPosition.current !== null) {
      const selection = window.getSelection()

      if (selection) {
        const range = document.createRange()
        // Ensure that the node is not null or undefined.
        const firstChildNode = contentEditableRef.current.childNodes[0]

        if (firstChildNode instanceof Node) {
          range.setStart(firstChildNode as Node, cursorPosition.current)
          range.collapse(true)
          selection.removeAllRanges()
          selection.addRange(range)
        }
      }
    }
  }, [watchedContent])

  // Handler for input: triggered when something is typed.
  const handleInput = (e: FormEvent<HTMLElement>) => {
    const selection = window.getSelection()
    if (selection) {
      cursorPosition.current = selection.focusOffset
      setValue?.(name, decode(e.currentTarget.textContent || ''), {
        shouldDirty: true,
      })
    }
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <span
          contentEditable={!disabled}
          {...field}
          className={className}
          dangerouslySetInnerHTML={{ __html: encode(field.value) }}
          onBlur={onBlur}
          onContextMenu={onContextMenu}
          onInput={handleInput}
          ref={(spanRef: HTMLSpanElement) => {
            contentEditableRef.current = spanRef
            field.ref(spanRef)

            // Set component ref.
            if (typeof ref === 'function') {
              ref(spanRef)
            } else if (ref) {
              ;(ref as React.MutableRefObject<HTMLSpanElement>).current =
                spanRef
            }
          }}
        />
      )}
    />
  )
})
