export function KeyControlIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#B6F1E6"
        d="M1 7a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7Z"
      />
      <path
        fill="#080D97"
        d="M2 6.5h20v-1H2v1Zm20.5.5v10h1V7h-1ZM22 17.5H2v1h20v-1ZM1.5 17V7h-1v10h1Zm.5.5a.5.5 0 0 1-.5-.5h-1A1.5 1.5 0 0 0 2 18.5v-1Zm20.5-.5a.5.5 0 0 1-.5.5v1a1.5 1.5 0 0 0 1.5-1.5h-1ZM22 6.5a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 22 5.5v1Zm-20-1A1.5 1.5 0 0 0 .5 7h1a.5.5 0 0 1 .5-.5v-1ZM5.525 15.096c-.55 0-1.024-.12-1.424-.36a2.496 2.496 0 0 1-.92-1.016c-.22-.437-.328-.944-.328-1.52 0-.57.109-1.072.328-1.504.218-.437.525-.778.92-1.024.4-.245.874-.368 1.424-.368.64 0 1.16.155 1.56.464.405.304.664.73.776 1.28h-.744a1.465 1.465 0 0 0-.528-.832c-.262-.213-.616-.32-1.064-.32-.4 0-.75.094-1.048.28-.3.181-.531.445-.696.792-.16.341-.24.752-.24 1.232s.08.894.24 1.24c.165.341.397.606.696.792a1.98 1.98 0 0 0 1.048.272c.448 0 .802-.101 1.064-.304.266-.208.442-.482.528-.824h.744c-.112.539-.371.96-.776 1.264-.4.304-.92.456-1.56.456ZM10.005 15V9.952H8.335V9.4h4.016v.552h-1.672V15h-.673ZM13.17 15V9.4h1.808c.437 0 .795.072 1.072.216.277.144.483.342.616.592.133.25.2.528.2.832 0 .352-.096.67-.288.952-.187.283-.48.483-.88.6L16.922 15h-.792l-1.136-2.312h-1.152V15h-.672Zm.672-2.84h1.088c.432 0 .747-.104.944-.312.197-.208.296-.475.296-.8 0-.33-.099-.592-.296-.784-.192-.197-.51-.296-.952-.296h-1.08v2.192ZM17.912 15V9.4h.672v5.064h2.608V15h-3.28Z"
      />
    </svg>
  )
}
