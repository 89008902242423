import { styled } from '@mui/material'
import type { DetailedHTMLProps, FormHTMLAttributes } from 'react'
import FormError from '~/components/form/FormError/FormError'
import { BaseDialog, type BaseDialogProps } from '../BaseDialog/BaseDialog'

export type DialogFormProps = BaseDialogProps<
  DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>
> & {
  /** The props applied to the `form` node. */
  formProps?: DetailedHTMLProps<
    FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >
  /** Any form error message to be displayed. */
  error?: string
}

const Form = styled('form')``

/**
 * Implements the `BaseDialog` component
 * with form support.
 */
export const DialogForm = (props: DialogFormProps) => {
  const { error, formProps, ...rest } = props

  return (
    <BaseDialog
      adjacentContent={<FormError className="mx-6" error={error} />}
      wrapper={Form}
      wrapperProps={formProps}
      {...rest}
    />
  )
}

export default DialogForm
