import type { SnackbarCloseReason, SnackbarProps } from '@mui/material'
import { produce } from 'immer'
import { useState, type PropsWithChildren, type SyntheticEvent } from 'react'
import AppSnackbar from '~/components/AppSnackbar/AppSnackbar'
import AppSnackbarContext, {
  type AppSnackbarContextPropsProps,
} from '~/contexts/AppSnackbarContext'

/**
 * Provider for the App Snackbar context.
 */
const AppSnackbarContextProvider = (props: PropsWithChildren) => {
  const { children } = props

  const [componentProps, setComponentProps] =
    useState<AppSnackbarContextPropsProps>({})

  // Open app snackbar.
  const openAppSnackbar = (componentProps: AppSnackbarContextPropsProps) =>
    setComponentProps(componentProps)

  // Close app snackbar.
  // This methods is similar to `onClose` from snackbar,
  // That is why it has `event` and `reason` params.
  const closeAppSnackbar = (
    _?: Event | SyntheticEvent<any, Event>,
    r?: SnackbarCloseReason,
  ) => {
    // Account only for `timeout` reason, when the
    // snackbar closes due to `auto hide` feature.
    if (r === 'timeout')
      setComponentProps?.((prevState) =>
        produce(prevState, (draft) => {
          if (!!draft.snackbarProps)
            (draft.snackbarProps as SnackbarProps).open = false
        }),
      )
  }

  return (
    <AppSnackbarContext.Provider
      value={{
        componentProps,
        setComponentProps,
        openAppSnackbar,
        closeAppSnackbar,
      }}
    >
      {children}

      <AppSnackbar />
    </AppSnackbarContext.Provider>
  )
}

export default AppSnackbarContextProvider
