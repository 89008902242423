import type { PopperProps } from '@mui/material'
import { produce } from 'immer'
import { useState, type PropsWithChildren } from 'react'
import AppPopper from '~/components/AppPopper/AppPopper'
import AppPopperContext from '~/contexts/AppPopperContext'

/**
 * Provider for the App Popper context.
 */
const AppPopperContextProvider = (props: PropsWithChildren) => {
  const { children } = props

  const [componentProps, setComponentProps] = useState<PopperProps>(
    {} as PopperProps,
  )

  // Open app popper.
  const openAppPopper = (componentProps: PopperProps) =>
    setComponentProps(componentProps)

  // Close app popper.
  const closeAppPopper = () => {
    setComponentProps?.((prevState) =>
      produce(prevState, (draft) => {
        draft.open = false
      }),
    )
  }

  return (
    <AppPopperContext.Provider
      value={{
        componentProps,
        setComponentProps,
        openAppPopper,
        closeAppPopper,
      }}
    >
      {children}

      <AppPopper />
    </AppPopperContext.Provider>
  )
}

export default AppPopperContextProvider
