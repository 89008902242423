import { AuthenticatedTemplate } from '@azure/msal-react'
import { GlobalStyles } from '@mui/material'
import { Outlet, useNavigation } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { Header } from '~/components/Header'

TopBarProgress.config({
  barColors: {
    '0': 'rgba(16,200,171,1)',
    '1.0': '#020890',
  },
  shadowBlur: 5,
})

export function CommonLayout() {
  const navigation = useNavigation()

  return (
    <>
      {navigation.state === 'loading' && <TopBarProgress />}
      <AuthenticatedTemplate>
        <Header />
        <GlobalStyles
          styles={{
            '#root': {
              display: 'block',
              height: '100svh',
            },
            strong: {
              fontWeight: 500,
            },
          }}
        />
        <Outlet />
      </AuthenticatedTemplate>
    </>
  )
}
