import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { Controller } from 'react-hook-form'
import { Text } from '~/components/Text'
import DialogForm from '~/components/dialogs/DialogForm/DialogForm'
import { DeletePropertyFormEnum } from '~/models/enums/forms/DeletePropertyFormEnum'
import type { CodeGenerationStrategyDialogProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyDialogProps'
import { useDialogDeleteProperty } from './useDialogDeleteProperty'

/**
 * Dialog to delete a property binding.
 */
export function DialogDeleteProperty(props: CodeGenerationStrategyDialogProps) {
  const { isDialogOpen, propertyBinding } = props

  const {
    control,
    error,
    handleClose,
    handleFormSubmit,
    handleSubmit,
    isPending,
  } = useDialogDeleteProperty(props)

  return (
    <DialogForm
      actions={
        <>
          <Button disabled={isPending} variant="outlined" onClick={handleClose}>
            No
          </Button>

          <LoadingButton
            autoFocus
            loading={isPending}
            type="submit"
            variant="contained"
          >
            Yes
          </LoadingButton>
        </>
      }
      content={
        <>
          Do you really want to delete the selected property?
          {!!propertyBinding?.propertyName && (
            <Text className="mt-2 text-slate-700">
              <strong>{propertyBinding.propertyName}</strong>
            </Text>
          )}
          <Controller
            control={control}
            name={DeletePropertyFormEnum.PROPERTY_BINDING_ID}
            render={({ field }) => <input type="hidden" {...field} />}
          />
          <Controller
            control={control}
            name={DeletePropertyFormEnum.FILE_ID}
            render={({ field }) => <input type="hidden" {...field} />}
          />
        </>
      }
      contentId="delete-dialog-description"
      disableEscapeKeyDown={isPending}
      error={error as string}
      formProps={{
        onSubmit: handleSubmit?.(handleFormSubmit),
      }}
      onClose={handleClose}
      open={isDialogOpen}
      title="Delete Object Property"
      titleId="delete-dialog-title"
    />
  )
}
