import AddIcon from '@mui/icons-material/Add'
import { Button, Stack } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { BusinessChildrenParams } from '~/routes/business/routes.types'

export function ButtonAddProcess() {
  const params = useParams<BusinessChildrenParams>()

  const path = params.processId
    ? `/${params.organisationId}/${params.platformId}/business/${params.processId}/add-process`
    : `/${params.organisationId}/${params.platformId}/business/add-process`

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        component={Link}
        to={path}
        fullWidth
        size="small"
        sx={{ borderColor: 'primary.main' }}
      >
        Add
      </Button>
    </Stack>
  )
}
