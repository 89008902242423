import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  SvgIcon,
} from '@mui/material'
import { Form, useLoaderData, useNavigation } from 'react-router-dom'
import { WizardContent } from '~/components/WizardContent'
import { switchIcon } from '~/routes/setup/platform/utils'
import { Platform } from '~/services/PlatformTeam.types'

export function PlatformHosting() {
  const navigation = useNavigation()
  const isButtonDisabled = navigation.state !== 'idle'
  const dataHostings =
    useLoaderData() as Platform['frontend']['hostingStrategies']

  return (
    <WizardContent
      step="3"
      intro=""
      title={
        <>
          Please select the <strong>Front-end Hosting</strong>
        </>
      }
    >
      <Stack direction="row" gap={2} sx={{ ml: 2 }}>
        <Paper sx={{ px: 2, py: 1 }}>
          <nav>
            <List aria-labelledby="label-select-frontend-strategy">
              {dataHostings.map((hosting) => (
                <ListItem disablePadding key={hosting.identity}>
                  <Box
                    component={Form}
                    method="post"
                    id="form-setup-platform-hosting"
                    sx={{ flexGrow: 1, display: 'flex' }}
                  >
                    <input
                      type="hidden"
                      defaultValue={hosting.identity}
                      name="frontend"
                    />
                    <ListItemButton
                      // disabled={item.disabled || isButtonDisabled}
                      disabled={isButtonDisabled}
                      component="button"
                      type="submit"
                    >
                      <ListItemIcon>
                        <SvgIcon>{switchIcon(hosting.identity)}</SvgIcon>
                      </ListItemIcon>
                      <ListItemText primary={hosting.name} />
                    </ListItemButton>
                  </Box>
                </ListItem>
              ))}
            </List>
          </nav>
        </Paper>
      </Stack>
    </WizardContent>
  )
}
