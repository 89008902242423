import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import invariant from 'tiny-invariant'
import { queryOrganisation } from '~/services/Discovery'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export async function getOrganisation(
  queryClient: QueryClient,
  pca: PublicClientApplication,
) {
  const userId = getUserId(pca)
  const email = getUserEmail(pca)
  invariant(userId, `Must be logged in: ${userId}`)
  invariant(email, `Must be logged in: ${userId}`)

  // Redirect if organisation was already filled and saved in db
  const query = queryOrganisation(userId, email)
  return await queryClient.ensureQueryData(query)
}
