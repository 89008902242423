import { ListItemText, ListItemTextProps, styled } from '@mui/material'

export const ServiceInfoText = styled((props: ListItemTextProps) => (
  <ListItemText
    primary={props.primary}
    secondary={props.secondary ? props.secondary : 'Not configured'}
    {...props}
  />
))(({ theme, secondary }) => ({
  wordWrap: 'break-word',
  '.MuiListItemText-secondary': {
    color: secondary ? theme.palette.secondary : theme.palette.error.main,
  },
}))
