import { Backdrop, CircularProgress, type BackdropProps } from '@mui/material'

/**
 * A loading overlay that will
 * rendered in front of all other components.
 */
export const LoadingOverlay = (props: Pick<BackdropProps, 'open'>) => {
  const { open } = props

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default LoadingOverlay
