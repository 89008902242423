import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs } from 'react-router-dom'
import { redirectToDeveloperHomeOrAggregate } from '~/routes/developer/utils'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { queryKeyDevelopment } from '~/services/Development'
import { queryOrganisation } from '~/services/Discovery'
import { getUserEmail } from '~/utils/getUserEmail'
import { getLocalUserId } from '~/utils/getUserId'

export const actionDeveloperPublishAll =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const userId = getLocalUserId(pca)
    const email = getUserEmail(pca)
    const { aggregateId, organisationId, platformId } = params

    const qOrganisation = queryOrganisation(userId, email)
    const fetchOrganisation = await queryClient.ensureQueryData(qOrganisation)
    const ecosystemName = fetchOrganisation.data.ecosystemName

    const formData = await request.formData()
    const commitMessage = formData.get('commitMessage')
    const branch = formData.get('branch')

    const postData = {
      commitMessage,
      developerId: userId,
      aggregateId,
      ecosystemName,
      branch,
      strategyType: 'PreDefined',
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.PublishAggregate
    const req = await serviceDevelopmentAggregate.post(url, postData)
    await queryClient.refetchQueries({
      queryKey: queryKeyDevelopment(platformId),
    })
    return redirectToDeveloperHomeOrAggregate(req, params)
  }
