import { Form } from 'react-router-dom'
import { WizardContent } from '~/components/WizardContent'
import { WizardFooterButtons } from '~/components/WizardFooterButtons'
import { WizardTextField } from '~/styled/WizardTextField'

// This file is not used anymore
// It's being handled automatically by Git Credentials `../git-credentials/index.tsx`

export function PlatformConfirm() {
  return (
    <WizardContent
      step="5"
      intro="dot-star will use it to push the code into
      your git repository"
      title={<>Enter the commit message</>}
    >
      <Form method="post" id="form-welcome-step7">
        <WizardTextField
          label="Git commit message"
          variant="outlined"
          name="comment"
          value="generating shared infrastructure code using Terraform and Azure DevOps pipeline"
          fullWidth
        />
        <WizardFooterButtons />
      </Form>
    </WizardContent>
  )
}

// generating shared infrastructure code using Terraform and Azure DevOps pipeline
