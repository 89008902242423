export type PayloadCreateOrganisation = {
  name: string
  organisationId: GUID
  ownerId: GUID
  ownerEmail: string
}

export type PayloadAddEcosystem = {
  name: string
  organisationId: GUID
}

export type PayloadAddCoreDomain = {
  name: string
  organisationId: GUID
}

export type PayloadAddSubDomain = {
  name: string
  organisationId: GUID
}

export type CoreDomain = {
  name: string
  description: string
}

export type SubDomain = {
  id: GUID
  name: string
  description: string
}

export type ResponseDiscoveryOrganisation = {
  coreDomain: Nullable<CoreDomain> // ? not appearing anymore
  ecosystemName: Nullable<string>
  identity: GUID
  name: Nullable<string>
  ownerId: GUID
  sharedWith: Nullable<unknown>
  subDomains: SubDomain[] // ? not appearing anymore
  subscriptionPlan: Nullable<SubscriptionPlan>
  isOrganisationOwner: boolean
}

export const FREE_FEATURES_USAGE = {
  ATTRIBUTE_AUTO_GENERATION: 'freeAttributeAutoGeneration',
  MOCKUP_ANALYSIS: 'freeMockupAnalysis',
  PROCESS_AUTO_GENERATION: 'freeProcessAutoGeneration',
  STEP_AUTOCOMPLETION: 'freeStepAutocompletion',
} as const

export type SubscriptionPlan = {
  capabilities: SubscriptionPlanCapabilities
  freeAttributeAutoGenerationLimit: number
  freeAttributeAutoGenerationRemaining: number
  freeMockupAnalysisLimit: number
  freeMockupAnalysisRemaining: number
  freeProcessAutoGenerationLimit: number
  freeProcessAutoGenerationRemaining: number
  freeStepAutocompletionLimit: number
  freeStepAutocompletionRemaining: number
  name: string
  type: string
  userLimit: number
  expirationDate: Nullable<string>
  isFreeSubscription: boolean
}

export type SubscriptionPlanCapabilities = {
  processMapping: boolean
  codeGeneration: boolean
}

export type ResponseCreateOrganisation = {
  coreDomain: null
  ecosystemName: null
  identity: GUID
  name: string
  ownerId: GUID
  subDomains: []
}

export type ResponseAddEcosystem = Omit<
  ResponseCreateOrganisation,
  'ecosystemName'
> & {
  ecosystemName: string
}

export type ResponseAddCoreDomain = Omit<ResponseAddEcosystem, 'coreDomain'> & {
  coreDomain: string
}

export type ResponseAddSubDomain = Omit<ResponseAddCoreDomain, 'subdomain'> & {
  subDomain: string[]
}

export type PayloadRemoveUser = {
  organisationId: GUID
  email: string
}

export type PayloadAddUser = {
  organisationId: GUID
  email: string
}

export type ResponseGetUserProfileByOrganisationId = {
  name: Nullable<string>
  email: string
  id: GUID
}

export type ResponseGetUsersByOrganisationId =
  ResponseGetUserProfileByOrganisationId[]

export type PayloadUpdateUserProfile = {
  organisationId: GUID
  email: string
  name?: string
}
