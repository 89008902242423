import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { queryKeyDevelopment } from '~/services/Development'
import { queryKeyOrganisation } from '~/services/Discovery'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const actionDeveloperEditDetails =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    invariant(userId, `Must be logged in: ${userId}`)
    invariant(email, `Must be logged in: ${userId}`)

    const { aggregateId, organisationId, platformId } = params

    await queryClient.invalidateQueries({
      queryKey: queryKeyOrganisation(userId, email),
    })

    // Get form data
    const formData = await request.formData()
    const boundedContext = formData.get('boundedContext')
    const name = formData.get('name')

    // Validate errors
    const errors: GenericObj<string> = {}

    if (
      !boundedContext ||
      typeof boundedContext !== 'string' ||
      boundedContext.trim().length <= 1
    ) {
      errors.boundedContext = 'Must have a Bounded context name'
    }
    if (!name || typeof name !== 'string' || name.trim().length <= 1) {
      errors.name = 'Must have an Aggregate name'
    }

    if (Object.keys(errors).length) {
      return errors
    }

    // Builds the data to send to server
    const postData = {
      aggregateId,
      boundedContext,
      name,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.EditAggregate
    const req = await serviceDevelopmentAggregate.post(url, postData)

    if (req.status === 200) {
      // Invalidates query to force refetch aggregates
      await queryClient.invalidateQueries({
        queryKey: queryKeyDevelopment(platformId),
      })

      return redirect(
        `/${organisationId}/${platformId}/developer/${aggregateId}`,
      )
    }

    return redirect(`/${organisationId}/${platformId}/developer`)
  }
