import { AxiosResponse } from 'axios'
import { Params, redirect } from 'react-router-dom'
import {
  Attribute,
  DomainEvent,
  DomainForCommand,
} from '~/services/Development.types'
import { removeDupes } from '~/utils/array'

export function parseDomains(arr: DomainForCommand, which: keyof DomainEvent) {
  const temp = arr.domainEvents.map((item) => item[which])
  if (temp.length) {
    return removeDupes(temp)
  }
  return []
}

export function redirectToDeveloperHomeOrAggregate(
  req: AxiosResponse<unknown, unknown>,
  params: Params<string>,
) {
  if (req.status === 200) {
    return redirect(
      `/${params.organisationId}/${params.platformId}/developer/${params.aggregateId}`,
    )
  }

  return redirect(`/${params.organisationId}/${params.platformId}/developer`)
}

export function parseReactionData(data: FormDataEntryValue) {
  try {
    const {
      boundedContext,
      aggregate,
      name: subscriptionName,
    } = JSON.parse(data as string)
    if (boundedContext && aggregate && subscriptionName) {
      return {
        boundedContext,
        aggregate,
        subscriptionName,
      }
    }
  } catch (err) {
    throw Error('Could not parse reaction data')
  }
}

type getAttributesFromFormArgs = {
  formEntries: { [key: string]: FormDataEntryValue }
  kind: string
}

export function getRaisedDomainEvents({
  formEntries,
  prefix = 'events',
}: {
  formEntries: { [key: string]: FormDataEntryValue }
  prefix?: string
}) {
  const eventList: any[] = []
  const eventAttributeList: any[] = []
  const filteredEvents = Object.entries(formEntries)
    .map((item) => {
      if (item[0].includes(prefix)) {
        return item
      }
      return
    })
    .filter(Boolean)
  filteredEvents.forEach((item) => {
    if (item?.[0].endsWith('.name') && !item?.[0].includes('attributes')) {
      eventList.push(item)
    } else {
      eventAttributeList.push(item)
    }
  })

  const events = eventList.map((eventItem) => ({
    name: eventItem[1],
    attributes: getAttributesFromForm({
      formEntries,
      kind: eventItem[0].replace('name', ''),
    }),
  }))

  return events
}

export function getAttributesFromForm({
  formEntries,
  kind,
}: getAttributesFromFormArgs) {
  const attributesArray = Object.entries(formEntries)
    .map((item) => {
      if (
        item[0].includes(`${kind}attributes`) &&
        !item[0].includes('is_array')
      ) {
        return item
      }
      return
    })
    .filter(Boolean)

  const arrayAttributesList = Object.entries(formEntries)
    .map((item) => {
      if (item[0].includes('is_array')) {
        return item
      }
      return
    })
    .filter(Boolean)

  const attributes: Attribute[] = []
  attributesArray.forEach((item, index) => {
    if (
      item?.[0] &&
      item[0]?.split(`${kind}attributes.`)?.length === 2 &&
      item[0]?.split(`${kind}attributes.`)?.[1] &&
      item?.[0]?.split(`${kind}attributes.`)?.[1]?.length &&
      item[0].split(`${kind}attributes.`)[1]!.length <= 7
    ) {
      if (index % 2 === 0 && item) {
        const endOfArray = attributesArray[index + 1]
        if (endOfArray) {
          const isArray = arrayAttributesList.find(
            (entry) => entry?.[0] === `${item?.[0]}_is_array`,
          )
          const obj: Attribute = {
            name: item[1] as string,
            type: `${isArray ? endOfArray[1] + '[]' : endOfArray[1]}`,
          }

          if (endOfArray[1] === 'object') {
            const nestedAttributes = getNestedAttributesFromForm(
              attributesArray,
              arrayAttributesList,
              `${item[0].split('.').slice(0, -1).join('.')}.attributes`,
            )
            obj.properties = nestedAttributes
          }

          if (obj.name) {
            attributes.push(obj)
          }
        }
      }
    }
  })
  return attributes
}

function getNestedAttributesFromForm(
  attributesArray: any[],
  arrayAttributesList: any[],
  prefix: string,
): Attribute[] {
  const nestedAttributes: Attribute[] = []
  const properties = attributesArray.filter(
    (attr) =>
      attr[0]?.split(prefix)?.length === 2 &&
      attr[0]?.split(prefix)?.[1]?.split('.').length === 3,
  )

  properties.forEach((property, propertyIndex) => {
    if (propertyIndex % 2 === 0 && property) {
      const nextValue = properties[propertyIndex + 1]
      if (nextValue) {
        const isArray = arrayAttributesList.find(
          (entry) => entry?.[0] === `${property?.[0]}_is_array`,
        )
        const prop: Attribute = {
          name: property[1],
          type: `${isArray ? nextValue[1] + '[]' : nextValue[1]}`,
        }

        if (nextValue[1] === 'object') {
          const nestedPrefix = `${property[0]
            .split('.')
            .slice(0, -1)
            .join('.')}.attributes`
          const nestedAttributes = getNestedAttributesFromForm(
            attributesArray,
            arrayAttributesList,
            nestedPrefix,
          )
          prop.properties = nestedAttributes
        }

        if (prop.name) {
          nestedAttributes.push(prop)
        }
      }
    }
  })
  return nestedAttributes
}

interface DataObject {
  properties?: DataObject[]
  attributes?: DataObject[]
}

export function renamePropertiesToAttributes(
  obj: DataObject | DataObject[] | undefined,
): DataObject | DataObject[] | any {
  if (obj) {
    if (Array.isArray(obj)) {
      // If it's an array, recursively call the function for each element.
      return obj.map(renamePropertiesToAttributes)
    } else if (typeof obj === 'object') {
      // If it's an object, rename "properties" to "attributes" if it exists.
      if (typeof obj === 'object' && obj.properties) {
        obj.attributes = renamePropertiesToAttributes(obj?.properties)
        delete obj.properties // Remove the old "properties" property.
      }

      // Recursively call the function for each property.
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key as keyof DataObject] = renamePropertiesToAttributes(
            obj[key as keyof DataObject] as
              | DataObject
              | DataObject[]
              | undefined,
          )
        }
      }
    }
  }

  return obj
}
