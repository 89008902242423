import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FirstLoading } from '~/components/FirstLoading'
import { constants } from '~/config'

export function Onboarding() {
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  const handleSignup = React.useCallback(async () => {
    return await instance.loginRedirect({
      redirectUri: constants.msal.redirectUri,
      scopes: [
        'openid',
        'profile',
        'https://dotstar.onmicrosoft.com/dotstar-spa-backend-services/access',
      ],
    })
  }, [instance])

  React.useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      handleSignup()
    }
  }, [handleSignup, inProgress, isAuthenticated, navigate])

  return <FirstLoading />
}
