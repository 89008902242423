export function MockupIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <g filter="url(#a)">
        <path stroke="#080D97" d="M2.5 5.5v14h4v-14z" />
      </g>
      <path
        stroke="#080D97"
        d="M12 14.051 14.18 12m0 0 2.179 2.051M14.179 12v5.128"
      />
      <g filter="url(#b)">
        <path stroke="#080D97" d="M2.5 5.5h19v14h-19z" />
      </g>
      <path stroke="#080D97" d="M6.5 9.5h15v-4h-15z" />
      <defs>
        <filter
          id="a"
          width={6}
          height={16}
          x={2}
          y={5}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx={1} dy={1} />
          <feColorMatrix values="0 0 0 0 0.713726 0 0 0 0 0.945098 0 0 0 0 0.901961 0 0 0 1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_825_4861"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_825_4861"
            result="shape"
          />
        </filter>
        <filter
          id="b"
          width={21}
          height={16}
          x={2}
          y={5}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx={1} dy={1} />
          <feColorMatrix values="0 0 0 0 0.713726 0 0 0 0 0.945098 0 0 0 0 0.901961 0 0 0 1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_825_4861"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_825_4861"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
