import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Fade, IconButton } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { MouseEvent } from 'react'
import { useFieldArray } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { Column } from '~/components/Column'
import { StatementEditionBoxFormEnum as FormEnum } from '~/models/enums/forms/StatementEditionBoxFormEnum'
import type { StatementsDiagramBaseProps } from '~/models/types/components/processInfo/StatementsDiagramBaseProps'
import type { StatementsRequiredData } from '~/services/Process.types'
import { Chip } from '../../../StatementEditionBox/Chip'
import type { UpdateStatementBaseProps } from '../Step/Step.types'
import { ControlledTextField } from './ControlledTextField'

type ContentRequiredDataInfoProps = UpdateStatementBaseProps &
  Pick<StatementsDiagramBaseProps, 'onAddReaction'>

/**
 * The `required data info` content.
 */
export const ContentRequiredDataInfo = (
  props: ContentRequiredDataInfoProps,
) => {
  const {
    control,
    data: { data },
    onSubmit,
  } = props

  const { isEditable, isFetching, requiredDataInfo } = data || {}

  // Hooks.
  const {
    fields: requiredDataInfoFields,
    append: requiredDataInfoAppend,
    remove: requiredDataInfoRemove,
  } = useFieldArray({
    control,
    name: FormEnum.REQUIRED_DATA_INFO,
  })

  // Methods.
  const handleAddNewRequiredData = () => {
    requiredDataInfoAppend({
      identity: uuidv4(),
      name: '',
    } as StatementsRequiredData)
  }

  const handleRemoveRequiredDataArrayItem = (
    e: MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    e.stopPropagation()
    requiredDataInfoRemove(index)
    onSubmit?.()
  }

  return (
    <Column
      className={twMerge('mb-2 gap-2 pr-3', isEditable && 'w-full gap-1 pr-0')}
    >
      <div className="flex items-center gap-2">
        <span className="text-2xs uppercase text-black">REQUIRED DATA</span>

        <Fade in={isEditable}>
          <IconButton
            aria-label="add required data info"
            className="p-0 text-sm transition-all hover:text-primary"
            disabled={isFetching}
            onClick={handleAddNewRequiredData}
          >
            <AddCircleIcon fontSize="inherit" />
          </IconButton>
        </Fade>
      </div>

      {isEditable ? (
        requiredDataInfoFields?.map((field, index) => (
          <div className="flex w-full items-center" key={field.id}>
            <ControlledTextField
              className="flex-1"
              control={control}
              inputProps={{
                className: 'p-2 py-1 text-sm',
              }}
              name={`${FormEnum.REQUIRED_DATA_INFO}.${index}.name`}
              onBlur={onSubmit}
            />

            <IconButton
              disabled={isFetching}
              onClick={(e) => handleRemoveRequiredDataArrayItem(e, index)}
              size="small"
            >
              <DeleteForeverIcon fontSize="small" />
            </IconButton>
          </div>
        ))
      ) : (
        <ul className="m-0 flex max-h-16 list-none flex-wrap gap-1 overflow-y-auto p-0">
          {requiredDataInfo?.map(
            ({ identity, name }: StatementsRequiredData) => (
              <li key={identity}>
                <Chip
                  eventId={identity}
                  eventType="RequiredData"
                  hideClose
                  label={name}
                />
              </li>
            ),
          )}
        </ul>
      )}
    </Column>
  )
}
