import type { PropsWithChildren } from 'react'

/**
 * A delimited/separator of a `text section`.
 */
export const DelimiterSeparator = (props: PropsWithChildren) => {
  const { children } = props

  if (!children) return null

  return (
    <span className="px-1 py-1 font-mono text-sm font-medium">{children}</span>
  )
}
