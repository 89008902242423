import CheckIcon from '@mui/icons-material/Check'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { useState } from 'react'
import { BadgeUsesLeft } from '~/components/BadgeUsesLeft'
import { Column } from '~/components/Column'
import { Row } from '~/components/Row'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { WizardStepIcon } from '~/styled/WizardBullet'

type ProcessFormDefaultValues = {
  name: string
  description: string
  generateProcessBasedOnDescription: boolean
}

type ProcessFormProps = {
  nameFieldLabel: string
  descriptionFieldLabel: string
  defaultValues?: ProcessFormDefaultValues
}

export function ProcessForm({
  nameFieldLabel,
  descriptionFieldLabel,
  defaultValues,
}: ProcessFormProps) {
  const [shouldAutoGenerate, setShouldAutoGenerate] = useState(
    defaultValues?.generateProcessBasedOnDescription || false,
  )

  return (
    <Stack gap={2} className="max-w-lg">
      {!defaultValues ? (
        <Row className="items-center gap-2">
          <WizardStepIcon icon="1" completed={false} active={true} />
          <Typography className="text-xl">Describe your process</Typography>
        </Row>
      ) : null}
      <TextField
        className="shadow-1"
        name={'name'}
        label={nameFieldLabel}
        defaultValue={defaultValues?.name}
        fullWidth
        type="text"
        disabled={!!defaultValues}
        inputProps={{
          'aria-label': `name`,
          placeholder: 'E.g., Customer Onboarding, Invoice Approval, etc.',
        }}
        required
      />
      <TextField
        className="shadow-1"
        name={'description'}
        label={descriptionFieldLabel}
        defaultValue={defaultValues?.description}
        disabled={!!defaultValues}
        fullWidth
        type="text"
        multiline
        size="medium"
        sx={{
          padding: 0,
        }}
        inputProps={{
          style: {
            height: '100px',
          },
          'aria-label': `description`,
          placeholder:
            'Starting from the start of the process describe it in detail...',
        }}
        required={shouldAutoGenerate}
      />
      <input
        readOnly
        name="generateProcessBasedOnDescription"
        hidden
        value={JSON.stringify(shouldAutoGenerate)}
      />
      {!defaultValues ? (
        <Row className="items-center gap-2">
          <WizardStepIcon icon="2" completed={false} active={true} />
          <Typography className="text-xl">
            Choose How to Create Your Process
          </Typography>
        </Row>
      ) : null}
      <RadioButton
        title="Manual"
        description="I'll describe each step manually"
        isDisabled={!!defaultValues}
        isSelected={!shouldAutoGenerate}
        onClick={() => setShouldAutoGenerate(false)}
      />
      <RadioButton
        title="Auto-generate"
        description="Let dot-star analyse my description and suggest a process structure"
        isDisabled={!!defaultValues}
        isSelected={shouldAutoGenerate}
        onClick={() => setShouldAutoGenerate(true)}
        shouldShowBadge={FEATURE_TOGGLE.UPGRADE_PLAN}
      />
    </Stack>
  )
}

type RadioButtonProps = {
  title: string
  description: string
  isSelected: boolean
  isDisabled: boolean
  onClick: () => void
  shouldShowBadge?: boolean
}
function RadioButton({
  isDisabled,
  isSelected,
  onClick,
  title,
  description,
  shouldShowBadge = false,
}: RadioButtonProps) {
  return (
    <Button
      className={twMerge(
        'flex flex-col items-start justify-start border border-solid border-primary-200 bg-primary-50 p-5',
        !isSelected && 'border-gray-400 bg-transparent',
      )}
      onClick={onClick}
      disabled={isDisabled}
    >
      <Row className="items-center gap-2">
        <Column>
          <Row className="gap-2">
            <Typography
              className={twMerge(
                'text-start font-semibold',
                !isSelected && 'text-gray-400',
              )}
            >
              {title}
            </Typography>
            {shouldShowBadge ? (
              <BadgeUsesLeft featureType="PROCESS_AUTO_GENERATION" />
            ) : null}
          </Row>
          <Typography
            className={twMerge('text-start', !isSelected && 'text-gray-400')}
          >
            {description}
          </Typography>
        </Column>
        <CheckIcon
          fontSize="large"
          className={!isSelected ? 'invisible' : ''}
        />
      </Row>
    </Button>
  )
}
