import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  type InputLabelProps,
  type SelectProps,
} from '@mui/material'
import { Controller, type Control, type FieldValues } from 'react-hook-form'

export type ControlledSelectOptionType = {
  /** The option depth level. */
  depth?: number
  /** The select option label. */
  label?: string
  /** The parent path of the current option. */
  parent?: string
  /** The select option value. */
  value: string | null
}

export type ControlledSelectProps = SelectProps & {
  /** The react hook form `control` from `useForm` hook. */
  control?: Control<FieldValues>
  /** The props applied to the `InputLabel` node. */
  InputLabelProps?: InputLabelProps
  /** The select options. */
  options?: ControlledSelectOptionType[]
}

/**
 * A select component based on MUI,
 * controlled by `react-hook-form`.
 */
const ControlledSelect = (props: ControlledSelectProps) => {
  const {
    className,
    control,
    InputLabelProps,
    label,
    labelId,
    name,
    options,
    ...rest
  } = props

  if (!name) return null

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl className={className} fullWidth size="small">
          <InputLabel id={labelId} shrink {...InputLabelProps}>
            {label}
          </InputLabel>

          <Select labelId={labelId} label={label} notched {...rest} {...field}>
            {options?.map(
              ({ depth = 0, label, value }: ControlledSelectOptionType) => {
                // TODO: This flow is very similar to the one on `MenuToReplaceText`
                // component, consider the refactor of both in the future
                // (This was not done at the moment due to regular changes to UI).
                const key = `${depth}-${value || label}`
                const style = {
                  marginLeft: `${depth * 15}px`,
                }

                if (value === null)
                  return (
                    <ListSubheader
                      className="font-bold leading-9"
                      key={key}
                      style={style}
                    >
                      {label}
                    </ListSubheader>
                  )

                return (
                  <MenuItem key={key} style={style} value={value}>
                    {label}
                  </MenuItem>
                )
              },
            )}
          </Select>
        </FormControl>
      )}
    />
  )
}

export default ControlledSelect
