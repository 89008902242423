import { useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { BusinessChildrenParams } from '~/routes/business/routes.types'
import { queryKeyProcess } from '~/services/Process'
import { ResponseDiscoveryProcess } from '~/services/Process.types'

type HookResponse = Partial<ResponseDiscoveryProcess>

type UseGetProcess = () => HookResponse

export const useGetProcess: UseGetProcess = () => {
  const params = useParams<BusinessChildrenParams>()
  const { processId } = params
  const queryClient = useQueryClient()
  const process = queryClient.getQueryData<
    AxiosResponse<ResponseDiscoveryProcess | null, unknown>
  >(queryKeyProcess(processId))

  if (process) {
    const { data } = process
    if (data) {
      return data
    }
  }

  return {}
}
