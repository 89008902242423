type ActionIconProps = React.SVGProps<SVGSVGElement> & {
  fontSize?: 'medium' | 'small'
}
export function ActionIcon({ fontSize = 'medium', ...props }: ActionIconProps) {
  const sizes = {
    ['medium']: {
      width: 24,
      height: 24,
    },
    ['small']: {
      width: 12,
      height: 12,
    },
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...sizes[fontSize]}
      {...props}
    >
      <path
        fill="#B6F1E6"
        stroke="#080D97"
        d="M19 12 6.25 19.794V4.206L19 12Z"
      />
    </svg>
  )
}
