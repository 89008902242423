import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { queryDevelopmentDomainsForCommands } from '~/services/Development'

export const loaderDeveloperAddReaction =
  (queryClient: QueryClient) =>
  async ({ request, params, context }: LoaderFunctionArgs) => {
    const { platformId } = params

    // * Query is invalidated in the action function from add-command and add-reaction
    const qDomains = queryDevelopmentDomainsForCommands(platformId)
    const fetchDomains = await queryClient.ensureQueryData(qDomains)
    invariant(
      fetchDomains.status === 200 && fetchDomains.data,
      `No data for development domains for commands: ${fetchDomains}`,
    )

    return fetchDomains
  }
