import { InteractionStatus, PublicClientApplication } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FirstLoading } from '~/components/FirstLoading'
import { constants } from '~/config'
import { msalConfig } from '~/config/auth'
import { getToken } from '~/utils/getToken'

const msalInstance = new PublicClientApplication(msalConfig)

export function Index() {
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  const handleLogin = useCallback(async () => {
    return await instance.loginRedirect({
      redirectUri: constants.msal.redirectUri,
      scopes: [
        'openid',
        'profile',
        'https://dotstar.onmicrosoft.com/dotstar-spa-backend-services/access',
      ],
    })
  }, [instance])

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      // Step to refresh cached data.
      getToken(msalInstance)

      // Redirect to login page.
      handleLogin()
    }

    if (isAuthenticated && inProgress === InteractionStatus.None) {
      navigate('/welcome/step1')
    }
  }, [handleLogin, inProgress, isAuthenticated, navigate])

  return <FirstLoading />
}
