export function MenuIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path stroke="#080D97" d="M5 7.5h14" />
      <path stroke="#B6F1E6" strokeWidth={2} d="M5 9h14" />
      <path stroke="#080D97" d="M5 12.5h14" />
      <path stroke="#B6F1E6" strokeWidth={2} d="M5 14h14" />
      <path stroke="#080D97" d="M5 17.5h14" />
      <path stroke="#B6F1E6" strokeWidth={2} d="M5 19h14" />
    </svg>
  )
}
