import { twMerge } from '^/tailwind.config'
import React from 'react'

const DEFAULT_COLUMN_CLASSNAMES = 'flex flex-col'

type ColumnComponentProps = React.ComponentPropsWithoutRef<'div'> & {
  className?: string
}
function ColumnComponent(
  props: ColumnComponentProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const mergedClassName = twMerge(DEFAULT_COLUMN_CLASSNAMES, props.className)

  return (
    <div ref={ref} {...props} className={mergedClassName}>
      {props.children}
    </div>
  )
}

export const Column = React.forwardRef<HTMLDivElement, ColumnComponentProps>(
  ColumnComponent,
)
