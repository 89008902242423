import { useContext } from 'react'
import SelectedTextSectionsContext, {
  type SelectedTextSectionsContextProps,
} from '~/contexts/SelectedTextSectionsContext'

/**
 * Hook to consume the Selected Text Sections context states.
 */
export const useSelectedTextSectionsContext =
  (): SelectedTextSectionsContextProps => {
    const context = useContext(SelectedTextSectionsContext)

    return context
  }
