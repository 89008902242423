import { createContext, type Dispatch, type SetStateAction } from 'react'
import type { SelectedTextSectionsType } from '~/models/types/components/codeGenerationStrategy/ManageNodeSelection/SelectedTextSectionsType'

export type SelectedTextSectionsContextProps = {
  /** Indicates if the editable text section is editable (its value is editable). */
  editableNode: GUID | null
  /** Set is editable state. */
  setEditableNode?: Dispatch<SetStateAction<GUID | null>>
  /** Indicates if the merge mode is active. */
  isMergeMode: boolean
  /** Set is merge mode state. */
  setIsMergeMode?: Dispatch<SetStateAction<boolean>>
  /** Indicates if the merge mode is active by keyboard. */
  isMergeModeActivateByKeyboard: boolean
  /** Set is merge mode activate by keyboard state. */
  setIsMergeModeActivateByKeyboard?: Dispatch<SetStateAction<boolean>>
  /** Selected text sections. */
  selectedTextSections: SelectedTextSectionsType | null
  /** Set selected text sections. */
  setSelectedTextSections?: Dispatch<
    SetStateAction<SelectedTextSectionsType | null>
  >
}

const defaultValue = {
  editableNode: null,
  isMergeMode: false,
  isMergeModeActivateByKeyboard: false,
  selectedTextSections: null,
}

/**
 * Context for the Selected Text Sections.
 */
const SelectedTextSectionsContext =
  createContext<SelectedTextSectionsContextProps>(defaultValue)

export default SelectedTextSectionsContext
