import type { Node } from '@xyflow/react'
import type { NodeStatement } from '~/services/Process.types'

/**
 * Calculates a node position, to properly focus on it.
 * It will take in consideration the parent node position,
 * to calculate the node absolute position.
 * @param node The node to get the absolute position.
 * @param nodes The array of all nodes.
 */
export const calculateNodeAbsolutePosition = (
  node: Node<NodeStatement>,
  nodes: Node<NodeStatement>[],
) => {
  let x = node.position.x
  let y = node.position.y
  let parent = nodes.find((n) => n.id === node.parentId)

  while (parent) {
    x += parent.position.x
    y += parent.position.y
    parent = nodes.find((n) => n.id === parent?.parentId)
  }

  return { x, y }
}
