import { AccountInfo } from '@azure/msal-browser'

export function getUserName(accounts: AccountInfo[]) {
  if (accounts.length) {
    if (accounts[0]?.idTokenClaims?.given_name) {
      return accounts[0].idTokenClaims?.given_name
    }
    if (accounts[0]?.idTokenClaims?.name) {
      return accounts[0].idTokenClaims?.name
    }

    return
  }

  return
}

export function getUserEmailByMsal(accounts: AccountInfo[]) {
  if (accounts.length) {
    if (accounts[0]?.idTokenClaims?.emails?.[0]) {
      return accounts[0].idTokenClaims?.emails?.[0]
    }
    return
  }

  return
}

export function getUserIdByMsal(accounts: AccountInfo[]) {
  if (accounts.length) {
    return accounts[0]?.idTokenClaims?.oid
  }

  return
}

export function later(delay: number) {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay)
  })
}

export function isObjectEmpty(object: unknown) {
  if (!object) return true

  const isObject =
    typeof object === 'object' &&
    Object.keys(object).length === 0 &&
    object.constructor === Object

  return !isObject
}
