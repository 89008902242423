import { CircularProgress } from '@mui/material'
import DotstarLogo from '~/assets/icons/dotstar-logo.svg?react'
import { StackCentered } from '~/styled/StackCentered'

export const FirstLoading = () => {
  return (
    <StackCentered sx={{ height: '100svh', gap: 5 }}>
      <DotstarLogo />
      <CircularProgress sx={{ color: 'primary.main' }} />
    </StackCentered>
  )
}
