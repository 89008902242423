import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material'
import { Form, useNavigation } from 'react-router-dom'
import { platforms } from '~/config'
import { switchIcon } from '~/routes/setup/platform/utils'

export function DeveloperCloudProvider() {
  const navigation = useNavigation()
  const isButtonDisabled = navigation.state !== 'idle'

  return (
    <>
      <Stack gap={2}>
        <Typography>Please select the cloud provider:</Typography>
        <Paper sx={{ px: 2, py: 1 }}>
          <List component="nav">
            {platforms?.map((platform) => (
              <ListItem disablePadding key={platform.identity}>
                <Box
                  component={Form}
                  method="post"
                  id="form-platform"
                  sx={{ flexGrow: 1, display: 'flex' }}
                >
                  <input
                    type="hidden"
                    defaultValue={platform.identity}
                    name="name"
                  />
                  <ListItemButton
                    // disabled={hosting.disabled || isButtonDisabled}
                    disabled={isButtonDisabled || platform.disabled}
                    component="button"
                    type="submit"
                  >
                    <ListItemIcon>
                      <SvgIcon>{switchIcon(platform.identity)}</SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={platform.name} />
                  </ListItemButton>
                </Box>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Stack>
    </>
  )
}
