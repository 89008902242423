import { useEffect, useRef, useState } from 'react'

const decode = (value: any) => {
  return JSON.stringify(value)
}

const encode = (value: any) => {
  return JSON.parse(value)
}

// useLocalStorage hook
const useLocalStorage = (key: string, defaultState: any) => {
  const currentKey = useRef(key)
  const [value, setValue] = useState(
    encode(localStorage.getItem(key) || null) || defaultState,
  )

  useEffect(() => {
    if (currentKey.current !== key) {
      setValue(encode(localStorage.getItem(key) || null) || defaultState)
      currentKey.current = key
      return
    }
    localStorage.setItem(key, decode(value))
  }, [defaultState, key, value])

  return [value, setValue]
}

export { useLocalStorage }
