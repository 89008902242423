import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import type { AddLocalChildStatementProps } from '~/models/types/components/processInfo/AddLocalChildStatementProps'
import type { BusinessChildrenParams } from '~/routes/business/routes.types'
import type {
  ResponseDiscoveryProcess,
  Statement,
} from '~/services/Process.types'
import { getLinkEditCommandOrReaction } from './useLinkEditCommandOrReaction'

type UseStatementKeyboardActionsProps = {
  /** */
  deleteSelectedStatement: (toggleDeleteModal: () => void) => void
  /** */
  insertLocalStatementBetween: () => void
  /** */
  process: ResponseDiscoveryProcess
  /** */
  selectFirstChildStatementOrAdd: () => void
  /** */
  selectNextSiblingStatement: () => void
  /** */
  selectNewAndRemoveCurrentIfNeed: (props: AddLocalChildStatementProps) => void
  /** */
  selectParentStatement: () => void
  /** */
  selectPreviousSiblingStatement: () => void
  /** */
  selectedStatement: Statement | null
  /** */
  selectedStatements: Statement[]
  /** */
  swapStatements: () => void
  /** */
  toggleMultiSelectEnablement: (newState: boolean) => void
}

export const useStatementsKeyboardActions = (
  props: UseStatementKeyboardActionsProps,
) => {
  const {
    deleteSelectedStatement,
    insertLocalStatementBetween,
    process,
    selectFirstChildStatementOrAdd,
    selectNextSiblingStatement,
    selectNewAndRemoveCurrentIfNeed,
    selectParentStatement,
    selectPreviousSiblingStatement,
    selectedStatement,
    swapStatements,
    selectedStatements,
    toggleMultiSelectEnablement,
  } = props

  // React Router Dom.
  const params = useParams<BusinessChildrenParams>()
  const { organisationId, platformId } = params

  const location = useLocation()

  const navigate = useNavigate()

  // States.
  const [isEditingMode, setIsEditingMode] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  // Ref.
  const currentProcess = useRef(process)
  const currEditingStatement = useRef<GUID>()

  // Constants.
  const { isFeature } = selectedStatement || {}
  const isEditable = !!isEditingMode && !isFeature

  const regex = new RegExp(
    `/${organisationId}/${platformId}/business(?:/${process.identity}(?!/)|$)`,
  )
  const isStatementsView = regex.test(location.pathname)
  const canDelete =
    !selectedStatement?.isLocal && selectedStatements.length === 1

  // Methods.
  const toggleDeleteModal = () => {
    setIsDeleteModalOpen((current) => !current)
  }

  // Handler for edit node.
  const handleEditNode = () => {
    const { id, isFeature, parsingInfo } = selectedStatement || {}
    const { type } = parsingInfo || {}

    // If it is marked as feature.
    if (isFeature) {
      const url = getLinkEditCommandOrReaction(type, id)

      // If there is an url navigate to it.
      if (!!url) return navigate(url)
      // Otherwise, do nothing.
      return
    }

    // If it is NOT marked as feature, enable edit mode.
    return setIsEditingMode?.(true)
  }

  const getStatementDirectionFunction = useCallback(
    (keyCode: string) => {
      const behavior = {
        ...(!isEditable &&
          !isEditingMode &&
          !isDeleteModalOpen && {
            ['ArrowLeft']: selectParentStatement,
            ['ArrowRight']: selectFirstChildStatementOrAdd,
            ['ArrowUp']: selectPreviousSiblingStatement,
            ['ArrowDown']: selectNextSiblingStatement,
            ['Help']: insertLocalStatementBetween,
            ['Insert']: insertLocalStatementBetween,
            ['i']: insertLocalStatementBetween,
            ['Control']: () => toggleMultiSelectEnablement(true),
            ['Meta']: () => toggleMultiSelectEnablement(true),
            ['s']: swapStatements,
          }),
        ...(!isEditable &&
          !isEditingMode && {
            ['Backspace']: canDelete ? toggleDeleteModal : () => {},
            ['Delete']: canDelete ? toggleDeleteModal : () => {},
          }),
        ['Enter']: !isDeleteModalOpen
          ? () => handleEditNode()
          : () => deleteSelectedStatement(toggleDeleteModal),
        ['Escape']: () => {
          setIsEditingMode(false)
          selectNewAndRemoveCurrentIfNeed({})
        },
      }

      const selectedBehavior =
        behavior[keyCode as keyof typeof behavior] || (() => {})
      return selectedBehavior()
    },
    [
      canDelete,
      deleteSelectedStatement,
      insertLocalStatementBetween,
      isDeleteModalOpen,
      isEditable,
      isEditingMode,
      selectFirstChildStatementOrAdd,
      selectNextSiblingStatement,
      selectParentStatement,
      selectPreviousSiblingStatement,
      swapStatements,
      toggleMultiSelectEnablement,
    ],
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (process.isGeneratingProcess) {
        return
      }
      if (isStatementsView) {
        getStatementDirectionFunction(event.key)
      }
    },
    [
      getStatementDirectionFunction,
      isStatementsView,
      process.isGeneratingProcess,
    ],
  )

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (process.isGeneratingProcess) return

      const behavior = {
        ...(!isEditingMode &&
          !isDeleteModalOpen && {
            ['Control']: () => toggleMultiSelectEnablement(false),
            ['Meta']: () => toggleMultiSelectEnablement(false),
          }),
      }

      const selectedBehavior =
        behavior[event.key as keyof typeof behavior] || (() => {})

      return selectedBehavior()
    },
    [
      isDeleteModalOpen,
      isEditingMode,
      process.isGeneratingProcess,
      toggleMultiSelectEnablement,
    ],
  )

  // Leave edit mode when not clicking on the same node.
  const handleGlobalClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Element
      const clickedElement = !!target && target.closest('[data-statement-id]')
      const clickedNodeId =
        !!clickedElement && clickedElement.getAttribute('data-statement-id')

      if (clickedNodeId !== currEditingStatement.current) {
        setIsEditingMode(false)
        currEditingStatement.current = undefined
      }
    },
    [selectedStatement, setIsEditingMode],
  )

  // Lifecycle.
  // Set current editing statement if necessary.
  useEffect(() => {
    // Initially set `currSelectedStatement` when a statement is editing.
    if (isEditingMode) currEditingStatement.current = selectedStatement?.id
  }, [isEditingMode])

  // Set event listeners.
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    if (isEditingMode) document.addEventListener('click', handleGlobalClick)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
      document.removeEventListener('click', handleGlobalClick)
    }
  }, [handleGlobalClick, handleKeyDown, handleKeyUp, isEditingMode])

  useEffect(() => {
    if (process.identity !== currentProcess.current.identity) {
      setIsEditingMode(false)
      currentProcess.current = process
    }
  }, [process])

  return {
    isDeleteModalOpen,
    isEditingMode,
    setIsEditingMode,
    toggleDeleteModal,
  }
}
