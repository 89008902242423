import { produce } from 'immer'
import { TextSection } from '~/services/GenerationStrategy.types'
import { replaceTabs } from '~/utils/strings/replaceTabs'

/**
 * Replace the `tabs` characters
 * part of `value` and `originalValue` properties.
 * The tabs will be replaces by 4 white spaces.
 */
export const replaceValueTabs = (node: TextSection) => {
  const { originalValue = '', value = '' } = node

  const tabRegex = /\t/

  // Check if the values have tabs.
  if (tabRegex.test(originalValue) || tabRegex.test(value))
    return produce(node, (draft: TextSection) => {
      if (tabRegex.test(originalValue))
        draft.originalValue = replaceTabs(originalValue)

      if (tabRegex.test(value)) draft.value = replaceTabs(value)
    })

  return node
}
