import CheckIcon from '@mui/icons-material/Check'
import { Box, StepIconProps, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'
import SvgWizardStateBullet from '~/assets/icons/wizard-state.svg?react'

type WizardBulletProps = PropsWithChildren & {
  step: string
  state?: 'active' | 'inactive' | 'completed'
  width?: number
  height?: number
}
export function WizardBullet({
  step,
  width = 34,
  height = 38,
  state,
  children,
}: WizardBulletProps) {
  return (
    <Box
      sx={{
        width,
        height,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!children ? (
          <Typography variant="welcomeWizardStepper" component="p">
            {step}
          </Typography>
        ) : (
          children
        )}
      </Box>
      <SvgWizardStateBullet />
    </Box>
  )
}

export function WizardStepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props

  return (
    <Box
      sx={{
        width: 34,
        height: 38,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: completed ? '#fff' : 'primary.main',
        }}
      >
        {completed ? (
          <CheckIcon stroke="white" />
        ) : (
          <Typography variant="welcomeWizardStepper" component="p">
            {icon}
          </Typography>
        )}
      </Box>
      <SvgWizardStateBullet
        fill={completed ? '#0eb79d' : active ? '#080d97' : '#dcdcfe'}
      />
    </Box>
  )
}
