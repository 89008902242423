export type GetSelectionStartEndReturn = {
  /** The caret selection start. */
  selectionStart: number
  /** The caret selection end. */
  selectionEnd: number
}

/**
 * Make use of the Selection and Range APIs
 * to calculate the selectionStart and selectionEnd.
 * This method is necessary to get the caret selection from
 * a content editable component - as this is not an
 * input component, it is not possible to get these data
 * directly from the component.
 * @param textComponent The text/input component to get the selection.
 */
export const getSelectionStartEnd = (
  textComponent: Node | HTMLTextAreaElement,
): GetSelectionStartEndReturn => {
  let selectionStart = 0
  let selectionEnd = 0

  if (textComponent instanceof HTMLTextAreaElement) {
    selectionStart = textComponent.selectionStart
    selectionEnd = textComponent.selectionEnd
  } else {
    const selection = window.getSelection()

    if (selection && selection.rangeCount > 0) {
      // Get the start by calculating the length of
      // the text up to the start of the selection.
      const range = selection.getRangeAt(0)
      const preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(textComponent)
      preCaretRange.setEnd(range.startContainer, range.startOffset)
      selectionStart = preCaretRange.toString().length

      // Get `end` from the focusOffset property from the selection.
      selectionEnd = selection.focusOffset
    }
  }

  return { selectionStart, selectionEnd }
}

/**
 * Get the `value` key from the provided component:
 * It will check for `value` or `innerText`
 * and returns the one part of the provided component.
 * This method is necessary to get the `value` of
 * a content editable component - as this is not an
 * input component, it is not possible to get `value`,
 * it is necessary to get `innerText`.
 * @param textComponent The text/input component to get the value key.
 */
export const getComponentValueKey = (
  textComponent: Node,
): keyof HTMLTextAreaElement => {
  let valueKey = '' as keyof HTMLTextAreaElement

  for (const objKey in textComponent) {
    if (objKey === 'value' || objKey === 'innerText') {
      valueKey = objKey
      break
    }
  }

  return valueKey
}
