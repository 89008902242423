import { Form } from 'react-router-dom'
import { ProcessForm } from '~/pages/business/components/ProcessForm'
import { FormFooter } from '~/pages/developer/components/FormFooter'

export function AddProcess() {
  return (
    <Form method="post" id="form-domain-add-process">
      <ProcessForm
        nameFieldLabel="Process name"
        descriptionFieldLabel="Add a comprehensive description of the process"
      />
      <FormFooter showReset={false} />
    </Form>
  )
}
