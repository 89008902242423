import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { platforms } from '~/config'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderDeveloperPersistence =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params, context }: LoaderFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    invariant(userId, `Must be logged in: ${userId}`)
    invariant(email, `Must be logged in: ${userId}`)
    const { aggregateId, organisationId, platformId } = params

    const queryPlat = queryPlatformTeam(organisationId)
    const dataFetchedPlat = await queryClient.ensureQueryData(queryPlat)

    if (!dataFetchedPlat?.data?.cloudPlatform) {
      return redirect(
        `/${organisationId}/${platformId}/developer/${aggregateId}/config-cloud-provider?redirect=/${organisationId}/${platformId}/developer/${aggregateId}/edit-persistence`,
      )
    }

    const persistences = platforms.find(
      (platform) => platform.identity === dataFetchedPlat.data?.cloudPlatform,
    )?.services.persistenceStrategies
    return persistences ?? null
  }
