import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import { IS_DEV } from '~/config/constants'
import { queryOrganisation } from '~/services/Discovery'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderBusinessLayout =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params }: LoaderFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    // if no user, redirect to login page
    if (!userId || !email) {
      return redirect('/')
    }

    const qOrganisation = queryOrganisation(userId, email)
    try {
      const fetchOrganisation = queryClient.ensureQueryData(qOrganisation)
      return {
        organisation: fetchOrganisation,
      }
      // if no organisation, redirect to welcome page
    } catch (err) {
      if (IS_DEV) {
        console.log({ err })
      }
      return redirect('/welcome/step1')
    }
  }
