import {
  Autocomplete,
  AutocompleteChangeReason,
  Button,
  TextField,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import {
  useEffect,
  useMemo,
  useState,
  type MouseEvent,
  type SyntheticEvent,
} from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import {
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
  useParams,
} from 'react-router-dom'
import { ButtonAutoGenerate } from '~/components/ButtonAutoGenerate'
import { Column } from '~/components/Column'
import { ActionIcon } from '~/components/Icons/ActionIcon'
import { ReactionIcon } from '~/components/Icons/ReactionIcon'
import { Row } from '~/components/Row'
import { FormActionName } from '~/pages/developer/components/FormActionName'
import { FormAttributes } from '~/pages/developer/components/FormAttributes'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { RaisedDomainEvent } from '~/pages/developer/components/FormRaisedDomainEvent'
import { loaderDeveloperEditReaction } from '~/routes/developer/edit-reaction/loader'
import { DeveloperChildrenParams } from '~/routes/developer/routes.types'
import {
  queryDevelopment,
  queryDevelopmentDomainsForCommands,
} from '~/services/Development'
import {
  Attribute,
  CommandDomainEvent,
  DomainEvent,
} from '~/services/Development.types'
import { reactionsByAggregate } from '~/utils/transform'
import { renamePropertiesToAttributes } from '../utils'

type DeveloperEditReactionProps = {
  showBoundedContextEdit: boolean
}

export function DeveloperEditReaction(props: DeveloperEditReactionProps) {
  // React Router Dom.
  const dataAutoGeneratedAttributes = useActionData() as {
    formName: string
    attributes: Attribute[]
  }
  const initialDataForAggregates = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loaderDeveloperEditReaction>>
  >
  const navigation = useNavigation()
  const params = useParams<DeveloperChildrenParams & { reactionId: string }>()

  // Queries.
  const { data } = useQuery({
    ...queryDevelopmentDomainsForCommands(params.platformId),
  })

  const { data: dataAggregates } = useQuery({
    ...queryDevelopment(params.platformId),
    initialData: initialDataForAggregates,
    select: (response) => response.data,
  })

  // Memoized resources.
  const selectedReaction = useMemo(() => {
    const reactions = reactionsByAggregate(dataAggregates)

    if (reactions) {
      return reactions.find(
        (reaction) => reaction.identity === params.reactionId,
      )
    }

    return
  }, [dataAggregates, params.reactionId])

  const shouldDisableAttributes = useMemo(() => {
    if (navigation.formData) {
      const checkIfButtonWasClicked = Object.fromEntries(
        navigation.formData,
      ).reactionAutoGenerateAttributes

      if (checkIfButtonWasClicked) {
        return true
      }
    }

    return false
  }, [navigation.formData])

  // States.
  // Controls for autocomplete
  const [autoCompleteValue, setAutoCompleteValue] =
    useState<DomainEvent | null>(data?.data.domainEvents[0] ?? null)
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState('')

  // React Hook Form.
  const methods = useForm({
    defaultValues: {
      events: renamePropertiesToAttributes(selectedReaction?.domainEvents) as
        | CommandDomainEvent[]
        | undefined,
      action: {
        name: selectedReaction?.actionName,
        attributes: renamePropertiesToAttributes(
          dataAutoGeneratedAttributes?.attributes ||
            selectedReaction?.attributes,
        ) as CommandDomainEvent[] | undefined,
      },
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'events',
  })

  // Methods.
  function handleAutoCompleteChange(
    _: SyntheticEvent<Element, Event>,
    value: DomainEvent | null | undefined,
    reason: AutocompleteChangeReason,
  ) {
    if (reason === 'clear') {
      setAutoCompleteInputValue('')
      setAutoCompleteValue(null)
    }
    if (reason === 'selectOption' && value) {
      setAutoCompleteInputValue(`${value.aggregate} > ${value.name}`)
      setAutoCompleteValue(value)
    }
  }

  function handleRemoveEvent(event: MouseEvent<HTMLButtonElement>) {
    const index = event.currentTarget.dataset.index
    if (index) {
      remove(Number(index))
    }
  }

  function handleAddRaisedDomainEvent() {
    append({ name: '', attributes: [] })
  }

  // Lifecycle.
  useEffect(() => {
    if (data?.data.domainEvents.length && selectedReaction) {
      const [boundedContext, aggregate] =
        selectedReaction.subscription.from.split('.')
      const domainEvent = data?.data.domainEvents.find(
        (event) =>
          event.boundedContext === boundedContext &&
          event.aggregate === aggregate &&
          event.name === selectedReaction.subscription.domainEventName,
      )

      if (boundedContext && aggregate) {
        setAutoCompleteValue(domainEvent ?? null)
        setAutoCompleteInputValue(
          `${aggregate} > ${selectedReaction.subscription.domainEventName}`,
        )
      }
    }
  }, [selectedReaction, data?.data.domainEvents])

  useEffect(() => {
    if (
      dataAutoGeneratedAttributes &&
      dataAutoGeneratedAttributes?.formName === 'action' &&
      dataAutoGeneratedAttributes?.attributes.length > 0
    ) {
      methods.setValue(
        'action.attributes',
        renamePropertiesToAttributes(
          dataAutoGeneratedAttributes?.attributes,
        ) as CommandDomainEvent[],
      )
    }
  }, [dataAutoGeneratedAttributes, methods])

  if (data) {
    return (
      <Column className="gap-4 pb-12">
        <FormProvider {...methods}>
          <Form method="post" id="form-aggregate-add-reaction">
            <input
              name="aggregateId"
              id="aggregateId"
              type="hidden"
              value={selectedReaction?.aggregateId}
            />

            <Column className="gap-4">
              {props.showBoundedContextEdit ? (
                <FormActionName
                  defaultValue={selectedReaction?.boundedContext}
                  inputLabel="Bounded Context"
                  inputName="boundedContext"
                >
                  <TextField
                    label="Aggregate"
                    variant="outlined"
                    defaultValue={selectedReaction?.aggregateName}
                    name="aggregateName"
                    required
                    fullWidth
                  />
                </FormActionName>
              ) : (
                <></>
              )}

              <Row className="items-center gap-2">
                <ActionIcon />

                <Autocomplete
                  id="domain-events"
                  options={data.data.domainEvents}
                  groupBy={(option) => option.boundedContext}
                  getOptionLabel={(option) =>
                    `${option.aggregate} > ${option.name}`
                  }
                  value={autoCompleteValue}
                  inputValue={autoCompleteInputValue}
                  onChange={handleAutoCompleteChange}
                  onInputChange={(_, newInputValue) => {
                    setAutoCompleteInputValue(newInputValue)
                  }}
                  sx={{ width: 1 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Reacting to Domain Event"
                      name="subscriptionName" // won't use it
                      required
                    />
                  )}
                />
              </Row>

              <input
                type="hidden"
                value={JSON.stringify(autoCompleteValue)}
                name="subscriptionValues"
              />

              <FormActionName
                inputLabel="Action Name"
                inputName="action.name"
                defaultValue={selectedReaction?.actionName}
              >
                <FormAttributes
                  aggregateId={selectedReaction?.aggregateId}
                  autoGenerateComponent={<ButtonAutoGenerate name="action." />}
                  formName="action"
                  isDisabled={shouldDisableAttributes}
                />
              </FormActionName>

              <Row className="gap-1">
                <ReactionIcon />

                <Typography>Raised Domain Events ({fields.length})</Typography>
              </Row>

              {fields.map((field, index) => (
                <RaisedDomainEvent
                  key={field.id}
                  defaultValue={field.name}
                  index={index}
                  handleRemoveEvent={handleRemoveEvent}
                  isRemoveDisabled={fields.length <= 1}
                  inputName={`events.${index}.name`}
                >
                  <FormAttributes
                    aggregateId={selectedReaction?.aggregateId}
                    autoGenerateComponent={
                      <ButtonAutoGenerate name={`events.${index}.`} />
                    }
                    formName={`events.${index}`}
                  />
                </RaisedDomainEvent>
              ))}

              <Button variant="outlined" onClick={handleAddRaisedDomainEvent}>
                Add raised domain event
              </Button>

              <FormFooter />
            </Column>
          </Form>
        </FormProvider>
      </Column>
    )
  }

  return null
}
