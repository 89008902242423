import { useEffect } from 'react'
import { useForm, type FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useDeleteFilePath } from '~/hooks/api/codeGenerationStrategy/useDeleteFilePath'
import { DeleteFilePathFormEnum } from '~/models/enums/forms/DeleteFilePathFormEnum'
import type { DialogDeleteFilePathProps } from '~/models/types/components/codeGenerationStrategy/DialogDeleteFilePathProps'
import type { CodeGenerationStrategyDialogHook } from '~/models/types/hooks/codeGenerationStrategy/CodeGenerationStrategyDialogHook'

/**
 * Hook to support the `DialogDeleteFilePath` component.
 */
export const useDialogDeleteFilePath = (
  props: DialogDeleteFilePathProps,
): CodeGenerationStrategyDialogHook => {
  const { filePath, handleCloseDialog, isDialogOpen } = props

  let { generationStrategyId } = useParams()

  // Form set up.
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FieldValues>({
    values: {
      [DeleteFilePathFormEnum.FILE_PATH]: filePath ?? '',
    },
  })

  // Form submit mutation.
  const {
    error,
    isPending,
    isSuccess,
    mutate,
    reset: resetMutation,
  } = useDeleteFilePath({
    generationStrategyId,
  })

  // Reset form states when the dialog is closed.
  useEffect(() => {
    if (!isDialogOpen) {
      resetForm?.()
      resetMutation?.()
    }
  }, [isDialogOpen])

  // Close dialog when mutation is success.
  useEffect(() => {
    if (isSuccess) handleCloseDialog()
  }, [isSuccess])

  // Handles the form submit.
  const handleFormSubmit = (data: FieldValues) => {
    mutate(data)
  }

  // Handle the close dialog.
  const handleClose = () => {
    if (!isPending) handleCloseDialog()
  }

  return {
    control,
    error,
    handleClose,
    handleFormSubmit,
    handleSubmit,
    isPending,
  }
}
