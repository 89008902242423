import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useCopyAndPasteAttributes } from '~/hooks/useCopyAndPasteAttributes'
import { AttributesFormValues } from '~/routes/developer/edit-command'
import type { Attribute as TAttribute } from '~/services/Development.types'

export type AttributeOption = {
  /**
   * The depth level:
   * - 0: means this option is a category one.
   * - 1: means it is an actual option.
   */
  depth?: number
  /** The option id. */
  id?: GUID
  /** The option label. */
  label?: string
  /** The option name. */
  name?: string
}

type AttributeProps = {
  attribute: TAttribute
  formName?: string
  handleKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void
  handleRemoveAttribute?: (event: React.MouseEvent<HTMLButtonElement>) => void
  index?: number
  inputLabel?: string
  isDisabled?: boolean
  isRemoveDisabled?: boolean
  /** The options for the `type` field. */
  options?: AttributeOption[]
}

export function Attribute({
  attribute,
  formName,
  handleKeyDown,
  handleRemoveAttribute,
  index,
  inputLabel = 'Attribute',
  isDisabled,
  isRemoveDisabled,
  options,
}: AttributeProps) {
  const { control } = useFormContext<AttributesFormValues>()

  const valueWatcher = useWatch({
    control,
    name: `${formName}.${index}.type` as 'events.0.attributes.0.type',
    defaultValue: attribute.type?.replace('[]', '') ?? 'string',
  })?.replace('[]', '')

  return (
    <Stack direction="column">
      <Controller
        control={control}
        name={
          `${formName}.${index}.name_is_array` as 'events.0.attributes.0.name'
        }
        defaultValue={attribute?.type?.includes('[]') ? 'true' : 'false'}
        shouldUnregister
        render={({ field: { onChange, value, name } }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={value === 'true' ? true : false}
                onChange={(e) =>
                  onChange(e.target.checked === true ? 'true' : 'false')
                }
                name={name}
                disabled={isDisabled}
              />
            }
            label="Is Array"
          />
        )}
      />

      <Stack direction="row" gap={2} alignItems={'center'}>
        <Controller
          control={control}
          name={`${formName}.${index}.name` as 'events.0.attributes.0.name'}
          defaultValue={attribute.name || ''}
          render={({ field }) => (
            <TextField
              label={inputLabel}
              fullWidth
              disabled={isDisabled}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name={`${formName}.${index}.type` as 'events.0.attributes.0.type'}
          defaultValue={attribute.type?.replace('[]', '') ?? 'string'}
          render={({ field }) => (
            <TextField
              label="Type"
              select
              fullWidth
              data-index={index}
              onKeyDown={handleKeyDown}
              disabled={isDisabled}
              {...field}
              value={field?.value?.replace('[]', '')}
            >
              {options?.map(({ depth, id, label, name }: AttributeOption) => (
                <MenuItem
                  className={
                    depth === 0
                      ? 'bg-slate-50 font-bold text-primary opacity-100'
                      : ''
                  }
                  disabled={depth === 0}
                  key={id || name}
                  style={{
                    ...(typeof depth === 'number' && {
                      marginLeft: `${depth * 15}px`,
                    }),
                  }}
                  value={name}
                >
                  {label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Box>
          <IconButton
            onClick={handleRemoveAttribute}
            disabled={isRemoveDisabled || isDisabled}
            color="warning"
            data-index={index}
            onKeyDown={handleKeyDown}
            aria-label="remove attribute"
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Box>
      </Stack>

      {valueWatcher === 'object' && (
        <Stack
          sx={{
            marginLeft: 1,
            paddingTop: 1,
            borderLeft: '1px #020890 solid',
            paddingLeft: 1,
          }}
        >
          <ObjectProperties
            formName={`${formName}.${index}.attributes`}
            isDisabled={isDisabled}
            options={options}
          />
        </Stack>
      )}
    </Stack>
  )
}

type ObjectPropertiesProps = {
  formName: string
  isDisabled?: boolean
  /** The options for the `type` field. */
  options?: AttributeOption[]
}

function ObjectProperties({
  formName,
  isDisabled,
  options,
}: ObjectPropertiesProps) {
  const { control } = useFormContext<AttributesFormValues>()

  const { pasteAttributesFromClipboard } = useCopyAndPasteAttributes({
    copyingWholeForm: false,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${formName}` as 'events.0.attributes',
  })

  function handleAddAttribute() {
    append({ name: '', type: 'string' })
  }

  function handleRemoveAttribute(event: React.MouseEvent<HTMLButtonElement>) {
    const index = event.currentTarget.dataset.index
    if (index) {
      remove(Number(index))
    }
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
    if (event.key === 'Tab' || event.code === 'Tab') {
      const dataIndex = event.currentTarget.dataset.index
      if (dataIndex) {
        const index = Number(dataIndex)
        if (typeof fields[index + 1] === 'undefined') {
          handleAddAttribute()
        }
      }
    }
  }

  const handlePasteAttributes = async () => {
    const copiedAttributes = await pasteAttributesFromClipboard()

    if (!copiedAttributes) {
      return
    }
    append(copiedAttributes)
  }

  useEffect(() => {
    if (fields.length === 0) {
      append({
        name: '',
        type: 'string',
        attributes: [],
      })
    }
  }, [append, fields.length])

  return (
    <Stack>
      <Stack gap={2} direction="row" sx={{ alignItems: 'center' }}>
        <Typography>Properties</Typography>

        <Button
          variant="outlined"
          color="primary"
          onClick={handleAddAttribute}
          startIcon={<PlaylistAddIcon />}
          sx={{ width: 'fit-content' }}
          disabled={isDisabled}
        >
          Add
        </Button>

        <Stack direction="row">
          <IconButton
            name={formName?.split('.').slice(0, -1).join('.') + '.copyButton'}
            type="submit"
            disabled={isDisabled}
          >
            <ContentCopyIcon />
          </IconButton>

          <IconButton onClick={handlePasteAttributes} disabled={isDisabled}>
            <ContentPasteIcon />
          </IconButton>
        </Stack>
      </Stack>

      <Stack direction="column" gap={2}>
        {fields.map((item, index) => (
          <Attribute
            attribute={item}
            formName={`${formName}`}
            handleKeyDown={handleKeyDown}
            handleRemoveAttribute={handleRemoveAttribute}
            index={index}
            isDisabled={isDisabled}
            isRemoveDisabled={fields.length < 2}
            key={item.id}
            options={options}
          />
        ))}
      </Stack>
    </Stack>
  )
}
