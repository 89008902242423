import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { queryKeyStrategiesByOrganisationId } from '~/services/GenerationStrategy'
import { PayloadDeleteGenerationStrategy } from '~/services/GenerationStrategy.types'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'

export const actionConfigurationGenerationStrategyDelete =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const { organisationId, platformId } = params
    const formData = await request.formData()
    const formEntries = Object.fromEntries(formData)
    // Types below are needed because FormDataEntryValue can be a string or a File
    const generationStrategyId = formEntries.generationStrategyId as
      | string
      | undefined

    invariant(generationStrategyId, 'Generation Strategy Id is required')
    invariant(organisationId, 'Organisation ID is required')

    const postData = {
      generationStrategyId,
      organisationId,
    } satisfies PayloadDeleteGenerationStrategy

    const url = GenerationStrategyAPI.DeleteStrategy
    const req = await serviceGenerationStrategy.post(url, postData)

    if (req.status === 200) {
      await queryClient.invalidateQueries({
        queryKey: queryKeyStrategiesByOrganisationId(organisationId),
      })
    }

    return redirect(
      `/${organisationId}/${platformId}/configuration/code-generation-strategies`,
    )

    // return null
  }
