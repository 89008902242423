import { LoadingButton } from '@mui/lab'
import { Button, TextField } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { Controller } from 'react-hook-form'
import { Column } from '~/components/Column'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'
import FormError from '~/components/form/FormError/FormError'
import { LoadRepositoryFormEnum } from '~/models/enums/forms/LoadRepositoryFormEnum'
import type { LoadRepositoryDataProps } from '~/models/types/components/codeGenerationStrategy/LoadRepositoryDataProps'
import { useLoadRepositoryData } from './useLoadRepositoryData'

/**
 * Load Repository Data component
 * part of the Code Generation Strategy (AI Blueprint) edition.
 */
export const LoadRepositoryData = (props: LoadRepositoryDataProps) => {
  const { dataDirectoryItems } = props

  const {
    control,
    error,
    handleFormSubmit,
    handleHideForm,
    handleShowForm,
    handleSubmit,
    isPending,
    showForm,
  } = useLoadRepositoryData({
    dataDirectoryItems,
  })

  return (
    <Row className={twMerge('justify-between', showForm && 'flex-col')}>
      <Column>
        <Text className="text-xl font-semibold">Repository</Text>

        <Text className="text-tertiary">Load a repository data</Text>
      </Column>

      {showForm && (
        <form role="form" onSubmit={handleSubmit?.(handleFormSubmit)}>
          <Column className="gap-2 pt-2">
            <Controller
              control={control}
              disabled={isPending}
              name={LoadRepositoryFormEnum.REPOSITORY_URL}
              render={({ field }) => (
                <TextField
                  label="Repository URL"
                  className="max-w-[400px]"
                  size="small"
                  {...field}
                />
              )}
            />

            <Controller
              control={control}
              disabled={isPending}
              name={LoadRepositoryFormEnum.AUTHENTICATION_TOKEN}
              render={({ field }) => (
                <TextField
                  label="Authentication Token"
                  type="password"
                  className="max-w-[400px]"
                  size="small"
                  {...field}
                />
              )}
            />

            <FormError className="mb-0.5 text-xs" error={error as string} />

            <Row className="gap-2">
              <LoadingButton
                type="submit"
                loading={isPending}
                variant="outlined"
                className="max-w-fit"
              >
                Load repository
              </LoadingButton>

              {!!dataDirectoryItems && (
                <Button
                  disabled={isPending}
                  variant="outlined"
                  size="small"
                  onClick={handleHideForm}
                >
                  Close form
                </Button>
              )}
            </Row>
          </Column>
        </form>
      )}

      {!showForm && (
        <div className="flex items-center">
          <Button
            onClick={handleShowForm}
            variant="outlined"
            size="small"
            disabled={isPending}
          >
            Show form
          </Button>
        </div>
      )}
    </Row>
  )
}
