import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined'
import { Box, Button, Drawer, Paper, Stack, Typography } from '@mui/material'
import {
  Link,
  Outlet,
  useNavigate,
  useOutlet,
  useParams,
} from 'react-router-dom'
import { BusinessChildrenParams } from '../routes.types'

export function ConfigBusiness() {
  const outlet = useOutlet()
  const params = useParams<BusinessChildrenParams>()
  const navigate = useNavigate()

  function toggleDrawer() {
    navigate(
      `/${params.organisationId}/${params.platformId}/business/config-business`,
    )
  }
  return (
    <>
      <Paper sx={{ py: 4, px: 6, width: 'fit-content' }} elevation={5}>
        <Stack>
          <Typography variant="h6">Design Your Process</Typography>
          <Typography mt={2} variant="subtitle1">
            Describe your process step-by-step, and <strong>dot-star</strong>{' '}
            will help you visualise and optimise it.
          </Typography>
          <Button
            variant="contained"
            size="large"
            component={Link}
            replace
            to={`add-process`}
            sx={{
              width: 'fit-content',
              alignSelf: 'flex-end',
              mt: 5,
              gap: 1,
            }}
          >
            <PlayArrowOutlinedIcon />
            Create My Process
          </Button>
        </Stack>
      </Paper>
      <Drawer
        anchor="right"
        open={!!outlet}
        onClose={toggleDrawer}
        disablePortal
      >
        <Box p={3} sx={{ minWidth: 550 }}>
          <Outlet />
        </Box>
      </Drawer>
    </>
  )
}
