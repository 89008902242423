import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { redirect } from 'react-router-dom'
import { queryKeyOrganisation, queryOrganisation } from '~/services/Discovery'
import { getUserEmail } from '~/utils/getUserEmail'
import { getLocalUserId } from '~/utils/getUserId'

export const loaderStep2 =
  (queryClient: QueryClient, pca: PublicClientApplication) => async () => {
    const userId = getLocalUserId(pca)
    const email = getUserEmail(pca)
    // data should be fresh here since the last action is invalidating the cache
    // although we do invalidate the cache, since we don't use the query before it doens't invalidate
    // so we'll have to fetch it again manually
    await queryClient.refetchQueries({
      queryKey: queryKeyOrganisation(userId, email),
    })
    const query = queryOrganisation(userId, email)
    const dataFetched = await queryClient.ensureQueryData(query)

    if (dataFetched.status === 200) {
      return dataFetched
    }

    return redirect('/welcome/step1')
  }
