import { shouldShowInProd, shouldShowOnlyInDev } from '~/config/environments'

export const FEATURE_TOGGLE = {
  DEVELOPMENT: {
    PUBLISH_BRANCH_FIELD: shouldShowInProd(),
    AUTOGENERATE_ATTRIBUTES: shouldShowInProd(),
  },
  BUSINESS: {
    QUICK_EDIT: shouldShowInProd(),
    HELP_AROUND_CARD: shouldShowInProd(),
    APPROVE_FOR_DEVELOPMENT: shouldShowInProd(),
    EDIT_BUTTON: false,
    PUBLISH: shouldShowInProd(),
    DELETE_PROCESS: shouldShowInProd(),
    PUBLISH_WITH_READ_MODELS: shouldShowOnlyInDev(),
  },
  UPGRADE_PLAN: shouldShowOnlyInDev(),
  EDIT_PROPERTIES_WITH_REQUIRED_DATA: shouldShowOnlyInDev(),
  MULTIPLE_ORGS: shouldShowInProd(),
  CODE_GENERATION_STRATEGIES: true,
  CODE_GENERATION_STRATEGIES_CONFIG: true,
}
