import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import { platforms } from '~/config'
import {
  checkPlatformOnboarding,
  getPlatform,
  getUrlWithoutSetup,
  platformSetupValidations,
} from '~/routes/setup/platform/utils'

const STEP_NAME = 'hosting'

export const loaderPlatformHosting =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params, context }: LoaderFunctionArgs) => {
    const { organisationId } = params
    const { redirectFn } = await platformSetupValidations(queryClient, pca)
    if (!organisationId) {
      return redirectFn
    }

    const {
      status,
      data: {
        identity: platformId,
        frontEndHosting,
        cloudPlatform,
        routingStrategy,
        isSubmitted,
      },
    } = await getPlatform(queryClient, organisationId)

    const shouldRedirect = checkPlatformOnboarding(
      status,
      isSubmitted,
      platformId,
      request.url,
      STEP_NAME,
      organisationId,
    )
    if (shouldRedirect) {
      return shouldRedirect
    }
    const search = new URL(request.url).search
    if (!routingStrategy) {
      const splitUrl = getUrlWithoutSetup(request.url)
      return redirect(
        `${splitUrl}/platform-setup/routing${search ? search : ''}`,
      )
    }

    if (frontEndHosting) {
      const splitUrl = getUrlWithoutSetup(request.url)
      return redirect(
        `${splitUrl}/platform-setup/git-credentials${search ? search : ''}`,
      )
    }

    const hostingStrategies = platforms.find(
      (platform) => platform.identity === cloudPlatform,
    )?.frontend.hostingStrategies
    return hostingStrategies ?? null
  }
