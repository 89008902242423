import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { produce } from 'immer'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { v4 as uuidv4 } from 'uuid'
import { queryKeyOrganisation, queryOrganisation } from '~/services/Discovery'
import { FREE_FEATURES_USAGE } from '~/services/Discovery.types'
import {
  queryProcess,
  queryProcessListByOrganisationId,
} from '~/services/Process'
import { DiscoveryProcessAPI, serviceDiscoveryProcess } from '~/services/base'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const actionBusinessAddProcess =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)

    invariant(userId, `Must be logged in: ${userId}`)
    invariant(email, `Must be logged in: ${userId}`)

    const { platformId } = params

    invariant(platformId, 'No platformId found')

    // get form data
    const formData = await request.formData()
    const name = formData.get('name')
    const description = formData.get('description')
    const generateProcessBasedOnDescription =
      formData.get('generateProcessBasedOnDescription') === 'true'

    const errors: GenericObj<string> = {}
    if (!name || typeof name !== 'string' || name.trim().length <= 1) {
      errors.name = 'Required'
    }
    if (description) {
      if (typeof description !== 'string' || description.trim().length <= 1) {
        errors.description = 'Required'
      }
    }
    if (Object.keys(errors).length) {
      return errors
    }

    const qOrganisation = queryOrganisation(userId, email)
    const fetchOrganisation = await queryClient.ensureQueryData(qOrganisation)
    const organisationId = fetchOrganisation.data.identity

    // building the data to send to server
    const newProcessId = uuidv4()
    const processUrl = DiscoveryProcessAPI.CreateProcess
    await serviceDiscoveryProcess.post(processUrl, {
      organisationId,
      platformId,
      processId: newProcessId,
      name,
      ownerId: fetchOrganisation.data.ownerId,
      description,
      generateProcessBasedOnDescription,
    })

    queryClient.setQueryData(
      queryKeyOrganisation(userId, email),
      (organisation: any) =>
        produce(organisation, (draft: any) => {
          draft.data.subscriptionPlan[
            `${FREE_FEATURES_USAGE.PROCESS_AUTO_GENERATION}Remaining`
          ] =
            organisation.data.subscriptionPlan[
              `${FREE_FEATURES_USAGE.PROCESS_AUTO_GENERATION}Remaining`
            ] - 1 || 0
        }),
    )

    await queryClient.fetchQuery({
      ...queryProcessListByOrganisationId(organisationId),
    })
    await queryClient.fetchQuery({
      ...queryProcess({ processId: newProcessId }),
    })
    await queryClient.fetchQuery({
      ...queryOrganisation(userId, email),
    })

    return redirect(`/${organisationId}/${platformId}/business/${newProcessId}`)
  }
