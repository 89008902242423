import {
  Alert,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextareaAutosize,
  TextField,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { twMerge } from '^/tailwind.config'
import { useMemo, useState, type ChangeEvent } from 'react'
import {
  Form,
  generatePath,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from 'react-router-dom'
import { Column } from '~/components/Column'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import type { ConfigGenerateCodeInitialData } from '~/models/types/components/generateCode/ConfigGenerateCodeInitialData'
import { queryDevelopment } from '~/services/Development'
import {
  AggregateCommand,
  AggregateEventType,
  AggregateReaction,
  AggregateReadModel,
} from '~/services/Development.types'
import { queryStrategiesByOrganisationId } from '~/services/GenerationStrategy'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import { useGlobalStore } from '~/store'
import { getAggregateById, getEventById } from '~/utils/aggregate'
import { CustomAiBlueprintTile } from './components/CustomAiBlueprintTile/CustomAiBlueprintTile'
import { PreConfiguredStrategyTile } from './components/PreConfiguredStrategyTile/PreConfiguredStrategyTile'

type ConfigGenerateCodeParams = {
  eventType?: AggregateEventType
  platformId?: GUID
  eventId?: GUID
  aggregateId?: GUID
  processId?: GUID
  organisationId?: GUID
}

type ConfigureGenerateCodeActionData = {
  repositoryUrl?: string
  token?: string
  type?: string
  gitConfigured?: boolean
  apiError?: boolean
  apiErrorMessage?: unknown
}

/**
 * The modal to configure the generation code
 * settings.
 */
export function ConfigGenerateCode() {
  // Global store states.
  const storedProcessId = useGlobalStore((state) => state.processId)

  // React Router Dom.
  const errors = useActionData() as ConfigureGenerateCodeActionData
  const initialData = useLoaderData() as ConfigGenerateCodeInitialData
  const location = useLocation()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const params = useParams<ConfigGenerateCodeParams>()

  const shouldDisableFields = navigation.state !== 'idle'
  const isBusiness = location.pathname.includes('business')
  const {
    aggregateId,
    eventId,
    eventType,
    organisationId,
    platformId,
    processId,
  } = params

  // Queries.
  const { data: dataStrategies } = useQuery({
    ...queryStrategiesByOrganisationId(initialData.organisationId),
    initialData: initialData.strategies,
    select: (response) => response.data,
  })

  const { data: dataAggregate } = useQuery({
    ...queryDevelopment(platformId),
    initialData: initialData.development,
    select: (response) => {
      if (aggregateId && response.data) {
        const aggregateInfo = getAggregateById(response.data, aggregateId)
        return aggregateInfo
      }

      return
    },
  })

  const { data: dataPlatform } = useQuery({
    ...queryPlatformTeam(initialData.organisationId),
    initialData: initialData.platform,
    select: (response) => response.data,
  })

  // States.
  const [strategyTypeSelected, setStrategyTypeSelected] = useState<
    string | undefined
  >(() =>
    dataAggregate?.host || dataAggregate?.persistence ? 'regular' : undefined,
  )

  const [shouldShowGitCredentials, setShouldShowGitCredentials] = useState(
    !dataAggregate?.gitRepositoryUrl,
  )

  const [enableAIAssistantValue, setEnableAIAssistantValue] =
    useState<boolean>(false)

  // Memoized resources.
  const isLoading = useMemo(() => {
    const state = {
      full: false,
      git: false,
    }
    if (navigation.state !== 'idle' || navigation.formData) {
      if (navigation.formData?.get('intent') === 'submit-git-credentials') {
        state.git = true
      }
      if (navigation.formData?.get('intent') === 'submit-full') {
        state.full = true
      }
    }
    return state
  }, [navigation.formData, navigation.state])

  const eventName = useMemo(() => {
    if (dataAggregate && eventType && eventId) {
      const eventInfo = getEventById(dataAggregate, eventId, eventType)
      if (eventInfo && eventType === 'reaction') {
        return (eventInfo as AggregateReaction).actionName
      }
      if (eventInfo) {
        return (eventInfo as AggregateCommand | AggregateReadModel).name
      }
    }

    return
  }, [dataAggregate, eventId, eventType])

  // Methods.
  function handleClickClose() {
    if (isBusiness) {
      return navigate(
        generatePath(BusinessRoutesEnum.BUSINESS_PROCESS, {
          organisationId: organisationId || '',
          platformId: platformId || '',
          processId: processId || storedProcessId || '',
        }),
      )
    }

    navigate('../')
  }

  function handleClickStrategySelected(event: EventFor<'div', 'onClick'>) {
    const type = event.currentTarget.dataset.type
    setStrategyTypeSelected(type)
  }

  function handleClickToggleGitCredentials() {
    setShouldShowGitCredentials((old) => !old)
  }

  const handleEnableAIAssistantChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEnableAIAssistantValue(e.target.checked)
  }

  return (
    <>
      <Dialog
        open={true}
        fullWidth
        maxWidth="md"
        onClose={handleClickClose}
        scroll="paper"
      >
        {dataAggregate && dataStrategies && dataPlatform ? (
          <>
            <Form method="POST">
              <input type="hidden" value={strategyTypeSelected} name="type" />
              <input
                type="hidden"
                value={
                  dataAggregate.gitRepositoryUrl
                    ? dataAggregate.gitRepositoryUrl
                    : undefined
                }
                name="aggregateGitRepositoryUrl"
              />
              <DialogTitle>Deploy your service code</DialogTitle>

              <DialogContent>
                <Column className="gap-4">
                  <div className="">
                    <Text>
                      Each service configuration can be used to generate code
                      which will be pushed directly into your repository.
                    </Text>

                    <Text>
                      Select one or many available AI blueprints below, and
                      enter your git credentials.
                    </Text>
                  </div>

                  <div className="flex gap-4">
                    <PreConfiguredStrategyTile
                      dataAggregate={dataAggregate}
                      initialData={initialData}
                      onTileClick={handleClickStrategySelected}
                      selectedStrategyType={strategyTypeSelected}
                      shouldDisableFields={shouldDisableFields}
                    />

                    <CustomAiBlueprintTile
                      blueprints={dataStrategies}
                      onTileClick={handleClickStrategySelected}
                      selectedStrategyType={strategyTypeSelected}
                      shouldDisableFields={shouldDisableFields}
                    />
                  </div>

                  {errors?.type ? (
                    <Column>
                      <Alert severity="error">
                        You need to select at least one option in a strategy
                      </Alert>
                    </Column>
                  ) : null}

                  <Column
                    className={twMerge(
                      'gap-2 px-4',
                      !shouldShowGitCredentials && '-mb-4',
                    )}
                  >
                    <Row className="gap-2">
                      <Text className="text-lg font-medium">
                        Git credentials
                      </Text>

                      <Button
                        onClick={handleClickToggleGitCredentials}
                        variant="outlined"
                        size="small"
                        disabled={shouldDisableFields}
                      >
                        {shouldShowGitCredentials ? 'Hide' : 'Edit'}
                      </Button>
                    </Row>

                    <Collapse in={shouldShowGitCredentials}>
                      <Column className="gap-2">
                        {errors && errors.gitConfigured === false ? (
                          <Alert severity="error">
                            An error ocurred while configuring your git
                            credentials. Please check the data and try again.
                          </Alert>
                        ) : null}

                        <TextField
                          label="Repository URL"
                          variant="outlined"
                          name="repositoryUrl"
                          disabled={shouldDisableFields}
                          defaultValue={
                            dataAggregate?.gitRepositoryUrl ||
                            dataPlatform?.gitRepositoryUrl
                          }
                          helperText={errors?.repositoryUrl}
                          error={!!errors?.repositoryUrl}
                          fullWidth
                          required
                        />

                        <TextField
                          label="Authentication token"
                          type="password"
                          variant="outlined"
                          name="token"
                          disabled={shouldDisableFields}
                          helperText={errors?.token}
                          error={!!errors?.token}
                          fullWidth
                        />

                        <Row className="gap-2">
                          <Button
                            name="intent"
                            value={'submit-git-credentials'}
                            variant="outlined"
                            size="small"
                            type="submit"
                            className="w-fit"
                            disabled={shouldDisableFields}
                            startIcon={
                              isLoading.git ? (
                                <CircularProgress size={16} />
                              ) : null
                            }
                          >
                            Configure git credentials
                          </Button>
                        </Row>
                      </Column>
                    </Collapse>
                  </Column>

                  <Column className="gap-2 px-4">
                    <Text className="text-lg font-medium">Git details</Text>

                    <Column className="gap-2">
                      <TextField
                        label="Commit message"
                        variant="outlined"
                        name="commitMessage"
                        disabled={shouldDisableFields}
                        defaultValue={
                          eventName
                            ? `Generate code for ${
                                eventType === 'read-model' ||
                                eventType === 'readmodel'
                                  ? 'query'
                                  : eventType
                              } ${eventName}`
                            : `Generate code for service ${dataAggregate?.name}`
                        }
                        helperText="Use the default message above or edit it"
                        fullWidth
                      />

                      <TextField
                        label="Branch"
                        variant="outlined"
                        name="branch"
                        disabled={shouldDisableFields}
                        helperText="Leave empty to use the default branch"
                        fullWidth
                      />
                    </Column>
                  </Column>

                  <Column className="gap-2 px-4">
                    <Text className="text-lg font-medium">
                      AI Pair Programmer
                    </Text>

                    <Text>
                      Ask your AI pair programmer to implement the business
                      logic for the scaffolded code.
                    </Text>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={enableAIAssistantValue}
                          name="enableAIAssistant"
                          onChange={handleEnableAIAssistantChange}
                        />
                      }
                      label="Enable AI pair programmer"
                      value={enableAIAssistantValue}
                    />

                    <Collapse in={enableAIAssistantValue}>
                      <Alert className="mb-4" severity="info">
                        Enter a branch name above (in the "Git details") to
                        create a new branch to allow you to pair safely before
                        merging your changes.
                      </Alert>
                      <TextareaAutosize
                        minRows={3}
                        placeholder="Provide the AI pair programmer instructions"
                        name="aiContext"
                        disabled={shouldDisableFields}
                        className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </Collapse>
                  </Column>
                </Column>

                {errors && errors.apiError ? (
                  <Column>
                    <Alert severity="error">
                      There was an error trying to process your request. Please
                      try again later.
                    </Alert>
                  </Column>
                ) : null}
              </DialogContent>

              <DialogActions>
                <Button
                  variant="outlined"
                  disabled={shouldDisableFields}
                  onClick={handleClickClose}
                >
                  Cancel
                </Button>

                <Button
                  name="intent"
                  value="submit-full"
                  variant="contained"
                  type="submit"
                  disabled={shouldDisableFields}
                  startIcon={
                    isLoading.full ? <CircularProgress size={16} /> : null
                  }
                >
                  Generate Code
                </Button>
              </DialogActions>
            </Form>
          </>
        ) : (
          <>
            <DialogTitle>Deploy your service code</DialogTitle>

            <DialogContent>
              <CircularProgress />
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  )
}
