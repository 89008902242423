/**
 * Enum with the form fields names
 * from `delete property` form.
 */
export enum DeletePropertyFormEnum {
  /** The file ID field. */
  FILE_ID = 'fileId',
  /** The property binding ID field. */
  PROPERTY_BINDING_ID = 'propertyBindingId',
}
