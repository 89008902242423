import { Platform } from '~/services/PlatformTeam.types'

export const b2cPolicies = {
  names: {
    signUpSignIn: 'b2c_1_susi',
    forgotPassword: 'b2c_1_reset',
    editProfile: 'b2c_1_edit_profile',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://dotstar.b2clogin.com/dotstar.onmicrosoft.com/b2c_1_susi',
    },
    forgotPassword: {
      authority:
        'https://dotstar.b2clogin.com/dotstar.onmicrosoft.com/b2c_1_reset',
    },
    editProfile: {
      authority:
        'https://dotstar.b2clogin.com/dotstar.onmicrosoft.com/b2c_1_edit_profile',
    },
  },
  authorityDomain: 'dotstar.b2clogin.com',
}

export const constants = {
  msal: {
    clientId: '245167f1-4d0c-43e4-9a4a-14d0d5242bdb',
    authority:
      'https://dotstar.b2clogin.com/dotstar.onmicrosoft.com/B2C_1_SUSI',
    scopes: [
      'https://dotstar.onmicrosoft.com/dotstar-spa-backend-services/access',
    ],
    redirectUri: window.location.origin,
  },
  axios: {
    baseURL: 'https://func-azureadb2c-test.azurewebsites.net/api',
  },
  API: {
    DISCOVERYORGANISATION: 'https://func-discoveryorganisation-ci3.azurewebsites.net/api',
    PLATFORMTEAM: 'https://func-platformteamcoreplatform-ci3.azurewebsites.net/api',
    DEVELOPMENTAGGREGATE: 'https://func-developmentfeaturedevelopment-ci3-1.azurewebsites.net/api',
    DISCOVERYPROCESS: 'https://func-discoveryprocess-ci3.azurewebsites.net/api',
    GENERATIONSTRATEGY: 'https://func-platformteamgenerationstrategy-ci3.azurewebsites.net/api',
  },
}

export const platforms: Platform[] = [
  {
    identity: 'azure',
    name: 'Microsoft Azure',
    disabled: false,
    routingStrategies: [
      {
        identity: 'azure-servicebus',
        name: 'Azure Service Bus',
      },
    ],
    frontend: {
      hostingStrategies: [
        {
          identity: 'azure-staticwebsite',
          name: 'Azure Static Website',
        },
      ],
    },
    services: {
      hostingStrategies: [
        {
          identity: 'azure-functions',
          name: 'Azure Functions',
        },
        {
          identity: 'spring-boot',
          name: 'Spring Boot',
        },
      ],
      persistenceStrategies: [
        {
          identity: 'cosmos-db',
          name: 'Cosmos DB',
        },
      ],
    },
  },
  {
    identity: 'aws',
    name: 'Amazon Web Services',
    disabled: false,
    routingStrategies: [
      {
        identity: 'aws-eventbridge',
        name: 'Amazon EventBridge',
      },
    ],
    frontend: {
      hostingStrategies: [
        {
          identity: 'aws-s3cloudfront',
          name: 'Amazon S3 + CloudFront',
        },
      ],
    },
    services: {
      hostingStrategies: [
        {
          identity: 'aws-serverless',
          name: 'AWS Lambda',
        },
      ],
      persistenceStrategies: [
        {
          identity: 'aws-aurora-postgres',
          name: 'Aurora PostgreSQL',
        },
        {
          identity: 'aws-rds-sql-server',
          name: 'RDS for SQL Server',
        },
        {
          identity: 'aws-dynamodb',
          name: 'DynamoDB',
        },
      ],
    },
  },
  {
    identity: 'gcp',
    name: 'Google Cloud Platform (soon)',
    disabled: true,
    routingStrategies: [
      {
        identity: 'gcp-routing',
        name: 'Google Routing',
      },
    ],
    frontend: {
      hostingStrategies: [
        {
          identity: 'gcp-hosting',
          name: 'Google Hosting',
        },
      ],
    },
    services: {
      hostingStrategies: [
        {
          identity: 'gcp-functions',
          name: 'Google Functions',
        },
      ],
      persistenceStrategies: [
        {
          identity: 'gcp-persistence',
          name: 'Google Persistence',
        },
      ],
    },
  },
]
