import EditIcon from '@mui/icons-material/Edit'
import EditOffIcon from '@mui/icons-material/EditOff'
import ReplayIcon from '@mui/icons-material/Replay'
import MarkedAsFeatureIcon from '~/assets/icons/star.svg?react'
import type { NodeStatement } from '~/services/Process.types'

type SideMenuUtilsProps = {
  /** Error from `mark as feature` mutation. */
  error?: {}
  /**
   * Indicates if the `mark as feature` is being processed:
   * Mutation in progress or background processing.
   */
  isProcessing?: boolean
  /** Indicates is it is already marked as feature. */
  markedAsFeature?: boolean
}

/**
 * Provides the tooltip text for the
 * `mark as feature` button.
 */
export const getMarkAsFeatureTooltipText = ({
  error,
  isProcessing,
  markedAsFeature,
}: SideMenuUtilsProps) => {
  if (isProcessing) return 'Processing.'

  if (!!error) return 'Retry mark the step as feature.'

  if (markedAsFeature) return 'Marked as feature.'

  return 'Mark this step as a feature to be able to generate code.'
}

/**
 * Provides the icon component for the
 * `mark as feature` button.
 */
export const getMarkAsFeatureIcon = ({
  error,
  isProcessing,
  markedAsFeature,
}: SideMenuUtilsProps) => {
  if (isProcessing) return null

  if (!!error) return <ReplayIcon />

  if (markedAsFeature)
    return <MarkedAsFeatureIcon className="fill-primary stroke-primary" />

  return <MarkedAsFeatureIcon className="fill-secondary-200 stroke-primary" />
}

/**
 * Provides the tooltip text for the
 * `edit statement` button.
 */
export const getEditTooltipText = ({
  isEditable,
  isFetching,
}: Pick<NodeStatement, 'isEditable' | 'isFetching'>) => {
  if (isFetching) return 'Edit in progress.'

  if (isEditable) return 'Stop editing.'

  return 'Start editing.'
}

/**
 * Provides the icon component for the
 * `edit statement` button.
 */
export const getEditTooltipIcon = ({
  isEditable,
}: Pick<NodeStatement, 'isEditable'>) => {
  if (isEditable) return <EditOffIcon />

  return <EditIcon />
}
