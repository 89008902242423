import CheckIcon from '@mui/icons-material/Check'
import { Button } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Column } from '~/components/Column'
import { Text } from '~/components/Text'
import { PreConfiguredStrategyTileProps } from '~/models/types/components/generateCode/PreConfiguredStrategyTileProps'
import { AvailableHostingStrategies } from './AvailableHostingStrategies'
import { PersistenceStrategies } from './PersistenceStrategies'

/**
 * The `pre configured strategy tile`
 * from the generate code modal.
 */
export const PreConfiguredStrategyTile = (
  props: PreConfiguredStrategyTileProps,
) => {
  const {
    dataAggregate,
    initialData,
    onTileClick,
    selectedStrategyType,
    shouldDisableFields,
  } = props

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { aggregateId, organisationId, platformId, processId } = useParams()

  // Check if a platform is already setup.
  const isPlatformSubmitted = initialData?.platform?.data?.isSubmitted

  // Handler for the `platform setup` click.
  const handlePlatformSetupClick = () => {
    const isBusiness = pathname.includes('business')

    let navigateTo = `/${organisationId}/${platformId}/developer/${aggregateId}/platform-setup`

    if (isBusiness)
      navigateTo = `/${organisationId}/${platformId}/business/${processId}/${aggregateId}/platform-setup`

    navigate(navigateTo)
  }

  return (
    <Column
      className={twMerge(
        'flex-1 gap-2 rounded-lg border-2 border-solid border-slate-200 p-4',
        selectedStrategyType === 'regular' && 'border-primary-300',
      )}
      onClick={onTileClick}
      data-type="regular"
    >
      <div
        className={twMerge(
          '-mx-4 -mt-4 flex cursor-pointer justify-between gap-2 rounded-lg rounded-b-none bg-tertiary-50 px-4 py-2',
          selectedStrategyType === 'regular' && 'bg-primary-50',
        )}
      >
        <Text>Pre-configured Strategy</Text>

        {selectedStrategyType === 'regular' ? (
          <CheckIcon className="text-primary" />
        ) : (
          <CheckIcon className="invisible text-primary" />
        )}
      </div>

      {!isPlatformSubmitted && (
        <>
          <Text>
            There are no pre-configured platforms, please click the following
            button to start the setup:
          </Text>

          <Button variant="outlined" onClick={handlePlatformSetupClick}>
            Platform Setup
          </Button>
        </>
      )}

      {isPlatformSubmitted && (
        <>
          <AvailableHostingStrategies
            dataAggregate={dataAggregate}
            initialData={initialData}
            selectedStrategyType={selectedStrategyType}
            shouldDisableFields={shouldDisableFields}
          />

          <PersistenceStrategies
            dataAggregate={dataAggregate}
            initialData={initialData}
            selectedStrategyType={selectedStrategyType}
            shouldDisableFields={shouldDisableFields}
          />
        </>
      )}
    </Column>
  )
}
