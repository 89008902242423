import type { PopperProps } from '@mui/material'
import { createContext, type Dispatch, type SetStateAction } from 'react'

export type AppPopperContextProps = {
  /**
   * Closes the popper: only sets `open` prop to false,
   * without changing any other component prop.
   * */
  closeAppPopper?: () => void
  /** The props that will compose the popper. */
  componentProps: Partial<PopperProps>
  /** Opens the popper with the provided props. */
  openAppPopper?: (componentProps: PopperProps) => void
  /** The set state for `props`. */
  setComponentProps?: Dispatch<SetStateAction<PopperProps>>
}

const defaultValue = {
  componentProps: { open: false },
}

/**
 * Context for the App Popper.
 */
const AppPopperContext = createContext<AppPopperContextProps>(defaultValue)

export default AppPopperContext
