import React, { useRef } from 'react'
import { useMatches } from 'react-router-dom'
import { ActionIcon } from '~/components/Icons/ActionIcon'
import { ReactionIcon } from '~/components/Icons/ReactionIcon'

export function useModalTitleByLocation() {
  const matches = useMatches()
  const [modalTitle, setModalTitle] = React.useState('Adding Service')
  const modalTitleIcon = useRef<JSX.Element | null>(null)

  // Changes outlet title according to route
  React.useEffect(() => {
    const matchesAddCommand = matches.some((match) =>
      match.pathname.includes('add-command'),
    )
    const matchesAddReaction = matches.some((match) =>
      match.pathname.includes('add-reaction'),
    )
    const matchesEditCommand = matches.some((match) =>
      match.pathname.includes('edit-command'),
    )
    const matchesEditReaction = matches.some((match) =>
      match.pathname.includes('edit-reaction'),
    )
    const matchesDeleteCommand = matches.some((match) =>
      match.pathname.includes('delete-command'),
    )
    const matchesDeleteReaction = matches.some((match) =>
      match.pathname.includes('delete-reaction'),
    )
    const matchesConfirm = matches.some((match) =>
      match.pathname.includes('confirm'),
    )
    const matchesPublishCommand = matches.some((match) =>
      match.pathname.includes('publish-command'),
    )
    const matchesPublishReaction = matches.some((match) =>
      match.pathname.includes('publish-reaction'),
    )
    const matchedEditDetails = matches.some((match) =>
      match.pathname.includes('edit-details'),
    )
    const matchedEditHosting = matches.some((match) =>
      match.pathname.includes('edit-hosting'),
    )
    const matchedEditPersistence = matches.some((match) =>
      match.pathname.includes('edit-persistence'),
    )
    const matchedEditGitCredentials = matches.some((match) =>
      match.pathname.includes('edit-git-credentials'),
    )
    const matchedPublishAll = matches.some((match) =>
      match.pathname.includes('publish-all'),
    )
    const matchedAddReadModel = matches.some((match) =>
      match.pathname.includes('add-readmodel'),
    )
    const matchesDeleteReadModel = matches.some((match) =>
      match.pathname.includes('delete-readmodel'),
    )
    const matchesEditReadModel = matches.some((match) =>
      match.pathname.includes('edit-readmodel'),
    )
    const matchesEditCodeGenerationStrategies = matches.some((match) =>
      match.pathname.includes('edit-code-generation-strategies'),
    )

    if (matchesAddCommand) {
      setModalTitle('Command')
      modalTitleIcon.current = <ActionIcon />
      return
    }
    if (matchesAddReaction) {
      setModalTitle('Reaction')
      modalTitleIcon.current = <ReactionIcon />
      return
    }
    if (matchesEditCommand) {
      setModalTitle('Editing Command')
      modalTitleIcon.current = <ActionIcon />
      return
    }
    if (matchesEditReaction) {
      setModalTitle('Editing Reaction')
      modalTitleIcon.current = <ReactionIcon />
      return
    }
    if (matchesDeleteCommand) {
      setModalTitle('Deleting Command')
      modalTitleIcon.current = null
      return
    }
    if (matchesDeleteReaction) {
      setModalTitle('Deleting Reaction')
      modalTitleIcon.current = null
      return
    }
    if (matchesConfirm) {
      setModalTitle('Initialising Service')
      modalTitleIcon.current = null
      return
    }
    if (matchesPublishCommand) {
      setModalTitle('Publishing Command')
      modalTitleIcon.current = null
      return
    }
    if (matchesPublishReaction) {
      setModalTitle('Publishing Reaction')
      modalTitleIcon.current = null
      return
    }
    if (matchedEditDetails) {
      setModalTitle('Editing Service Details')
      modalTitleIcon.current = null
      return
    }
    if (matchedEditHosting) {
      setModalTitle('Editing Service Hosting')
      modalTitleIcon.current = null
      return
    }
    if (matchedEditPersistence) {
      setModalTitle('Editing Service Persistence')
      modalTitleIcon.current = null
      return
    }
    if (matchedEditGitCredentials) {
      setModalTitle('Editing Service Git Credentials')
      modalTitleIcon.current = null
      return
    }
    if (matchedPublishAll) {
      setModalTitle('Publishing All')
      modalTitleIcon.current = null
      return
    }
    if (matchedAddReadModel) {
      setModalTitle('Add query')
      modalTitleIcon.current = null
      return
    }
    if (matchesDeleteReadModel) {
      setModalTitle('Delete query')
      modalTitleIcon.current = null
      return
    }
    if (matchesEditReadModel) {
      setModalTitle('Edit query')
      modalTitleIcon.current = null
      return
    }
    if (matchesEditCodeGenerationStrategies) {
      setModalTitle('Edit AI Blueprints')
      modalTitleIcon.current = null
      return
    }

    setModalTitle('Adding Service')
    modalTitleIcon.current = null
    return
  }, [matches])

  return { modalTitle, modalTitleIcon: modalTitleIcon.current }
}
