import type { Viewport } from '@xyflow/react'
import { useState, type PropsWithChildren } from 'react'
import AppPopper from '~/components/AppPopper/AppPopper'
import ReactFlowStatesContext, {
  type ReactFlowState,
} from '~/contexts/ReactFlowStatesContext'

/**
 * Provider for the ReactFlow States context.
 */
const ReactFlowStatesContextProvider = (props: PropsWithChildren) => {
  const { children } = props

  const [serviceMapViewport, setServiceMapViewport] = useState<
    Viewport | undefined
  >()

  const [statementsDiagramViewport, setStatementsDiagramViewport] = useState<
    ReactFlowState | undefined
  >()

  return (
    <ReactFlowStatesContext.Provider
      value={{
        serviceMapViewport,
        setServiceMapViewport,
        statementsDiagramViewport,
        setStatementsDiagramViewport,
      }}
    >
      {children}

      <AppPopper />
    </ReactFlowStatesContext.Provider>
  )
}

export default ReactFlowStatesContextProvider
