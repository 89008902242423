export function EyeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#B6F1E6"
        d="M12.5 7C8.9 7 4 10.333 2 12c0 .5 6.5 5 10 5 2.8 0 7.5-3.333 9.5-5 0 0-4.5-5-9-5Z"
      />
      <circle
        cx={3}
        cy={3}
        r={3}
        fill="#080D97"
        transform="matrix(1 0 0 -1 9 15)"
      />
      <circle
        cx={1}
        cy={1}
        r={1}
        fill="#080D97"
        transform="matrix(1 0 0 -1 11 13)"
      />
      <path
        stroke="#080D97"
        strokeLinejoin="round"
        d="M2 12c9-7 11.2-7.2 20 0M22 12c-9 7-11.2 7.2-20 0"
      />
    </svg>
  )
}
