import {
  gridClasses,
  type GridCellParams,
  type GridRowClassNameParams,
} from '@mui/x-data-grid'

const DEPTH_PADDING_FACTOR = 10

export const dataGridStyles = {
  [`& .${gridClasses.cell}:not([data-field="name"]):focus, & .${gridClasses.cell}:not([data-field="name"]):focus-within`]:
    {
      outline: 'none',
    },
  [`& .${gridClasses.columnHeader}:not([data-field="name"]):focus, & .${gridClasses.columnHeader}:not([data-field="name"]):focus-within`]:
    {
      outline: 'none',
    },
  [`& .${gridClasses.columnHeader}, .MuiDataGrid-cell:not(:last-child)`]: {
    borderRight: `1px solid #f0f0f0`,
  },
  ['& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell:not(:last-child)']: {
    borderBottom: `1px solid #f0f0f0`,
  },

  // Cell from a header row.
  [`& .header .depth-1`]: {
    paddingLeft: `${1 * DEPTH_PADDING_FACTOR}px`,
  },
  [`& .header .depth-2`]: {
    paddingLeft: `${2 * DEPTH_PADDING_FACTOR}px`,
  },
  [`& .header .depth-3`]: {
    paddingLeft: `${3 * DEPTH_PADDING_FACTOR}px`,
  },
  [`& .header .depth-4`]: {
    paddingLeft: `${4 * DEPTH_PADDING_FACTOR}px`,
  },
  [`& .header .depth-5`]: {
    paddingLeft: `${5 * DEPTH_PADDING_FACTOR}px`,
  },
}

/**
 * Sets css classes for a header row.
 * @param params Grid row class name params.
 */
export const handleRowClassName = (params: GridRowClassNameParams) => {
  const {
    row: { isGrouping },
  } = params

  if (isGrouping) return 'header bg-slate-500 font-bold text-white'

  return ''
}

/**
 * Sets css classes for a header row cell.
 * @param params Grid cell params.
 */
export const handleCellClassName = (params: GridCellParams) => {
  const {
    row: { depth, isGrouping },
  } = params

  if (isGrouping) return `depth-${depth} border-slate-500`

  return ''
}
