import { InsertDriveFile as FileIcon } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Popper,
  Slide,
  Tooltip,
  Typography,
  debounce,
} from '@mui/material'
import {
  Background,
  BackgroundVariant,
  MiniMap,
  Panel,
  useEdgesState,
  useNodesState,
  useReactFlow,
  type Edge,
  type Node,
  type ReactFlowProps,
  type Viewport,
} from '@xyflow/react'
import tailwindConfig from '^/tailwind.config'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import resolveConfig from 'tailwindcss/resolveConfig'
import { useReactFlowStatesContext } from '~/hooks/contexts/useReactFlowStatesContext'
import { useGetProcessFiles } from '~/hooks/useGetProcessFiles'
import type { StatementsDiagramBaseProps } from '~/models/types/components/processInfo/StatementsDiagramBaseProps'
import GroupNode from '~/pages/business/components/ProcessInfo/StatementsDiagram/CustomNodes/GroupNode'
import type { NodeStatement } from '~/services/Process.types'
import { getLayoutedNodesAndEdgesFromStatements } from '../utils'
import { CustomEdge } from './CustomEdge'
import { CollapsedStep } from './CustomNodes/CollapsedStep'
import { ExpandedStep } from './CustomNodes/ExpandedStep'
import { NodeTypesEnum } from './CustomNodes/types'
import { FileProcessingStatus, FileStatus } from './FileProcessingStatus'
import {
  ReactFlowControl,
  type ReactFlowControlProps,
} from './ReactFlowControl/ReactFlowControl'
import { Controls, ReactFlow } from './StatementsDiagram.styles'
import { useStatementsDiagram } from './useStatementsDiagram'
import {
  NODES_TRANSITION_DURATION_ON_SELECT,
  useStatementsDiagramReactFlow,
} from './useStatementsDiagramReactFlow'

// Nodes and Edges.
const NODE_TYPES = {
  [NodeTypesEnum.COLLAPSED_STEP]: CollapsedStep,
  [NodeTypesEnum.EXPANDED_STEP]: ExpandedStep,
  [NodeTypesEnum.GROUP]: GroupNode,
}

const EDGE_TYPES = {
  customEdge: CustomEdge,
}

// Resolve the Tailwind config to get access to the theme.
const fullConfig = resolveConfig(tailwindConfig)

export type StatementsDiagramProps = StatementsDiagramBaseProps &
  Pick<ReactFlowControlProps, 'onToggleExpandClick'> &
  Pick<ReactFlowProps, 'onNodeClick' | 'onNodeDoubleClick'>

export type StatementsDiagramHandle = {
  /**
   * Handles the react flow fit bounds to centralize the provided node.
   * @node The node to be fit to screen.
   * @zoom The zoom level when fitting to screen.
   */
  handleNodeFit: (node: Node<NodeStatement>, zoom?: number) => void
}

export const StatementsDiagram = forwardRef<
  StatementsDiagramHandle,
  StatementsDiagramProps
>((props: StatementsDiagramProps, ref) => {
  const {
    isEditingMode,
    isExpanded = false,
    isGeneratingProcess,
    onAddLocalChild,
    onAddLocalNextSibling,
    onAddLocalParent,
    onAddLocalPreviousSibling,
    onAddReaction,
    onDropNodeIntoDropAreas,
    onNodeClick,
    onNodeDoubleClick,
    onToggleExpandClick,
    selectedStatements,
    setIsEditingMode,
    statementInputRef,
    statements,
    updateStatement,
  } = props

  // Hooks.
  const { processId = '' } = useParams()

  const { statementsDiagramViewport, setStatementsDiagramViewport } =
    useReactFlowStatesContext()
  const currentViewport = statementsDiagramViewport?.[processId]

  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([])
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([])

  const { fitView, setViewport } = useReactFlow()

  const { handleConnect } = useStatementsDiagram({
    nodes,
    updateStatement,
  })

  const {
    handleNodeFit,
    handleReactFlowDragEnd,
    handleReactFlowDragStart,
    isReactFlowDragging,
    isReactFlowSpacePressed,
  } = useStatementsDiagramReactFlow({ isExpanded, nodes })

  // Ref.
  const flowParentContainer = useRef<HTMLDivElement>(null)
  const flowParentContainerHeight =
    flowParentContainer?.current?.getBoundingClientRect().height

  // Debounce: set nodes and edges.
  // This is to avoid multiple calls to set nodes and edges.
  const debouncedSetNodesAndEdges = useCallback(
    debounce((nodes: Node[], edges: Edge[]) => {
      setNodes(nodes)
      setEdges(edges)
    }, 100),
    [],
  )

  // Lifecycle.
  useEffect(() => {
    const { nodes: receivedNodes, edges: receivedEdges } =
      getLayoutedNodesAndEdgesFromStatements({
        isEditingMode,
        isExpanded,
        isGeneratingProcess,
        onAddLocalChild,
        onAddLocalNextSibling,
        onAddLocalParent,
        onAddLocalPreviousSibling,
        onAddReaction,
        onDropNodeIntoDropAreas,
        selectedStatements,
        setIsEditingMode,
        statementInputRef,
        statements: statements || [],
        updateStatement,
      })

    debouncedSetNodesAndEdges(receivedNodes, receivedEdges)
  }, [
    debouncedSetNodesAndEdges,
    isEditingMode,
    isExpanded,
    isGeneratingProcess,
    onAddLocalChild,
    onAddLocalNextSibling,
    onAddLocalParent,
    onAddLocalPreviousSibling,
    onAddReaction,
    onDropNodeIntoDropAreas,
    statements,
    updateStatement,
  ])

  // Lifecycle to re-position viewport when the process id changes.
  useEffect(() => {
    if (!!processId)
      setTimeout(() => {
        // If there is a viewport.
        if (!!currentViewport) {
          setViewport(currentViewport as Viewport, {
            duration: NODES_TRANSITION_DURATION_ON_SELECT,
          })
        } else {
          fitView({ duration: NODES_TRANSITION_DURATION_ON_SELECT })
        }
      }, NODES_TRANSITION_DURATION_ON_SELECT)
  }, [processId])

  // Use imperative to have access to it from the parent component.
  useImperativeHandle(ref, () => ({
    // Fits the node to viewport (focus on it).
    handleNodeFit,
  }))

  // Methods.
  // Handler for viewport move.
  const handleMove = (_: MouseEvent | TouchEvent | null, data: Viewport) => {
    if (!!processId)
      setStatementsDiagramViewport?.((prevState) => ({
        ...prevState,
        [processId]: data,
      }))
  }
  // Debounce due to multiple calls when moving de viewport.
  const debouncedMove = debounce(handleMove, 300)

  const [isFileStatusPanelOpen, setIsFileStatusPanelOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const {
    data: processFiles,
    error,
    invalidateQuery,
    isLoading,
  } = useGetProcessFiles()

  // Use this single toggle function for the file status panel
  const toggleFileStatusPanel = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
      setIsFileStatusPanelOpen((prev) => !prev)
    },
    [],
  )

  const fileStatus: FileStatus[] =
    processFiles?.files.map(
      (file: { id: string; relativePath: string; status: any }) => ({
        id: file.id,
        status: file.status,
        relativePath: file.relativePath,
      }),
    ) || []

  const handleRefresh = useCallback(() => {
    invalidateQuery().then((r) => {})
  }, [invalidateQuery])

  return (
    <div
      ref={flowParentContainer}
      className="flex flex-1 bg-background-business"
    >
      <div
        data-height={flowParentContainerHeight}
        className={`w-full h-[${flowParentContainerHeight}px]`}
      >
        {!!flowParentContainerHeight && (
          <ReactFlow
            connectionLineStyle={{
              stroke: fullConfig.theme.colors.event.DEFAULT,
              strokeWidth: '2px',
            }}
            defaultViewport={currentViewport}
            deleteKeyCode={null}
            edges={edges}
            edgesFocusable={false}
            edgeTypes={EDGE_TYPES}
            elevateEdgesOnSelect={false}
            fitView={!currentViewport}
            nodes={nodes}
            nodesDraggable={false}
            nodeTypes={NODE_TYPES}
            onConnect={handleConnect}
            onDragEnd={handleReactFlowDragEnd}
            onDragStart={handleReactFlowDragStart}
            onEdgesChange={onEdgesChange}
            onMove={debouncedMove}
            onNodesChange={onNodesChange}
            onNodeClick={onNodeClick}
            onNodeDoubleClick={onNodeDoubleClick}
            panOnDrag={[1, 2]}
            zoomOnDoubleClick={false}
            $isDragging={isReactFlowDragging}
            $isSpacePressed={isReactFlowSpacePressed}
          >
            <Background color="#9FA8DA" variant={BackgroundVariant.Dots} />

            <Controls>
              {!!statements?.length && (
                <ReactFlowControl
                  isExpanded={isExpanded}
                  onToggleExpandClick={onToggleExpandClick}
                />
              )}
            </Controls>
            <MiniMap
              nodeStrokeWidth={3}
              pannable={true}
              zoomable={true}
              zoomStep={1}
            />
            <Panel
              position="top-right"
              className="z-20 rounded-lg bg-background-paper p-1 shadow-2"
            >
              <Tooltip title="Files">
                <IconButton
                  onClick={toggleFileStatusPanel}
                  data-is-opened={isFileStatusPanelOpen}
                  className="data-[is-opened=true]:bg-primary-300 data-[is-opened=true]:bg-opacity-40"
                >
                  <FileIcon />
                </IconButton>
              </Tooltip>

              <Popper
                open={isFileStatusPanelOpen}
                anchorEl={anchorEl}
                transition
                disablePortal
                placement="left-start"
                sx={{
                  zIndex: 10,
                  height: 'calc(100vh - 100px)', // Adjust this value as needed
                  maxWidth: '300px', // Adjust as needed
                }}
              >
                {({ TransitionProps }) => (
                  <Slide {...TransitionProps} direction="left">
                    <Paper
                      sx={{
                        height: '100%',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ flexGrow: 1, overflow: 'auto', p: 0 }}>
                        {isLoading ? (
                          <CircularProgress />
                        ) : error ? (
                          <Typography color="error">
                            Error loading file status:{' '}
                            {(error as Error).message}
                          </Typography>
                        ) : fileStatus.length === 0 ? (
                          <Typography>No file status available</Typography>
                        ) : (
                          <FileProcessingStatus
                            files={fileStatus}
                            onRefresh={handleRefresh}
                            onOpenFileLocation={(file) => console.log(file)}
                          />
                        )}
                      </Box>
                    </Paper>
                  </Slide>
                )}
              </Popper>
            </Panel>
          </ReactFlow>
        )}
      </div>
    </div>
  )
})
