import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import { getPlatform, getUrlWithoutSetup } from '~/routes/setup/platform/utils'
import { queryKeyPlatformTeam } from '~/services/PlatformTeam'
import { PlatformPostResponse } from '~/services/PlatformTeam.types'
import { PlatformTeamAPI, servicePlatformTeam } from '~/services/base'
import { getOrganisation } from '~/utils/getOrganisation'

export const actionPlatformCloud =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params, context }: ActionFunctionArgs) => {
    const {
      data: { identity: organisationId },
    } = await getOrganisation(queryClient, pca)
    if (!organisationId) {
      return redirect('/welcome/step1')
    }

    await queryClient.invalidateQueries({
      queryKey: queryKeyPlatformTeam(organisationId),
    })

    const {
      data: { identity: platformId },
    } = await getPlatform(queryClient, organisationId)

    // Get form data
    const formData = await request.formData()
    const cloudPlatformName = formData.get('cloudPlatformName')

    // Builds the data to send to server
    const postData = {
      platformId,
      cloudPlatformName,
    }

    // Setups and send to server
    const url = PlatformTeamAPI.SelectCloudPlatform
    const req = (await servicePlatformTeam.post(
      url,
      postData,
    )) as AxiosResponse<PlatformPostResponse, unknown>

    // If it succeeed, just redirect to the next step
    if (req.status === 200) {
      await queryClient.invalidateQueries({
        queryKey: queryKeyPlatformTeam(organisationId),
      })

      const search = new URL(request.url).search
      const splitUrl = getUrlWithoutSetup(request.url)
      return redirect(
        `${splitUrl}/platform-setup/routing${search ? search : ''}`,
      )
    }

    // Else, redirect to "home"
    return redirect('/')
  }
