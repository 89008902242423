import {
  Box,
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import { ReactFlowProvider } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import type { AxiosResponse } from 'axios'
import {
  Outlet,
  generatePath,
  useLoaderData,
  useNavigate,
  useOutlet,
} from 'react-router-dom'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'
import { useModalTitleByLocation } from '~/hooks/useModalTitleByLocation'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import { useBizDevResources } from '~/routes/business-developer/biz-dev-utils/useBizDevResources'
import {
  queryDevelopment,
  queryDevelopmentDomainsForCommands,
} from '~/services/Development'
import type {
  DomainByPlatformId,
  DomainForCommand,
} from '~/services/Development.types'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import type { PlatformByOrganisation } from '~/services/PlatformTeam.types'
import { AggregateInfo } from './components/AggregateInfo'
import { ServiceMap } from './components/ServiceMap'

export function Developer() {
  // React Router Dom.
  // cannot use typeof loader below because the loader redirects
  // and that causes its return to also be a Response, which we don't cover here
  const initialData = useLoaderData() as {
    development: Awaited<AxiosResponse<DomainByPlatformId | null, unknown>>
    platform: Awaited<AxiosResponse<PlatformByOrganisation | null, unknown>>
    organisationId: string
    domains: Awaited<AxiosResponse<DomainForCommand | null, unknown>>
  }

  const navigate = useNavigate()
  const outlet = useOutlet()

  // Base resources.
  const {
    // Pathnames.
    isCommandPage,
    isCommitsHistoryPage,
    isEditPage,
    isPlatformSetup,
    isReactionPage,
    isReadModelPage,
    // URL params.
    aggregateId,
    boundedContext,
    commandId,
    organisationId,
    platformId,
    reactionId,
    readModelId,
    // Constants.
    dataId,
    dataType,
    eventType,
  } = useBizDevResources()

  // React Query.
  const { data: dataDevelopment } = useQuery({
    ...queryDevelopment(platformId),
    initialData: initialData.development,
    select: (response) => response.data,
  })

  const { data: dataPlatform } = useQuery({
    ...queryPlatformTeam(initialData.organisationId),
    initialData: initialData.platform,
    select: (response) => response.data,
  })

  const { data: dataDomains } = useQuery({
    ...queryDevelopmentDomainsForCommands(platformId),
    initialData: initialData.domains,
    select: (response) => response.data,
  })

  // Hooks.
  const { modalTitle, modalTitleIcon } = useModalTitleByLocation()

  // Constants.
  const selectedBoundedContext =
    aggregateId !== 'add-service'
      ? dataDevelopment?.boundedContexts.filter((context) =>
          context.aggregates.find(
            (aggregate) => aggregate.identity === aggregateId,
          ),
        )
      : undefined

  const menuSelected =
    selectedBoundedContext && selectedBoundedContext[0]
      ? selectedBoundedContext[0].name
      : ''

  const selectedAggregate = selectedBoundedContext
    ? selectedBoundedContext[0]?.aggregates.filter(
        (aggregate) => aggregate.identity === aggregateId,
      )
    : undefined

  // Methods.
  function toggleDrawer() {
    if (aggregateId && !aggregateId.includes('service')) {
      navigate(
        generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
          aggregateId,
          boundedContext,
          organisationId,
          platformId,
        }),
      )
    } else {
      navigate(
        generatePath(DeveloperRoutesEnum.DEVELOPER_BOUNDED_CONTEXT, {
          boundedContext,
          organisationId,
          platformId,
        }),
      )
    }
  }

  function handleNavigate(event: EventFor<'div', 'onClick'>) {
    const dataset = event?.currentTarget.dataset
    if (dataset?.eventId) {
      if (dataset.eventType === 'query' || dataset.eventType === 'readModel')
        return navigate(
          generatePath(DeveloperRoutesEnum.EDIT_READ_MODEL, {
            aggregateId,
            boundedContext,
            organisationId,
            platformId,
            readModelId: dataset.eventId,
          }),
        )

      if (dataset.eventType === 'command')
        return navigate(
          generatePath(DeveloperRoutesEnum.EDIT_COMMAND, {
            aggregateId,
            boundedContext,
            commandId: dataset.eventId,
            organisationId,
            platformId,
          }),
        )

      if (dataset.eventType === 'reaction')
        return navigate(
          generatePath(DeveloperRoutesEnum.EDIT_REACTION, {
            aggregateId,
            boundedContext,
            organisationId,
            platformId,
            reactionId: dataset.eventId,
          }),
        )
    }
  }

  const handleNavigateToHistory = () => {
    if (eventType === 'commands')
      return navigate(
        generatePath(DeveloperRoutesEnum.EDIT_COMMAND_COMMITS_HISTORY, {
          aggregateId,
          boundedContext,
          commandId,
          organisationId,
          platformId,
        }),
      )

    if (eventType === 'reactions')
      return navigate(
        generatePath(DeveloperRoutesEnum.EDIT_REACTION_COMMITS_HISTORY, {
          aggregateId,
          boundedContext,
          organisationId,
          platformId,
          reactionId,
        }),
      )

    if (eventType === 'readModels')
      return navigate(
        generatePath(DeveloperRoutesEnum.EDIT_READ_MODEL_COMMITS_HISTORY, {
          aggregateId,
          boundedContext,
          organisationId,
          platformId,
          readModelId,
        }),
      )
  }

  return (
    <div className="relative flex flex-1 justify-center overflow-y-auto">
      {dataDevelopment?.boundedContexts?.length && (
        <ReactFlowProvider>
          <div className="flex flex-grow flex-col">
            <div className="flex-1 overflow-auto">
              <ServiceMap />
            </div>

            {selectedBoundedContext && selectedAggregate && (
              <Paper className="m-5 flex-1 overflow-auto p-0">
                {selectedAggregate.map((aggregate) => (
                  <AggregateInfo
                    aggregate={aggregate}
                    menuSelected={menuSelected}
                    key={aggregate.identity}
                    platformConfigured={dataPlatform}
                    domains={dataDomains}
                  />
                ))}
              </Paper>
            )}
          </div>
        </ReactFlowProvider>
      )}

      {isPlatformSetup ? (
        <Outlet />
      ) : isEditPage || isCommitsHistoryPage ? (
        <Drawer
          anchor="right"
          disablePortal
          onClose={toggleDrawer}
          open={!!outlet}
        >
          <Box className="flex h-full min-w-[550px] flex-col md:w-screen md:min-w-fit">
            <Stack
              alignItems={'center'}
              className="p-6 shadow-appBar"
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                {modalTitleIcon}

                <Typography variant="h6">{modalTitle}</Typography>
              </Stack>

              <Button variant="outlined" onClick={toggleDrawer}>
                Close
              </Button>
            </Stack>

            <Row className="flex-1 overflow-hidden">
              <div className="flex min-w-[260px] flex-col bg-white px-6 pb-4 pt-6 shadow-menuLeft">
                <Text className="text-xs uppercase text-slate-500">Action</Text>

                <List>
                  <ListItemButton
                    className="mb-1"
                    data-event-id={dataId}
                    data-event-type={dataType}
                    onClick={handleNavigate}
                    selected={
                      (isCommandPage || isReactionPage || isReadModelPage) &&
                      !isCommitsHistoryPage
                    }
                  >
                    <ListItemText primary="Edit action" />
                  </ListItemButton>

                  <ListItemButton
                    onClick={handleNavigateToHistory}
                    selected={isCommitsHistoryPage}
                  >
                    <ListItemText primary="History" />
                  </ListItemButton>
                </List>
              </div>

              <div className="min-w-[550px] max-w-[550px] overflow-y-scroll p-6">
                <Outlet />
              </div>
            </Row>
          </Box>
        </Drawer>
      ) : (
        <Drawer
          anchor="right"
          open={!!outlet}
          onClose={toggleDrawer}
          disablePortal
        >
          <Box p={3} className="min-w-[550px] md:w-screen md:min-w-fit">
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Stack direction={'row'} mb={2} alignItems={'center'} gap={1}>
                {modalTitleIcon}
                <Typography variant="h6">{modalTitle}</Typography>
              </Stack>

              <Button
                variant="outlined"
                onClick={toggleDrawer}
                className="mb-5"
              >
                Close
              </Button>
            </Stack>

            <Outlet />
          </Box>
        </Drawer>
      )}
    </div>
  )
}
