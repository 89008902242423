export function HelpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          stroke="#B6F1E6"
          strokeWidth={2}
          d="M13.658 13.487v3M13.658 18.487v2"
        />
        <path
          fill="#B6F1E6"
          fillRule="evenodd"
          d="M10.882 7.882a3.012 3.012 0 1 1 1.777 5.122v1.984h-.255a5.012 5.012 0 1 0-4.405-5h2.001c.006-.763.3-1.524.882-2.106Z"
          clipRule="evenodd"
        />
        <path stroke="#080D97" d="M12.5 13v3M12.5 18v2" />
        <path
          fill="#080D97"
          fillRule="evenodd"
          d="M10.029 7.029a3.512 3.512 0 1 1 2.13 5.978v1.004A4.512 4.512 0 1 0 8 9.488h1a3.5 3.5 0 0 1 1.029-2.46Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
