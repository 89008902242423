import { Dialog } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import { StripePricingTable } from '~/components/StripePricingTable'
import { Text } from '~/components/Text'
import { ButtonIconClose } from '../../ButtonIconClose'
import {
  SlideTransition,
  type SlideTransitionProps,
} from '../../SlideTransition'

type DialogUpgradePlanProps = {
  open: boolean
  onClose: Dispatch<SetStateAction<boolean>>
  currentPlan?: string
}

export function DialogUpgradePlan({
  open,
  onClose,
  currentPlan,
}: DialogUpgradePlanProps) {
  function handleClose() {
    onClose(false)
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      TransitionProps={{ direction: 'up' } as SlideTransitionProps}
      sx={{
        '.MuiPaper-root': {
          maxWidth: 'unset',
          width: '90%',
          // height: '90%',
          minHeight: '757px',
          borderRadius: '16px',
          position: 'relative',
        },
      }}
    >
      <div className="absolute right-2 top-2">
        <ButtonIconClose onClick={handleClose} />
      </div>

      <div className="mb-6 mt-12 text-center text-3xl">
        <Text>Upgrade your plan</Text>
        {currentPlan ? (
          <Text className="pt-2 text-xl">
            Current plan: <span className="font-medium">{currentPlan}</span>
          </Text>
        ) : null}
      </div>
      <div className="px-4 py-6">
        <StripePricingTable />
      </div>
    </Dialog>
  )
}
