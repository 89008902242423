import { Button, CircularProgress } from '@mui/material'
import { useNavigation } from 'react-router-dom'
import MagicBatIcon from '~/assets/icons/magic-bat.svg?react'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { BadgeUsesLeft } from '../BadgeUsesLeft'

type ButtonAutoGenerateProps = {
  name: string
}
export function ButtonAutoGenerate({ name }: ButtonAutoGenerateProps) {
  const buttonName = `${name}AutoGenerateAttributes`
  const navigation = useNavigation()
  const isLoading = navigation.formData
    ? !!Object.fromEntries(navigation.formData)?.[buttonName]
    : false

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        type="submit"
        startIcon={
          isLoading ? <CircularProgress size="1rem" /> : <MagicBatIcon />
        }
        endIcon={
          FEATURE_TOGGLE.UPGRADE_PLAN ? (
            <BadgeUsesLeft featureType="ATTRIBUTE_AUTO_GENERATION" />
          ) : null
        }
        sx={{ width: 'fit-content' }}
        disabled={isLoading || navigation.state !== 'idle'}
        name={buttonName}
        value="true"
      >
        Auto-generate
      </Button>
    </>
  )
}
