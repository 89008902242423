import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import React from 'react'
import { Form, useLoaderData, useParams } from 'react-router-dom'
import { Text } from '~/components/Text'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { queryDevelopment } from '~/services/Development'
import { DomainByPlatformId } from '~/services/Development.types'
import { queryStrategiesByOrganisationId } from '~/services/GenerationStrategy'
import { ResponseListGenerationStrategies } from '~/services/GenerationStrategy.types'
import { getAggregateById } from '~/utils/aggregate'

export function ConfigCodeGenerationStrategies() {
  const params = useParams()
  const initialData = useLoaderData() as {
    strategies: Awaited<
      AxiosResponse<ResponseListGenerationStrategies[] | null, unknown>
    >
    organisationId: GUID
    development: Awaited<AxiosResponse<DomainByPlatformId | null, unknown>>
  }
  const { data } = useQuery({
    ...queryStrategiesByOrganisationId(initialData.organisationId),
    initialData: initialData.strategies,
    select: (response) => response.data,
  })
  const { data: dataDevelopment } = useQuery({
    ...queryDevelopment(params.platformId),
    initialData: initialData.development,
    select: (response) => response.data,
  })

  const aggregateData = React.useMemo(() => {
    if (dataDevelopment && params.aggregateId) {
      const data = getAggregateById(
        dataDevelopment,
        params.aggregateId,
      )?.generationStrategies

      return data
    }
  }, [dataDevelopment, params.aggregateId])

  return (
    <>
      <Stack gap={2}>
        <Text className="text-xl">
          Please select all the <strong>AI blueprints</strong> for your service
        </Text>

        <Form method="post">
          <Paper sx={{ px: 2, py: 1 }}>
            <FormGroup>
              {data?.map((strategy) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="strategy"
                        value={strategy.identity}
                        defaultChecked={aggregateData?.some(
                          (aggregate) => aggregate.id === strategy.identity,
                        )}
                      />
                    }
                    label={
                      strategy.description
                        ? strategy.name + ' - ' + strategy.description
                        : strategy.name
                    }
                    key={strategy.identity}
                  />
                )
              })}
            </FormGroup>
          </Paper>

          <FormFooter />
        </Form>
      </Stack>
    </>
  )
}
