import { useEffect } from 'react'
import { useForm, type FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useDeleteProperty } from '~/hooks/api/codeGenerationStrategy/useDeleteProperty'
import { DeletePropertyFormEnum } from '~/models/enums/forms/DeletePropertyFormEnum'
import type { CodeGenerationStrategyDialogProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyDialogProps'
import type { CodeGenerationStrategyDialogHook } from '~/models/types/hooks/codeGenerationStrategy/CodeGenerationStrategyDialogHook'

/**
 * Hook to support the `DialogDeleteProperty` component.
 */
export const useDialogDeleteProperty = (
  props: CodeGenerationStrategyDialogProps,
): CodeGenerationStrategyDialogHook => {
  const { fileId, handleCloseDialog, isDialogOpen, propertyBinding } = props

  let { generationStrategyId } = useParams()

  // Form set up.
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FieldValues>({
    values: {
      [DeletePropertyFormEnum.FILE_ID]: fileId ?? '',
      [DeletePropertyFormEnum.PROPERTY_BINDING_ID]: propertyBinding.id ?? '',
    },
  })

  // Form submit mutation.
  const {
    error,
    isPending,
    isSuccess,
    mutate,
    reset: resetMutation,
  } = useDeleteProperty({
    generationStrategyId,
    fileId,
    propertyBindingId: propertyBinding.id,
  })

  // Reset form states when the dialog is closed.
  useEffect(() => {
    if (!isDialogOpen) {
      resetForm?.()
      resetMutation?.()
    }
  }, [isDialogOpen])

  // Close dialog when mutation is success.
  useEffect(() => {
    if (isSuccess) handleCloseDialog()
  }, [isSuccess])

  // Handles the form submit.
  const handleFormSubmit = () => {
    mutate()
  }

  // Handle the close dialog.
  const handleClose = () => {
    if (!isPending) handleCloseDialog()
  }

  return {
    control,
    error,
    handleClose,
    handleFormSubmit,
    handleSubmit,
    isPending,
  }
}
