import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import UndoIcon from '@mui/icons-material/Undo'
import { Alert, Button, TextField } from '@mui/material'
import { AxiosResponse } from 'axios'
import {
  Form,
  Link,
  useActionData,
  useNavigation,
  useRouteLoaderData,
} from 'react-router-dom'
import { ResponseDiscoveryOrganisation } from '~/services/Discovery.types'

export function ConfigurationOrganisationUsers() {
  const data = useRouteLoaderData('configuration-organisation-users') as {
    organisation: Awaited<
      AxiosResponse<ResponseDiscoveryOrganisation | null, unknown>['data']
    >
  }
  const errors = useActionData() as { error: string; key: string }[]
  const navigation = useNavigation()

  return (
    <>
      <Form className="flex gap-2" method="POST">
        {/* <TextField
        label="User Name"
        placeholder="User Name"
        name="name"
        variant="outlined"
        size="small"
      /> */}
        <TextField
          label="User E-mail"
          placeholder="User E-mail"
          name="email"
          variant="outlined"
          size="small"
          className="min-w-[300px]"
        />
        <input
          type="hidden"
          name="organisationId"
          value={data?.organisation?.identity}
        />
        <Button
          type="submit"
          variant="contained"
          size="small"
          disabled={navigation.state === 'submitting'}
          startIcon={<KeyboardReturnIcon />}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          size="small"
          component={Link}
          to="../"
          startIcon={<UndoIcon />}
        >
          Cancel
        </Button>
      </Form>
      {errors && errors.length && errors[0] ? (
        <div className="mt-4">
          <Alert severity="error">{errors[0].error}</Alert>
        </div>
      ) : null}
    </>
  )
}
