import { Box, debounce } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import {
  Controls,
  ReactFlow,
  useReactFlow,
  type Node,
  type Viewport,
} from '@xyflow/react'
import { AxiosResponse } from 'axios'
import dagre from 'dagre'
import { useEffect, useRef, type MouseEvent as ReactMouseEvent } from 'react'
import {
  generatePath,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { IS_DEV, TIMER } from '~/config/constants'
import { useReactFlowStatesContext } from '~/hooks/contexts/useReactFlowStatesContext'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import type { DeveloperChildrenParams } from '~/routes/developer/routes.types'
import { queryDevelopment } from '~/services/Development'
import type { DomainByPlatformId } from '~/services/Development.types'
import { CustomEdge } from './CustomEdge'
import { CustomSubscribeEdgePointer } from './CustomSubscribeEdgePointer'
import { EventNode } from './EventNode'
import { ServiceNode } from './ServiceNode'
import {
  parseDomainToGetServicesAndEvents,
  useGetLayoutedElements,
} from './config'

const dagreGraph = new dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

const nodeTypes = {
  serviceNode: ServiceNode,
  eventNode: EventNode,
}

const edgeTypes = {
  customEdge: CustomEdge,
}

export function ServiceMap() {
  // Ref.
  // react flow needs a parent with a fixed height value, so we're setting the to that parent the whole available page's space by getting a div's height that fills the whole page
  const flowParentContainer = useRef<HTMLDivElement>(null)
  const flowParentContainerHeight =
    flowParentContainer?.current?.getBoundingClientRect().height

  // React Router Dom.
  const params = useParams<DeveloperChildrenParams>()
  const { boundedContext = '', organisationId = '', platformId = '' } = params

  const initialData = useLoaderData() as {
    data: Awaited<AxiosResponse<DomainByPlatformId | null, unknown>>
  }

  const navigate = useNavigate()

  // React Query.
  const { data: dataDevelopment } = useQuery({
    ...queryDevelopment(platformId),
    initialData: initialData.data,
    refetchInterval: IS_DEV ? false : TIMER.REFRESH_DATA_FOR_SERVICE_MAP,
    refetchOnWindowFocus: true,
    select: (response) => response.data,
  })

  const parsedData = parseDomainToGetServicesAndEvents(
    dataDevelopment,
    boundedContext,
  )

  // Hooks.
  const { serviceMapViewport, setServiceMapViewport } =
    useReactFlowStatesContext()

  const { nodes, edges } = useGetLayoutedElements({
    dagreGraph,
    data: parsedData,
  })

  const { fitView } = useReactFlow()

  // Lifecycle
  useEffect(() => {
    // Use setTimeout to ensure the nodes changed before fitting the view.
    setTimeout(() => {
      fitView({ padding: 0.2, duration: 500 })
    }, 1)
  }, [boundedContext, fitView])

  // Methods.
  // Handler for viewport move.
  const handleMove = (_: MouseEvent | TouchEvent | null, data: Viewport) => {
    setServiceMapViewport?.(data)
  }
  // Debounce due to multiple calls when moving de viewport.
  const debouncedMove = debounce(handleMove, 300)

  const handleNodeClick = (
    e: ReactMouseEvent<Element, MouseEvent>,
    node: Node,
  ) => {
    e.stopPropagation()

    const { id, type } = node
    if (type === 'serviceNode' && !!id) {
      navigate(
        generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
          aggregateId: id,
          boundedContext,
          organisationId,
          platformId,
        }),
      )

      setTimeout(() => {
        fitView({ padding: 0.2, duration: 500 })
      }, 1)
    }
  }

  return (
    <>
      <Box ref={flowParentContainer} height="100%">
        {!!flowParentContainerHeight && (
          <ReactFlow
            defaultViewport={serviceMapViewport}
            edges={edges}
            edgeTypes={edgeTypes}
            elementsSelectable={false}
            fitView={!serviceMapViewport}
            nodes={nodes}
            nodesDraggable={false}
            nodesFocusable={false}
            nodeTypes={nodeTypes}
            onMove={debouncedMove}
            onNodeClick={handleNodeClick}
          >
            <Controls />
          </ReactFlow>
        )}

        <CustomSubscribeEdgePointer />
      </Box>
    </>
  )
}
