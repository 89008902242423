import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { platforms } from '~/config'
import { queryDevelopment } from '~/services/Development'
import { queryOrganisation } from '~/services/Discovery'
import { queryStrategiesByOrganisationId } from '~/services/GenerationStrategy'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderConfigGenerateCode =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params }: LoaderFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    invariant(userId, `Must be logged in: ${userId}`)
    invariant(email, `Must be logged in: ${userId}`)

    const { platformId } = params
    const queryOrg = queryOrganisation(userId, email)

    // Organisation
    const dataOrganisationFetched = await queryClient.ensureQueryData(queryOrg)
    const organisationId = dataOrganisationFetched.data.identity
    invariant(organisationId, 'organisationId is not defined')

    // Platform Team
    const qPlatform = queryPlatformTeam(organisationId)
    const fetchPlatform = await queryClient.ensureQueryData(qPlatform)

    // Custom strategies data
    const qStrategies = queryStrategiesByOrganisationId(organisationId)
    const fetchStrategies = queryClient.ensureQueryData(qStrategies)

    // Hostings and persistences options
    const hostings = platforms.find(
      (platform) => platform.identity === fetchPlatform.data?.cloudPlatform,
    )?.services.hostingStrategies

    const persistences = platforms.find(
      (platform) => platform.identity === fetchPlatform.data?.cloudPlatform,
    )?.services.persistenceStrategies

    // Aggregate data
    const qDevelopment = queryDevelopment(platformId)
    const fetchDevelopment = queryClient.ensureQueryData(qDevelopment)

    return {
      organisationId,
      hostings,
      persistences,
      strategies: fetchStrategies,
      development: fetchDevelopment,
      platform: fetchPlatform,
    }
  }
