import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { redirect } from 'react-router-dom'
import AwsIcon from '~/assets/icons/brand-aws.svg?react'
import AzureIcon from '~/assets/icons/brand-azure.svg?react'
import GcpIcon from '~/assets/icons/brand-gcp.svg?react'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import { PlatformByOrganisation } from '~/services/PlatformTeam.types'
import { getOrganisation } from '~/utils/getOrganisation'

export function switchIcon(identity: string) {
  if (identity.includes('azure')) return <AzureIcon />
  if (identity.includes('gcp')) return <GcpIcon />
  if (identity.includes('aws')) return <AwsIcon />

  return <AzureIcon />
}

export async function getPlatform(
  queryClient: QueryClient,
  organisationId?: string,
): Promise<AxiosResponse<PlatformByOrganisation>> {
  const query = queryPlatformTeam(organisationId)
  return await queryClient.ensureQueryData(query)
}

export async function platformSetupValidations(
  queryClient: QueryClient,
  pca: PublicClientApplication,
) {
  const {
    data: { identity: organisationId },
  } = await getOrganisation(queryClient, pca)

  if (!organisationId) {
    return {
      organisationId: null,
      redirectFn: redirect('/welcome/step1'),
    }
  }
  return {
    organisationId,
    redirectFn: null,
  }
}

export function checkPlatformOnboarding(
  status: number,
  isSubmitted: boolean,
  platformId: string,
  url: string,
  step: string,
  organisationId?: GUID,
) {
  if (status === 204) {
    if (step === 'cloud') {
      return null
    }
    if (platformId) {
      const splitUrl = getUrlWithoutSetup(url)
      return redirect(`${splitUrl}/platform-setup/cloud`)
    }
    return redirect('/')
  }

  if (isSubmitted) {
    return redirect(`/${organisationId}/${platformId}/developer`)
  }
  return null
}

export function getUrlWithoutSetup(url: string) {
  const newUrl = new URL(url)
  const splitUrl = newUrl.pathname.split('/platform-setup')
  return splitUrl[0]
}
