import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { queryKeyDevelopment } from '~/services/Development'
import { getLocalUserId } from '~/utils/getUserId'

export const actionDeveloperPublishReadModel =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params, context }: ActionFunctionArgs) => {
    const userId = getLocalUserId(pca)
    const { aggregateId, organisationId, platformId, readModelId } = params

    const formData = await request.formData()
    const commitMessage = formData.get('commitMessage')
    const branch = formData.get('branch')
    // DTO to send data
    const postData = {
      aggregateId,
      readModelId,
      developerId: userId,
      commitMessage,
      branch,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.PublishReadModel
    const req = await serviceDevelopmentAggregate.post(url, postData)

    if (req.status === 200) {
      await queryClient.refetchQueries({
        queryKey: queryKeyDevelopment(platformId),
      })
      return redirect(
        `/${organisationId}/${platformId}/developer/${aggregateId}`,
      )
    }

    throw req
  }
