import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { Controller } from 'react-hook-form'
import { Text } from '~/components/Text'
import DialogForm from '~/components/dialogs/DialogForm/DialogForm'
import { DeleteFilePathFormEnum } from '~/models/enums/forms/DeleteFilePathFormEnum'
import type { DialogDeleteFilePathProps } from '~/models/types/components/codeGenerationStrategy/DialogDeleteFilePathProps'
import { useDialogDeleteFilePath } from './useDialogDeleteFilePath'

export function DialogDeleteFilePath(props: DialogDeleteFilePathProps) {
  const { filePath, isDialogOpen } = props

  const {
    control,
    error,
    handleClose,
    handleFormSubmit,
    handleSubmit,
    isPending,
  } = useDialogDeleteFilePath(props)

  return (
    <DialogForm
      actions={
        <>
          <Button disabled={isPending} onClick={handleClose} variant="outlined">
            No
          </Button>

          <LoadingButton
            autoFocus
            loading={isPending}
            type="submit"
            variant="contained"
          >
            Yes
          </LoadingButton>
        </>
      }
      content={
        <>
          Do you really want to delete this selected file / path?
          <div className="mt-2">
            <Text className="text-slate-700">
              <strong>{filePath}</strong>
            </Text>
          </div>
          <Controller
            control={control}
            name={DeleteFilePathFormEnum.FILE_PATH}
            render={({ field }) => <input type="hidden" {...field} />}
          />
        </>
      }
      contentId="delete-dialog-description"
      disableEscapeKeyDown={isPending}
      error={error as string}
      formProps={{ onSubmit: handleSubmit?.(handleFormSubmit) }}
      onClose={handleClose}
      open={isDialogOpen}
      title="Delete File / Path"
      titleId="delete-dialog-title"
    />
  )
}
