import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { queryStrategiesByOrganisationId } from '~/services/GenerationStrategy'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderCodeGenerationStrategies =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)

    invariant(userId, 'User ID is required')
    invariant(email, 'User email is required')

    const { organisationId } = params

    const queryStrategies = queryStrategiesByOrganisationId(organisationId)
    const dataFetchedStrategies = await queryClient.fetchQuery(queryStrategies)

    return {
      organisationId,
      strategies: dataFetchedStrategies.data,
    }
  }
