import { Typography } from '@mui/material'
import { Form, useParams } from 'react-router-dom'
import { FormFooter } from '~/pages/developer/components/FormFooter'

export function DeveloperDeleteCommand() {
  const params = useParams()

  return (
    <>
      <Typography>Do you really want to delete this Command?</Typography>
      <Form id="delete-command-form" method="post">
        <input type="hidden" name="platformId" value={params.platformId} />
        <input type="hidden" name="aggregateId" value={params.aggregateId} />
        <input type="hidden" name="commandId" value={params.commandId} />
        <FormFooter showReset={false} />
      </Form>
    </>
  )
}
