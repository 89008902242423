import { Box, Drawer, Stack, Typography } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import '@xyflow/react/dist/style.css'
import { AxiosResponse } from 'axios'
import React, { useCallback, useEffect, useMemo } from 'react'
import {
  Navigate,
  Outlet,
  generatePath,
  useLoaderData,
  useLocation,
  useNavigate,
  useOutlet,
  useParams,
} from 'react-router-dom'
import { ButtonIconClose } from '~/components/ButtonIconClose'
import { DrawerConfig } from '~/components/DrawerConfig'
import { TIMER } from '~/config/constants'
import { BusinessDeveloperRoutesEnum } from '~/models/enums/routes/BusinessDeveloperRoutesEnum'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import { BusinessChildrenParams } from '~/routes/business/routes.types'
import { queryKeyDevelopment } from '~/services/Development'
import { DomainByPlatformId } from '~/services/Development.types'
import { ResponseDiscoveryOrganisation } from '~/services/Discovery.types'
import { PlatformByOrganisation } from '~/services/PlatformTeam.types'
import { queryProcess } from '~/services/Process'
import { ResponseDiscoveryProcess } from '~/services/Process.types'
import { useGlobalStore } from '~/store'
import { ProcessInfo } from './components/ProcessInfo'

export type BusinessInitialData = {
  organisation: Awaited<
    AxiosResponse<ResponseDiscoveryOrganisation | null, unknown>
  >
  selectedProcess: Awaited<
    AxiosResponse<ResponseDiscoveryProcess | null, unknown>
  >
  userId: string
  processId: string
  platform: Awaited<AxiosResponse<PlatformByOrganisation | null, unknown>>
  development: Awaited<AxiosResponse<DomainByPlatformId | null, unknown>>
  shouldConfigCoreProcess?: boolean
}

export function Business() {
  // React Router Dom.
  const params = useParams<BusinessChildrenParams>()
  const { organisationId, platformId } = params

  // cannot use typeof loader below because the loader redirects
  // and that causes its return to also be a Response, which we don't cover here
  const initialData = useLoaderData() as BusinessInitialData

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const outlet = useOutlet()

  // States.
  const [isGeneratingProcess, setIsGeneratingProcess] = React.useState(true)

  // Queries.
  const { data: dataProcess } = useQuery({
    ...queryProcess({ processId: initialData.processId }),
    initialData: initialData.selectedProcess,
    enabled: !!initialData.processId,
    refetchInterval: isGeneratingProcess
      ? TIMER.REFRESH_GENERATING_PROCESS
      : false,
  })

  const queryClient = useQueryClient()

  // Params based on pathname.
  const isConfigBusinessPage = pathname.includes(
    `/${organisationId}/${platformId}/business/config-business`,
  )
  const isPlatformSetup =
    pathname.includes('platform-setup') || pathname.includes('generate-code')

  const isEditCommandPage = pathname.includes('edit-command')
  const isEditReactionPage = pathname.includes('edit-reaction')
  const isQueryPage = pathname.includes('readmodel')
  const isCommitsHistoryPage = pathname.includes(
    BusinessDeveloperRoutesEnum.COMMITS_HISTORY,
  )

  // Memoized.
  const title = useMemo(() => {
    const isReactionPublish = pathname.includes('publish-reaction')
    const isCommandPublish = pathname.includes('publish-command')
    const isConfiguringGitCredentialsForInitialise =
      pathname.includes(`edit-git-credentials`)
    const isConfiguringAggregate =
      pathname.includes(`${params.aggregateId}/edit-`) &&
      !isConfiguringGitCredentialsForInitialise
    const isConfirmingConfiguringAggregate = pathname.includes(
      `${params.aggregateId}/confirm`,
    )
    const isAddingProcess = pathname.includes('add-process')

    if (isEditCommandPage || isEditReactionPage || isQueryPage) {
      return 'Edit properties'
    }

    if (isReactionPublish || isCommandPublish) {
      return 'Generate Feature Code'
    }

    if (isConfiguringAggregate) {
      return 'dot-star will generate the service code for this feature. Please select the configuration for the service below.'
    }

    if (isConfirmingConfiguringAggregate) {
      return 'dot-star will now generate Infrastructure as Code, a pipeline as code and deployment scripts for your service.'
    }

    if (isConfiguringGitCredentialsForInitialise) {
      return 'Enter your git repository details to generate your code.'
    }

    if (isAddingProcess) {
      return ''
    }

    return 'Process'
  }, [
    isEditCommandPage,
    isEditReactionPage,
    isQueryPage,
    params.aggregateId,
    pathname,
  ])

  // Constants.
  const businessPath = generatePath(BusinessRoutesEnum.BUSINESS, {
    organisationId: organisationId || '',
    platformId: platformId || '',
  })

  // Methods.
  const updateDevelopment = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKeyDevelopment(platformId),
    })
  }, [platformId, queryClient])

  function toggleDrawer() {
    if (params.processId) {
      navigate(
        generatePath(BusinessRoutesEnum.BUSINESS_PROCESS, {
          organisationId: organisationId || '',
          platformId: platformId || '',
          processId: params.processId || '',
        }),
      )
    } else {
      navigate(businessPath)
    }
  }

  // Lifecycle.
  useEffect(() => {
    // Store process ID to navigate to the last opened process (in case necessary).
    if (params.processId)
      useGlobalStore.setState({
        processId: params.processId,
      })
  }, [params.processId])

  useEffect(() => {
    if (dataProcess?.data) {
      if (params.processId && !dataProcess.data.isGeneratingProcess) {
        updateDevelopment()
      }
    }
    setIsGeneratingProcess(!!dataProcess?.data?.isGeneratingProcess)
  }, [dataProcess, params.processId, queryClient, updateDevelopment])

  // Redirects the user to the first process of the platform in case it's missing
  if (!params.processId && !initialData.shouldConfigCoreProcess) {
    return (
      <Navigate
        to={generatePath(BusinessRoutesEnum.BUSINESS_PROCESS, {
          organisationId: organisationId || '',
          platformId: platformId || '',
          processId: initialData.processId || '',
        })}
      />
    )
  }

  if (dataProcess?.data?.isDeleted) {
    return <Navigate to={businessPath} />
  }

  return (
    <>
      {isConfigBusinessPage ? (
        //Configure Core Process
        <Stack className="h-full w-full items-center justify-center">
          <Outlet />
        </Stack>
      ) : (
        <>
          {dataProcess?.data ? (
            <ProcessInfo process={dataProcess.data} />
          ) : null}

          {isPlatformSetup ? (
            <Outlet /> //Configure platform
          ) : null}

          {!isPlatformSetup &&
          (isEditCommandPage ||
            isEditReactionPage ||
            isQueryPage ||
            isCommitsHistoryPage) ? (
            <DrawerConfig
              isOpen={!!outlet}
              title={title}
              toggleDrawer={toggleDrawer}
            >
              <Outlet />
            </DrawerConfig>
          ) : (
            <>
              {!isPlatformSetup ? (
                <Drawer anchor="right" disablePortal open={!!outlet}>
                  <ButtonIconClose
                    className="absolute right-5 top-5"
                    onClick={toggleDrawer}
                    size="small"
                  />

                  <Box className="md:w-100  p-6">
                    {title ? (
                      <Typography variant="h6" className="mb-8">
                        {title}
                      </Typography>
                    ) : null}

                    <Outlet />
                  </Box>
                </Drawer>
              ) : null}
            </>
          )}
        </>
      )}
    </>
  )
}
