import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser'
import { constants } from '~/config'
import { loginRequest } from '~/config/auth'

export async function getToken(
  pca: PublicClientApplication,
): Promise<null | AuthenticationResult> {
  const activeAccount = pca.getActiveAccount()

  // Redirect to login in case no account is active
  if (!activeAccount) {
    await pca.loginRedirect({
      redirectUri: constants.msal.redirectUri,
      ...loginRequest,
    })
    return null
  }

  // creates the request object for silent token acquisition
  const tokenRequest = {
    account: activeAccount,
    redirectUri: constants.msal.redirectUri,
    ...loginRequest,
  }

  // acquire token silently
  try {
    const requestToken = await pca.acquireTokenSilent(tokenRequest)
    // should be requestToken.accessToken, but it's returning empty, check azure config
    return requestToken
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      await pca.acquireTokenRedirect(tokenRequest)
    }
    throw err
  }
}
