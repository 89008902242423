import { Typography } from '@mui/material'
import { Form, useParams } from 'react-router-dom'
import { FormFooter } from '~/pages/developer/components/FormFooter'

export function DeveloperDeleteReaction() {
  const params = useParams()

  return (
    <>
      <Typography>Do you really want to delete this Reaction?</Typography>
      <Form id="delete-reaction-form" method="post">
        <input type="hidden" name="platformId" value={params.platformId} />
        <input type="hidden" name="aggregateId" value={params.aggregateId} />
        <input type="hidden" name="reactionId" value={params.reactionId} />
        <FormFooter showReset={false} />
      </Form>
    </>
  )
}
