import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import {
  generatePath,
  redirect,
  type ActionFunctionArgs,
} from 'react-router-dom'
import invariant from 'tiny-invariant'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { queryKeyDevelopment } from '~/services/Development'
import { queryKeyOrganisation, queryOrganisation } from '~/services/Discovery'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const actionDeveloperDetails =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)

    invariant(userId, `Must be logged in: ${userId}`)
    invariant(email, `Must be logged in: ${userId}`)

    const { boundedContext: boundedContextParam = '', platformId = '' } = params

    await queryClient.invalidateQueries({
      queryKey: queryKeyOrganisation(userId, email),
    })

    const qOrganisation = queryOrganisation(userId, email)
    const fetchOrganisation = await queryClient.ensureQueryData(qOrganisation)

    const ecosystemName = fetchOrganisation.data.ecosystemName
    const organisationId = fetchOrganisation.data.identity

    // Get form data
    const formData = await request.formData()
    const boundedContext = formData.get('boundedContext')
    const name = formData.get('name')

    // Validate errors
    const errors: GenericObj<string> = {}

    if (
      !boundedContext ||
      typeof boundedContext !== 'string' ||
      boundedContext.trim().length <= 1
    ) {
      errors.boundedContext = 'Must have a Bounded context name'
    }
    if (!name || typeof name !== 'string' || name.trim().length <= 1) {
      errors.name = 'Must have an Aggregate name'
    }

    if (Object.keys(errors).length) {
      return errors
    }

    const qPlatform = queryPlatformTeam(organisationId)
    const fetchPlatform = await queryClient.ensureQueryData(qPlatform)
    const cloudPlatform = fetchPlatform.data.cloudPlatform

    // Builds the data to send to server
    const postData = {
      ecosystemName,
      boundedContext,
      name,
      platformId,
      cloudPlatform,
      organisationId,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.CreateAggregate
    const req = await serviceDevelopmentAggregate.post(url, postData)

    if (req.status === 200) {
      // Invalidates query to force refetch aggregates
      await queryClient.invalidateQueries({
        queryKey: queryKeyDevelopment(platformId),
      })

      const newPlatformId = fetchPlatform.data.identity

      const path = generatePath(DeveloperRoutesEnum.DEVELOPER_BOUNDED_CONTEXT, {
        boundedContext: boundedContextParam,
        organisationId,
        platformId: newPlatformId,
      })

      return redirect(path)
    }

    const path = generatePath(DeveloperRoutesEnum.DEVELOPER_BOUNDED_CONTEXT, {
      boundedContext: boundedContextParam,
      organisationId,
      platformId,
    })

    return redirect(path)
  }
