import { IconButton } from '@mui/material'
import { useMediaQuery } from '@uidotdev/usehooks'
import { Panel, ReactFlowProvider } from '@xyflow/react'
import { useRef } from 'react'
import { BadgeUsesLeft } from '~/components/BadgeUsesLeft'
import { Column } from '~/components/Column'
import { Row } from '~/components/Row'
import LoadingOverlay from '~/components/loadings/LoadingOverlay/LoadingOverlay'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { useStatements } from '~/hooks/useStatements'
import { useStatementsKeyboardActions } from '~/hooks/useStatementsKeyboardActions'
import type { ResponseDiscoveryProcess } from '~/services/Process.types'
import { DeleteNodeModal } from './DeleteNodeModal/DeleteNodeModal'
import { DiagramDownloadButton } from './DiagramDownloadButton'
import { StatementInput } from './StatementInput'
import { StatementsDiagram } from './StatementsDiagram'
import { HelperButton } from './StatementsDiagram/HelperButton'
import { SideMenuButton } from './StatementsDiagram/SideMenuButton'
import { StatementsTitle } from './StatementsTitle'
import { UploadMockupButton } from './UploadMockupButton/UploadMockupButton'
import { UploadOverlay } from './UploadOverlay/UploadOverlay'
import { useProcessInfo } from './useProcessInfo'

type ProcessInfoProps = {
  process: ResponseDiscoveryProcess
}

export function ProcessInfo({ process }: ProcessInfoProps) {
  // Hooks.
  const {
    addLocalChildStatement,
    addLocalFirstStatement,
    addLocalLastChildStatement,
    addLocalParentStatement,
    deleteSelectedStatement,
    insertLocalStatementBetween,
    isExpanded,
    isUpdatingParsingInfo,
    isUpdatingStatementContent,
    onHandleType,
    selectFirstChildStatementOrAdd,
    selectNextSiblingStatement,
    selectNewAndRemoveCurrentIfNeed,
    selectParentStatement,
    selectPreviousSiblingStatement,
    selectStatementByNode,
    selectedStatement,
    selectedStatements,
    shouldEdit,
    statementDiagramRef,
    statements,
    swapStatements,
    toggleIsExpanded,
    toggleMultiSelectEnablement,
    updateStatement,
  } = useStatements({
    process,
  })

  const {
    isDeleteModalOpen,
    isEditingMode,
    setIsEditingMode,
    toggleDeleteModal,
  } = useStatementsKeyboardActions({
    deleteSelectedStatement,
    insertLocalStatementBetween,
    process,
    selectFirstChildStatementOrAdd,
    selectNextSiblingStatement,
    selectNewAndRemoveCurrentIfNeed,
    selectParentStatement,
    selectPreviousSiblingStatement,
    selectedStatement,
    selectedStatements,
    swapStatements,
    toggleMultiSelectEnablement,
  })

  const isSmallDevice = useMediaQuery('only screen and (max-width : 767px)')

  const {
    handleAddLocalChild,
    handleAddLocalNextSibling,
    handleAddLocalParent,
    handleAddLocalPreviousSibling,
    handleAddStatementReaction,
    handleExplanationClick,
    handleDropNodeIntoDropAreas,
    handleNodeDoubleClick,
    isExplanationVisible,
    isLoading,
  } = useProcessInfo({
    addLocalChildStatement,
    addLocalFirstStatement,
    addLocalLastChildStatement,
    addLocalParentStatement,
    insertLocalStatementBetween,
    process,
    selectNewAndRemoveCurrentIfNeed,
    setIsEditingMode,
    statements,
  })

  // Refs.
  const statementInputRef = useRef<HTMLElement>(null)

  if (!process) return null

  return (
    <>
      <ReactFlowProvider>
        <UploadOverlay>
          {({ getRootProps }) => (
            <Column className="relative flex h-full w-full" {...getRootProps()}>
              <Column className="absolute top-[10px] z-10 mt-5 w-[830px] self-center justify-self-center rounded-lg backdrop-blur-sm md:w-[90%]">
                <StatementsTitle />

                <StatementInput
                  isEditingMode={isEditingMode}
                  isGeneratingProcess={process.isGeneratingProcess}
                  isLoading={isUpdatingParsingInfo}
                  onHandleType={onHandleType}
                  ref={statementInputRef}
                  selectedStatement={selectedStatement}
                  setIsEditingMode={setIsEditingMode}
                  shouldEdit={shouldEdit}
                />

                {/* {isEditingMode && FEATURE_TOGGLE.BUSINESS.QUICK_EDIT ? (
                  <StatementEditionBox
                    isUpdatingStatementContent={isUpdatingStatementContent}
                    onCloseClick={() => setIsEditingMode(false)}
                    selectedStatement={selectedStatement}
                    updateStatement={updateStatement}
                  />
                ) : null} */}
              </Column>

              <Panel
                position={isSmallDevice ? 'bottom-center' : 'top-left'}
                className="z-20 rounded-lg bg-background-paper p-1 shadow-2"
              >
                <Row className="gap-1">
                  <SideMenuButton />

                  <Row>
                    <UploadMockupButton />

                    {FEATURE_TOGGLE.UPGRADE_PLAN ? (
                      <IconButton className="w-fit">
                        <BadgeUsesLeft featureType="MOCKUP_ANALYSIS" />
                      </IconButton>
                    ) : null}
                  </Row>

                  {!process.isGeneratingProcess && (
                    <>
                      <DiagramDownloadButton />
                      <HelperButton />
                    </>
                  )}
                </Row>
              </Panel>

              {/* {!process.isGeneratingProcess &&
              statements.length &&
              isExplanationVisible ? (
                <ExplanationDialog handleClick={handleExplanationClick} />
              ) : null} */}

              <StatementsDiagram
                isEditingMode={isEditingMode}
                isExpanded={isExpanded}
                isGeneratingProcess={process.isGeneratingProcess}
                onAddLocalChild={handleAddLocalChild}
                onAddLocalNextSibling={handleAddLocalNextSibling}
                onAddLocalParent={handleAddLocalParent}
                onAddLocalPreviousSibling={handleAddLocalPreviousSibling}
                onAddReaction={handleAddStatementReaction}
                onDropNodeIntoDropAreas={handleDropNodeIntoDropAreas}
                onNodeClick={selectStatementByNode}
                onNodeDoubleClick={handleNodeDoubleClick}
                onToggleExpandClick={toggleIsExpanded}
                ref={statementDiagramRef}
                selectedStatements={selectedStatements}
                setIsEditingMode={setIsEditingMode}
                statementInputRef={statementInputRef}
                statements={statements}
                updateStatement={updateStatement}
              />

              <DeleteNodeModal
                onDeleteClick={() => deleteSelectedStatement(toggleDeleteModal)}
                isDeleteModalOpen={isDeleteModalOpen}
                statementContent={selectedStatement?.content}
                toggleDeleteModal={toggleDeleteModal}
              />
            </Column>
          )}
        </UploadOverlay>
      </ReactFlowProvider>

      <LoadingOverlay open={isLoading} />
    </>
  )
}
