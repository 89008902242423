import type { ResponseListParametersByGenerationId } from '~/services/GenerationStrategy.types'

/**
 * Provides the `domain dictionary` data
 * to be provided as a payload for endpoint:
 * Remove the data with `isGrouping` flag.
 * @param domainDictionary The raw domain dictionary data.
 */
export const getDomainDictionaryPayloadData = (
  domainDictionary: ResponseListParametersByGenerationId[],
): ResponseListParametersByGenerationId[] =>
  domainDictionary.filter(
    ({ isGrouping }: ResponseListParametersByGenerationId) => !isGrouping,
  )
