import {
  Aggregate,
  AggregateCommand,
  AggregateEventType,
  AggregateReaction,
  AggregateReadModel,
  DomainByPlatformId,
} from '~/services/Development.types'

export function getAggregateById(
  data: DomainByPlatformId,
  aggregateId: GUID,
): Aggregate | null {
  for (const boundedContext of data.boundedContexts) {
    for (const aggregate of boundedContext.aggregates) {
      if (aggregate.identity === aggregateId) {
        return aggregate
      }
    }
  }
  return null
}

function pluralizeEventType(
  eventType: AggregateEventType,
): keyof Pick<Aggregate, 'commands' | 'reactions' | 'readModels'> {
  if (
    eventType === 'query' ||
    eventType === 'read-model' ||
    eventType === 'readmodel'
  ) {
    return 'readModels'
  }
  return `${eventType}s`
}
export function getEventById(
  aggregate: Aggregate,
  eventId: GUID,
  eventType: AggregateEventType,
): AggregateCommand | AggregateReaction | AggregateReadModel | null {
  const events = aggregate[pluralizeEventType(eventType)]
  return events?.filter((event) => event.identity === eventId)[0] || null
}
