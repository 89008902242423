import type {
  AlertProps,
  SnackbarCloseReason,
  SnackbarProps,
} from '@mui/material'
import {
  createContext,
  type Dispatch,
  type SetStateAction,
  type SyntheticEvent,
} from 'react'

export type AppSnackbarContextPropsProps = {
  /**
   * The alert props. Necessary to set up alert types and content.
   * @link https://mui.com/material-ui/react-snackbar/#use-with-alerts
   */
  alertProps?: AlertProps
  /** The snackbar props. */
  snackbarProps?: SnackbarProps
}

export type AppSnackbarContextProps = {
  /**
   * Closes the snackbar: only sets `open` prop to false,
   * without changing any other component prop.
   * */
  closeAppSnackbar?: (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason,
  ) => void
  /** The props that will compose the snackbar. */
  componentProps: AppSnackbarContextPropsProps
  /** Opens the snackbar with the provided props. */
  openAppSnackbar?: (componentProps: AppSnackbarContextPropsProps) => void
  /** The set state for `props`. */
  setComponentProps?: Dispatch<SetStateAction<AppSnackbarContextPropsProps>>
}

const defaultValue = {
  componentProps: {},
}

/**
 * Context for the App Snackbar.
 */
const AppSnackbarContext = createContext<AppSnackbarContextProps>(defaultValue)

export default AppSnackbarContext
