import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs, generatePath, redirect } from 'react-router-dom'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import { queryOrganisation } from '~/services/Discovery'
import { queryProcess } from '~/services/Process'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderBusinessViewProcess =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    // if no user, redirect to login page
    if (!userId || !email) {
      return redirect('/')
    }

    // if no organisation, redirect to welcome page
    const qOrganisation = queryOrganisation(userId, email)
    const fetchOrganisation = await queryClient.ensureQueryData(qOrganisation)
    if (fetchOrganisation.status !== 200) {
      return redirect('/welcome/step1')
    }

    const { platformId, processId } = params
    const organisationId = fetchOrganisation.data.identity
    const url = new URL(request.url)
    if (
      !fetchOrganisation.data.coreDomain &&
      url.pathname ===
        generatePath(BusinessRoutesEnum.BUSINESS, {
          organisationId: organisationId || '',
          platformId: platformId || '',
        })
    ) {
      return redirect(
        `/${organisationId}/${platformId}/business/config-business`,
      )
    }
    if (!fetchOrganisation.data.coreDomain) {
      return {
        organisation: fetchOrganisation,
        userId,
        process: null,
        processId: '',
      }
    }

    const qProcess = queryProcess({ processId })
    const fetchProcess = await queryClient.ensureQueryData(qProcess)

    return {
      process: fetchProcess,
      processId,
    }
  }
