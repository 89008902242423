import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import { queryKeyDevelopment } from '~/services/Development'
import { PayloadSelectGenerationStrategies } from '~/services/Development.types'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'

export const actionConfigCodeGenerationStrategies =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const { platformId, aggregateId } = params

    // Get form data
    const formData = await request.formData()
    const strategies = formData.getAll('strategy') as string[] | undefined

    // invariant(strategies, 'at least one strategy must be selected')

    // Builds the data to send to server
    const postData: PayloadSelectGenerationStrategies = {
      aggregateId,
      generationStrategyIds: strategies ? strategies : [],
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.SelectGenerationStrategies
    await serviceDevelopmentAggregate.post(url, postData)

    // Invalidate the query
    await queryClient.invalidateQueries({
      queryKey: queryKeyDevelopment(platformId),
    })

    // Redirects to the next page
    return redirect('../')
  }
