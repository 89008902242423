import { useParams } from 'react-router-dom'
import UploadFilesOverlay from '~/components/UploadFilesOverlay.tsx/UploadFilesOverlay'
import { useUploadFilesSnackbar } from '~/components/snackbars/UploadFilesSnackbar/useUploadFilesSnackbar'
import { useUploadCode } from '~/hooks/api/codeGenerationStrategy/useUploadCode'
import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'
import { filesValidator } from '~/utils/uploadFiles/filesValidator'

const PLACEHOLDER_TEXT = (
  <span className="overflow-hidden text-ellipsis whitespace-nowrap px-1 text-[10px] text-white">
    Drop files to upload.
  </span>
)

export type TreeItemLabelProps = { node: FolderOrFileStructure }

/**
 * Renders the labels for the `tree items`:
 * In case the node has children, this means it as folder,
 * and so, it should support the file upload.
 */
export const TreeItemLabel = (props: TreeItemLabelProps) => {
  const { node } = props
  const { children, name, path } = node || {}

  // React Router Dom.
  const params = useParams()
  const { generationStrategyId } = params

  // React Query.
  const { error, isPending, isSuccess, mutate, reset } = useUploadCode({
    generationStrategyId: generationStrategyId,
  })

  // Snackbar.
  const { handleRejected } = useUploadFilesSnackbar({
    error,
    isPending,
    isSuccess,
    reset,
  })

  if (!children?.length) return name

  return (
    <UploadFilesOverlay
      /**
       * The `dropAreaRejectText` and `dropAreaText` needs to be the same
       * because the empty `accept` param makes all file types to be
       * marked as rejected.
       */
      dropAreaActiveText={PLACEHOLDER_TEXT}
      dropAreaRejectText={PLACEHOLDER_TEXT}
      useDropzoneProps={{
        onDrop: (acceptedFiles, fileRejections) => {
          if (!!acceptedFiles?.length)
            mutate({ files: acceptedFiles, originalPath: path })

          if (!!fileRejections?.length) handleRejected(fileRejections)
        },
        validator: filesValidator,
      }}
    >
      {({ getRootProps }) => (
        <span className="flex" {...getRootProps()}>
          {name}
        </span>
      )}
    </UploadFilesOverlay>
  )
}
