import { MarkerType, Position, type Edge, type Node } from '@xyflow/react'
import dagre from 'dagre'
import { v4 as uuid } from 'uuid'
import type {
  AggregateType,
  DomainByPlatformId,
} from '~/services/Development.types'
import { useGlobalStore } from '~/store'

export const nodeWidth = 250
export const nodeHeight = 90

const colors = {
  dark: {
    publishEdgeColors: {
      line: '#FF00D6',
      label: '#FF51E3',
    },
    subscribeEdgeColors: {
      line: '#00FFD1',
      label: '#78F8E1',
    },
  },
  light: {
    publishEdgeColors: {
      line: '#EC26CC',
      label: '#8A069F',
    },
    subscribeEdgeColors: {
      line: '#2AD6B7',
      label: '#147C69',
    },
  },
}

const arrowWidth = '2px'

type Subscription = {
  eventId: string
  id: string
  name: string
  context: string
}

type ServiceAndEvents = {
  id: string
  context: string
  services: {
    id: string
    name: string
    raisedEvents: {
      id: string
      name: string
      serviceListeners: {
        id: string
        name: string
        context: string
      }[]
    }[]
    typeDescription?: AggregateType
  }[]
}[]

export const useGetColors = () => {
  const colorMode = useGlobalStore((state) => state.colorMode)

  return colorMode === 'dark' ? colors.dark : colors.light
}

export const useGetLayoutedElements = ({
  data,
  dagreGraph,
}: {
  data: ServiceAndEvents
  dagreGraph: dagre.graphlib.Graph
}) => {
  const { nodes, edges } = useGetElements(data)

  const direction = 'LR' //left to right
  dagreGraph.setGraph({ rankdir: direction })

  nodes.forEach((node) => {
    // adding 15% on the width between the nodes to improve visibility
    dagreGraph.setNode(node.id, {
      width: nodeWidth * 2,
      height: nodeHeight / 2,
    })
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = Position.Left
    node.sourcePosition = Position.Left

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    }

    return node
  })

  return { nodes, edges }
}

type NodesAndEdges = {
  nodes: Node[]
  edges: Edge[]
}

function useGetElements(data: ServiceAndEvents): NodesAndEdges {
  const colors = useGetColors()
  if (!data) {
    return {
      nodes: [],
      edges: [],
    }
  }
  let nodes: Node[] = []
  let edges: Edge[] = []

  const defaultPosition = {
    x: 0,
    y: 0,
  }

  data.forEach((context) => {
    context.services.forEach((service) => {
      nodes.push({
        id: service.id,
        data: {
          context: service.typeDescription?.name || context.context,
          content: !!service.typeDescription?.attributes?.length ? (
            <div className="flex flex-col px-3 py-2 text-[#020890]">
              {service.typeDescription.attributes.map((attr) => (
                <p className="m-0" key={attr.identity}>
                  <b>{attr.name}:</b> <span>{attr.type}</span>
                </p>
              ))}
            </div>
          ) : (
            <p className="m-0 flex flex-1 items-center justify-center text-[#020890]">
              {service.name} Service
            </p>
          ),
        },
        position: defaultPosition,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        type: 'serviceNode',
      })

      service.raisedEvents.forEach((event) => {
        nodes.push({
          id: event.id,
          data: {
            context: context.context,
            label: event.name,
          },
          position: defaultPosition,
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          type: 'eventNode',
        })

        edges.push({
          source: service.id,
          target: event.id,
          id: `${service.name}-${event.name}-${uuid()}`,
          style: {
            stroke: colors.publishEdgeColors.line,
            strokeDasharray: 5,
            strokeWidth: arrowWidth,
          },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 15,
            height: 15,
            color: colors.publishEdgeColors.line,
          },
          label: 'Publishes',
          labelStyle: {
            fontWeight: 'bold',
            fill: colors.publishEdgeColors.label,
          },
          type: 'customEdge',
        })

        event.serviceListeners?.forEach((listener) => {
          const listenerNodeId = `${service.id}_${listener.id}_listener`
          const alreadyCreated = nodes.find(
            (node: Node) => node.id === listenerNodeId,
          )
          if (!alreadyCreated) {
            nodes.push({
              id: listenerNodeId,
              data: {
                label: `${listener.name} Service`,
                context: listener.context,
              },
              position: defaultPosition,
              sourcePosition: Position.Right,
              targetPosition: Position.Left,
              type: 'serviceNode',
            })
          }

          edges.push({
            source: event.id,
            target: listenerNodeId,
            id: `${event.name}-${listener.name}-${uuid()}`,
            label: 'Subscribed By',
            labelStyle: {
              fill: colors.subscribeEdgeColors.label,
              fontWeight: 'bold',
            },
            style: {
              stroke: colors.subscribeEdgeColors.line,
              strokeDasharray: 0,
              strokeWidth: arrowWidth,
            },
            markerStart: 'logo',
            type: 'customEdge',
          })
        })
      })
    })
  })

  return {
    nodes,
    edges,
  }
}

// TODO: Remove the next below two functions after getting the new Flow endpoint

const getSubscriptions = (data: DomainByPlatformId): Subscription[] => {
  const subs: Subscription[] = []
  data?.boundedContexts.forEach((context) => {
    context.aggregates.map((service) => {
      service.reactions.forEach((reaction) => {
        subs.push({
          eventId: reaction.subscription.domainEventName,
          id: service.identity,
          name: service.name,
          context: context.name,
        })
      })
    })
  })

  return subs
}

export const parseDomainToGetServicesAndEvents = (
  data: DomainByPlatformId | null,
  boundedContext: string,
): ServiceAndEvents => {
  if (!data) return []

  const dataFilteredByBoundedContext = data?.boundedContexts.filter(
    (context) => context.name.toLowerCase() === boundedContext,
  )

  const subs = getSubscriptions(data)
  const parsedData = dataFilteredByBoundedContext?.map((context) => ({
    id: context.name,
    context: context.name,
    services: context.aggregates.map((service) => {
      const commandRaisedEvents: any[] = []
      const reactRaisedEvents: any[] = []
      service.commands.forEach((event) => {
        event.domainEvents.forEach((raisedEvents) => {
          commandRaisedEvents.push({
            id: raisedEvents.name,
            name: raisedEvents.name,
            serviceListeners: subs
              .filter((sub) => sub.eventId === raisedEvents.name)
              .map((sub) => ({
                id: sub.id,
                name: sub.name,
                context: sub.context,
              })),
          })
        })
      })
      service.reactions.forEach((reaction) => {
        return reaction.domainEvents.forEach((raisedEvents) => {
          reactRaisedEvents.push({
            id: raisedEvents.name,
            name: raisedEvents.name,
            serviceListeners: subs
              .filter((sub) => sub.eventId === raisedEvents.name)
              .map((sub) => ({
                id: sub.id,
                name: sub.name,
                context: sub.context,
              })),
          })
        })
      })
      return {
        id: service.identity,
        name: service.name,
        typeDescription: service.typeDescription,
        raisedEvents: [...commandRaisedEvents, ...reactRaisedEvents],
      }
    }),
  }))

  return parsedData
}
