import '@fontsource/dm-sans/400.css'
import '@fontsource/dm-sans/500.css'
import '@fontsource/dm-sans/700.css'
import ReactDOM from 'react-dom/client'
import { App } from '~/App'
import './main.css'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />,
)
