import { z } from 'zod'
import { IS_DEV } from '~/config/constants'
import { renamePropertiesToAttributes } from '~/routes/developer/utils'

const baseAttributeSchema = z.object({
  name: z.string(),
  type: z.string(),
})

type Attribute = z.infer<typeof baseAttributeSchema> & {
  properties?: Attribute[]
}

const attributesSchema: z.ZodType<Array<Attribute>> = z.array(
  baseAttributeSchema.extend({
    properties: z.lazy(() => attributesSchema.optional()),
  }),
)

interface FormFields {
  [field: string]: Array<{
    name: string
    type: string
  }>
}

export const useCopyAndPasteAttributes = ({
  copyingWholeForm,
}: {
  copyingWholeForm: boolean
}) => {
  function transformAttributes(
    attributes: Array<{ name: string; type: string; name_is_array?: boolean }>,
    input: FormFields,
    attributesKey: string,
  ) {
    return attributes.map((attribute) => {
      const transformedAttribute: Attribute = {
        name: attribute.name,
        type: attribute.name_is_array ? `${attribute.type}[]` : attribute.type,
      }

      const nestedAttributesKey = Object.keys(input).find((key) =>
        key.includes(`${attribute.name}_${attributesKey}`),
      )

      if (attribute.type === 'object' && nestedAttributesKey) {
        transformedAttribute.properties = transformAttributes(
          input[nestedAttributesKey],
          input,
          nestedAttributesKey,
        )
      }

      return transformedAttribute
    })
  }

  function transformObject(input: FormFields) {
    const firstAttributeKey = copyingWholeForm
      ? 'action_object_attributes'
      : Object.keys(input)[0]
    const firstAttribute = Object.values(input)[0]

    return transformAttributes(firstAttribute, input, firstAttributeKey)
  }

  const copyAttributesToClipboard = async (formAttributes: FormFields) => {
    const parsedAttributes = transformObject(formAttributes)
    await navigator.clipboard.writeText(JSON.stringify(parsedAttributes))
  }

  const pasteAttributesFromClipboard = async (): Promise<z.infer<
    typeof attributesSchema
  > | null> => {
    try {
      const value = await navigator.clipboard.readText()
      const parsedData = attributesSchema.safeParse(JSON.parse(value))

      if (parsedData.success) {
        return renamePropertiesToAttributes(parsedData.data)
      }
      return null
    } catch (err) {
      if (IS_DEV) {
        console.error(err)
      }

      return null
    }
  }

  return {
    copyAttributesToClipboard,
    pasteAttributesFromClipboard,
  }
}
