import EditIcon from '@mui/icons-material/Edit'
import { Button, Typography } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PublishIcon from '~/assets/icons/rocket.svg?react'
import { ConfirmationButton } from '~/components/ConfirmationButton'
import { useGetSubscriptionPlanCapabilities } from '~/hooks/useGetSubscriptionPlanCapabilities'
import { AggregateInfoHeaderButton } from '~/pages/developer/components/styled/AggregateInfoHeaderButton'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { queryKeyDevelopment } from '~/services/Development'
import { Aggregate } from '~/services/Development.types'

type AggregateInfoHeaderNewProps = {
  aggregate: Aggregate
  menuSelected: string
}

export function AggregateInfoHeaderNew(props: AggregateInfoHeaderNewProps) {
  const { aggregate, menuSelected } = props

  // React Router Dom.
  const navigate = useNavigate()

  const params = useParams()
  const { platformId } = params

  // React Query.
  const queryClient = useQueryClient()

  const deleteMutation = useMutation({
    mutationFn: ({ aggregateId }: { aggregateId: string }) => {
      const url = DevelopmentAggregateAPI.DeleteAggregate
      return serviceDevelopmentAggregate.post(url, {
        aggregateId,
      })
    },
    onSettled: () => {
      if (!!platformId) {
        queryClient.invalidateQueries({
          queryKey: queryKeyDevelopment(platformId),
        })
      }
    },
  })

  // Hooks.
  const { codeGeneration: isCodeGenerationAllowed } =
    useGetSubscriptionPlanCapabilities()

  // Methods.
  function handleEdit(e: React.MouseEvent<HTMLDivElement>) {
    const edit = e.currentTarget.dataset.edit
    navigate(`edit-${edit}`)
  }

  const handleConfirmDeletion = () => {
    deleteMutation.mutate({
      aggregateId: aggregate.identity,
    })
  }

  return (
    <div className="flex flex-row items-end justify-between gap-8 pb-0">
      <AggregateInfoHeaderButton
        p={2}
        gap={1}
        onClick={handleEdit}
        data-edit="details"
      >
        <Typography variant="h6" sx={{ mb: -1, fontWeight: 400 }}>
          {menuSelected}
        </Typography>

        <Typography
          variant="subhero"
          sx={{ lineHeight: 1, strong: { fontWeight: 700 } }}
        >
          <strong>{aggregate.name}</strong>
        </Typography>

        <EditIcon />
      </AggregateInfoHeaderButton>

      <div className="ml-2 flex flex-col gap-2">
        <ConfirmationButton
          buttonText="Delete"
          confirmButtonText="Delete service"
          isRegularButton
          itemId={aggregate.identity}
          modalText="Once removed, this action cannot be undone."
          onConfirmDeleteClick={handleConfirmDeletion}
          titleText={
            <span>
              Delete &quot;
              <strong>{aggregate.name}</strong>&quot; service?
            </span>
          }
          tooltipText="Delete service"
        />

        {!!isCodeGenerationAllowed && (
          <Button
            component={Link}
            startIcon={<PublishIcon />}
            to={'generate-code'}
            variant="outlined"
          >
            Generate Code
          </Button>
        )}
      </div>
    </div>
  )
}
