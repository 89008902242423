import { Box, Drawer, Typography } from '@mui/material'
import { useGlobalStore } from '~/store'

type MenuWrapperProps = {
  children: React.ReactNode
  isMenuOpen: boolean
  toggleIsMenuOpen: () => void
}
export function MenuWrapper({
  children,
  isMenuOpen,
  toggleIsMenuOpen,
}: MenuWrapperProps) {
  const colorMode = useGlobalStore((state) => state.colorMode)

  return (
    <Drawer anchor="left" open={isMenuOpen} onClose={toggleIsMenuOpen}>
      <Box sx={{ minWidth: 270 }}>
        <Typography
          variant="h5"
          sx={{
            mb: 2,
            borderBottom:
              colorMode === 'dark' ? '1px solid #272b39' : '1px solid #e5e5e5',
            pt: 2,
            mx: 2,
          }}
        >
          Menu
        </Typography>
        {children}
      </Box>
    </Drawer>
  )
}
