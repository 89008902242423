import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs, generatePath, redirect } from 'react-router-dom'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import { queryKeyOrganisation, queryOrganisation } from '~/services/Discovery'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import { useGlobalStore } from '~/store'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderOnboarding =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params, context }: LoaderFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)

    if (!userId || !email) {
      return null
    }
    // invariant(userId, `Must be logged in: ${userId}`)

    // if the user comes from the demo
    const url = new URL(request.url)
    const search = url.searchParams.get('term')
    if (search) {
      const searchDecoded = search.replaceAll('+', ' ').trim()

      if (searchDecoded) {
        useGlobalStore.setState({ demoTerm: searchDecoded })
        useGlobalStore.setState({ isDemo: true })
      }
    }

    // Redirect if organisation was already filled
    await queryClient.refetchQueries({
      queryKey: queryKeyOrganisation(userId, email),
    })
    const qOrganisation = queryOrganisation(userId, email)
    const fetchOrganisation = await queryClient.ensureQueryData(qOrganisation)
    const organisationId = fetchOrganisation.data.identity

    // There is an organisation and something filled
    if (fetchOrganisation.status === 200) {
      // redirect to step 2 in case the name is already filled and there is no ecosystem name
      if (
        !fetchOrganisation.data.ecosystemName &&
        fetchOrganisation.data.name
      ) {
        return redirect('/welcome/step2')
      }

      // check if there is a platform team
      const qPlatform = queryPlatformTeam(organisationId)
      const fetchPlatform = await queryClient.ensureQueryData(qPlatform)
      const platformId = fetchPlatform.data.identity

      // if a user has already filled the organisation and the ecosystem, and has a platform created, so we redirect him to the business page
      return redirect(
        generatePath(BusinessRoutesEnum.BUSINESS, {
          organisationId: organisationId || '',
          platformId: platformId || '',
        }),
      )
    }

    // There is no organisation yet, so we redirect to the welcome page
    if (fetchOrganisation.status === 204) {
      return redirect('/welcome/step1')
    }

    // Nothing to do here
    return null
  }
