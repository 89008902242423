import { twMerge } from '^/tailwind.config'
import { revertToOriginalValueClasses } from './RevertToOriginalValue.styles'

export type RevertToOriginalValueLabelProps = {
  /** The label string. */
  label?: string
  /** The value string. */
  value?: string | null
}

/**
 * The labels for the `RevertToOriginalValue` component.
 */
export const RevertToOriginalValueLabel = (
  props: RevertToOriginalValueLabelProps,
) => {
  const { label, value } = props

  return (
    <div
      className={twMerge(
        'flex items-start gap-2 text-slate-300',
        revertToOriginalValueClasses.row,
      )}
    >
      {label && (
        <label className={revertToOriginalValueClasses.label}>{label}:</label>
      )}

      <span
        className={twMerge('break-all', revertToOriginalValueClasses.value)}
      >
        {value ? value : '(empty)'}
      </span>
    </div>
  )
}
