import { Chip, List, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'
import { ActionIcon } from '~/components/Icons/ActionIcon'
import { ReactionIcon } from '~/components/Icons/ReactionIcon'
import { useGetSubscriptionPlanCapabilities } from '~/hooks/useGetSubscriptionPlanCapabilities'
import { ListAttributes } from '~/pages/developer/components/ListAttributes'
import { MenuMore } from '~/pages/developer/components/MenuMore'
import {
  ListItemForDeveloper,
  ListItemHeader,
} from '~/pages/developer/components/styled/ListItem'
import { AggregateCommand } from '~/services/Development.types'

type ListCommandsProps = {
  commands: AggregateCommand[]
}
export function ListCommands({ commands }: ListCommandsProps) {
  const { codeGeneration: isCodeGenerationAllowed } =
    useGetSubscriptionPlanCapabilities()

  return (
    <>
      {commands.length ? (
        <Stack mx={1}>
          <List>
            {commands.map((command, index) => {
              return (
                <ListItemForDeveloper key={command.name + index.toString()}>
                  <Stack sx={{ flexGrow: 1 }} gap={1}>
                    <ListItemHeader>
                      <Stack
                        direction="row"
                        sx={{ alignItems: 'center' }}
                        gap={1}
                      >
                        <ActionIcon />
                        <Typography>
                          <strong>{command.name}</strong>
                        </Typography>
                      </Stack>
                      <Stack gap={1} direction="row" alignItems="center">
                        {isCodeGenerationAllowed && (
                          <Chip
                            label={command.state.name}
                            color={
                              command.state.name === 'Published'
                                ? 'success'
                                : 'warning'
                            }
                            size="small"
                            variant="outlined"
                          />
                        )}
                        <MenuMore
                          linkPublish={`generate-code/command/${command.identity}`}
                          linkEdit={`edit-command/${command.identity}`}
                          linkDelete={
                            command.state.name === 'Published'
                              ? undefined
                              : `delete-command/${command.identity}`
                          }
                          type="Command"
                          eventId={command.identity}
                        />
                      </Stack>
                    </ListItemHeader>

                    <ListAttributes attributes={command.attributes} />
                    {command.domainEvents.map(
                      (domainEvent, domainEventIndex) => (
                        <React.Fragment
                          key={domainEvent.name + domainEventIndex.toString()}
                        >
                          <Stack
                            key={domainEvent.name}
                            direction="row"
                            sx={{ alignItems: 'center', mt: 1 }}
                            gap={1}
                          >
                            <ReactionIcon />
                            <Typography variant="body2">
                              {domainEvent.name}
                            </Typography>
                          </Stack>
                          <ListAttributes attributes={domainEvent.attributes} />
                        </React.Fragment>
                      ),
                    )}
                  </Stack>
                </ListItemForDeveloper>
              )
            })}
          </List>
        </Stack>
      ) : null}
    </>
  )
}
