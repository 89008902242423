import type { AggregateType } from '~/services/Development.types'

export const AGGREGATE_TYPES: AggregateType[] = [
  {
    label: 'integer',
    name: 'integer',
  },
  {
    label: 'number',
    name: 'number',
  },
  {
    label: 'string',
    name: 'string',
  },
  {
    label: 'boolean',
    name: 'boolean',
  },
  {
    label: 'date',
    name: 'date',
  },
  {
    label: 'date-time',
    name: 'date-time',
  },
  {
    label: 'byte',
    name: 'byte',
  },
  {
    label: 'binary',
    name: 'binary',
  },
  {
    label: 'uuid',
    name: 'uuid',
  },
  {
    label: 'float',
    name: 'float',
  },
  {
    label: 'double',
    name: 'double',
  },
  {
    label: 'int32',
    name: 'int32',
  },
  {
    label: 'int64',
    name: 'int64',
  },
]
