import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import type {
  ErrorResponseDataType,
  ErrorResponseType,
} from '~/models/types/http/ErrorResponseType'

/**
 * Returns a customized error when a query/mutation error happens.
 * @param isError Indicates if there is a query/mutation error.
 * @param error The error object.
 */
export const getQueryMutationError = (
  isError?: boolean,
  error?: ErrorResponseType,
): {} | undefined => {
  const responseError = (error?.response?.data?.[0] as ErrorResponseDataType)
    ?.error

  switch (true) {
    case isError && error?.response?.status === 500:
    case isError && !responseError:
      return ERROR_MESSAGES.generic

    case isError && !!responseError:
      return responseError

    default:
      return undefined
  }
}
