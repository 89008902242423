import { useEffect, useState } from 'react'
import { useSelectedTextSectionsContext } from '~/hooks/contexts/useSelectedTextSectionsContext'
import type { PanelAsideDetailsBaseProps } from '~/models/types/components/codeGenerationStrategy/PanelAsideDetailsBaseProps'

type UseManagePanelAsideDetailsHook = PanelAsideDetailsBaseProps & {
  /** Handler for toggle node properties. */
  handleToggleNodePropertiesClick: () => void
  /** Handler for toggle parameters. */
  handleToggleParametersClick: () => void
}

/**
 * A hook to support the panel aside details:
 * it manages the states to toggle the `panel aside details`,
 * either with parameters content or node properties.
 */
export const useManagePanelAsideDetails =
  (): UseManagePanelAsideDetailsHook => {
    const [showNodePropertiesPanel, setShowNodePropertiesPanel] =
      useState<boolean>(false)
    const [showParametersPanel, setShowParametersPanel] =
      useState<boolean>(false)

    // Selected text sections state.
    const { editableNode } = useSelectedTextSectionsContext()

    // Lifecycle.
    useEffect(() => {
      // Set `showNodePropertiesPanel` when the user is editing a text section.
      if (!!editableNode) setShowNodePropertiesPanel(true)
    }, [editableNode])

    // Handler for toggle node properties.
    const handleToggleNodePropertiesClick = () => {
      // If parameters panel is marked as `show`, set it to false.
      if (showParametersPanel) setShowParametersPanel(false)

      setShowNodePropertiesPanel((prevState) => !prevState)
    }

    // Handler for toggle parameters.
    const handleToggleParametersClick = () => {
      // If node properties panel is marked as `show`, set it to false.
      if (showNodePropertiesPanel) setShowNodePropertiesPanel(false)

      setShowParametersPanel((prevState) => !prevState)
    }

    return {
      showNodePropertiesPanel,
      showParametersPanel,
      handleToggleNodePropertiesClick,
      handleToggleParametersClick,
    }
  }
