import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import { queryKeyPlatformTeam } from '~/services/PlatformTeam'
import { PlatformPostResponseGitCredentials } from '~/services/PlatformTeam.types'
import { PlatformTeamAPI, servicePlatformTeam } from '~/services/base'

export const actionPlatformGitCredentials =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params, context }: ActionFunctionArgs) => {
    const { aggregateId, organisationId, platformId, processId } = params

    // Get form data
    const formData = await request.formData()
    const repositoryUrl = formData.get('repositoryUrl')
    const token = formData.get('token')
    // Builds the data to send to server
    const postData = {
      repositoryUrl,
      token,
      platformId,
    }

    // Setups and send to server
    const url = PlatformTeamAPI.SubmitGitCredentials
    const req = (await servicePlatformTeam.post(
      url,
      postData,
    )) as AxiosResponse<PlatformPostResponseGitCredentials, unknown>

    // If it succeeed, just redirect to the next step
    if (req.status === 200) {
      const confirmPostData = {
        comment:
          'Generating shared infrastructure code using Terraform and Azure DevOps pipeline',
        platformId,
      }
      const confirmUrl = PlatformTeamAPI.Publish

      // Data for both success and error below
      const isBusiness = request.url.includes('business')
      const requestUrl = new URL(request.url)
      const eventId = requestUrl.searchParams.get('eventId')
      const eventType = requestUrl.searchParams.get('eventType')

      try {
        // const confirmReq = await servicePlatformTeam.post(
        //   confirmUrl,
        //   confirmPostData,
        // )

        // Call API
        await servicePlatformTeam.post(confirmUrl, confirmPostData)

        // Invalidate queries
        await queryClient.invalidateQueries({
          queryKey: queryKeyPlatformTeam(organisationId),
        })

        // After API is called, simply redirect
        if (isBusiness) {
          return eventType && eventId
            ? redirect(
                `/${organisationId}/${platformId}/business/${processId}/${aggregateId}/generate-code/${eventType}/${eventId}`,
              )
            : redirect(
                `/${organisationId}/${platformId}/business/${processId}/${aggregateId}/generate-code`,
              )
        }

        return eventType && eventId
          ? redirect(
              `/${organisationId}/${platformId}/developer/${aggregateId}/generate-code/${eventType}/${eventId}`,
            )
          : `/${organisationId}/${platformId}/developer/${aggregateId}/generate-code`

        // // If it succeeed, just redirect to the next step
        // if (confirmReq.status === 200) {
        //   const requestUrl = new URL(request.url)
        //   const { platformId, processId, aggregateId } = params

        //   // if user is coming from the business side, we want to continue to the initialise step
        //   const eventId = requestUrl.searchParams.get('initialise')
        //   const eventType = requestUrl.searchParams.get('type')
        //   if (requestUrl.pathname.includes('business')) {
        //     return redirect(
        //       `/${platformId}/business/${processId}/${aggregateId}/edit-hosting?initialise=${eventId}&type=${eventType}`,
        //     )
        //   }

        //   // Developer side
        //   if (eventId && eventType) {
        //     return redirect(
        //       `/${platformId}/developer/${aggregateId}/edit-hosting?initialise=${eventId}&type=${eventType}`,
        //     )
        //   }

        //   return redirect(`/${platformId}/developer`)
        // }
      } catch (err: any) {
        // If it doesn't succeed, we need to redirect the user to the git credentials again with some kind of error message

        return {
          error: true,
          errorPayload: err,
          errorMessage: err?.message,
        }

        const searchParams = requestUrl.searchParams.toString()
          ? `?${requestUrl.searchParams.toString()}`
          : ''

        // Business side
        if (requestUrl.pathname.includes('business')) {
          return redirect(
            `/${platformId}/business/${processId}/${aggregateId}/platform-setup/git-credentials${searchParams}`,
          )
        }

        // Developer side
        return redirect(
          `/${platformId}/developer/${aggregateId}/platform-setup/git-credentials${searchParams}`,
        )
      }
    }

    // Else, redirect to "home"
    return redirect('/')
  }
