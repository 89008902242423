import { styled } from '@mui/material'
import {
  Controls as XyControls,
  ReactFlow as XyReactFlow,
  type ReactFlowProps,
} from '@xyflow/react'

export type ReactFlowStyledProps = ReactFlowProps & {
  /** Indicates if react flow is dragging. */
  $isDragging?: boolean
  /** Indicates if the space key is pressed. */
  $isSpacePressed?: boolean
}

export const ReactFlow = styled(XyReactFlow, {
  shouldForwardProp: (prop) =>
    prop !== '$isDragging' && prop !== '$isSpacePressed',
})<ReactFlowStyledProps>(({ $isDragging, $isSpacePressed }) => {
  let cursor = 'default'
  if ($isSpacePressed) cursor = 'grab'
  if ($isDragging) cursor = 'grabbing'

  return {
    '& .react-flow__pane.draggable': {
      cursor,
    },
  }
})

export const Controls = styled(XyControls)`
  & .react-flow__controls-button {
    width: 32px;
    height: 32px;

    svg {
      max-width: 15px;
      max-height: 15px;
    }
  }
`
