import { useIsFetching } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm, type FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useLoadGitRepository } from '~/hooks/api/codeGenerationStrategy/useLoadGitRepository'
import { LoadRepositoryFormEnum } from '~/models/enums/forms/LoadRepositoryFormEnum'
import type { LoadRepositoryDataProps } from '~/models/types/components/codeGenerationStrategy/LoadRepositoryDataProps'
import { FormHook } from '~/models/types/hooks/FormHook'
import { QueryMutationHook } from '~/models/types/hooks/QueryMutationHook'
import { queryKeyStrategy } from '~/services/GenerationStrategy'

type UseLoadRepositoryDataReturn = FormHook &
  QueryMutationHook & {
    /** Handler to hide the repository form. */
    handleHideForm: () => void
    /** Handler to show the repository form. */
    handleShowForm: () => void
    /** Indicates whether the repository form should be display. */
    showForm: boolean
  }

/**
 * Hook to support the `LoadRepositoryData` component.
 */
export const useLoadRepositoryData = (
  props: LoadRepositoryDataProps,
): UseLoadRepositoryDataReturn => {
  const { dataDirectoryItems } = props

  let { generationStrategyId } = useParams()

  const [showForm, setShowForm] = useState(
    Boolean(!dataDirectoryItems?.children?.length),
  )

  // Form set up.
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FieldValues>({
    defaultValues: {
      [LoadRepositoryFormEnum.AUTHENTICATION_TOKEN]: '',
      [LoadRepositoryFormEnum.REPOSITORY_URL]: '',
    },
  })

  // Form submit mutation.
  const {
    error,
    isPending: isPendingMutation,
    isSuccess,
    mutate,
    reset: resetMutation,
  } = useLoadGitRepository({
    generationStrategyId,
  })

  // Query fetch state.
  const isFetchingStrategy = Boolean(
    useIsFetching({
      queryKey: queryKeyStrategy(generationStrategyId as string),
    }),
  )

  // Reset form state.
  useEffect(() => {
    if (!showForm) {
      resetForm?.()
      resetMutation?.()
    }
  }, [showForm])

  // Reset `showForm` state.
  useEffect(() => {
    if (isSuccess) setShowForm(false)
  }, [isSuccess])

  // Handles the `hide form`.
  const handleHideForm = () => {
    setShowForm(false)
  }

  // Handles the `show form`.
  const handleShowForm = () => {
    setShowForm(true)
  }

  // Handles the form submit.
  const handleFormSubmit = (data: FieldValues) => {
    mutate(data)
  }

  return {
    control,
    error,
    handleFormSubmit,
    handleHideForm,
    handleShowForm,
    handleSubmit,
    isPending: isPendingMutation || isFetchingStrategy,
    showForm,
  }
}
