import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { queryDevelopment } from '~/services/Development'

export const loaderDeveloperEditType =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const { platformId } = params

    // fetch the data
    const qDevelopment = queryDevelopment(platformId)
    const fetchDevelopment = await queryClient.ensureQueryData(qDevelopment)
    invariant(
      fetchDevelopment.status === 200 && fetchDevelopment.data,
      `No data for development aggregates: ${fetchDevelopment}`,
    )

    return fetchDevelopment
  }
