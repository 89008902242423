import { twMerge } from '^/tailwind.config'
import {
  useDropzone,
  type DropzoneOptions,
  type DropzoneRootProps,
} from 'react-dropzone'
import type { UploadFilesOverlayBaseProps } from '~/models/types/components/UploadFilesOverlayBaseProps'
import { UploadFilesOverlayFeedback } from './UploadFilesOverlayFeedback'

export type UploadFilesOverlayProps = UploadFilesOverlayBaseProps & {
  /** The children node. */
  children: (props: { getRootProps: () => DropzoneRootProps }) => JSX.Element
  /** The class name applied to the drop zone node. */
  className?: string
  /**
   * The draggable node position: absolute or fixed.
   * The default value is `absolute`.
   */
  position?: 'absolute' | 'fixed'
  /**
   * The props to be applied to `useDropzone` hook:
   * it will allow replace the default options, or
   * adding new ones.
   */
  useDropzoneProps?: DropzoneOptions
}

/**
 * Renders a droppable area with an overlay
 * to upload files.
 */
const UploadFilesOverlay = (props: UploadFilesOverlayProps) => {
  const {
    children,
    className,
    dropAreaActiveText,
    dropAreaRejectText,
    position = 'absolute',
    useDropzoneProps,
  } = props

  const { getRootProps, isDragActive, isDragReject } = useDropzone({
    noClick: true,
    noKeyboard: true,
    ...useDropzoneProps,
  })

  return (
    <>
      <div
        className={twMerge(
          'pointer-events-none inset-0 z-[999] flex h-full w-full items-center justify-center bg-transparent',
          isDragActive && 'bg-[rgba(0,0,0,0.85)]',
          position,
          className,
        )}
        draggable
        {...getRootProps()}
      >
        <UploadFilesOverlayFeedback
          dropAreaRejectText={dropAreaRejectText}
          dropAreaActiveText={dropAreaActiveText}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        />
      </div>

      {children({
        getRootProps,
      })}
    </>
  )
}

export default UploadFilesOverlay
