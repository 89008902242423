import { useEffect, useRef } from 'react'
import { UpdateStatementBaseProps } from '../Step/Step.types'

type UseExpandedStepFieldsProps = UpdateStatementBaseProps

/**
 * Provides resources related to the
 * expanded step input fields (available when the
 * node is editable).
 */
export const useExpandedStepFields = (props: UseExpandedStepFieldsProps) => {
  const {
    data: { data },
    watch,
  } = props
  const { isEditable, statementInputRef, type: statementType } = data

  const formType = watch?.('type')

  const type = formType || statementType

  // Input/button fields refs.
  const actorFieldRef = useRef<HTMLElement>(null)
  const typeFieldButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    // Callback handler for keyboard TAB key press.
    const handleTabKey = (e: KeyboardEvent) => {
      if (e.key !== 'Tab') return

      // Current focused element.
      const currFocused = document.activeElement

      // TAB vs SHIFT + TAB.
      if (e.shiftKey) {
        handleShiftTab(currFocused, e)
      } else {
        handleTab(currFocused, e)
      }
    }

    // Handler for SHIFT + TAB.
    const handleShiftTab = (currFocused: Element | null, e: KeyboardEvent) => {
      if (!statementInputRef || !statementInputRef.current) return

      switch (type) {
        case 'Command':
          // When the current focus is the `actor field`, focus on the statement input next.
          if (currFocused === actorFieldRef.current) {
            e.preventDefault()
            statementInputRef.current.focus()
          }
          break
        case 'Reaction':
          // When the current focus is the `type button field`, focus on the statement input next.
          if (currFocused === typeFieldButtonRef.current) {
            e.preventDefault()
            statementInputRef.current.focus()
          }
          break
      }
    }

    // Handler for TAB.
    const handleTab = (currFocused: Element | null, e: KeyboardEvent) => {
      if (!statementInputRef || currFocused !== statementInputRef.current)
        return

      // Focus on the statement input next.
      switch (type) {
        case 'Command':
          if (actorFieldRef.current) {
            e.preventDefault()
            actorFieldRef.current.focus()
          }
          break
        case 'Reaction':
          if (typeFieldButtonRef.current) {
            e.preventDefault()
            typeFieldButtonRef.current.focus()
          }
          break
      }
    }

    if (isEditable) document.addEventListener('keydown', handleTabKey)

    return () => document.removeEventListener('keydown', handleTabKey)
  }, [isEditable, type])

  return { actorFieldRef, typeFieldButtonRef }
}
