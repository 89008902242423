import { Stack, styled } from '@mui/material'

export const AggregateInfoHeaderButton = styled(Stack)(({ theme }) =>
  theme.unstable_sx({
    position: 'relative',
    border: '1px solid transparent',
    cursor: 'pointer',
    '.MuiSvgIcon-root': {
      position: 'absolute',
      right: -4,
      top: -4,
      fontSize: '1rem',
      visibility: 'hidden',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      right: -10,
      top: -10,
      width: 30,
      height: 30,
      bgcolor: 'background.default',
      borderRadius: '50%',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'primary.main',
      visibility: 'hidden',
    },
    '&:hover': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'primary.main',
      borderRadius: 2,
      bgcolor: 'background.highlight',

      '.MuiSvgIcon-root, &::before': {
        visibility: 'visible',
      },
    },
  }),
)
