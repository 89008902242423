import type { QueryClient } from '@tanstack/react-query'
import { queryKeyStrategy } from '~/services/GenerationStrategy'

/**
 * Invalidate queries from "Code Generation Strategy" (AI Blueprints) features.
 * @param queryClient The 'react query' client.
 * @param queryKey The query key to be invalidated.
 */
export const invalidateQueries = (
  queryClient?: QueryClient,
  queryKey?: string,
): void => {
  if (queryKey)
    queryClient?.invalidateQueries({
      queryKey: queryKeyStrategy(queryKey),
    })
}
