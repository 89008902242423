import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Box, Fade, IconButton, Tooltip } from '@mui/material'
import { Handle, Position } from '@xyflow/react'
import { twMerge } from '^/tailwind.config'
import type { MouseEvent } from 'react'
import { useFieldArray } from 'react-hook-form'
import { Column } from '~/components/Column'
import { ReactionIcon } from '~/components/Icons/ReactionIcon'
import { StatementEditionBoxFormEnum as FormEnum } from '~/models/enums/forms/StatementEditionBoxFormEnum'
import type { StatementsDiagramBaseProps } from '~/models/types/components/processInfo/StatementsDiagramBaseProps'
import type { RaisedDomainEvent } from '~/services/Process.types'
import { generateUniqueIdNumber } from '~/utils/api/generateUniqueIdNumber'
import type { UpdateStatementBaseProps } from '../Step/Step.types'
import { ControlledTextField } from './ControlledTextField'

const HANDLE_BORDER = 2
const HANDLE_CONNECT_CLASSES = 'absolute transform-none border-0 opacity-0'

type ContentRaisedDomainEventProps = UpdateStatementBaseProps &
  Pick<StatementsDiagramBaseProps, 'onAddReaction'> & {
    /** Callback handler for the `raised domain event` mouse down event. */
    onRaisedDomainEventMouseDown: () => void
  }

type DomainEventType = { id: number; name: string }

/**
 * The `raised domain event` content.
 */
export const ContentRaisedDomainEvent = (
  props: ContentRaisedDomainEventProps,
) => {
  const {
    control,
    data: { data, id },
    onAddReaction,
    onRaisedDomainEventMouseDown,
    onSubmit,
  } = props

  const { isEditable, isFetching, raisedDomainEvents } = data || {}

  // Hooks.
  const {
    fields: raisedDomainEventFields,
    append: raisedDomainEventAppend,
    remove: raisedDomainEventRemove,
  } = useFieldArray({
    control,
    name: FormEnum.RAISED_DOMAIN_EVENTS,
  })

  // Methods.
  const handleAddRaisedDomainEvent = () => {
    raisedDomainEventAppend({
      id: generateUniqueIdNumber(),
      name: '',
    } as RaisedDomainEvent)
  }

  const handleRemoveRaisedDomainEventArrayItem = (
    e: MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    e.stopPropagation()
    raisedDomainEventRemove(index)
    onSubmit?.()
  }

  const handleAddReactionClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onAddReaction?.(`When ${name}, `, id)
  }

  return (
    <Column className={twMerge('w-full gap-2', isEditable && 'gap-1')}>
      <div className="flex items-center gap-2">
        <span className="text-10 uppercase text-black">OUTPUT</span>

        <Fade in={isEditable}>
          <IconButton
            aria-label="add raised domain event"
            className="p-0 text-sm transition-all hover:text-primary"
            disabled={isFetching}
            onClick={handleAddRaisedDomainEvent}
          >
            <AddCircleIcon fontSize="inherit" />
          </IconButton>
        </Fade>
      </div>

      {isEditable ? (
        raisedDomainEventFields?.map((field, index) => (
          <div className="flex w-full items-center" key={field.id}>
            <ControlledTextField
              className="w-full"
              control={control}
              inputProps={{
                className: 'p-2 py-1 text-sm',
              }}
              name={`${FormEnum.RAISED_DOMAIN_EVENTS}.${index}.name`}
              onBlur={onSubmit}
            />

            <IconButton
              disabled={isFetching}
              onClick={(e) => handleRemoveRaisedDomainEventArrayItem(e, index)}
              size="small"
            >
              <DeleteForeverIcon fontSize="small" />
            </IconButton>
          </div>
        ))
      ) : (
        <ul className="m-0 flex w-full list-none flex-col gap-3 p-0">
          {raisedDomainEvents?.map(
            ({ id: identity, name }: DomainEventType) => (
              <li
                key={identity + name}
                className="group relative w-full items-center overflow-visible rounded-l-full bg-event-light py-[2px] pl-2 pr-4 text-xs text-event"
                onMouseDown={(e) => {
                  e.stopPropagation()
                  onRaisedDomainEventMouseDown()
                }}
              >
                <span className="inline-block w-11/12 break-words">{name}</span>

                {/*
                 * Handle to allow the `connect` action from a `Reaction` to
                 * a `Command` - this will turn the command into a reaction.
                 * The `target` handle is part of the `ExpandedStep` component.
                 */}
                <Handle
                  className={twMerge(
                    HANDLE_CONNECT_CLASSES,
                    'left-0 top-0 h-[100%] w-[100%]',
                  )}
                  id={String(identity)}
                  position={Position.Right}
                  type="source"
                />

                <Handle
                  className={`right-0 flex h-6 w-6 items-center justify-center border-solid border-event bg-white`}
                  id={String(identity)}
                  isConnectable={false}
                  isConnectableStart={false}
                  position={Position.Right}
                  style={{
                    borderWidth: `${HANDLE_BORDER}px`,
                  }}
                  type="source"
                >
                  <ReactionIcon className="scale-75" viewBox="0 0 22 22" />

                  {/* Handle for connect command/reaction. */}
                  <Handle
                    className={HANDLE_CONNECT_CLASSES}
                    id={String(identity)}
                    position={Position.Right}
                    style={{
                      top: `-${HANDLE_BORDER}px`,
                      right: `-${HANDLE_BORDER}px`,
                      bottom: `-${HANDLE_BORDER}px`,
                      left: `-${HANDLE_BORDER}px`,
                      width: `calc(100% + ${2 * HANDLE_BORDER}px)`,
                      height: `calc(100% + ${2 * HANDLE_BORDER}px)`,
                    }}
                    type="source"
                  />
                </Handle>

                <Box className="absolute left-[104%] top-[50%] hidden -translate-y-[50%] text-blue-900 transition group-hover:block group-hover:text-purple-600">
                  <Tooltip arrow placement="right" title={`When ${name}, `}>
                    <IconButton
                      aria-label="Add Reaction"
                      onClick={handleAddReactionClick}
                    >
                      <AddToPhotosOutlinedIcon
                        className="scale-75 text-blue-900"
                        sx={{ fontSize: 25 }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </li>
            ),
          )}
        </ul>
      )}
    </Column>
  )
}
