import { Text } from '~/components/Text'
import type { RenderTreeItemProps } from '~/models/types/components/codeGenerationStrategy/RenderTreeItemProps'
import type {
  FolderOrFileStructure,
  PropertyBinding,
  ValuePropertyBinding,
} from '~/services/GenerationStrategy.types'
import type { RenderLabelProps } from '../RenderLabel/RenderLabel'
import ValueBindingTreeItem from '../ValueBindingTreeItem/ValueBindingTreeItem'

/**
 * Provides the props for `RenderLabel` component
 * part for the `RenderTreeItemLabel` component.
 */
export const getRenderLabelProps = (
  props: RenderTreeItemProps,
): RenderLabelProps => {
  const {
    dataDomainDictionary,
    fileId,
    isCollectionItem,
    node = {} as FolderOrFileStructure | PropertyBinding,
    onClickOpenMappingConditionDialog,
    onDeletePropertyBindingClick,
    selectedDomainDictionaryItem,
  } = props

  let renderLabelProps: RenderLabelProps = {
    nodeId: node.id,
    node,
    isCollectionItem,
    onDeleteNodeClick: onDeletePropertyBindingClick,
    onOpenMappingConditionClick: onClickOpenMappingConditionDialog,
    children:
      node.propertyName === 'Content' ? (
        <div className="py-2">
          <Text className="font-medium">{node.propertyName}</Text>
          <Text className="text-sm">
            Saved automatically upon leaving the text field
          </Text>
        </div>
      ) : (
        <div className="py-2">{node.propertyName}</div>
      ),
  }

  if ((node as PropertyBinding)._t === 'ValuePropertyBinding') {
    renderLabelProps = {
      ...renderLabelProps,
      fileId,
      children: (
        <ValueBindingTreeItem
          initialValue={(node as PropertyBinding).value as string}
          node={node as ValuePropertyBinding}
          fileId={fileId}
          dataDomainDictionary={dataDomainDictionary}
          selectedDomainDictionaryItem={selectedDomainDictionaryItem}
        />
      ),
    }
  }

  if ((node as PropertyBinding)._t === 'CollectionPropertyBinding') {
    renderLabelProps = {
      ...renderLabelProps,
      isCollection: true,
      isCollectionItem: undefined,
      fileId,
      children: <div className="py-2">{node.propertyName}</div>,
    }
  }

  return renderLabelProps
}
