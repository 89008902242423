import { Box, Typography } from '@mui/material'
import { Handle, Position, type Node, type NodeProps } from '@xyflow/react'
import type { NodeStatement } from '~/services/Process.types'
import { nodeHeight, nodeWidth } from '../config'

export function ServiceNode(props: NodeProps<Node<NodeStatement>>) {
  const { data } = props

  return (
    <Box
      className="flex cursor-pointer flex-col rounded-[6px] border-[1px] border-solid border-[#d3d3d3] bg-white"
      minWidth={nodeWidth}
      minHeight={nodeHeight}
    >
      <Typography
        borderBottom={'1px solid #d3d3d3'}
        fontWeight={'bold'}
        textAlign={'center'}
        color={'#020890'}
      >
        {data.context}
      </Typography>

      {data.content}

      <Handle
        type="target"
        position={Position.Left}
        style={{
          left: '2px',
          visibility: 'hidden',
        }}
      />

      <Handle
        type="source"
        position={Position.Right}
        style={{
          right: '2px',
          visibility: 'hidden',
        }}
      />
    </Box>
  )
}
