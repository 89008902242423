import ReadMoreIcon from '@mui/icons-material/ReadMore'
import { Chip, List, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useGetSubscriptionPlanCapabilities } from '~/hooks/useGetSubscriptionPlanCapabilities'
import { ListAttributes } from '~/pages/developer/components/ListAttributes'
import { MenuMore } from '~/pages/developer/components/MenuMore'
import {
  ListItemForDeveloper,
  ListItemHeader,
} from '~/pages/developer/components/styled/ListItem'
import { AggregateType } from '~/services/Development.types'

type ListTypesProps = {
  types: AggregateType[]
}
export function ListTypes({ types }: ListTypesProps) {
  const { codeGeneration: isCodeGenerationAllowed } =
    useGetSubscriptionPlanCapabilities()

  return (
    <>
      {types.length ? (
        <Stack mx={1}>
          <List>
            {types.map((type, index) => (
              <ListItemForDeveloper key={type.name + index.toString()}>
                <Stack sx={{ flexGrow: 1 }} gap={1}>
                  <ListItemHeader>
                    <Stack
                      direction="row"
                      sx={{ alignItems: 'center' }}
                      gap={1}
                    >
                      <ReadMoreIcon fontSize="small" />
                      <Typography>
                        <strong>{type.name}</strong>
                      </Typography>
                    </Stack>
                    <Stack gap={1} direction="row" alignItems="center">
                      {isCodeGenerationAllowed && (
                        <Chip
                          label={type.state.name}
                          color={
                            type.state.name === 'Published'
                              ? 'success'
                              : 'warning'
                          }
                          size="small"
                          variant="outlined"
                        />
                      )}
                      <MenuMore
                        linkPublish={`generate-code/type/${type.identity}`}
                        linkEdit={`edit-type/${type.identity}`}
                        linkDelete={
                          type.state.name === 'Published'
                            ? undefined
                            : `delete-type/${type.identity}`
                        }
                        type="Type"
                        eventId={type.identity}
                      />
                    </Stack>
                  </ListItemHeader>
                  <ListAttributes attributes={type.attributes} />
                </Stack>
              </ListItemForDeveloper>
            ))}
          </List>
        </Stack>
      ) : null}
    </>
  )
}
