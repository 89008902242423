import { twMerge } from '^/tailwind.config'
import React from 'react'
const DEFAULT_TEXT_CLASSNAMES = 'm-0 text-primary'

type TextComponentProps = React.ComponentPropsWithoutRef<'p'> & {
  className?: string
}
function TextComponent(
  props: TextComponentProps,
  ref: React.Ref<HTMLParagraphElement>,
) {
  const mergedClassName = twMerge(DEFAULT_TEXT_CLASSNAMES, props.className)

  return (
    <p ref={ref} {...props} className={mergedClassName}>
      {props.children}
    </p>
  )
}

export const Text = React.forwardRef<HTMLParagraphElement, TextComponentProps>(
  TextComponent,
)
