import type { FileType } from '~/models/types/components/files/FileType'
import {
  EXCLUDE_PATTERNS,
  INCLUDE_EXTENSIONS,
} from './filesValidator.constants'

/**
 * Validates the file:
 * The file against the list of `include extensions`
 * and `exclude patterns`.
 * @param file The current file to be validated.
 * @param includeExtensions The list of include extensions (acceptable extensions).
 * @param excludePatterns The list of exclude patterns.
 */
export const filesValidator = (
  file: FileType,
  includeExtensions = INCLUDE_EXTENSIONS,
  excludePatterns = EXCLUDE_PATTERNS,
) => {
  const fileName = file.name
  const filePath = file.path
  const fileFullName = filePath || fileName

  // Convert fileFullName to lowercase.
  const fileFullNameLower = fileFullName?.toLowerCase()

  // Check for acceptable extensions.
  const isAcceptable = includeExtensions.find((extension: string) =>
    fileFullNameLower?.endsWith(extension?.toLowerCase()),
  )

  // Check for restricted patterns.
  const isRestricted = excludePatterns.find((pattern: string) =>
    fileFullNameLower?.includes(pattern?.toLowerCase()),
  )

  if (isRestricted || !isAcceptable)
    return {
      code: 'restricted-file-type',
      message: `This file type is not allowed.`,
    }

  return null
}
