import type { SnackbarProps } from '@mui/material'
import type { UseMutationResult } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import type { FileRejection } from 'react-dropzone'
import { useAppSnackbarContext } from '~/hooks/contexts/useAppSnackbarContext'
import type { FileType } from '~/models/types/components/files/FileType'
import {
  UploadFilesSnackbar,
  type UploadFilesSnackbarProps,
} from './UploadFilesSnackbar'

export type UseUploadFilesSnackbarProps = Pick<
  UseMutationResult,
  'isPending' | 'isSuccess' | 'reset'
> &
  Pick<UploadFilesSnackbarProps, 'isProcessingFiles'> & {
    /** Any error message (eg. API error). */
    error?: {}
  }

type UseUploadFilesSnackbarReturn = {
  /**
   * Callback handler for rejected files from validation.
   * It receives an array of `FileRejection`.
   */
  handleRejected: (fileRejections: FileRejection[]) => void
}

/**
 * Hook to support upload files snackbar feature:
 * It will handle the proper snackbar content
 * to be open depending on the state.
 */
export const useUploadFilesSnackbar = (
  props: UseUploadFilesSnackbarProps,
): UseUploadFilesSnackbarReturn => {
  const { error, isPending, isProcessingFiles, isSuccess, reset } = props

  // States.
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>()

  // Constants.
  const snackbarProps = {
    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    open: true,
  } as SnackbarProps

  // Snackbar hook.
  const { closeAppSnackbar, openAppSnackbar } = useAppSnackbarContext()

  // Close snackbar when component unmounts.
  useEffect(
    () => () => {
      closeAppSnackbar?.(undefined, 'timeout')
    },
    [],
  )

  // Set rejected files state.
  const handleRejected = (fileRejections: FileRejection[]) => {
    setRejectedFiles(fileRejections)
  }

  // Manage snackbar.
  useEffect(() => {
    // Open error snackbar.
    if (!!error) {
      // Clean `rejected files` because API error snackbar
      // takes priority.
      if (!!rejectedFiles?.length) setRejectedFiles(undefined)

      return openAppSnackbar?.({
        alertProps: {
          children: error as string,
          severity: 'error',
        },
        snackbarProps: {
          ...snackbarProps,
          autoHideDuration: 3000,
          onClose: (e, r) => {
            reset?.()
            closeAppSnackbar?.(e, r)
          },
        },
      })
    }

    // Open `is pending` / `is processing files` snackbar.
    if (isPending || isProcessingFiles) {
      return openAppSnackbar?.({
        alertProps: { className: 'hidden' },
        snackbarProps: {
          ...snackbarProps,
          children: (
            <UploadFilesSnackbar
              isPending={isPending}
              isProcessingFiles={isProcessingFiles}
              onCloseClick={(e) => {
                reset?.()
                closeAppSnackbar?.(e, 'timeout')
              }}
            />
          ),
        },
      })
    }

    // Close snackbar when not `processing files`, `is success` and no rejected files.
    if (!isProcessingFiles && isSuccess && !rejectedFiles?.length) {
      return closeAppSnackbar?.(undefined, 'timeout')
    }

    // Open snackbar with rejected files.
    if (!error && !isPending && !isProcessingFiles && !!rejectedFiles?.length) {
      return openAppSnackbar?.({
        alertProps: { className: 'hidden' },
        snackbarProps: {
          ...snackbarProps,
          children: (
            <UploadFilesSnackbar
              onCloseClick={(e) => {
                reset?.()
                closeAppSnackbar?.(e, 'timeout')
                setRejectedFiles(undefined)
              }}
              rejectedFiles={rejectedFiles.map(
                ({ file }: FileRejection) =>
                  (file as FileType).path || file.name,
              )}
            />
          ),
        },
      })
    }
  }, [
    error,
    isPending,
    isProcessingFiles,
    isSuccess,
    rejectedFiles,
    setRejectedFiles,
  ])

  return {
    handleRejected,
  }
}
