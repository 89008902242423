import { useQuery } from '@tanstack/react-query'
import { useLoaderData } from 'react-router-dom'
import { BusinessInitialData } from '~/pages/business'
import { queryOrganisation } from '~/services/Discovery'
import { FREE_FEATURES_USAGE } from '~/services/Discovery.types'
import { useGetUserEmail } from './useGetAccount'
import { useGetUserId } from './useGetUserId'

export const useSubscriptionUsage = ({
  featureType,
}: {
  featureType: keyof typeof FREE_FEATURES_USAGE
}) => {
  const { userId } = useGetUserId()
  const { email } = useGetUserEmail()
  const loaderData = useLoaderData() as BusinessInitialData

  const { data: dataOrganisation } = useQuery({
    ...queryOrganisation(userId, email),
    initialData: loaderData?.organisation,
  })

  if (!dataOrganisation?.data?.subscriptionPlan) {
    return {}
  }

  const remainingUsage =
    dataOrganisation?.data?.subscriptionPlan?.[
      `${FREE_FEATURES_USAGE[featureType]}Remaining`
    ]
  const remainingUsageTotal =
    dataOrganisation?.data?.subscriptionPlan?.[
      `${FREE_FEATURES_USAGE[featureType]}Limit`
    ]

  return {
    remainingUsage,
    remainingUsageTotal,
  }
}
