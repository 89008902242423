import { useState, type PropsWithChildren } from 'react'
import AppSnackbar from '~/components/AppSnackbar/AppSnackbar'
import SelectedTextSectionsContext from '~/contexts/SelectedTextSectionsContext'
import type { SelectedTextSectionsType } from '~/models/types/components/codeGenerationStrategy/ManageNodeSelection/SelectedTextSectionsType'

/**
 * Provider for the Selected Text Sections context.
 */
const SelectedTextSectionsContextProvider = (props: PropsWithChildren) => {
  const { children } = props

  // Editable Node state.
  const [editableNode, setEditableNode] = useState<GUID | null>(null)

  // Is Merge Mode state.
  const [isMergeMode, setIsMergeMode] = useState<boolean>(false)

  // Is Merge Mode Activate By Keyboard state.
  const [isMergeModeActivateByKeyboard, setIsMergeModeActivateByKeyboard] =
    useState<boolean>(false)

  // Selected Text Sections state.
  const [selectedTextSections, setSelectedTextSections] =
    useState<SelectedTextSectionsType | null>(null)

  return (
    <SelectedTextSectionsContext.Provider
      value={{
        editableNode,
        setEditableNode,
        isMergeMode,
        setIsMergeMode,
        isMergeModeActivateByKeyboard,
        setIsMergeModeActivateByKeyboard,
        selectedTextSections,
        setSelectedTextSections,
      }}
    >
      {children}

      <AppSnackbar />
    </SelectedTextSectionsContext.Provider>
  )
}

export default SelectedTextSectionsContextProvider
