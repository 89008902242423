import { useQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

export type UseGetDomainReadModelTypesProps = {
  /** The aggregate ID. */
  aggregateId?: GUID
}

/**
 * Hook that implements react query
 * to get the domain read model types.
 */
export const useGetDomainReadModelTypes = (
  props: UseGetDomainReadModelTypesProps,
) => {
  const { aggregateId } = props

  const {
    error: queryError,
    isError,
    ...restQuery
  } = useQuery({
    enabled: !!aggregateId,
    queryKey: [QueryKeysEnum.DOMAIN_READ_MODEL_TYPES, aggregateId],
    queryFn: async () => {
      invariant(aggregateId, ERROR_MESSAGES.aggregateId)

      const url = DevelopmentAggregateAPI.getDomainReadModelTypes(aggregateId)
      return serviceDevelopmentAggregate.get(url)
    },
    select: (response) => response.data,
  })

  const error = getQueryMutationError(isError, queryError as ErrorResponseType)

  return { ...restQuery, error, isError }
}
