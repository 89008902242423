import { BaseEdge, getSmoothStepPath, type EdgeProps } from '@xyflow/react'

export function CustomEdge(props: EdgeProps) {
  const {
    markerStart,
    markerEnd,
    sourcePosition,
    sourceX,
    sourceY,
    style = {},
    targetPosition,
    targetX,
    targetY,
  } = props

  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <BaseEdge
      path={edgePath}
      markerEnd={markerEnd}
      markerStart={markerStart}
      style={{ stroke: '#878ACB', ...style }}
    />
  )
}
