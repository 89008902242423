import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { queryOrganisation } from '~/services/Discovery'
import { ResponseDiscoveryOrganisation } from '~/services/Discovery.types'
import { useGetUserEmail } from './useGetAccount'
import { useGetUserId } from './useGetUserId'

type HookResponse = Partial<ResponseDiscoveryOrganisation>

type UseGetProcess = () => HookResponse

export const useGetOrganisation: UseGetProcess = () => {
  const { userId } = useGetUserId()
  const { email } = useGetUserEmail()

  const { data: organisation } = useQuery<
    AxiosResponse<ResponseDiscoveryOrganisation | null>
  >(queryOrganisation(userId, email))

  if (organisation) {
    const { data } = organisation
    if (data) return data
  }

  return {}
}
