import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { queryDevelopment } from '~/services/Development'
import { queryOrganisation } from '~/services/Discovery'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderCommonLayout =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params }: LoaderFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    // if no user, redirect to login page
    if (!userId || !email) {
      return redirect('/')
    }

    // if no organisation, redirect to welcome page
    const qOrganisation = queryOrganisation(userId, email)
    const fetchOrganisation = await queryClient.ensureQueryData(qOrganisation)
    const organisationId = fetchOrganisation.data.identity

    const platformId = params.platformId

    if (!platformId) {
      const qPlatform = queryPlatformTeam(organisationId)
      const fetchPlatform = await queryClient.ensureQueryData(qPlatform)
      const newPlatformId = fetchPlatform.data.identity

      return redirect(`/${organisationId}/${newPlatformId}/configuration/users`)
    }

    // fetch the data from development service
    const qDevelopment = queryDevelopment(platformId)
    const fetchDevelopment = await queryClient.ensureQueryData(qDevelopment)
    invariant(
      fetchDevelopment.status === 200 && fetchDevelopment.data,
      `No data for development aggregates: ${fetchDevelopment}`,
    )

    return { organisation: fetchOrganisation }
  }
