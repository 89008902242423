export function KeyDeleteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#B6F1E6"
        d="M2 7a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7Z"
      />
      <path
        fill="#080D97"
        d="M3 6.5h18v-1H3v1Zm18.5.5v10h1V7h-1ZM21 17.5H3v1h18v-1ZM2.5 17V7h-1v10h1Zm.5.5a.5.5 0 0 1-.5-.5h-1A1.5 1.5 0 0 0 3 18.5v-1Zm18.5-.5a.5.5 0 0 1-.5.5v1a1.5 1.5 0 0 0 1.5-1.5h-1ZM21 6.5a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 21 5.5v1Zm-18-1A1.5 1.5 0 0 0 1.5 7h1a.5.5 0 0 1 .5-.5v-1ZM5.128 15V8.7h1.935c1.11 0 1.926.282 2.448.846.528.564.792 1.338.792 2.322 0 .966-.264 1.731-.792 2.295-.522.558-1.338.837-2.448.837H5.128Zm.756-.63h1.16c.613 0 1.099-.099 1.459-.297.366-.204.627-.492.783-.864.156-.378.234-.825.234-1.34 0-.529-.078-.982-.234-1.36a1.733 1.733 0 0 0-.783-.873c-.36-.204-.846-.306-1.458-.306H5.884v5.04ZM11.395 15V8.7h3.896v.621h-3.14v2.196h2.87v.612h-2.87v2.25h3.14V15h-3.896ZM16.475 15V8.7h.756v5.697h2.934V15h-3.69Z"
      />
    </svg>
  )
}
