import { Stack } from '@mui/material'
import { CircleForIcon } from '~/pages/developer/components/styled/CircleForIcon'

type FormInputWithIconProps = {
  icon: React.ReactNode
  input: React.ReactNode
  noMargin?: boolean
}
export function FormInputWithIcon({
  icon,
  input,
  noMargin = false,
}: FormInputWithIconProps) {
  return (
    <Stack
      direction="row"
      sx={{ alignItems: 'center', mb: noMargin ? 0 : 2 }}
      gap={2}
    >
      <CircleForIcon>{icon}</CircleForIcon>
      {input}
    </Stack>
  )
}
