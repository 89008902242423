import type { RenderTreeItemProps } from '~/models/types/components/codeGenerationStrategy/RenderTreeItemProps'
import type {
  PropertyBinding,
  TextSection,
} from '~/services/GenerationStrategy.types'
import { RenderTextSections } from '../RenderTextSections/RenderTextSections'
import { RenderTreeItem } from './RenderTreeItem'

/**
 * Renders a tree item object node or all the remaining scenarios.
 */
const RenderTreeObject = (props: RenderTreeItemProps) => {
  const { node, ...rest } = props

  if (!node) return null

  const { _t: type, content, propertyBindings } = node as PropertyBinding

  // Text Property Binding.
  if (type === 'TextPropertyBinding') {
    return (
      <RenderTextSections
        fileId={rest.fileId}
        node={content as TextSection}
        nodes={content?.children}
        {...rest}
      />
    )
  }

  // All the rest.
  if (propertyBindings && Array.isArray(propertyBindings)) {
    return propertyBindings.map((item) => (
      <RenderTreeItem
        key={item.id}
        node={item satisfies PropertyBinding}
        {...rest}
      />
    ))
  }

  return null
}

export default RenderTreeObject
