import { twMerge } from '^/tailwind.config'

export type FormErrorProps = {
  /** The class name applied to the containing node. */
  className?: string
  /** The error message. */
  error?: string
}

/**
 * Form error component:
 * Renders the provided `error` with
 * a form error style.
 */
const FormError = ({ className, error }: FormErrorProps) => {
  if (!!error)
    return (
      <p
        className={twMerge(
          'mb-4 mt-0 rounded-lg bg-red-50 p-4 text-left text-sm text-red-800 dark:bg-gray-800 dark:text-red-400',
          className,
        )}
        role="alert"
      >
        <>{error}</>
      </p>
    )

  return null
}

export default FormError
