import { MoveStatementOptionsEnum as OptionsEnum } from '~/models/enums/api/MoveStatementOptionsEnum'
import type { NodeStatement } from '~/services/Process.types'
import type { UseStepReturn } from '../Step/useStep'
import { DropArea, type DropAreaProps } from './DropArea'

export type DropAreasProps = Pick<
  NodeStatement,
  'id' | 'isLocal' | 'onDropNodeIntoDropAreas'
> &
  Pick<
    UseStepReturn,
    'isAnyDragging' | 'isNodeDragging' | 'isNodeLayerDragging'
  >

/**
 * The statement drop areas:
 * These are areas where the draggable node
 * can be dropped into.
 */
export const DropAreas = (props: DropAreasProps) => {
  const {
    id,
    isAnyDragging,
    isLocal,
    isNodeDragging,
    isNodeLayerDragging,
    onDropNodeIntoDropAreas,
  } = props

  if (isLocal || isNodeDragging || isNodeLayerDragging || !isAnyDragging)
    return null

  const defaultProps = {
    id,
    onDropNodeIntoDropAreas,
  } as DropAreaProps

  return (
    <>
      <DropArea {...defaultProps} position={OptionsEnum.TOP} />

      <DropArea {...defaultProps} position={OptionsEnum.BOTTOM} />

      <DropArea {...defaultProps} position={OptionsEnum.LEFT} />

      <DropArea {...defaultProps} position={OptionsEnum.RIGHT} />
    </>
  )
}
