import { twMerge } from '^/tailwind.config'
import React from 'react'

const DEFAULT_CLASSNAMES =
  'rounded bg-[#DADBFC] px-1 text-black no-underline hover:bg-primary hover:text-white'

type ExternalLinkComponentProps = React.ComponentPropsWithoutRef<'a'> & {
  className?: string
}
export function ExternalLinkComponent(
  props: ExternalLinkComponentProps,
  ref: React.Ref<HTMLAnchorElement>,
) {
  const mergedClassName = twMerge(DEFAULT_CLASSNAMES, props.className)
  return (
    <a
      ref={ref}
      {...props}
      target="_blank"
      rel="noreferrer"
      className={mergedClassName}
    >
      {props.children}
    </a>
  )
}

export const ExternalLink = React.forwardRef<
  HTMLAnchorElement,
  ExternalLinkComponentProps
>(ExternalLinkComponent)
