import { Fade, Popper } from '@mui/material'
import { useAppPopperContext } from '~/hooks/contexts/useAppPopperContext'

/**
 * The application popper component.
 */
const AppPopper = () => {
  const { componentProps, setComponentProps } = useAppPopperContext()
  const { children, ...rest } = componentProps || {}

  /**
   * Reset the state when the transition exited.
   * To preserve the "close" transition effect, it is necessary
   * to keep all the props and only change `open` prop,
   * otherwise the popper will simply disappear.
   * This method will make sure to cleanup the popper contents
   * and make it ready for any other necessary use.
   */
  const handleTransitionExited = () => {
    setComponentProps?.({ open: false })
  }

  return (
    <Popper
      disablePortal
      modifiers={[{ name: 'flip', enabled: false }]}
      open={false}
      placement="top"
      transition
      {...rest}
    >
      {({ TransitionProps }) => (
        <Fade
          {...TransitionProps}
          onExited={handleTransitionExited}
          timeout={350}
        >
          <div>
            <>{children}</>
          </div>
        </Fade>
      )}
    </Popper>
  )
}

export default AppPopper
