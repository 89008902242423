import { ListItem, Stack, StackProps, styled } from '@mui/material'

export const ListItemForDeveloper = styled(ListItem)(({ theme }) => ({
  borderWidth: '1px',
  borderColor: `${theme.palette.mode === 'dark' ? '#51576f' : '#e1e2fa'}`,
  borderStyle: 'solid',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '2px 2px 5px rgb(19 22 36 / 60%)'
      : '2px 2px 5px rgb(225 226 250 / 70%)',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(3),
}))

export const ListItemHeader = styled((props: StackProps) => (
  <Stack direction="row" gap={2} {...props} />
))(({ theme }) =>
  theme.unstable_sx({
    justifyContent: 'space-between',
    alignItems: 'center',
    bgcolor: theme.palette.mode === 'dark' ? 'background.paper' : '#f6f6ff',
    m: -3,
    mb: 1,
    p: 1,
    pr: 0.5,
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: `${
      theme.palette.mode === 'dark' ? '#51576f' : '#e1e2fa'
    }`,
  }),
)
