import type {
  PropertyBinding,
  TextPropertyBinding,
} from '~/services/GenerationStrategy.types'

// Properties we want to customize the value from
export const PRIORITIZED_PROPERTY_NAMES = [
  'namespace',
  'filename',
  'packagename',
  'name',
  'content',
]

/**
 * It will go deep into the nodes tree to get
 * a property name to be rendered.
 * In case the current level doesn't match the condition,
 * it will go 1 level deeper.
 */
export const getDeepPropertyName = (
  node: PropertyBinding,
  index: number,
): string => {
  const nodesArr = node.propertyBindings || node.mappedValues

  // It must have `propertyBindings` or `mappedValues`.
  if (nodesArr?.length) {
    for (const property of nodesArr) {
      // The condition is:
      // - Property with `property name`;
      // - The `property name` matches one of the pre-defined property names;
      // - In case there is a `property value`.
      const condition =
        property.propertyName &&
        PRIORITIZED_PROPERTY_NAMES.includes(
          property.propertyName.toLowerCase(),
        ) &&
        property.value

      // In case the condition does not match and property has a deeper level.
      if (
        !condition &&
        (property?.mappedValues?.length || property?.propertyBindings?.length)
      ) {
        return getDeepPropertyName(property, index)
      }

      // In case the condition matches.
      if (condition) return property.value || index.toString()
    }
  }

  return index.toString()
}

/**
 * Map callback function to get customized mapped values
 * for `RenderTreeObjectOrValue`.
 * @param node The map current node.
 * @param index The map index.
 */
export const mapCustomizedMappedValues = (
  node: PropertyBinding,
  index: number,
) => {
  if (node.propertyBindings?.length && node._t === 'ObjectPropertyBinding') {
    return {
      ...node,
      propertyName: getDeepPropertyName(node, index),
    }
  }

  if (
    node._t === 'ValuePropertyBinding' ||
    (node as TextPropertyBinding)._t === 'TextPropertyBinding'
  ) {
    return {
      ...node,
      propertyName: node.propertyName || '', // Consider reusing the `getDeepPropertyName`.
    }
  }

  return null
}
