import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import { queryKeyPlatformTeam } from '~/services/PlatformTeam'
import {
  checkPlatformOnboarding,
  getPlatform,
  getUrlWithoutSetup,
  platformSetupValidations,
} from '../utils'

const STEP_NAME = 'confirm'

// This file is not used anymore
// it's being handled by Git Credentials `../git-credentials/loader.ts`

export const loaderPlatformConfirm =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: LoaderFunctionArgs) => {
    const { organisationId } = params
    const { redirectFn } = await platformSetupValidations(queryClient, pca)
    if (!organisationId) {
      return redirectFn
    }

    // Refetch query so we get the latest version always in this step
    await queryClient.invalidateQueries({
      queryKey: queryKeyPlatformTeam(organisationId),
    })
    const { status, data } = await getPlatform(queryClient, organisationId)

    const shouldRedirect = checkPlatformOnboarding(
      status,
      data.isSubmitted,
      data.identity,
      request.url,
      STEP_NAME,
      organisationId,
    )
    if (shouldRedirect) {
      return shouldRedirect
    }

    const search = new URL(request.url).search
    if (!data.gitRepositoryUrl) {
      const splitUrl = getUrlWithoutSetup(request.url)
      return redirect(`${splitUrl}/platform-setup/git-credentials${search}`)
    }

    return data
  }
