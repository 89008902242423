import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { redirect } from 'react-router-dom'
import { queryOrganisation } from '~/services/Discovery'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const loaderPlatformSetup =
  (queryClient: QueryClient, pca: PublicClientApplication) => async () => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    if (userId && email) {
      const queryOrg = queryOrganisation(userId, email)
      const dataOrg = await queryClient.ensureQueryData(queryOrg)

      if (!isEmpty(dataOrg.data)) {
        const queryPlat = queryPlatformTeam(dataOrg.data.identity)
        const dataPlat = await queryClient.ensureQueryData(queryPlat)

        if (!isEmpty(dataPlat.data)) {
          if (dataPlat.data.isSubmitted) {
            return redirect(`/${dataPlat.data.identity}/developer`)
          }
        }

        return {
          organisation: dataOrg,
          platform: dataPlat,
          organisationId: dataOrg.data.identity,
        }
      }
    }

    return {
      organisation: undefined,
      platform: undefined,
      organisationId: undefined,
    }
  }
