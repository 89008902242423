import {
  Box,
  CircularProgress,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material'
import { twMerge } from '^/tailwind.config'
import isEqual from 'lodash/isEqual'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { BadgeUsesLeft } from '~/components/BadgeUsesLeft'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { Statement } from '~/services/Process.types'

const TEXTS = [
  'dot-star is mapping your business process step by step',
  'User flows are mapped in simple natural language',
  'Each user step is added in sequence',
  'Automatic system steps are also mapped',
  'Once complete, select a step and press enter to edit',
  'Mark a step as a feature to generate a service',
  'Generate code and publish directly to your code repository once complete',
]

const EXAMPLES = [
  'example: The customer adds an item to the cart',
  'example: When an item is added to the cart, the total is recalculated',
  'example: The user searches for a flight',
  'example: The user selects an outbound flight',
  'example: When a flight is selected the booking is updated',
]

export type StatementInputProps = {
  isEditingMode: boolean
  isGeneratingProcess: boolean
  isLoading: boolean
  onHandleType: ({
    value,
    shouldDebounce,
  }: {
    value: string
    shouldDebounce?: boolean
  }) => void
  selectedStatement: Statement | null
  setIsEditingMode: (value: boolean) => void
  shouldEdit: boolean
}

export const StatementInput = forwardRef<HTMLElement, StatementInputProps>(
  (props: StatementInputProps, ref) => {
    const {
      isEditingMode,
      isGeneratingProcess,
      isLoading,
      onHandleType,
      selectedStatement,
      setIsEditingMode,
      shouldEdit,
    } = props

    // States.
    const [value, setValue] = useState<string>(
      selectedStatement?.content ||
        selectedStatement?.suggestedDescription ||
        '',
    )
    const [currentStatement, setCurrentStatement] = useState<Statement | null>(
      null,
    )

    const [currentIndex, setCurrentIndex] = useState(0)

    const [exampleIndex, setExampleIndex] = useState(0)

    // Ref.
    const inputRef = useRef<HTMLInputElement>(null)

    // Forward the internal ref to the parent component.
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement)

    // Memoized resources.
    const labelText = useMemo(() => {
      if (value) {
        return 'Step description'
      }

      if (!value) {
        return 'Describe the new step'
      }

      return 'Describe the step'
    }, [value])

    // Lifecycle.
    useEffect(() => {
      // this validation is to prevent to update the field value if the user is typing and the selected statement changes from an api call's response
      if (
        selectedStatement?.id !== currentStatement?.id ||
        (selectedStatement?.id === currentStatement?.id &&
          !isEqual(
            selectedStatement?.parsingInfo,
            currentStatement?.parsingInfo,
          ))
      ) {
        setValue(
          selectedStatement?.content ||
            selectedStatement?.suggestedDescription ||
            '',
        )
        setCurrentStatement(selectedStatement)
      } else {
        setCurrentStatement(selectedStatement)
      }
    }, [currentStatement?.id, currentStatement?.parsingInfo, selectedStatement])

    useEffect(() => {
      if (shouldEdit && isEditingMode) {
        inputRef.current?.focus()
      } else {
        inputRef.current?.blur()
      }
    }, [isEditingMode, shouldEdit])

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % TEXTS.length)
      }, 5000) // change text every 3 seconds

      return () => clearInterval(interval) // Cleanup on unmount
    }, [TEXTS.length])

    useEffect(() => {
      const interval = setInterval(() => {
        setExampleIndex((prevIndex) => (prevIndex + 1) % EXAMPLES.length)
      }, 3000) // change text every 3 seconds

      return () => clearInterval(interval) // Cleanup on unmount
    }, [EXAMPLES.length])

    if (isGeneratingProcess)
      return (
        <>
          <Typography color="#020890" sx={{ textAlign: 'center' }}>
            {TEXTS[currentIndex]}
          </Typography>

          <Box sx={{ mt: 2, width: '100%' }}>
            <LinearProgress />
          </Box>
        </>
      )

    return (
      <div className="relative flex" data-statement-id={selectedStatement?.id}>
        <TextField
          className="text flex-auto"
          disabled={!shouldEdit || isLoading}
          inputProps={{ 'aria-label': 'statement' }}
          inputRef={inputRef}
          InputProps={{
            className: twMerge('text-17 text-primary font-medium'),
            endAdornment: isLoading ? (
              <CircularProgress className="ml-2" size={'1.5rem'} />
            ) : FEATURE_TOGGLE.UPGRADE_PLAN ? (
              <div className="ab">
                <BadgeUsesLeft featureType="STEP_AUTOCOMPLETION" />
              </div>
            ) : null,
          }}
          label={labelText}
          name="statement"
          onChange={(e) => {
            setValue(e.target.value)
          }}
          onFocus={() => setIsEditingMode(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onHandleType({ value, shouldDebounce: false })
            }
          }}
          placeholder={EXAMPLES[exampleIndex]}
          type="text"
          value={value}
        />
      </div>
    )
  },
)
