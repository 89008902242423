import { useQuery } from '@tanstack/react-query'
import type { Connection, Node } from '@xyflow/react'
import { useGetProcess } from '~/hooks/useGetProcess'
import type { StatementsDiagramBaseProps } from '~/models/types/components/processInfo/StatementsDiagramBaseProps'
import { queryRaisedDomainEventsByOrganisationId } from '~/services/Process'
import type {
  NodeStatement,
  PayloadUpdateStatementParsingInfoCommand,
  RaisedDomainEvent,
} from '~/services/Process.types'

export type UseStatementsDiagramProps = StatementsDiagramBaseProps & {
  /** The react flow nodes. */
  nodes: Node<NodeStatement>[]
}

type UseStatementsDiagramReturn = {
  handleConnect: (connection: Connection) => void
}

/**
 * Hook to support the `statements diagram`.
 */
export const useStatementsDiagram = (
  props: UseStatementsDiagramProps,
): UseStatementsDiagramReturn => {
  const { nodes, updateStatement } = props

  const { identity: processId, organisationId } = useGetProcess()

  // React Query.
  const { data: allDomainEvents = [] } = useQuery({
    ...queryRaisedDomainEventsByOrganisationId(organisationId ?? ''),
    enabled: !!organisationId,
  })

  // Callback handler for connect nodes.
  const handleConnect = (connection: Connection) => {
    const {
      source: sourceId, // This is the target node ID.
      sourceHandle: sourceHandleId, // This is the domain event ID from the source node.
      target: targetId, // This is the target node ID.
    } = connection

    // If the source and target ID are the same, don't do anything.
    if (sourceId === targetId) return

    // Get the raised domain event:
    // This will be used to update the `reactingToDomainEvent` on the target.
    const raisedEvent = allDomainEvents.find(
      (event: RaisedDomainEvent) => sourceHandleId === String(event.id),
    )

    // The target node.
    const targetNode = nodes?.find((node: Node) => targetId === node.id)
    const targetDataType = targetNode?.data?.type

    // In case the target is not a reaction OR
    // the `reacting to domain event` is different from the source `raised event`.
    if (
      targetDataType !== 'Reaction' ||
      targetNode?.data.reactingToDomainEvent?.id !== raisedEvent?.id
    ) {
      const {
        action,
        actor,
        aggregate,
        boundedContext,
        raisedDomainEvents,
        reactingToDomainEvent,
        requiredDataInfo,
      } = targetNode?.data as NodeStatement

      const data = {
        processId,
        statementId: targetNode?.id,
        parsingInfo: {
          type: 'Reaction', // Turn command into a reaction.
          action,
          actor,
          aggregate,
          boundedContext,
          raisedDomainEvents,
          reactingToDomainEvent: raisedEvent ?? reactingToDomainEvent,
          requiredDataInfo,
        },
      } as unknown as PayloadUpdateStatementParsingInfoCommand

      updateStatement?.(data)
    }
  }

  return { handleConnect }
}
