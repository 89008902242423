import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import UndoIcon from '@mui/icons-material/Undo'
import { Alert, Button, TextField } from '@mui/material'
import {
  Form,
  Link,
  useActionData,
  useNavigation,
  useRouteLoaderData,
} from 'react-router-dom'

export function CodeGenerationStrategiesAdd() {
  const data = useRouteLoaderData('code-generation-strategies') as {
    organisationId: GUID
  }
  const errors = useActionData() as { error: string; key: string }[]
  const navigation = useNavigation()

  return (
    <>
      <Form className="flex gap-2" method="POST">
        <TextField
          label="Strategy Name"
          placeholder="Strategy Name"
          name="name"
          variant="outlined"
          size="small"
          className="min-w-[300px]"
        />
        <Button
          type="submit"
          variant="contained"
          size="small"
          disabled={navigation.state === 'submitting'}
          startIcon={<KeyboardReturnIcon />}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          size="small"
          component={Link}
          to="../"
          startIcon={<UndoIcon />}
        >
          Cancel
        </Button>
      </Form>
      {errors && errors.length && errors[0] ? (
        <div className="mt-4">
          <Alert severity="error">{errors[0].error}</Alert>
        </div>
      ) : null}
    </>
  )
}
