import { MoveStatementOptionsEnum as OptionsEnum } from '~/models/enums/api/MoveStatementOptionsEnum'

/**
 * Provides the proper CSS position property.
 * @param position The drop area position.
 */
export const getStylePosition = (position?: OptionsEnum) => {
  if (position === OptionsEnum.BOTTOM) return 'bottom'
  if (position === OptionsEnum.LEFT) return 'left'
  if (position === OptionsEnum.RIGHT) return 'right'
  return 'top'
}
