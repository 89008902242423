import CompressIcon from '@mui/icons-material/Compress'
import { twMerge } from '^/tailwind.config'
import { PanelResizeHandle } from 'react-resizable-panels'

export default function ResizeHandle({
  className = '',
  id,
}: {
  className?: string
  id?: string
}) {
  return (
    <PanelResizeHandle
      className={twMerge(
        'relative shrink-0 grow-0 basis-2 bg-transparent outline-none hover:bg-slate-300 data-[resize-handle-active]:bg-slate-600',
        className,
      )}
      id={id}
    >
      <div className={twMerge('absolute inset-1 rounded border transition')}>
        <CompressIcon className="invisible absolute left-[calc(50%-0.25rem)] top-[calc(50%-0.25rem)] h-2 w-2 rotate-90 text-slate-400" />
      </div>
    </PanelResizeHandle>
  )
}
