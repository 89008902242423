export function KeyLeftClickIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#B6F1E6"
        d="M12 22a6 6 0 0 1-6-6V8a6 6 0 0 1 12 0v8a6 6 0 0 1-6 6Z"
      />
      <path
        fill="#080D97"
        d="M6.5 16V8h-1v8h1Zm11-8v8h1V8h-1Zm0 8a5.5 5.5 0 0 1-5.5 5.5v1a6.5 6.5 0 0 0 6.5-6.5h-1ZM12 2.5A5.5 5.5 0 0 1 17.5 8h1A6.5 6.5 0 0 0 12 1.5v1ZM6.5 8A5.5 5.5 0 0 1 12 2.5v-1A6.5 6.5 0 0 0 5.5 8h1Zm-1 8a6.5 6.5 0 0 0 6.5 6.5v-1A5.5 5.5 0 0 1 6.5 16h-1Z"
      />
      <path stroke="#080D97" d="M6 10.5h12" />
      <path fill="#080D97" d="M6.5 10.5C5 4 10 2 12 2.5v8H6.5Z" />
    </svg>
  )
}
