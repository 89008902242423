import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { HelpIcon } from '~/components/Icons/HelpIcon'
import { KeyBackspaceIcon } from '~/components/Icons/KeyBackspaceIcon'
import { KeyControlIcon } from '~/components/Icons/KeyControlIcon'
import { KeyDeleteIcon } from '~/components/Icons/KeyDeleteIcon'
import { KeyDownArrowIcon } from '~/components/Icons/KeyDownArrowIcon'
import { KeyEnterIcon } from '~/components/Icons/KeyEnterIcon'
import { KeyInsertIcon } from '~/components/Icons/KeyInsertIcon'
import { KeyLeftArrowIcon } from '~/components/Icons/KeyLeftArrowIcon'
import { KeyLeftClickIcon } from '~/components/Icons/KeyLeftClickIcon'
import { KeyRightArrowIcon } from '~/components/Icons/KeyRightArrowIcon'
import { KeyTopArrowIcon } from '~/components/Icons/KeyTopArrowIcon'
import { Key_S_Icon } from '~/components/Icons/Key_S_Icon'
import { ExplanationDialogContent } from '../../../ExplanationDialog/ExplanationDialogContent'

export function HelperButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Tooltip title="Help">
        <IconButton
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          data-is-opened={open}
          className="data-[is-opened=true]:bg-secondary-300 data-[is-opened=true]:bg-opacity-40"
        >
          <HelpIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            maxWidth: 450,
            overflow: 'visible',
            filter: 'drop-shadow(3px 3px 3px rgb(24 27 101 / 10%))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 10,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disablePortal
      >
        <Stack sx={{ gap: 1, py: 1, px: 2 }}>
          <section className="mb-4 w-full">
            <ExplanationDialogContent />
          </section>
          <Typography
            variant="subtitle2"
            sx={{ color: '#76779C', fontWeight: 300 }}
          >
            Keyboard Shortcuts
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              borderBottom: '1px solid #fff',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: '500' }}>
              Navigate / Create New Step{' '}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'center',
                alignItems: 'center',
                background: '#F8F8FF',
                padding: '4px 6px 4px 10px',
                borderRadius: '4px',
                border: '1px solid',
              }}
            >
              <Typography sx={{ fontSize: '14px' }}>Press </Typography>
              <KeyLeftArrowIcon />
              <KeyTopArrowIcon />
              <KeyRightArrowIcon />
              <KeyDownArrowIcon />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              borderBottom: '1px solid #fff',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: '500' }}>
              Enter Edit Mode{' '}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'center',
                alignItems: 'center',
                background: '#F8F8FF',
                padding: '4px 6px 4px 10px',
                borderRadius: '4px',
                border: '1px solid',
              }}
            >
              <Typography sx={{ fontSize: '14px' }}>Press </Typography>
              <KeyEnterIcon />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              borderBottom: '1px solid #fff',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: '500' }}>
              Delete Step{' '}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'center',
                alignItems: 'center',
                background: '#F8F8FF',
                padding: '4px 6px 4px 10px',
                borderRadius: '4px',
                border: '1px solid',
              }}
            >
              <Typography sx={{ fontSize: '14px' }}>Press </Typography>
              <KeyDeleteIcon /> or <KeyBackspaceIcon />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              borderBottom: '1px solid #fff',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: '500' }}>
              Insert Step{' '}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'center',
                alignItems: 'center',
                background: '#F8F8FF',
                padding: '4px 6px 4px 10px',
                borderRadius: '4px',
                border: '1px solid',
              }}
            >
              <Typography sx={{ fontSize: '14px' }}>Press </Typography>
              <KeyInsertIcon />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              borderBottom: '1px solid #fff',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: '500' }}>
              Select Multiple Steps{' '}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'center',
                alignItems: 'center',
                background: '#F8F8FF',
                padding: '4px 6px 4px 10px',
                borderRadius: '4px',
                border: '1px solid',
              }}
            >
              <Typography>Hold </Typography>
              <KeyControlIcon /> + Press <KeyLeftClickIcon />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              borderBottom: '1px solid #fff',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: '500' }}>
              Swap 2 Selected Steps{' '}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'center',
                alignItems: 'center',
                background: '#F8F8FF',
                padding: '4px 6px 4px 10px',
                borderRadius: '4px',
                border: '1px solid',
              }}
            >
              <Typography>Press </Typography>
              <Key_S_Icon />
            </Box>
          </Box>
        </Stack>
      </Menu>
    </>
  )
}
