import { v4 as uuidv4 } from 'uuid'

// Maximum value for the generated number.
const MAX_NUMBER = 2147483647

/**
 * Generates a unique number based on a UUID.
 * The number is always less than MAX_NUMBER.
 */
export const generateUniqueIdNumber = () => {
  const uuid = uuidv4()

  // Convert the UUID to a number.
  const hash = Array.from(uuid).reduce((acc, char) => {
    // Multiply by 31 (prime number) and add the char code.
    // Use modulo to keep the result within MAX_NUMBER.
    return (acc * 31 + char.charCodeAt(0)) % MAX_NUMBER
  }, 0)

  return hash
}
