/**
 * Error messages related to the "Code Generation Strategy" (AI Blueprint).
 */
export const ERROR_MESSAGES = {
  aggregateId: 'Aggregate ID is required',
  fileId: 'File ID is required.',
  generationStrategyId: 'Generation Strategy ID is required.',
  propertyBindingId: 'Property Binding ID is required.',

  // Mark a step as feature.
  eventId: 'Event ID is required.',
  processId: 'Process ID is required.',

  // Load git repository.
  authenticationToken: 'Authentication Token is required.',
  repositoryUrl: 'Repository URL is required.',

  // Delete file path.
  filePath: 'The file path is required.',

  // Submit value property binding.
  value: 'The value is required.',

  // Generic error.
  generic:
    'Something went wrong, please try again later or contact IT support.',
}
